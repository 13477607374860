import React, { useEffect, useState } from "react";
import "./mobilemenupopup.scss";
import { NavLink } from "react-router-dom";
import MenuCloseIcon from "assets/images/mobile/menu-close.svg";
import Tawuniya_Logo_full from "../../../assets/images/mobile/TawuniyaLogo.png";
import SearchIcon from "assets/images/mobile/search.svg";
import UserIcon from "assets/images/mobile/User_Icon.svg";
import Avatar_Active from "assets/images/mobile/Avatar_Active.svg";
import CloseDark from "assets/images/mobile/close_dark.png";
import LoginPageMobile from "component/Auth/LoginPageMobile";
import NormalPopup from "../NormalPopup";
import SearchBoxMobile from "./SearchBoxMobile";
import { NormalSearch } from "../NormalSearch";
import { useTranslation } from "react-i18next";
import arrow_blue from "assets/images/mobile/arrow_blue.svg";
import arrow_small_blue from "assets/images/mobile/arrow_small_blue.svg";
import policies from "assets/images/mobile/loggedInMenuIcons/policies.svg";
import approval from "assets/images/mobile/loggedInMenuIcons/approval.svg";
import claims from "assets/images/mobile/loggedInMenuIcons/claims.svg";
import logout from "assets/images/mobile/loggedInMenuIcons/logout.svg";
import profile from "assets/images/mobile/loggedInMenuIcons/profile.svg";
import support from "assets/images/mobile/loggedInMenuIcons/support.svg";
import { useDispatch, useSelector } from "react-redux";
import { logoutEvent } from "action/LoginResponse";
import Vitality from "assets/images/mobile/Tawuniya_Vitality.svg";
import ithra from "assets/images/mobile/ithra.svg";
import drive from "assets/images/mobile/drive.svg";
import blue_arrow_left from "assets/images/mobile/loyaltyMenu/blue_arrow_left.svg";
import BottomPopup from "component/common/MobileReuseable/BottomPopup";
import ProductsAndServices from "component/CustomerService/AllServiceMobile/ProductsAndServices";
import { staticSearchData } from "component/common/MockData";
import ServicesOffered from "../MobileReuseable/ServicesOffered";
import MobileLanguageModal from "./MobileLanguageModal";
import LoyalityProgramsCard from "assets/images/mobile/LoyaltyPrograms/LoyalityProgramsCards.png";
import LoyalityProgramsCard1 from "assets/images/mobile/LoyaltyPrograms/LoyalityProgramsCards1.png";
import LoyalityProgramsCard2 from "assets/images/mobile/LoyaltyPrograms/LoyalityProgramsCards2.png";
import LoyalityProgramsCard5 from "assets/images/mobile/LoyaltyPrograms/LoyalityProgramsCards5.png";
import ithra_prime from "assets/images/mobile/ithra_prime.svg";
import periodicInspection from "assets/svg/icons/all-services/periodic-inspection.svg";
import carAccident from "assets/images/mobile/carAccident.svg";
import roadAssist from "assets/images/mobile/roadAssist.svg";
import asstAmerica from "assets/images/mobile/asstAmerica.svg";
import refilMedi from "assets/images/mobile/refilMedi.svg";
import medReim from "assets/images/mobile/medReim.svg";
import teleMedi from "assets/images/mobile/teleMedi.svg";
import eligibilityLetter from "assets/images/mobile/eligibilityLetter.svg";
import chrnicDises from "assets/images/mobile/chrnicDises.svg";
import homeChildVacc from "assets/images/mobile/homeChildVacc.svg";
import ArabicIcon from "assets/images/mobile/ArabicIcon.png";
import EnglishIcon from "assets/images/mobile/EnglishIcon.png";
import { useHistory } from "react-router-dom";
import phoneSvg from "assets/svg/myservices_popup_phone.svg";
import appStore from "assets/images/applestore.svg";
import playStore from "assets/images/playStoreFooter.png";
import claimSaudi from "assets/svg/claim-saudi.svg";
import fileClose from "assets/svg/file-close.svg";
import AdditionalCoverage from "assets/svg/AdditionalCoverage.svg";
import fileDownlad from "assets/svg/file-download.svg";

const MobileMenuPopup = (props) => {
  const { i18n, t } = useTranslation();
  const isRTL = i18n.dir() === "rtl";
  const { closeMenu } = props;
  const [menuIndex, setMenuIndex] = useState(0);
  const [isOpenLoginModel, setIsOpenLoginModel] = useState(false);
  const [isSearchModel, setIsSearchModel] = useState(false);
  const [search, setSearch] = useState("");
  const [langSpecificResults, setLangSpecificResults] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const isLoggedIn = useSelector(
    (state) => state?.loginDetailsReducer?.loginResponse?.user_info
  );
  const language = localStorage.getItem("newTawuniyaLanguageParam");
  const userName = isLoggedIn?.fullName.split(" ")?.[0];
  const dispatch = useDispatch();
  const [showLoyalty, setShowLoyalty] = useState(false);
  const [showCustomerService, setShowCustomerService] = useState(false);
  const [viewAllServices, setViewAllServices] = useState(false);
  const [activeServiceType, setActiveServiceType] = useState("all");
  const [isOpenLanguageModal, setIsOpenLanguageModal] = useState(false);
  const history = useHistory();
  const [menuProduct, setMenuProduct] = useState("individuals");
  const appData = [appStore, playStore];

  const servicesOffered = [
    {
      content: "servicesOffered.periodic_insptn",
      cardIcon: periodicInspection,
      type: "health",
      url: "/individuals/customer-service/periodic-inspection",
    },
    {
      content: "servicesOffered.road_asst",
      cardIcon: roadAssist,
      type: "motor",
      url: "/individuals/customer-service/road-assistances",
    },
    {
      content: "servicesOffered.car_acc",
      cardIcon: carAccident,
      type: "motor",
      url: "/individuals/car-accident",
    },
    {
      content: "servicesOffered.refil_medi",
      cardIcon: refilMedi,
      type: "health",
      url: "/individuals/products/health/dawai",
    },
    {
      content: "servicesOffered.med_rem",
      cardIcon: medReim,
      type: "health",
      url: "/individuals/products/health/medical-reimbursement",
    },
    {
      content: "servicesOffered.telemedi",
      cardIcon: teleMedi,
      type: "health",
      url: "/individuals/products/health/tele-medicine",
    },
    {
      content: "servicesOffered.elg_ltr",
      cardIcon: eligibilityLetter,
      type: "health",
      url: "/individuals/products/health/eligibility-letter",
    },
    {
      content: "servicesOffered.chrnic_dises_mngt",
      cardIcon: chrnicDises,
      type: "health",
      url: "/individuals/customer-service/chronic-disease-management",
    },
    {
      content: "servicesOffered.home_child_vacc",
      cardIcon: homeChildVacc,
      type: "health",
      url: "/individuals/customer-service/home-child-vaccination",
    },
    {
      content: "servicesOffered.ass_amrca",
      cardIcon: asstAmerica,
      type: "health",
      url: "/individuals/customer-service/assist-america",
    },
  ];

  const [filteredServices, setFilteredServices] = useState(servicesOffered);
  const [showPopup, setShowPopup] = useState(false);

  const handleButtonClick = () => {
    setShowPopup(true);
  };

  const handleOverlayClick = () => {
    setShowPopup(false);
  };

  useEffect(() => {
    let pathName = history.location.pathname;

    if (pathName === "/individuals") {
      setMenuIndex(0);
    } else if (pathName === "/corporate") {
      setMenuIndex(1);
    } else if (pathName === "/investor") {
      setMenuIndex(2);
    } else {
      setMenuIndex(null);
    }
  }, [history.location.pathname]);

  useEffect(() => {
    if (language) {
      if (language === "en") {
        const lSpecData = staticSearchData.filter((item) => item.lang === "EN");
        setLangSpecificResults(lSpecData);
      } else if (language === "ar") {
        const lSpecData = staticSearchData.filter((item) => item.lang === "AR");
        setLangSpecificResults(lSpecData);
      }
    }
  }, [language]);

  useEffect(() => {
    if (!search) return setSearchResults([]);

    const fSearch = langSpecificResults.filter((item) =>
      item.keywords.split(",").find((itm) => itm.includes(search))
    );
    setSearchResults(fSearch);
  }, [search, langSpecificResults]);

  const loggedInMenuList = [
    {
      title: t("your_policies"),
      url: "/dashboard",
      icon: policies,
    },
    {
      title: t("your_claims"),
      url: "/dashboard/all-claims",
      icon: claims,
    },
    {
      title: t("your_approvals"),
      url: "/dashboard/approvals",
      icon: approval,
    },
    {
      title: t("your_profile"),
      url: "/dashboard/profile",
      icon: profile,
    },
    {
      title: t("support_tickets"),
      url: "/individuals/customer-service/support-center",
      icon: support,
    },
  ];

  const individualMenuList = [
    {
      title: t("Home"),
      url: "/",
      activeMenu: true,
    },
    {
      title: t("Products"),
      url: "/individuals/all-products-mobile",
      activeMenu: true,
    },

    {
      title: t("Loyalty_Programs"),
      url: "/",
      action: () => {
        setShowLoyalty(true);
        setMenuProduct("individuals");
      },
    },
    {
      title: t("Customer_Service"),
      url: "/",
      action: () => {
        setShowCustomerService(true);
        setMenuProduct("individuals");
      },
    },
    {
      title: t("Claims"),
      url: "/individuals/products/motor/claims",
    },
  ];

  const corporateMenuList = [
    {
      title: t("Products"),
      url: "/individuals/all-products-mobile",
      activeMenu: true,
    },

    {
      title: t("Loyalty_Programs"),
      url: "/",
      action: () => {
        setShowLoyalty(true);
        setMenuProduct("corporate");
      },
    },
    {
      title: t("Customer_Service"),
      url: "/",
      action: () => {
        setShowCustomerService(true);
        setMenuProduct("corporate");
      },
    },
    {
      title: t("Claims"),
      url: "/individuals/products/motor/claims",
    },
  ];

  const investorMenuList = [
    {
      title: t("Investor_Relations"),
      url: "/investor",
      activeMenu: true,
      tabNumber: 1,
    },
    {
      title: t("Fact_Sheet"),
      url: "/investor",
      activeMenu: true,
      tabNumber: 2,
    },
    {
      title: t("Financial_Highlights"),
      url: "/investor",
      tabNumber: 3,
    },
    {
      title: t("Share_Information"),
      url: "/investor",
      tabNumber: 4,
    },
    {
      title: t("Dividends"),
      url: "/investor",
      tabNumber: 5,
    },
    {
      title: t("Analyst_Coverage"),
      url: "/investor",
      tabNumber: 6,
    },
    {
      title: t("Announcements"),
      url: "/investor",
      tabNumber: 7,
    },
    {
      title: t("investors.whitePage"),
      url: "/investor",
      tabNumber: 8,
    },
  ];

  const sharedMenuList = [
    {
      title: t("navbar.Partners"),
      url: "https://jisr.waseel.com/signIn",
    },
    {
      title: t("About_Us"),
      url: "/aboutus",
    },
    // {
    //   title: t("navbar.emergency"),
    //   url: "/quick-support",
    // },
    {
      title: t("reportFraud.reportViolations"),
      url: "/individuals/customer-service/violations",
    },
    {
      title: t("Careers"),
      url: "/careers",
    },
    {
      title: t("Contact_Us"),
      url: "/contactus",
    },
    {
      title: t("MotorClaim"),
      url: "/individuals/products/motor/claims",
    },
    {
      title: t("settings"),
      url: "/app-setting",
    },
  ];

  const handleTypeClick = (type) => {
    if (activeServiceType === type) return;

    if (type === "all") {
      setFilteredServices(servicesOffered);
    } else {
      const fSrvs = servicesOffered.filter((data) => data.type === type);
      setFilteredServices(fSrvs);
    }

    setActiveServiceType(type);
  };
  const trackEvent = (tabClicked) => {
    window.lmSMTObj.track(tabClicked);
  };

  const trackEventWithProduct = (tabClicked, product) => {
    window.lmSMTObj.track(tabClicked, {
      Product: product,
    });
  };

  const handleSmeButtonClick = () => {
    window.open("https://tawuniya.com/sme-eservice/auth/login", "_blank");
  };

  const handleCorporateButtonClick = () => {
    window.open(
      "https://online.tawuniya.com.sa/Tawuniya/portal/home.action?langId=1&_gl=1*",
      "_blank"
    );
  };

  const serviceStartedData = [
    {
      id: 0,
      icon: claimSaudi,
      serviceTitle: t("my-services.submit"),
    },
    {
      id: 1,
      icon: fileClose,
      serviceTitle: t("my-services.cancel"),
    },
    {
      id: 2,
      icon: fileDownlad,
      serviceTitle: t("my-services.print"),
    },
    {
      id: 3,
      icon: AdditionalCoverage,
      serviceTitle: t("my-services.benefits"),
    },
  ];

  return (
    <>
      {showPopup && (
        <div className="my-services-popup-overlay" onClick={handleOverlayClick}>
          <div className="popup-content" onClick={(e) => e.stopPropagation()}>
            <div className="complaince-main">
              <div className="comp-main-wrapper">
                <div
                  style={{
                    cursor: "pointer",
                    color: "#808080",
                    alignSelf: "flex-end",
                  }}
                  onClick={handleOverlayClick}
                >
                  <img src={MenuCloseIcon} alt="MenuCloseIcon" />
                </div>
                <h5 className="my-services-popup-left-title">
                  {t("my-services.downApp")}
                </h5>
                <p className="my-services-popup-left-para">
                  {t("my-services.key")}
                </p>
                <div className="key-services">
                  {serviceStartedData.map((card, index) => {
                    return (
                      <div className="complaince-card" key={index}>
                        <img src={card.icon} alt="icon-small" />
                        <p className="complaince-text">{card.serviceTitle}</p>
                      </div>
                    );
                  })}
                </div>

                <div className="footerPositionIcons mb-3">
                  <div
                    className="d-flex pb-lg-0 pb-3 classchanges gap-4"
                    style={{ cursor: "pointer" }}
                  >
                    {appData.map((i, index) => {
                      return (
                        <a
                          title={index === 0 ? "APP Store" : "Google Play"}
                          href={
                            index === 0
                              ? "https://apps.apple.com/us/app/%D8%A7%D9%84%D8%AA%D8%B9%D8%A7%D9%88%D9%86%D9%8A%D8%A9-tawuniya/id1639353191"
                              : "https://play.google.com/store/apps/details?id=com.dxp.TawuniyaInsurance"
                          }
                          target={"_blank"}
                          onClick={() =>
                            trackEvent(
                              index === 0
                                ? "APP Store"
                                : "Google Play" + "TabClicked"
                            )
                          }
                          key={index}
                        >
                          <img
                            src={i}
                            className="img-fluid pr-lg-3 pr-md-3"
                            alt="socialapp"
                          />
                        </a>
                      );
                    })}
                  </div>
                </div>
              </div>

              <div className="flex-fill  align-content-end comp-subMain-container">
                <img
                  className="comp-main-img"
                  src={phoneSvg}
                  alt="mobilephone"
                  width="130"
                />
              </div>
            </div>

            {/*  white section */}
            <div className="my-services-right-container flex-column">
              <div className="d-flex flex-column flex-fill banner-parent">
                <div className="my-services-banner-container-sme">
                  <p className="my-services-banner-text">
                    {t("my-services.sme")}
                  </p>
                </div>

                <div className="banner-details">
                  <p>
                    {t("my-services.sme-content")}{" "}
                    <strong>{t("my-services.sme-portal")}</strong>
                  </p>
                  <button
                    onClick={handleSmeButtonClick}
                    class="btn cursor-pointer d-flex justify-content-center align-items-center my-services-login-btn p-3"
                  >
                    <span>{t("my-services.sme-login")} &nbsp;&nbsp; &gt;</span>
                  </button>
                </div>
              </div>

              <div className="d-flex flex-column  flex-fill banner-parent">
                <div className="my-services-banner-container-corporate">
                  <p className="my-services-banner-text">
                    {t("my-services.corporate")}
                  </p>
                </div>
                <div className="banner-details">
                  <h6>{t("my-services.corporate-services")}</h6>
                  <p>
                    {t("my-services.corporate-content")}{" "}
                    <strong>{t("my-services.corporate-portal")}</strong>
                  </p>
                  <button
                    onClick={handleCorporateButtonClick}
                    class="btn cursor-pointer d-flex justify-content-center align-items-center my-services-login-btn p-3"
                  >
                    <span>
                      {t("my-services.corporate-login")} &nbsp;&nbsp; &gt;
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className={"page-navbar-menu " + (props.active ? "active" : "")}>
        <div className="menu-header">
          <div className="mobile-menu-close d-flex justify-content-between align-items-center mb-4">
            <div className="d-flex align-content-center">
              <div>
                <img
                  style={{ height: "25px" }}
                  src={Tawuniya_Logo_full}
                  alt="Tawuniya_Logo_full"
                />
              </div>
            </div>
            <div className="flag-lang d-flex align-items-center">
              <button
                className="content"
                onClick={() => setIsOpenLanguageModal(true)}
              >
                <span>{!isRTL ? "العربية" : "English"}</span>
                <img
                  src={isRTL ? EnglishIcon : ArabicIcon}
                  alt="flag"
                  className="language-icon"
                />
              </button>
            </div>
          </div>
          <div className="mobile-menu-login-option d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <div className="user-image">
                <img
                  src={isLoggedIn ? Avatar_Active : UserIcon}
                  alt="UserIcon"
                />
              </div>
              <div className="login-text">
                {isLoggedIn ? (
                  userName
                ) : (
                  <label
                    className="mb-0"
                    onClick={() => history.push("/login")}
                  >
                    {t("my_account")}
                  </label>
                )}
              </div>
            </div>

            <button
              class="btn cursor-pointer d-flex justify-content-center align-items-center my-services"
              onClick={handleButtonClick}
            >
              <span>{t("my-services.my-services")}</span>
            </button>
          </div>
          <div className="mobile-menu-search">
            <input
              type="text"
              placeholder={t("navbar.search.placeholder1")}
              onClick={() => {
                setIsSearchModel(true);
              }}
            />
            <img src={SearchIcon} alt="SearchIcon" />
          </div>
        </div>

        <div className="mobile-menu-inner-wrapper">
          {isLoggedIn && (
            <div className="mobile-menu-box-content">
              <div className="individuals-menu">
                <div className="right-side-menu-list">
                  <p className="dash_title">{t("your_dashboard")}</p>
                  {loggedInMenuList?.map(
                    ({ title, count, url, activeMenu, icon }, index) => {
                      return (
                        <NavLink
                          to={url}
                          key={index.toString()}
                          className={
                            "bold-menu " + (activeMenu ? "active-menu" : "")
                          }
                          onClick={() => {
                            history.push(url);
                            closeMenu(null);
                          }}
                        >
                          <img
                            className="link_icon"
                            src={icon}
                            alt="link_icon"
                          />
                          {title}
                        </NavLink>
                      );
                    }
                  )}
                  <NavLink
                    className={"bold-menu "}
                    to={"/"}
                    onClick={() => {
                      dispatch(logoutEvent({}));
                      history.push("/");
                    }}
                  >
                    <img className="link_icon" src={logout} alt="link_icon" />
                    {t("logout")}
                  </NavLink>
                </div>
              </div>
            </div>
          )}

          <div className="mobile-menu-box-content">
            {isSearchModel && (
              <NormalPopup
                isSearchModel={isSearchModel}
                setIsSearchModel={setIsSearchModel}
                fullBg
              >
                <div>
                  <div className="mobile-search-header">
                    <img
                      src={CloseDark}
                      alt="CloseDark"
                      onClick={() => {
                        setSearch("");
                        // setIsSearchModel(false)
                      }}
                    />

                    <NormalSearch
                      needRightIcon={true}
                      onChange={(e) => setSearch(e.target.value)}
                      type="text"
                      value={search}
                      placeholder={t("navbar.search.placeholder2")}
                    />
                  </div>

                  {Boolean(searchResults.length) && (
                    <SearchBoxMobile data={searchResults} searchText={search} />
                  )}
                </div>
              </NormalPopup>
            )}
            <div className="looking-for-text text-center">
              {t("I_Looking_for")}
            </div>
            <div className="mobile-menu-tabs">
              <div
                onClick={() => {
                  history.push("/individuals");
                  //setMenuIndex(0);
                  setShowLoyalty(false);
                  setShowCustomerService(false);
                  trackEvent(t("Individuals") + "Clicked");
                }}
                className={
                  menuIndex === 0
                    ? "active-tab-index text-center"
                    : "text-center"
                }
              >
                {t("Individuals")}
              </div>
              <div
                onClick={() => {
                  //setMenuIndex(1);
                  history.push("/corporate");
                  setShowLoyalty(false);
                  setShowCustomerService(false);
                  trackEvent(t("Corporate") + "Clicked");
                }}
                className={
                  menuIndex === 1
                    ? "active-tab-index text-center"
                    : "text-center"
                }
              >
                {t("Corporate")}
              </div>
              <div
                onClick={() => {
                  history.push("/investor");
                  //setMenuIndex(2);
                  setShowLoyalty(false);
                  setShowCustomerService(false);
                  trackEvent(t("Investor") + "Clicked");
                }}
                className={
                  menuIndex === 2
                    ? "active-tab-index text-center"
                    : "text-center"
                }
              >
                {t("Investor")}
              </div>
            </div>
            {menuIndex === 0 && !showLoyalty && !showCustomerService && (
              <div className="individuals-menu">
                <div className="right-side-menu-list">
                  {individualMenuList?.map(
                    ({ title, count, url, activeMenu, action }, index) => {
                      return (
                        <p
                          key={index.toString()}
                          className={
                            "bold-menu " + (activeMenu ? "active-menu" : "")
                          }
                          onClick={() => {
                            trackEvent(title + "Clicked");
                            if (action) {
                              action();
                            } else {
                              history.push(url);
                              closeMenu(null);
                            }
                          }}
                        >
                          {title}
                          {count !== undefined && (
                            <span className="count">{count}</span>
                          )}
                          <img src={arrow_blue} alt="arrow_blue" />
                        </p>
                      );
                    }
                  )}
                </div>
              </div>
            )}
            {menuIndex === 1 && !showLoyalty && !showCustomerService && (
              <div className="enterprise-menu">
                <div className="right-side-menu-list">
                  {corporateMenuList?.map(
                    ({ title, url, count, activeMenu, action }, index) => {
                      return (
                        <p
                          key={index.toString()}
                          className={
                            "bold-menu " + (activeMenu ? "active-menu" : "")
                          }
                          onClick={() => {
                            trackEvent(title + "Clicked");
                            if (action) {
                              action();
                            } else {
                              history.push(url);
                              closeMenu(null);
                            }
                          }}
                        >
                          {title}
                          {count !== undefined && (
                            <span className="count">{count}</span>
                          )}
                          <img src={arrow_blue} alt="arrow_blue" />
                        </p>
                      );
                    }
                  )}
                </div>
              </div>
            )}
            {menuIndex === 2 && !showLoyalty && !showCustomerService && (
              <div className="iframe-div-parent">
                <div className="iframe-div">
                  <iframe
                    src={`https://ksatools.eurolandir.com/tools/ticker/html/?companycode=sa-8010&v=staticr2022&lang=${
                      i18n.language === "ar" ? "ar-AE" : "en-GB"
                    }`}
                    width="100%"
                    height="263px"
                    scrolling="no"
                    frameborder="0"
                    title="Card"
                  />
                </div>
              </div>
            )}
            {menuIndex === 2 && !showLoyalty && !showCustomerService && (
              <div className="iframe-div-2"></div>
            )}
            {menuIndex === 2 && !showLoyalty && !showCustomerService && (
              <div className="sme-menu1">
                <div className="right-side-menu-list">
                  {investorMenuList?.map(
                    ({ title, url, count, activeMenu, tabNumber }, index) => {
                      return (
                        <p
                          key={index.toString()}
                          className={
                            "bold-menu " + (activeMenu ? "active-menu" : "")
                          }
                          onClick={() => {
                            history.push(`${url}?tab=${tabNumber}`);
                            closeMenu(null);
                            trackEventWithProduct(
                              title + "Clicked",
                              "Investor"
                            );
                          }}
                        >
                          {title}
                          {count !== undefined && (
                            <span className="count">{count}</span>
                          )}
                          <img src={arrow_blue} alt="arrow_blue" />
                        </p>
                      );
                    }
                  )}
                </div>
              </div>
            )}
            {!showLoyalty && !showCustomerService && (
              <>
                <hr />
                <div className="shared-menu">
                  <div className="right-side-menu-list">
                    {sharedMenuList?.map(
                      ({ title, count, url, activeMenu }, index) => {
                        return url.includes("https") ? (
                          <a
                            onClick={() => {
                              trackEvent(title + "Clicked");
                            }}
                            key={index.toString()}
                            className={
                              "bold-menu " + (activeMenu ? "active-menu" : "")
                            }
                            href={url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {title}
                            <img src={arrow_small_blue} alt="arrow_blue" />
                          </a>
                        ) : (
                          <NavLink
                            to={url}
                            key={index.toString()}
                            className={
                              "bold-menu " + (activeMenu ? "active-menu" : "")
                            }
                            onClick={() => {
                              history.push(url);
                              closeMenu(null);
                              trackEvent(title + "Clicked");
                            }}
                          >
                            {title}
                            {count !== undefined && (
                              <span className="count">{count}</span>
                            )}
                            <img src={arrow_small_blue} alt="arrow_blue" />
                          </NavLink>
                        );
                      }
                    )}
                  </div>
                </div>
              </>
            )}

            {showLoyalty && (
              <div className="loyalty_container">
                <div
                  className="back_loyalty"
                  onClick={() => setShowLoyalty(false)}
                >
                  <p>{t("Back")}</p>
                  <img src={blue_arrow_left} alt="blue_arrow_left" />
                </div>
                <div className="swipercards ">
                  <div
                    className="card overflow-hiddentext-wrap"
                    onClick={() => {
                      history.push(
                        "/individuals/loyalty-program/tawuniya-vitality"
                      );
                      trackEventWithProduct("VitalityClicked", menuProduct);
                    }}
                  >
                    <img src={LoyalityProgramsCard} alt="Vitality"></img>
                    <div className="bottom-slide">
                      <img src={Vitality} alt="Vitality" />
                      <h4>
                        {t("Get_Off_annual")}
                        <br />
                        {t("fitness_time_gym")}
                        <br />
                        {t("membership")}
                      </h4>
                    </div>
                  </div>
                  <div
                    className="card overflow-hiddentext-wrap one"
                    onClick={() => {
                      history.push(
                        "/individuals/loyalty-program/tawuniya-ithra"
                      );
                      trackEventWithProduct("IthraClicked", menuProduct);
                    }}
                  >
                    <img src={LoyalityProgramsCard1} alt="Ithra"></img>
                    <div className="bottom-slide">
                      <img src={ithra} alt="ithra" />
                      <h4>
                        {t("Access_to_hundreds_of")} <br></br>
                        {t("exclusive_offers")}
                        <br></br>
                        {t("discounts_and_more")}
                      </h4>
                    </div>
                  </div>

                  <div
                    className="card overflow-hiddentext-wrap two"
                    onClick={() => {
                      history.push("/individuals/loyalty-program/drive");
                      trackEventWithProduct("DriveClicked", menuProduct);
                    }}
                  >
                    <img src={LoyalityProgramsCard2} alt="Drive"></img>
                    <div className="bottom-slide">
                      <img src={drive} alt="drive" />
                      <h4>
                        {t("It_measures_your_driving")} <br></br>
                        {t("behavior_and_rewards")}
                        <br></br>
                        {t("you_weekly")}
                      </h4>
                    </div>
                  </div>
                  <div
                    className="card overflow-hiddentext-wrap two"
                    onClick={() => {
                      history.push("/individuals/loyalty-program/ithra-prime");
                      trackEventWithProduct("IthraPrimeClicked", menuProduct);
                    }}
                  >
                    <img src={LoyalityProgramsCard5} alt="IthraPrime"></img>
                    <div className="bottom-slide ithra_prime">
                      <img src={ithra_prime} alt="ithra_prime" />
                      <h4>
                        {t("Ithea_Prime_App_bring")} <br></br>
                        {t("you_great_value_with")}
                        <br></br>
                        {t("over_thousands_offers")}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {showCustomerService && (
              <div className="customer_services">
                <div
                  className="back_loyalty"
                  onClick={() => setShowCustomerService(false)}
                >
                  <img src={blue_arrow_left} alt="blue_arrow_left" />
                  {t("Back")}
                </div>
                <div className="customer_service_items">
                  <div className="customer_service_item">
                    <h4>{t("drawer.customer_service.network_covrg")}</h4>
                    <p>{t("drawer.customer_service.all_you_need")}</p>
                    <h5
                      onClick={() => {
                        history.push(
                          "/individuals/customer-service/network-coverage"
                        );
                        trackEventWithProduct(
                          "ServiceProviderClicked",
                          menuProduct
                        );
                      }}
                    >
                      {t("drawer.customer_service.service_provider")}
                      <img src={arrow_blue} alt="arrow_blue" />
                    </h5>
                    <h5>
                      {t("drawer.customer_service.taw_agent")}
                      <img src={arrow_blue} alt="arrow_blue" />
                    </h5>
                    <h4>{t("menuData.Surplus")}</h4>
                    <h5
                      onClick={() => {
                        window.open(
                          `https://store.tawuniya.com.sa/${i18n.language}/web/store/surplus`
                        );
                        trackEventWithProduct(
                          t("menuData.title") + "Clicked",
                          menuProduct
                        );
                      }}
                    >
                      <p>
                        {t("menuData.title")}{" "}
                        <img src={arrow_blue} alt="arrow_blue" />
                      </p>
                    </h5>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <ServicesOffered onClickAll={() => setViewAllServices(true)} />
        <div style={{ marginBottom: "135px" }} />

        <LoginPageMobile
          isOpenLoginModel={isOpenLoginModel}
          setIsOpenLoginModel={setIsOpenLoginModel}
        />
        <MobileLanguageModal
          isOpenLanguageModal={isOpenLanguageModal}
          setIsOpenLanguageModal={setIsOpenLanguageModal}
        />
        <BottomPopup
          open={viewAllServices}
          setOpen={setViewAllServices}
          bg={"gray"}
          fullScreen
        >
          <ProductsAndServices
            data={filteredServices}
            activeType={activeServiceType}
            onClick={handleTypeClick}
          />
        </BottomPopup>
      </div>
    </>
  );
};

export default MobileMenuPopup;