import React from "react";
import { Box, Typography, Card, Icon, Divider } from "@material-ui/core";
import serviceArrow from "assets/svg/Arrows/serviceArrow.svg";
import styles from "./style.module.scss";
import { history } from "service/helpers";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function CardComp(props) {
	const { item, translate = false } = props;
	const { t, i18n } = useTranslation();
	const isRTL = i18n.dir() === "rtl";
	const lang = i18n.language

	const trackEvent = (tabClicked, product) => {
		window.lmSMTObj.track(tabClicked, {
			"Product": product
		});
	};

	const handleClick = (item) => {
		if (item.externalLinks) {
			history.push(item.url + `?lang=${lang}`);
		}
	};

	return (
		<Box className={styles.sCardContainer} sx={{ mb: 4 }}>
			<Box className={styles.sCardTextBox}>
				<Typography className={styles.sCardBoxHeading} variant="h6">
					{translate ? t(item.headingEl) : item.headingEl}
				</Typography>
				<Typography className={styles.sCardBoxDescription} component={"p"} variant="h6">
					{translate ? t(item.discrptionEl) : item.discrptionEl}
				</Typography>
			</Box>
			<Box className={styles.sCardIconBox}>
				<img
					src={item.iconE1}
					className={styles.tawuniyaSliderCardIcons}
					alt="Motor"
				/>

				{item.externalLinks ? (
					// If it's an external link, use an onClick handler for history.push()
					<a
						href={item.url}
						target="_blank"
						rel="noopener noreferrer"
						onClick={(e) => {
							e.preventDefault(); // Prevent the default anchor behavior
							trackEvent(t(item.headingEl) + "Clicked", props.pillName);
							handleClick(item); // Trigger external navigation with history.push
						}}
					>
						<img
							className={`${isRTL ? styles.arCardArrow : styles.sCardArrow}`}
							src={serviceArrow}
							alt="Arrow"
						/>
					</a>
				) : (
					<Link
						to={item?.url}
						onClick={() => {
							trackEvent(t(item.headingEl) + "Clicked", props.pillName);
						}}
					>
						<img
							className={`${isRTL ? styles.arCardArrow : styles.sCardArrow}`}
							src={serviceArrow}
							alt="Arrow"
						/>
					</Link>
				)}
			</Box>
		</Box>
	);
}

export default CardComp;
