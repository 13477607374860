import MenuCloseIcon from "assets/images/menuicons/menu-close.svg";
import RightArrowBlackIcon from "assets/images/menuicons/right-arrow-black.svg";
import RightArrowWhiteIcon from "assets/images/menuicons/right-arrow-white-sm.svg";
import RightArrowIcon from "assets/images/menuicons/right-arrow.svg";
import CryptoJS from "crypto-js";
import ClaimsForm from "pages/Dashboard/DashboardClaims/ClaimsFormPage/ClaimsForm";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
// import { history } from "service/helpers";
import AboutUs from "./AboutUs/AboutUs";
import CustomerService from "./CustomerService/CustomerService";
import LoyaltyPrograms from "./LoyaltyPrograms/LoyaltyPrograms";
import "./menupopup.scss";
import Products from "./Products/Products";
import { externalLinks } from "helpers/constant";
import {
  Alshamal,
  SanadIcon
  // framearrow
} from "assets/svg/motor/ValueAddedIcons";
import framearrow from "assets/svg/motor/framearrow.svg";
import colors from "assets/scss/abstracts/colors.scss";

const MenuPopup = (props) => {
  const { i18n, t } = useTranslation(); const loginResponse = useSelector((state) => state.loginDetailsReducer.loginResponse);
  const iqamahID = loginResponse?.user_info?.iqamahID;
  const yob = loginResponse?.user_info?.birthYear;
  const userID = loginResponse?.user_info?.userID;
  const history = useHistory();
  const location = useLocation();
  const selectedLanguage = localStorage.getItem("lang");

  const updateQueryParam = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('tab');
    const newSearch = searchParams.toString();
    history.push(`${location.pathname}?${newSearch}`);
  };

  useEffect(() => {
    const body = document.querySelector("body");
    const desktopMenu = document.querySelector("#navbarDesktop");

    if (body) body.style.overflow = "hidden";
    if (desktopMenu) desktopMenu.classList.add("navbar_open");

    return () => {
      if (body) body.style.overflow = "auto";
      if (desktopMenu) desktopMenu.classList.remove("navbar_open");
    };
  }, []);

  function encrypt(value) {
    const idx = process.env.REACT_APP_EXTERNAL_IDX;
    const message = CryptoJS.AES.encrypt(value, idx);
    return message.toString();
  }

  const rightSideMenuList = [
    {
      title: t("Home"),
      url: "/",
    },
    {
      title: t("Products"),
    },
    {
      title: t("Loyalty_Programs"),
    },
    {
      title: t("Customer_Service"),
    },
    {
      title: t("About_Us"),
    },
    // {
    //  title: t("Media_Center"),
    //  outer: "https://www.tawuniya.com.sa/about-us/media",
    // },
    {
      title: t("Careers"),
      url: "/careers",
    },
    {
      title: t("Contact_Us"),
      url: "/contactus",
    },
  ];

  const insiderMenuList = [
    {
      title: t("Motor"),
      outer: userID
        ? externalLinks.common + "co-claims?" +
        "token1=" +
        encrypt(iqamahID) +
        "&" +
        "token2=" +
        encrypt(yob)
        : externalLinks.common + "tp-claims",
    },
    {
      title: t("productOfferings.prpty_casualty"),
      url:'/login'
    },
    {
      title: t("Travel"),
      url:'/login'
    },
    {
      title: t("Medical_Malpractice"),
      url:'/login'
    },
  ];

  const investorMenuList = [
    {
      title: t("Investor_Relations"),
      url: "/investor",
      number: "0",
    },
    {
      title: t("Fact_Sheet"),
      url: "/investor",
      number: "1",
    },
    {
      title: t("Financial_Information"),
      url: "/investor",
      number: "2",
    },
    {
      title: t("Share_Information"),
      url: "/investor",
      number: "3",
    },
    {
      title: t("Dividends"),
      url: "/investor",
      number: "4",
    },
    {
      title: t("Analyst_Coverage"),
      url: "/investor",
      number: "5",
    },
    {
      title: t("Announcements"),
      url: "/investor",
      number: "6",
    },
    {
      title: t("investors.whitePage"),
      url: "/investor",
      number: "7",
    },
    {
      title: t("About_Us"),
      url: "/aboutus",
      number: "0",
    },
    {
      title: t("Contact_Us"),
      url: "/contactus",
    },
  ];
  const isLoggedIn = true;
  const [saveClaim, setSaveClaim] = useState("");
  const validateClaim = () => {
    window.location.assign(process.env.REACT_APP_CLAIM_SEARCH + saveClaim);
  };
  const { menuIndex, setMenuOpen, navContent } = props;
  const [currentListMenuIndex, setCurrentListMenuIndex] = useState(1);
  const [text, setText] = useState("Individuals");

  //  useEffect(() => {
  //   if (text !== "Individual") {
  //    setText("Corporate");
  //   }

  //   if (text !== "الافراد") {
  //    setText("الشركات");
  //   }
  //   if (text !== "الشركات") {
  //    setText("الافراد");
  //   }
  //  }, [setText]);

  useEffect(() => {
    let pathName = history.location.pathname;
    if (pathName === "/corporate") {
      setText("Corporate");
    }
  }, [history.location.pathname]);
  
  const calLem = (url) => {
    window.lmSMTObj.page(url, {
        path: document.location.pathname,
        language: selectedLanguage == 'ar' ? "Arabic" : "English",
        referrer: document.referrer,
        title: document.title,
        url: document.URL,
        search: location.search,
    });
};

  const TrackEvent = (tabClicked) => {
    window.lmSMTObj.track(tabClicked);
  }

  const TrackEventWithProduct = (tabClicked, product) => {
    window.lmSMTObj.track(tabClicked,{
      "Product":product
    });
  }

  const rightSideMenu = ({ isInsiderMenu = false } = {}) => {
    let menuList = isInsiderMenu ? insiderMenuList : rightSideMenuList;
    return (
      <div className="right-side-menu-list">
        {menuList?.map(({ title, url, outer }, index) => {
          if (url) {
            return (
              <Link
                to={{
                  pathname: menuIndex === 1 && url === "/" ? "/corporate" : url,
                }}
                key={index.toString()}
                className="bold-menu-link"
                exact
                onClick={(e) => {
                  setMenuOpen(null);
                  setCurrentListMenuIndex(1);
                  TrackEventWithProduct(title+"TabClicked","Claims");
                }}
              >
                {title} <img src={RightArrowIcon} className="orange-color" alt="rightarrow" />{" "}
                <img className="black-color" src={RightArrowIcon} alt="..." />
              </Link>
            );
          } else {
            return (
              <div
                className={"bold-menu " + (currentListMenuIndex === index ? "active" : "")}
                key={index.toString()}
                onClick={() => {
                  setCurrentListMenuIndex(index);
                  //  outer && window.open(outer);
                  calLem(outer);
                  TrackEventWithProduct(title+"TabClicked","Claims");
                }}
              >
                <a href={outer} target="_blank" className="global_link global_link_flex">
                  {title} <img src={RightArrowIcon} className="orange-color" alt="rightarrow" />{" "}
                  <img className="black-color" src={RightArrowBlackIcon} alt="rightarrowblack" />
                </a>
              </div>
            );
          }
        })}
      </div>
    );
  };

  const handleTabClick = (index, text, link) => {
    if (link?.includes('https')) {
      window.open(link, '_blank')
    }
    else if (index === menuIndex) {
      history.push(link);
    } else {
      setMenuOpen(index);
      setText(text);
      history.push(link);
    }
  };

  return (
    <div className="page-navbar-menu-desktop">
      <div className="page-navbar-title-section">
        <div className="left-section">
          <span>{t("IamLooking")}</span>
          <div className="menu-tabs">
            {navContent.map((item, index) => {
              return (
                <>
                  {item.link ?
                    <div
                      key={index.toString()}
                      onClick={() => {handleTabClick(item.id, item.navText, item.link); 
                      window.lmSMTObj.track(item.navText+'TabClicked')
                      }}>
                      <div  className={item.id === menuIndex ? "active" : "deactive"}>
                        {item.navText}
                      </div>
                    </div> :
                    <div key={index.toString()}
                      onClick={() => {handleTabClick(item.id, item.navText, item.link); 
                        window.lmSMTObj.track(item.navText+'TabClicked')
                      }}
                      className={item.id === menuIndex ? "active" : ""}>
                      {item.navText}
                    </div>}
                </>
              );
            })}
          </div>
        </div>
        <div className="right-section">
          {text == "Corporate" || text == "الشركات" ? (
            <>
              <button className="greyBtn" onClick={() => TrackEvent(t("SME_Login")+"TabClicked")}>
                <a
                  target="_blank"
                  className="global_link global_link_flex"
                  href="https://tawuniya.com/sme-eservice/auth/login"
                  
                >
                  {t("SME_Login")}
                  <img src={RightArrowWhiteIcon} alt="RightArrowWhiteIcon" />
                </a>
              </button>
              <button className="global_link global_link_flex" onClick={() => TrackEvent(t("Enterprise_Login")+"TabClicked")}>
                <a
                  target="_blank"
                  className="global_link global_link_flex"
                  href="https://online.tawuniya.com.sa/Tawuniya/portal/home.action"
                >
                  {t("Enterprise_Login")}
                  <img src={RightArrowWhiteIcon} alt="RightArrowWhiteIcon" />
                </a>
              </button>
            </>
          ) : (
            <button className="orangeBtn" onClick={() => {setMenuOpen(null); 
            window.lmSMTObj.track('MyAccountTabClicked')
          }
            }>
              <Link to="/login" className="global_link global_link_flex">
                {t("My_Account")}
                <img src={RightArrowWhiteIcon} alt="RightArrowWhiteIcon" />
              </Link>
            </button>
          )}
          {/* <img
            src={MenuCloseIcon}
            className="close-icon"
            alt="Close"
            onClick={() => {setMenuOpen(null); updateQueryParam();}}
          /> */}
        </div>
      </div>
      {menuIndex === 0 && (
        <div className="individuals-menu">
          <div className="top-menu-section left-section-small">
            <div className="left-section">{rightSideMenu()}</div>
            <div className="right-section">
              {currentListMenuIndex === 0 && <div></div>}
              {currentListMenuIndex === 1 && (
                <Products setMenuOpen={setMenuOpen} isLoggedIn={isLoggedIn} currentMenu="Individuals" />
              )}
              {(currentListMenuIndex === 2 || currentListMenuIndex === null) && (
                <LoyaltyPrograms closeMenu={() => setMenuOpen(null)} currentMenu="Individuals" />
              )}
              {currentListMenuIndex === 3 && <CustomerService closeMenu={() => setMenuOpen(null)} currentMenu="Individuals"/>}
              {currentListMenuIndex === 4 && <AboutUs closeMenu={() => setMenuOpen(null)} currentMenu="Individuals"/>}
            </div>
          </div>
        </div>
      )}
      {menuIndex === 1 && (
        <div className="enterprise-menu">
          <div className="top-menu-section left-section-small">
            <div className="left-section">{rightSideMenu()}</div>
            <div className="right-section">
              {currentListMenuIndex === 0 && <div></div>}
              {currentListMenuIndex === 1 && (
                <Products setMenuOpen={setMenuOpen} isLoggedIn={isLoggedIn} currentMenu="Corporate" />
              )}
              {(currentListMenuIndex === 2 || currentListMenuIndex === null) && (
                <LoyaltyPrograms closeMenu={() => setMenuOpen(null)} currentMenu="Corporate" />
              )}
              {currentListMenuIndex === 3 && <CustomerService closeMenu={() => setMenuOpen(null)} currentMenu="Corporate"/>}
              {currentListMenuIndex === 4 && <AboutUs closeMenu={() => setMenuOpen(null)} currentMenu="Corporate"/>}
            </div>
          </div>
        </div>
      )}
      {menuIndex === 2 && (
        <div className="sme-menu">
          <div className="top-menu-section">
            <div className="left-section  investor-columns">
              <div className="investor-menu-list">
                {investorMenuList?.map((item, index) => {
                  return (
                    <div className="bold-menu" key={index.toString()} onClick={() => {setMenuOpen(null);TrackEventWithProduct(item.title+"TabClicked","Investor")}}>
                      <Link to={item?.url + "?tab=" + item.number} className="global_link global_link_flex">
                        {item?.title} <img src={RightArrowIcon} alt="RightArrowIcon" />
                      </Link>
                    </div>
                  );
                })}
              </div>

            </div>
            <div className="right-section">
              <iframe
                src={`https://ksatools.eurolandir.com/tools/ticker/html/?companycode=sa-8010&v=staticr2022&lang=${ i18n.language === 'ar' ? 'ar-AE' : 'en-GB'}`}
                width="100%"
                height="263px"
                scrolling="no"
                frameborder="0"
                title="Card"
              />
            </div>
          </div>
        </div>
      )}
      {menuIndex === 3 && (
        <div className="investor-menu">
          <div className="menu-title-section left-section-small">
            <div className="title-content">
              <strong>{t("claimTracker.Hope")}</strong>
              <p>{t("claimTracker.Available")}</p>
            </div>
          </div>
          <div className="top-menu-section ">
            <div className="left-section">
              <div className="small-title">{t("Submit-1")}</div>
              {rightSideMenu({ isInsiderMenu: true })}
            </div>
            <div className="right-section">
              <div className="d-flex align-items-center">
                <div className="w-70">
                  <div className="Container mt-5 mb-4">
                    <div className="row mx-1"> <h4 className="">{t("individualClaims")}</h4></div>
                    <div className="row">


                      <div className="col-md-12 d-flex mt-2 flex-column ">
                        <div className="p-3 menuclaim mb-2" onClick={() => {window.location.assign("/login")}}>
                          <div className="Tiitldis my-2">{t("claimTracker.AlShamel")} {t("claimTracker.andOp")} {t("claimTracker.Sanad")} {t("claimTracker.for_individual")}</div>
                          <img className="my-2 cursor-pointer" src={framearrow} onClick={() => {TrackEvent("co-claimsTabClicked")}}/>
                        </div>
                        <div className="p-3 menuclaim" onClick={() => window.location.assign("https://tawuniya.com/cxp-pxp/motor-claim/business-details")}>
                          <div className="Tiitldis my-2">{t("claimTracker.AlShamel")} {t("claimTracker.andOp")} {t("claimTracker.Sanad")} {t("claimTracker.for_corporate")}</div>
                          <img className="my-2 cursor-pointer" src={framearrow} onClick={() => { calLem("https://store.tawuniya.com.sa/web/store/co-claims");TrackEvent("co-claimsTabClicked")}}/>
                        </div>
                      </div>
                      <div className="col-md-12 d-flex mt-2" onClick={() => window.location.assign(externalLinks.common + "tp-claims")} >
                        <div className="px-3 menuclaim">
                          <div className="divdis my-2">
                            {t("Motor_vehicle_liability_insurance")}
                          </div>
                          <img className="mt-1 mb-3 cursor-pointer" src={framearrow} onClick={() => {calLem(window.location.assign(externalLinks.common + "tp-claims"));TrackEventWithProduct("tp-claimsTabClicked","Claims")}}/>                  
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="w-100">
                  <ClaimsForm setMenuOpen={setMenuOpen} />
                </div>
              </div>

            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MenuPopup;