import React, { useState, useEffect } from "react";
import { NormalButton } from "component/common/NormalButton";
import arrow from "assets/svg/orangeArrow.svg";
import "./style.scss";
import { history } from "service/helpers";
import { useTranslation } from "react-i18next";
import { newsUpdates } from "component/MediaCenter/Schema/MediaCenterData";
import { Link } from "react-router-dom";
import { formatDateToOrdinal } from "helpers/utils";

export const FooterCard = () => {
  const currentLanguage = localStorage.getItem("newTawuniyaLanguageParam");
  const { i18n, t } = useTranslation();
  const [latestNews, setLatestNews] = useState([]);
  const [itemsToShow, setItemsToShow] = useState(5); // Default to desktop
  const [animatedText, setAnimatedText] = useState(null);
  const [texts, setTexts] = useState([]); // Initialize as an empty array
  const language = i18n.language;

  // Function to update the number of items to show based on screen size
  const updateItemsToShow = () => {
    const width = window.innerWidth;
    if (width < 585) {
      setItemsToShow(1); // Mobile
    } else if (width < 800) {
      setItemsToShow(2); // Tablet
    } else if (width < 1200) {
      setItemsToShow(3); // Tablet
    } else {
      setItemsToShow(5); // Desktop
    }
  };

  useEffect(() => {
    updateItemsToShow();
    window.addEventListener("resize", updateItemsToShow);

    // Cleanup listener
    return () => {
      window.removeEventListener("resize", updateItemsToShow);
    };
  }, []);

  useEffect(() => {
    if (newsUpdates && newsUpdates.length > 0) {
      setLatestNews(newsUpdates.slice(0, itemsToShow));
    } else {
      setLatestNews([]);
    }
  }, [newsUpdates, itemsToShow]);

  // Set the `texts` array after translations are loaded
  useEffect(() => {
    setTexts([
      t("footerNews.animatedText1"),
      t("footerNews.animatedText2"),
      t("footerNews.animatedText3"),
    ]);
    setAnimatedText(t("footerNews.animatedText1")); // Start with the first text
  }, [i18n.language]); // Re-run when language changes

  useEffect(() => {
    const interval = setTimeout(() => {
      const current = texts.findIndex((item) => animatedText === item);
      if (current !== -1 && current !== texts.length - 1) {
        setAnimatedText(texts[current + 1]);
      } else {
        setAnimatedText(texts[0]);
      }
    }, 2000);

    // Clear the timeout to prevent memory leaks
    return () => clearTimeout(interval);
  }, [animatedText, texts]);

  return (
    <>
      <div className="news-animated px-4 px-lg-5 ">
        <div>
          <span>{t("footerNews.title")}</span> <span>{animatedText}</span>
        </div>
        <div className="news-paragraph">{t("footerNews.paragraph")}</div>
      </div>
      <div className="row justify-content-between px-4 px-lg-5 mainCardsFooter py-3 gap-1">
        {latestNews?.map((item, index) => {
          return (
            <Link to={`/mediacenter/medianewsdetails/${item.id}`} className="eventCard" key={index}>
              <div className="eventCard-content-box">
                <div className="d-flex justify-content-between flex-wrap">
                  <div>
                    <NormalButton label={t(`media.${item?.newsType}`)} className="cardEventBtn" />
                  </div>
                  <div>
                    <p className="fs-11 fw-400 dateText">{formatDateToOrdinal(item.dateCreated, language)}</p>
                  </div>
                </div>
                <div className="d-flex align-items-end eventCardSize">
                  <div>
                    <p className={`${item.eventParaClass} fw-800 eventContent`}>
                      {currentLanguage === "ar" ? item.eventTtitle : item.eventTtitle}
                    </p>
                  </div>
                </div>
              </div>
            </Link>

          );
        })}
        <div className="col-lg-12 col-12">
          <div className="d-flex justify-content-end pt-3">
            <Link to="/mediacenter" className="cursor-pointer d-flex align-items-center footer-flip-image">
              <span className="fs-12 fw-800 text-uppercase viewUpdateFooterLink pr-2">
                {t("footerNews.viewAllUpdates")}
              </span>
              <img src={arrow} className="img-fluid footerOrangeArrow" alt="arrow" />
            </Link>

          </div>
        </div>
      </div>
    </>
  );
};
