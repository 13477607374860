import React from "react";
import Vitality from "assets/images/menu-details-logo6.png";
import { useTranslation } from "react-i18next";
import MenuDetailsLogo2 from "assets/images/menu-details-logo2.png";
import MenuDetailsLogo3 from "assets/images/menu-details-logo3.png";
import VitalityBg from "assets/images/mobile/Vitality.png";
import DriveBg from "assets/images/mobile/Drive.png";
import IthraBg from "assets/images/mobile/Ithra.png";
import PetrominnExpress from "assets/images/Control-V.png";
import RightArrowBlackIcon from "assets/images/menuicons/right-arrow.svg";
import LoyalityProgramsCards from "assets/images/mobile/LoyalityProgramsCards2.png";
import LoyalityProgramsCards1 from "assets/images/mobile/LoyalityProgramsCards1.png";
import LoyalityProgramsCards2 from "assets/images/mobile/Drive.png";
import Petromin from "assets/images/mobile/Petromin.jpg";
import "./style.scss";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
// import { history } from "service/helpers";

const Products = ({ currentMenu, closeMenu }) => {
  const { i18n, t } = useTranslation(); 
  const history = useHistory();
  const TrackEvent = (tabClicked) => {
    window.lmSMTObj.track(tabClicked,{
      category:currentMenu,
      product:"LoyaltyPrograms"
    });
  }
  return (
    <div className="loyalityprograms-menu-list">
      {currentMenu === "Individuals" ? (
        <div
          className="details-menu"
        //  onClick={(e) => {
        //   closeMenu();
        //   window.location.assign("/individuals/loyalty-program/vitality");
        //  }}
        onClick={(e) => TrackEvent(t("Lemn.TawuniyaVitality")+"TabClicked")}
        >
          <Link to="/individuals/loyalty-program/vitality" onClick={closeMenu}>
            <div className="bg-image">
              <img src={LoyalityProgramsCards} alt="VitalityBg" />
            </div>
            <div className="img-content">
              <div className="content-logo">
                <img src={Vitality} alt="Vitality" />
              </div>
              <div
                className="text-content"
              //  onClick={() => window.location.assign("/individuals/loyalty-program/vitality")}
              >
                <p>{t("loyality.text_1")}</p>
                <a>
                  <span>
                    <img src={RightArrowBlackIcon} alt="backarrow" />
                  </span>
                  {t("loyality.learn_more")}
                </a>
              </div>
            </div>
          </Link>
        </div>
      ) : (
        <div className="details-menu" onClick={(e) => TrackEvent(t("Lemn.TawuniyaVitality")+"TabClicked")}>
          <Link to="/individuals/loyalty-program/vitality" onClick={closeMenu}>
            <div className="bg-image">
              <img src={LoyalityProgramsCards} alt="VitalityBg" />
            </div>
            <div className="img-content">
              <div className="content-logo">
                <img src={Vitality} alt="Vitality" />
              </div>
              <div
                className="text-content"
              // onClick={() => window.location.assign("/individuals/loyalty-program/vitality")}
              >
                <p>{t("loyality.text_1")}</p>
                <a>
                  <span>
                    <img src={RightArrowBlackIcon} alt="backarrow" />
                  </span>
                  {t("loyality.learn_more")}
                </a>
              </div>
            </div>
          </Link>
        </div>
      )}
      <div
        className="details-menu"
      // onClick={(e) => {
      //  closeMenu();
      //  window.location.assign("/individuals/loyalty-program/drive");
      // }}
      onClick={(e) => TrackEvent(t("Lemn.TawuniyaDrive")+"TabClicked")}
      >
        <Link to="/individuals/loyalty-program/drive" onClick={closeMenu}>
          <div className="bg-image">
            <img src={LoyalityProgramsCards2} alt="DriveBg" />
          </div>
          <div className="img-content">
            <div className="content-logo">
              <img src={MenuDetailsLogo2} alt="MenuDetailsLogo2" />
            </div>
            <div
              className="text-content"
            //  onClick={() => window.location.assign("/individuals/loyalty-program/drive")}
            >
              <p>{t("loyality.text_2")}</p>
              <a>
                <span>
                  <img src={RightArrowBlackIcon} alt="backarrow" />
                </span>
                {t("loyality.learn_more")}
              </a>
            </div>
          </div>
        </Link>
      </div>
      <div className="details-menu"  onClick={(e) => TrackEvent(t("Lemn.TawuniyaIthra")+"TabClicked")}>
        <Link to="/individuals/loyalty-program/ithra" onClick={closeMenu}>
          <div className="bg-image">
            <img src={LoyalityProgramsCards1} alt="IthraBg" />
          </div>
          <div className="img-content">
            <div className="content-logo">
              <img src={MenuDetailsLogo3} alt="MenuDetailsLogo3" />
            </div>
            <div
              className="text-content"
            //  onClick={() => window.location.assign("/individuals/loyalty-program/ithra")}
            >
              <p>{t("loyality.text_3")}</p>
              <a>
                <span>
                  <img src={RightArrowBlackIcon} alt="backarrow" />
                </span>
                {t("loyality.learn_more")}
              </a>
            </div>
          </div>
        </Link>
      </div>
      {/* <div className="details-menu"  onClick={(e) => TrackEvent(t("Lemn.TawuniyaIthraPrime")+"TabClicked")}>
        <Link
          to="/individuals/loyalty-program/ithra-prime"
          onClick={closeMenu}
          target="_blank"
        >
          <div className="bg-image ithra-prime_bg">
            <img src={LoyalityProgramsCards5} alt="IthraPrimeBg" />
          </div>
          <div className="img-content">
            <div className="content-logo ithra-prime">
              <img src={ithra_prime} alt="ithra_prime" />
            </div>
            <div
              className="text-content"
              onClick={() =>
                history.push("/individuals/loyalty-program/ithra-prime")
              }
            >
              <p>{t("loyality.text_5")}</p>
              <a>
                <span>
                  <img src={RightArrowBlackIcon} alt="backarrow" />
                </span>
                {t("loyality.learn_more")}
              </a>
            </div>
          </div>
        </Link>
      </div> */}
      <div className="details-menu"  onClick={(e) => TrackEvent(t("Lemn.TawuniyaPetrominExpress")+"TabClicked")}>
        <Link
          to={"/individuals/loyalty-program/tawuniya-petromin"}
          onClick={closeMenu}
        >
          <div className="bg-image ithra-prime_bg">
            <img src={Petromin} alt="IthraPrimeBg" />
          </div>
          <div className="img-content">
            <div className="content-logo ithra-prime">
              <img src={PetrominnExpress} alt="ithra_prime" />
            </div>
            <Link
              className="text-content"
              to={"/individuals/loyalty-program/tawuniya-petromin"}
            >
              <p>{t("Petromin.card1.imageText")}</p>
              <a>
                <span>
                  <img src={RightArrowBlackIcon} alt="backarrow" />
                </span>
                {t("loyality.learn_more")}
              </a>
            </Link>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Products;
