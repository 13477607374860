import React, { useEffect, useState } from "react";
import "./style.scss";
import { Myfamilyinsurance } from "component/MyFamilyMobilePage/Myfamilyinsurance";
import header_down from "assets/images/mobile/header_down.png";
import header_left_menu from "assets/images/mobile/header_left_menu.svg";
import header_up_arrow from "assets/images/mobile/menu-close.svg";
import BottomPopup from "../BottomPopup";
import { history } from "service/helpers";
import { ProductList, externalLinks } from "helpers/constant";
import { getI18n } from "react-i18next";
import { Switch } from "react-router-dom/cjs/react-router-dom.min";
// import { getI18n, useTranslation } from "react-i18next";
const HeaderStepsSticky = ({
    title,
    isSelect,
    selectTitle,
    leftIcon,
    menuList,
    selectedMenuOption,
    buttonTitle,
    flexPart = false,
    fromMotor = false,
    buyNowLink = false,
    quoteFor,
}) => {
    const [openMenu, setOpenMenu] = useState(false);
    const [bottomPop, setBottomPop] = useState(false);
    const currentLanguage = getI18n().language;
    const activeMenu = (val) => {
        selectedMenuOption(val);
        setOpenMenu(!openMenu);
    };



    const [offset, setOffset] = useState(0);
    // const { i18n, t } = useTranslation(); const currentLanguage = getI18n().language;
    // const isRTL = i18n.dir() === "rtl";
    useEffect(() => {
        const onScroll = () => setOffset(window.pageYOffset);
        // clean up code
        window.removeEventListener("scroll", onScroll);
        window.addEventListener("scroll", onScroll, { passive: true });
        return () => window.removeEventListener("scroll", onScroll);
    }, []);
    const trackEvent = (tabClicked) => {
        window.lmSMTObj.track(tabClicked);
    }

    const getProductExternalLink = () => {
        switch (quoteFor) {
            case ProductList.MOTOR:
                return externalLinks.Motor + (currentLanguage === "ar" ? "?lang=ar" : "?lang=en")
            case ProductList.MBI:
                return externalLinks.Motor + "motor/mechanical-breakdown"
            case ProductList.MYFAMILY:
                return externalLinks.myFamily
            case ProductList.TRAVEL:
                return externalLinks.travelInsurance_v3+`?lang=${currentLanguage}`
            case ProductList.MEDICALMAL:
                return externalLinks.medmal_v3+`?lang=${currentLanguage}`
            case ProductList.VISTVISA:
                return externalLinks.visitVisa
            case ProductList.DOMESTICWORKER:
                return externalLinks.DomesticWorkerHealth
            case ProductList.PARENTS:
                return externalLinks.ParentHealth
            default:
                return ""
        }
    }

    return (
        <>
            {(history.location.pathname === "/individuals/products/motor" ? offset > 500 : offset > 0) && (
                <div className="header_steps_container d-flex align-items-center">
                    <div className="header_body_container mx-1 flex-wrap">
                        <div className="header_steps_body">
                            {/* {leftIcon && <img src={leftIcon} alt="Arrow" />} */}
                            <h6 className="mx-2">{title}</h6>
                        </div>
                        {isSelect && (
                            <div className={flexPart ? "d-flex" : ""}>
                                <label className="mx-2">{selectTitle.label}</label>
                                <img
                                    src={openMenu ? header_up_arrow : header_down}
                                    alt="Arrow"
                                    onClick={() => setOpenMenu(!openMenu)}
                                />
                            </div>
                        )}
                        {buttonTitle && (
                            <div onClick={() => { trackEvent(title + buttonTitle + "Clicked") }}>
                                {/* <button
                                    className="header_button"
                                    onClick={() => window.location.assign(currentLanguage === "ar"
                                        ? fromMotor ? externalLinks.Motor + "motor?lang=ar" : ""
                                        : fromMotor ? externalLinks.Motor + "motor?lang=en" : "")}>
                                    {buttonTitle}
                                </button> */}
                                <button
                                    className="header_button"
                                    onClick={() => window.location.assign(getProductExternalLink())}>
                                    {buttonTitle}
                                </button>
                            </div>
                        )}
                    </div>
                    {openMenu && menuList?.length > 0 && (
                        <div className="mt-4">
                            {menuList?.map((val) => {
                                return (
                                    <div className="header_menu_list">
                                        <label
                                            className={`${val.id === selectTitle.id && "menu_active"}`}
                                            onClick={() => { activeMenu(val); trackEvent(val.label + "Clicked") }}
                                        >
                                            {/* <img src={header_left_menu} alt="Arrow" /> */}
                                            {val.label}
                                        </label>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                    <BottomPopup open={bottomPop} setOpen={setBottomPop} bg="gray" isBottom={true}>
                        <Myfamilyinsurance />
                    </BottomPopup>
                </div>
            )}
        </>
    );
};

export default HeaderStepsSticky;
