import boost from "assets/about/boost.png";
import gift from "assets/about/gift.png";
import strength from "assets/about/strength.png";
import driveBg from "assets/images/LoyalityProgramCards-1drivene.png";
import IthraPrimeBg from "assets/images/LoyalityProgramCards-2bal.png";
import tawnIthraBg from "assets/images/LoyalityProgramCardsith.png";
import Malpractice_Insurance from "assets/images/Malpractice_Insurance.svg";
import healthProducts from "assets/images/health-products.png";
import KSAFlagImage from "assets/images/ksa_flag.png";
import Damage_insurance from "assets/images/mobile/Damage_insurance.svg";
import domesticWorker from "assets/images/mobile/Domistic Workers icon.svg";
import Engineering_Insurance from "assets/images/mobile/Engineering_Insurance.svg";
import Hajj_insurance from "assets/images/mobile/Hajj_insurance.svg";
import Home_insurance from "assets/images/mobile/Home_insurance.svg";
import Industrial_Insurance from "assets/images/mobile/Industrial_Insurance.svg";
import International_Travel_insurance from "assets/images/mobile/International_Travel_insurance.svg";
import SanadNew from "assets/images/mobile/SanadNew.svg";
import Services_Insurance from "assets/images/mobile/Services_Insurance.svg";
import Umrah_insurance from "assets/images/mobile/Umrah_insurance.svg";
import Visit_insurance from "assets/images/mobile/Visit_insurance.svg";
import al_shamel_motor from "assets/images/mobile/al_shamel_motor.svg";
import asstAmerica from "assets/images/mobile/asstAmerica.svg";
import cancelPolicy from "assets/images/mobile/cancelPolicy.svg";
import carAccident from "assets/images/mobile/carAccident.svg";
import careers_image_one from "assets/images/mobile/careers_image_two.png";
import careers_image_three from "assets/images/mobile/careers_image_three.png";
import careers_image_two from "assets/images/mobile/careers_image_two.png";
import chrnicDises from "assets/images/mobile/chrnicDises.svg";
import eligibilityLetter from "assets/images/mobile/eligibilityLetter.svg";
import extentPolicy from "assets/images/mobile/extentPolicy.svg";
import family_insurance_medical from "assets/images/mobile/family_insurance_medical.svg";
import HealthPolicy from "assets/images/mobile/health.png";
import homeChildVacc from "assets/images/mobile/homeChildVacc.svg";
import ithraPrime from "assets/images/mobile/ithra_prime.svg";
import liability_insurance from "assets/images/mobile/liability_insurance.svg";
import Marine_Cargo_insurance from "assets/images/mobile/marine_cargo.svg";
import mech_breakdown_motor from "assets/images/mobile/mech_breakdown_motor.svg";
import medReim from "assets/images/mobile/medReim.svg";
import policyBenefits from "assets/images/mobile/policyBenefits.svg";
import refilMedi from "assets/images/mobile/refilMedi.svg";
import roadAssist from "assets/images/mobile/roadAssist.svg";
import sanad_plus_motor from "assets/images/mobile/sanad_plus_motor.svg";
import tDrive from "assets/images/mobile/tDrive.svg";
import mobilityEcosystemIcon from "assets/svg/motor/mobility-ecosystem-icon.svg"
import CBODICON from "assets/svg/motor/CBOD.svg"
import teleMedi from "assets/images/mobile/teleMedi.svg";
import motorProducts from "assets/images/motor-products.png";
import driveLogo from "assets/images/productsSection/drive-logo.png";
import travelProducts from "assets/images/travel-products.png";
import engineerIcon1 from "assets/news/engineerIcon1.png";
import engineerIcon10 from "assets/news/engineerIcon10.png";
import engineerIcon11 from "assets/news/engineerIcon11.png";
import engineerIcon12 from "assets/news/engineerIcon12.png";
import engineerIcon13 from "assets/news/engineerIcon13.png";
import engineerIcon14 from "assets/news/engineerIcon14.png";
import engineerIcon15 from "assets/news/engineerIcon15.png";
import engineerIcon16 from "assets/news/engineerIcon16.png";
import engineerIcon2 from "assets/news/engineerIcon2.png";
import engineerIcon3 from "assets/news/engineerIcon3.png";
import engineerIcon4 from "assets/news/engineerIcon4.png";
import engineerIcon5 from "assets/news/engineerIcon5.png";
import engineerIcon6 from "assets/news/engineerIcon6.png";
import engineerIcon7 from "assets/news/engineerIcon7.png";
import engineerIcon8 from "assets/news/engineerIcon8.png";
import engineerIcon9 from "assets/news/engineerIcon9.png";
import financialIcon7 from "assets/news/financialIcon7.png";
import financialIcon8 from "assets/news/financialIcon8.png";
import financialIcon9 from "assets/news/financialIcon9.png";
import industrialIcon10 from "assets/news/industrialIcon10.png";
import industrialIcon16 from "assets/news/industrialIcon16.png";
import industrialIcon4 from "assets/news/industrialIcon4.png";
import industrialIcon7 from "assets/news/industrialIcon7.png";
import retailIcon10 from "assets/news/retailIcon10.png";
import retailIcon12 from "assets/news/retailIcon12.png";
import retailIcon13 from "assets/news/retailIcon13.png";
import retailIcon7 from "assets/news/retailIcon7.png";
import retailIcon8 from "assets/news/retailIcon8.png";
import serviceIcon10 from "assets/news/serviceIcon10.png";
import serviceIcon12 from "assets/news/serviceIcon12.png";
import serviceIcon13 from "assets/news/serviceIcon13.png";
import transportIcon10 from "assets/news/transportIcon10.png";
import transportIcon5 from "assets/news/transportIcon5.png";
import transportIcon7 from "assets/news/transportIcon7.png";
import transportIcon8 from "assets/news/transportIcon8.png";
import transportIcon9 from "assets/news/transportIcon9.png";
import assistAmerica from "assets/svg/AssistMedical.svg";
import carBanner from "assets/svg/CarAccident.svg";
import chronicDisease from "assets/svg/ChronicDisease.svg";
import homeChildVaccination from "assets/svg/HomeChildVaccination.svg";
import fraudBanner from "assets/svg/Layer 1.svg";
import healthReim from "assets/svg/MedicalReimbursement.svg";
import motorFraud from "assets/svg/Motorfaudbanner.svg";
import telemedicine from "assets/svg/RequestTelemedicine.svg";
import serviceIconOne from "assets/svg/TawuniyaServicesIcons/serviceIcons1.svg";
import accident from "assets/svg/accident.svg";
import breakSystem from "assets/svg/breakDownIcons/break.svg";
import cylinder from "assets/svg/breakDownIcons/cylinder.svg";
import differential from "assets/svg/breakDownIcons/differential.svg";
import electricComponents from "assets/svg/breakDownIcons/elcetricComponents.svg";
import engine from "assets/svg/breakDownIcons/engine.svg";
import fuel from "assets/svg/breakDownIcons/fuelSystem.svg";
import gear from "assets/svg/breakDownIcons/gearbox.svg";
import prop from "assets/svg/breakDownIcons/propShaft.svg";
import steer from "assets/svg/breakDownIcons/steer.svg";
import suspension from "assets/svg/breakDownIcons/suspension.svg";
import turbo from "assets/svg/breakDownIcons/turbo.svg";
import wheelBearing from "assets/svg/breakDownIcons/wheelBearing.svg";
import chronicDiseaseMedication from "assets/svg/chronic_disease_medication.svg";
import vaccinate from "assets/svg/dashboardIcons/childVaccination.svg";
import downArrow from "assets/svg/downArrow.svg";
import eligibility from "assets/svg/eligibilityPrimary.svg";
import flight from "assets/svg/flightdelayclaim.svg";
import footerArrow from "assets/svg/footerArrow.svg";
import greyArrow from "assets/svg/greyArrow.svg";
import periodicInspection from "assets/svg/icons/all-services/periodic-inspection.svg";
import tawnIthra from "assets/svg/ithra.svg";
import ithra from "assets/svg/ithraPrimary.svg";
import medAssisst from "assets/svg/medassisst.svg";
import motorInspect from "assets/svg/motorInspectPrimary.svg";
import violationsCardIcon from "assets/svg/motorViolations.svg";
import orangeArrow from "assets/svg/orangeArrow.svg";
import xOrangeCircle from "assets/svg/orangeCloseIcon.svg";
import progressArrow from "assets/svg/progressGreyArrow.svg";
import roadSide from "assets/svg/roadSidePrimary.svg";
import serviceArrow from "assets/svg/serviceArrow.svg";
import teleMedBanner from "assets/svg/teleMedDasboardBanner.svg";
import telemed from "assets/svg/telemedicine.svg";
import theft from "assets/svg/theft.svg";
import travelFraud from "assets/svg/travelFraudBanner.svg";
import { externalLinks } from "helpers/constant";

export const FHTimeLineData = [
  {
    fhyear: "2021",
    fhTimeLineList: [
      {
        lineTrue: true,
        fhTitile: "Excellence in Customer Service- KSA ",
        fhReference: "By Global Brands Magazine",
      },
      {
        lineTrue: true,
        fhTitile: "Best Health Insurance Brand in Saudi Arabia",
        fhReference: "By Global Brands Magazine",
      },
      {
        lineTrue: true,
        fhTitile: "Best Auto Insurance Company-KSA",
        fhReference: "By Global Banking and Finance Review",
      },
      {
        lineTrue: true,
        fhTitile: "Best Shared Value Insurance Concept – Tawuniya Vitality – Saudi Arabia 2021",
        fhReference: "By International Finance Awards",
      },
      {
        lineTrue: true,
        fhTitile: "Outstanding Contribution to Social Impact in Saudi Arabia",
        fhReference: "By CFI British Magazine",
      },
      {
        lineTrue: true,
        fhTitile: "Best Innovative Insurance Company ",
        fhReference: "International Business Magazine",
      },
      {
        lineTrue: true,
        fhTitile: "The best Takaful insurance company in the world",
        fhReference: "Global Islamic Finance Awards",
      },
      {
        lineTrue: true,
        fhTitile: "Top 10 Most Valuable Insurance Brands in the Middle East",
        fhReference: "By Brand Finance",
      },
      {
        lineTrue: true,
        fhTitile: "Top 100 Companies in the Middle East",
        fhReference: "By Forbes Magazine",
      },
      {
        lineTrue: false,
        fhTitile: "Selection of Tawuniya CEO among Top 50 Influential Leaders- KSA",
        fhReference: "By Arabia Business Magazine",
      },
    ],
  },
  {
    fhyear: "2020",
    fhTimeLineList: [
      {
        lineTrue: true,
        fhTitile: "Excellence in Customer Service- KSA ",
        fhReference: "By Global Brands Magazine",
      },
      {
        lineTrue: true,
        fhTitile: "Best Shared Value Insurance Concept – Tawuniya Vitality – Saudi Arabia 2021",
        fhReference: "By International Finance Awards",
      },
      {
        lineTrue: false,
        fhTitile: "Selection of Tawuniya CEO among Top 50 Influential Leaders- KSA",
        fhReference: "By Arabia Business Magazine",
      },
    ],
  },
];

export const faqContainerCovidNineteenData = [
  {
    serviceTitle: "You’ve got questions, we’ve got answers",
    servicePara:
      "Review answers to commonly asked questions at Tawuniya, which enable you to be directly involved in improving our support experience.",
  },
];

export const FHCardData = [
  {
    fhCardTitle: "Total Assets",
    fhCardValue: "14.6 Billion",
  },
  {
    fhCardTitle: "Total Equity",
    fhCardValue: "3 Billion",
  },
  {
    fhCardTitle: "Gross Written Premiums (GWP)",
    fhCardValue: "10.2 Billion",
  },
  {
    fhCardTitle: "Net Earned Premiums",
    fhCardValue: "7.9 Billion",
  },
  {
    fhCardTitle: "Net Incurred Claims",
    fhCardValue: "6.6 Billion",
  },
  {
    fhCardTitle: "Net Profit before Zakat",
    fhCardValue: "350 Million",
  },
  {
    fhCardTitle: "Surplus of Insurance Operations Minus Policyholders Investments Revenues",
    fhCardValue: "77.5 Million",
  },
  {
    fhCardTitle: "Net income of Policyholders Investments",
    fhCardValue: "145.7 Million",
  },
  {
    fhCardTitle: "Net income of Shareholders Capital Investments",
    fhCardValue: "157 Million",
  },
  {
    fhCardTitle: "Earnings per Share",
    fhCardValue: "2.13",
  },
  {
    fhCardTitle: "Issued and Paid-up Capital",
    fhCardValue: "1,250 Million",
  },
];

export const AboutUsFixedHeightCard = [
  {
    cardIcon: orangeArrow,
    cardTitle: "Our Competitive Advantages",
    cardPara1: "Financial Highlights of Tawuniya in 2021",
    cardUrl: "/aboutus/financialhighlights",
    cardPara2: "Competitive Advantages",
    cardPara3: "Tawuniya Awards & Ranking- 2021",
    cardTitleColor: "#6B47F5",
    cardSubtitleColor: "#FFFFFF",
    cardBackgroundColor: "#1A1C1F",
  },
  {
    cardTitle: "Board Of Directors & Senior Executives",
    cardPara1: "Board Of Directors (BOD)",
    cardPara2: "Senior Executives",
    cardTitleColor: "#FFFFFF",
    cardUrl: "/aboutus/directors",
    cardSubtitleColor: "#FFFFFF",
    cardBackgroundColor: "#312E52",
  },
  {
    cardIcon: orangeArrow,
    cardTitle: "Social Responsibility",
    cardPara1: "Adopting the CSR Strategy",
    cardPara2: "Social Contributions in 2021",
    cardUrl: "/social",
    cardTitleColor: "#6B47F5",
    cardSubtitleColor: "#4C565C",
    cardBackgroundColor: "#FFFFFF",
  },
];

export const AboutUsBannerCards = [
  {
    cardTitle: "Our Mission",
    cardPara: "Exceed expectations through superior customer experience and service excellence",
  },
  {
    cardTitle: "Our Purpose",
    cardPara: "Together for a safer life and bigger dreams",
  },
  {
    cardTitle: "Our Vision",
    cardPara: "Largest insurer in the MENA region",
  },
];

export const violationsObCardTop = [
  {
    cardIcon: violationsCardIcon,
    cardTitle: "Ascertaining credibility In reporting",
    cardPara: "by avoiding any rumors and allegations that are not based on real facts.",
    cardClass: "",
  },
  {
    cardIcon: violationsCardIcon,
    cardTitle: "Avoiding Malicious Reports",
    cardPara:
      "for the purpose of defaming the reputation of others, inflicting revenge on them, revenge or undermining confidence in the financial institution, its employees or stakeholders.",
    cardClass: "alignfitCard",
  },
  {
    cardIcon: violationsCardIcon,
    cardTitle: "The complete Confidentiality",
    cardPara: "for the reported violation in order to achieve the public interest.",
    cardClass: "alignfitCard",
  },
];

export const violationsObCardBtm = [
  {
    cardIcon: violationsCardIcon,
    cardTitle: "Non-Disclosure",
    cardPara: "Not to disclose any information about the whistleblower.",
    cardClass: "",
  },
  {
    cardIcon: violationsCardIcon,
    cardTitle: "Anonymous Reporter is not covered",
    cardPara:
      "The informant, whose name and identity has not been disclosed, is not entitled to claim the protection covered under Subsection (10-4-1) of the Whistleblowing Policy.",
    cardClass: "alignfitCard",
  },
  {
    cardIcon: violationsCardIcon,
    cardTitle: "Protection of non-malicious reporting persons",
    cardPara: "From any revengeful action taken against the whistleblower.",
    cardClass: "alignfitCard",
  },
];

export const productContents = [
  {
    id: 0,
    productTitle: "Products",
    arrowIcon: orangeArrow,
    highlightItem: true,
  },
  {
    id: 1,
    productTitle: "Claim Assistance",
    arrowIcon: greyArrow,
    highlightItem: false,
  },
  {
    id: 2,
    productTitle: "Tawuniya Vitality",
    arrowIcon: greyArrow,
    highlightItem: false,
  },
  {
    id: 3,
    productTitle: "Help Center",
    arrowIcon: greyArrow,
    highlightItem: false,
  },
  {
    id: 4,
    productTitle: "About",
    arrowIcon: greyArrow,
    highlightItem: false,
  },
  {
    id: 5,
    productTitle: "Investor Relations",
    arrowIcon: null,
    highlightItem: false,
  },
  {
    id: 6,
    productTitle: "Contact Us",
    arrowIcon: null,
    highlightItem: false,
  },
];

export const otherProducts = [
  {
    id: 0,
    productTitle: "Motor Insurance",
    productContentIsArray: true,
    productContent: ["AlShamel", "Third party", "Third party plus", "Motor Flex", "Tawuniya drive"],
    subIcon: downArrow,
  },
  {
    id: 0,
    productTitle: "Property & casualty",
    productContent: [
      "Home insurance",
      "International Travel insurance",
      "Medical malpractice insurance",
    ],
    subIcon: downArrow,
  },
  {
    id: 0,
    productTitle: "Medical & Takaful",
    productContent: [
      "My family medical insurance",
      "Visit visa medical insurance",
      "Umrah Medical Insurance",
    ],
    subIcon: downArrow,
  },
];

export const moreProductData = [
  {
    id: 0,
    title: "Motor",
    content: ["Tawuniya drive", "Motor Flex", "Third party plus", "Third party", "Al Shameel"],
  },
  {
    id: 1,
    title: "Property & casualty",
    content: [
      "Shop Owners Insurance",
      "Medical malpractice insurance",
      "International Travel insurance",
      "Home insurance",
    ],
  },
  {
    id: 2,
    title: "Medical & Takaful",
    content: [
      "Umrah Medical Insurance",
      "Visit visa medical insurance",
      "My family medical insurance",
    ],
  },
];

export const footerData = [
  {
    id: 0,
    title: "Motor",
    titleAr: "تأمين المركبات",
    footerIcon: footerArrow,
    content: [
      {
        id: 1,
        label: "AlShamel",
        labelAr: "التأمين الشامل",
        routeURL: "/individuals/products/motor",
      },
      {
        id: 2,
        label: "Third party Insurance",
        labelAr: "تأمين ضد الغير",
        routeURL: "/individuals/products/motor",
      },
      {
        id: 3,
        label: "Third party plus Insurance ",
        labelAr: "تأمين ضد الغير بلس",
        routeURL: "/individuals/products/motor",
      },
      {
        id: 4,
        label: "Mechanical breakdown",
        labelAr: "تأمين الأعطال  الميكانيكية",
        routeURL: "/individuals/products/motor/mechanical-breakdown",
      },
      {
        id: 5,
        label: "Tawuniya drive",
        labelAr: "التعاونية درايف",
        routeURL: "/individuals/loyalty-program/drive",
      },
      {
        id: 6,
        label: "Cross Border Own Damage",
        labelAr: "الأضرار الشخصية عبر الحدود",
        routeURL: "/individuals/products/motor/cross-border-own-damage",
      },
      {
        id: 7,
        label: "Mobility Ecosystem",
        labelAr: "المنظومة المتكاملة للمركبات",
        routeURL: "/individuals/products/motor/mobility-ecosystem",
      },
    ],
  },
  {
    id: 1,
    title: "General insurance",
    titleAr: "تأمين العام",
    footerIcon: footerArrow,
    content: [
      {
        id: 1,
        label: "Home insurance",
        labelAr: "تأمين على المساكن",
        routeURL: "/individuals/products/property-casualty/home-insurance",
      },
      {
        id: 2,
        label: "International Travel insurance",
        labelAr: "تأمين السفر",
        routeURL: "/individuals/products/property-casualty/international-travel",
      },
      {
        id: 4,
        label: "Medical malpractice insurance",
        labelAr: "تأمين أخطاء ممارسة المهن الطبية",
        routeURL: "/individuals/products/property-casualty/medical-malpractice",
      },
      {
        id: 5,
        label: "Domestic Worker Contract Insurance",
        labelAr: "تأمين عقد العمالة المنزلية",
        routeURL: "/individuals/products/domestic-worker-insurance",
      }
    ],
  },
  {
    id: 2,
    title: "Health Insurance",
    titleAr: "تأمين الصحة",
    footerIcon: footerArrow,
    content: [
      {
        id: 1,
        label: "My family medical insurance",
        labelAr: "تأمين عائلتي الصحي",
        routeURL: "/individuals/products/health/my-family-insurance",
      },
      {
        id: 2,
        label: "Visit visa medical insurance",
        labelAr: "تأمين تاشيرة الزيارة الصحي",
        routeURL: "/individuals/products/health/visit-visa-insurance",
      },
      {
        id: 3,
        label: "Hajj insurance program",
        labelAr: "برنامج تأمين الحج",
        routeURL: "/individuals/products/health/hajj-insurance",
      },
      {
        id: 4,
        label: "Umrah Medical Insurance",
        labelAr: "برنامج تأمين العمرة",
        routeURL: "/individuals/products/health/umrah-insurance",
      },
      {
        id: 5,
        label: "Domestic Worker Health Insurance",
        labelAr: "تأمين الصحي للعمالة المنزلية",
        routeURL: "/individuals/products/domestic-worker-health-insurance",
      },
      {
        id: 6,
        label: "Parent Health Insurance",
        labelAr: "تأمين الوالدين لحالات التنويم و الطوارئ",
        routeURL: "individuals/products/Parent-health-insurance",
      },
      {
        id: 7,
        label: "Premium Residency Insurance",
        labelAr: "تأمين الإقامة المميزة",
        routeURL: externalLinks.premium,
        externalLinks: true
      },
    ],
  },
  {
    id: 2,
    title: "Life Insurance",
    titleAr: "تأمين الحياة",
    footerIcon: footerArrow,
    content: [
      {
        id: 0,
        label: "Life Insurance",
        labelAr: "تأمين الحياة",
        routeURL: "/individuals/products/life-insurance",
      },
      {
        id: 1,
        label: "Life Plus Insurance",
        labelAr: "تأمين الحياة بلس",
        routeURL: "/individuals/products/life-insurance",
      },
      {
        id: 2,
        label: "Retirement Insurance",
        labelAr: "تأمين التقاعد",
        routeURL: "/individuals/products/life-insurance",
      },
      {
        id: 3,
        label: "Education Program for Children",
        labelAr: "برنامج التعاونية للأبناء",
        routeURL: "/individuals/products/life-insurance",
      },
    ],
  },
  {
    id: 3,
    title: "Customer Service",
    titleAr: "خدمة العملاء",
    footerIcon: footerArrow,
    content: [
      {
        id: 1,
        label: "Help Center",
        labelAr: "مركز المساعدة",
        routeURL: "/individuals/customer-service/support",
      },
      {
        id: 2,
        label: "Motor FAQs",
        labelAr: "الاسئلة الشائعة - المركبات",
        routeURL: "/individuals/products/motor?to=faq",
      },
      {
        id: 3,
        label: "Medical FAQs",
        labelAr: "الاسئلة الشائعة - صحي",
        routeURL: "/individuals/products/property-casualty/medical-malpractice?to=faq",
      },
      {
        id: 4,
        label: "Property & casualty FAQs",
        labelAr: "الاسئلة الشائعة - الممتلكات والحوادث",
        routeURL: "/corporate/products/health/360-property_casualty-insurance?to=faq",
      },
      {
        id: 5,
        label: "Open a Support Request",
        labelAr: "فتح تذكرة جديدة",
        routeURL: "/individuals/customer-service/support",
      },
    ],
  },
];

export const footerNextData = [
  {
    id: 0,
    title: "About Us",
    titleAr: "عنا",
    footerIcon: footerArrow,

    content: [
      {
        id: 1,
        label: "About Tawuniya",
        labelAr: "عن التعاونية",
        routeURL: "/aboutus",
      },
      {
        id: 2,
        label: "Financial Highlights",
        labelAr: "المعلومات المالية",
        routeURL: "/aboutus/financialhighlights",
      },
      {
        id: 3,
        label: "Competitive Advantages",
        labelAr: "المميزات التنافسية",
        routeURL: "/aboutus/financialhighlights",
      },
      {
        id: 4,
        label: "Board Of Directors",
        labelAr: "مجلس الادارة",
        routeURL: "/aboutus/board-of-directors",
      },

      {
        id: 5,
        label: "Social Responsibility",
        labelAr: "مسؤليتنا الاجتماعية",
        routeURL: "/social",
      },
      //  {
      //   id: 6,
      //   label: "Media Center",
      //   labelAr: "المركز الإعلامي",
      //   routeURL: "https://www.tawuniya.com.sa/about-us/media",
      //  },
      {
        id: 7,
        label: "Contact Us",
        labelAr: "اتصل بنا",
        routeURL: "/contactus",
      },
      {
        id: 7,
        label: "Careers",
        labelAr: "الوظائف",
        routeURL: "/careers",
      },
    ],
  },
  {
    id: 1,
    title: "Fraud & Violations",
    titleAr: "الاحتيال والتجاوزات",
    footerIcon: footerArrow,
    content: [
      {
        id: 1,
        label: "Motor Fraud",
        labelAr: "احتيال المركبات",
        routeURL: "/individuals/customer-service/motor-fraud",
      },
      {
        id: 2,
        label: "Medical Fraud",
        labelAr: "الاحتيال الطبي",
        routeURL: "/individuals/customer-service/medical-fraud",
      },
      {
        id: 3,
        label: "General insurance",
        labelAr: "احتيال التأمين العام",
        routeURL: "/individuals/customer-service/travel-fraud",
      },
      {
        id: 4,
        label: "Report Violations",
        labelAr: "الإبلاغ عن التجاوزات",
        routeURL: "/individuals/customer-service/violations",
      },
    ],
  },
];

export const footerNextExtData = [
  {
    id: 0,
    title: "Claim Assistance",
    titleAr: "مساعدة في المطالبات",
    footerIcon: footerArrow,
    content: [
      {
        id: 1,
        label: "Motor Insurance Claims",
        labelAr: "تتبع مطالبات المركبات",
        routeURL: externalLinks.common + "tp-claims",
      },
      {
        id: 2,
        label: "Property & casualty Claims",
        labelAr: "تتبع مطالبات الممتلكات والحوادث",
        routeURL: "/individuals?tab=claims",
        routeURL: "/individuals?tab=claims",
      },
      {
        id: 3,
        label: "Medical & Takaful Claims",
        labelAr: "تتبع المطالبات الصحية",
        routeURL: "/individuals?tab=claims",
        routeURL: "/individuals?tab=claims",
      },
      {
        id: 4,
        label: "Track Your Claim",
        labelAr: "تتبع المطالبات",
        routeURL: "/login",
        routeURL: "/login",
      },
    ],
  },
];

export const bannerMedicalData = [
  {
    fraudTitle: "custm_medical.title",
    fraudPara: "custm_medical.sub_para",
    fraudList: [
      {
        id: 0,
        para: "custm_medical.list_1",
      },
      {
        id: 1,
        para: "custm_medical.list_2",
      },
      {
        id: 2,
        para: "custm_medical.list_3",
      },
      {
        id: 3,
        para: "custm_medical.list_4",
      },
    ],
    btnLabel: "custm_medical.report_medical",
    url: "reportpage",
    bannerPic: fraudBanner,
    fraudBannerImgClass: "medicFraudBanner",
    bannerLayout: "fraudBannerOne",
    serviceRequested: "medicalFraud",
  },
];

export const bannerMotorData = [
  {
    fraudTitle: "cust_motor.title",
    fraudPara: "cust_motor.para",
    fraudList: [
      {
        id: 0,
        para: "cust_motor.motor.list_1",
      },
      {
        id: 1,
        para: "cust_motor.motor.list_2",
      },
      {
        id: 2,
        para: "cust_motor.motor.list_3",
      },
      {
        id: 3,
        para: "cust_motor.motor.list_4",
      },
      {
        id: 4,
        para: "cust_motor.motor.list_5",
      },
      {
        id: 5,
        para: "cust_motor.motor.list_6",
      },
    ],
    btnLabel: "cust_motor.report_btn",
    url: "reportpage",
    bannerPic: motorFraud,
    fraudBannerImgClass: "motorFraudBanner",
    bannerLayout: "fraudBannerTwo",
    serviceRequested: "motorFraud",
  },
];

export const bannerTravelData = [
  {
    fraudTitle: "cust_travel.title",
    fraudPara: "cust_travel.para",
    btnLabel: "cust_travel.report_btn",
    bannerPic: travelFraud,
    fraudBannerImgClass: "travelFraudBanner",
    bannerLayout: "fraudBannerThree",
    url: "reportpage",
    serviceRequested: "travelFraud",
  },
];

export const bannerChronicDiseaseManagementData = [
  {
    fraudTitle: "Chronic disease management",
    fraudSubTitle: "We take care of your health to the smallest detail.",
    fraudPara:
      "If you suffer from any chronic disease, this service allows you to get comprehensive health care that takes care of the smallest detail. From medical consultations to receiving the necessary medication, in addition to laboratory services. In some cases, the service is provided at your residence.",
    btnLabel: "Request Chronic disease management ",
    bannerPic: fraudBanner,
    fraudBannerImgClass: "chronicdiseaseBanner",
    bannerLayout: "chronicBannerThree",
    url: "/chronic-disease-management/request-service",
    url: "/chronic-disease-management/request-service",
    serviceRequested: "travelFraud",
  },
];

export const bannerHomeChildVaccinationData = [
  {
    fraudTitle: "Home Child Vaccination",
    fraudSubTitle: "Vaccinating your child at home.",
    fraudPara:
      "Vaccination is essential for your child's safety and protection, which is part of our responsibility. Therefore, we provide vaccination service at home to Tawuniya members whose age between 0 – 7 years based on the basic vaccinations schedule issued by MOH and included in the Cooperative Health Insurance Uniﬁed Policy published by CCHI",
    btnLabel: "Request Home Child Vaccination",
    bannerPic: fraudBanner,
    fraudBannerImgClass: "chronicdiseaseBanner",
    bannerLayout: "chronicBannerThree",
    url: "home-child-vaccination#request-service",
    serviceRequested: "travelFraud",
  },
];

export const legendaryMedicSupportData = [
  {
    heading: "custm_medical.legendary_support.title",
    para: "custm_medical.legendary_support.sub_title",
    legendParaOne: "custm_medical.legendary_support.para_1",
    legendParaTwo: "custm_medical.legendary_support.para_2",
    btnLabelOne: "custm_medical.legendary_support.report_motor",
    btnLabelTwo: "custm_medical.legendary_support.report_travel",
    btnLinkOne: "/individuals/customer-service/report-fraud",
    btnLinkOnePrimary: "/individuals/customer-service/report-fraud",
    btnLinkTwo: "/individuals/customer-service/report-fraud",
    btnLinkTwoPrimary: "/individuals/customer-service/report-fraud",
    fraudTitleOne: "custm_medical.legendary_support.motor",
    fraudTitleTwo: "custm_medical.legendary_support.fraud",
    positionLayerTwo: "positionMedic",
    fraudType: "medicalfraud"
  },
];

export const legendaryMotorSupportData = [
  {
    heading: "cust_motor.legendary_support.title",
    para: "cust_motor.legendary_support.sub_title",
    legendParaOne: "cust_motor.legendary_support.medParagraph",
    legendParaTwo: "cust_motor.legendary_support.para_2",
    btnLabelOne: "cust_motor.legendary_support.reportHealth",
    btnLabelTwo: "cust_motor.legendary_support.report_travel",
    btnLinkOne: "/individuals/customer-service/report-fraud",
    btnLinkOnePrimary: "/individuals/customer-service/report-fraud",
    btnLinkTwo: "/individuals/customer-service/report-fraud",
    btnLinkTwoPrimary: "/individuals/customer-service/report-fraud",
    fraudTitleOne: "cust_motor.legendary_support.health",
    fraudTitleTwo: "cust_motor.legendary_support.fraud",
    positionLayerTwo: "positionMotor",
    fraudType: "motorfraud"
  },
];

export const legendaryTravelSupportData = [
  {
    heading: "cust_travel.legendary_support.title",
    para: "cust_travel.legendary_support.sub_title",
    legendParaOne: "cust_travel.legendary_support.para_1",
    legendParaTwo: "cust_motor.legendary_support.medParagraph",
    btnLabelOne: "cust_travel.legendary_support.report_motor",
    btnLabelTwo: "cust_motor.legendary_support.reportHealth",
    btnLinkOne: "/individuals/customer-service/report-fraud",
    btnLinkOnePrimary: "/individuals/customer-service/report-fraud",
    btnLinkTwo: "/individuals/customer-service/report-fraud",
    btnLinkTwoPrimary: "/individuals/customer-service/report-fraud",
    fraudTitleOne: "cust_travel.legendary_support.motor",
    fraudTitleTwo: "cust_motor.legendary_support.health",
    positionLayerTwo: "positionTravel",
    fraudType: "travelfraud"
  },
];

export const legendaryChronicSupportData = [
  {
    heading: "Can't find what you're looking for?",
    para: "Contact the legendary support team right now.",
    legendParaOne:
      "Vaccinating your child at home Vaccination is essential for your child's safety and protection, which is part of our responsibility. Therefore, we provide vaccination service at home to Tawuniya members whose age between 0 – 7 years based on the basic vaccinations schedule issued by MOH and included in the Cooperative Health Insurance Uniﬁed Policy published by CCHI.",
    legendParaTwo:
      "An unforgettable pregnancy journey We will do our best to make your pregnancy journey full of memorable and beautiful memories through the Pregnancy follow-up program that takes care of a pregnant mother and increases her awareness of all procedures and provide awarness tips during all the phases of pregnancy",
    btnLabelOne: "Request Home Child Vaccination",
    btnLabelTwo: "Request Pregnancy follow-up program",
    fraudTitleOne: "Home Child Vaccination",
    fraudTitleTwo: "Pregnancy follow-up program",
    positionLayerTwo: "positionTravel",
  },
];

export const legendaryHomeChildVaccinationSupportData = [
  {
    heading: "Can't find what you're looking for?",
    para: "Contact the legendary support team right now.",
    legendParaOne:
      "An unforgettable pregnancy journey We will do our best to make your pregnancy journey full of memorable and beautiful memories through the Pregnancy follow-up program that takes care of a pregnant mother and increases her awareness of all procedures and provide awarness tips during all the phases of pregnancy",
    legendParaTwo:
      "We take care of your health to the smallest detail. If you suffer from any chronic disease, this service allows you to get comprehensive health care that takes care of the smallest detail. From medical consultations to receiving the necessary medication, in addition to laboratory services. In some cases, the service is provided at your residence.",
    btnLabelOne: "Request Pregnancy follow-up program",
    btnLabelTwo: "Request Chronic disease management",
    fraudTitleOne: "Pregnancy follow-up program",
    fraudTitleTwo: "Chronic disease management",
    positionLayerTwo: "positionTravel",
  },
];

export const heathInsureCardData = [
  {
    heading: "Can't find what you're looking for?",
    para: "Contact the legendary support team right now.",
    legendParaOne: "Comprehensive protection from risks related to travel outside the Kingdom.",
    legendParaTwo:
      "Get quality car insurance at an affordable price. with Tawuniya, find a car insurance that suits your needs.",
    btnLabelOne: "Buy now",
    btnLabelTwo: "Buy now",
    fraudTitleOne: "Health Messaage No.1",
    fraudTitleTwo: "Health Messaage No.2",
    positionLayerTwo: "positionHealth",
  },
];

export const faqMedicData = [
  {
    id: 0,
    question: "custm_medical.faq.q1",
    answer: "custm_medical.faq.a1",
  },
  {
    id: 1,
    question: "custm_medical.faq.q2",
    answer: "custm_medical.faq.a2",
  },
  {
    id: 2,
    question: "custm_medical.faq.q3",
    answer: "custm_medical.faq.a3",
  },
  {
    id: 3,
    question: "custm_medical.faq.q4",
    answer: "custm_medical.faq.a4",
  },
  {
    id: 4,
    question: "custm_medical.faq.q5",
    answer: "custm_medical.faq.a5",
  },
  {
    id: 5,
    question: "custm_medical.faq.q6",
    answer: "custm_medical.faq.a6",
  },
  {
    id: 6,
    question: "custm_medical.faq.q7",
    answer: "custm_medical.faq.a7",
  },
  {
    id: 7,
    question: "custm_medical.faq.q8",
    answer: "custm_medical.faq.a8",
  },
];

export const faqMotorData = [
  {
    id: 0,
    question: "motorfraud.faq1",
    answer: "motorfraud.ans1",
  },
  {
    id: 1,
    question: "motorfraud.faq2",
    answer: "motorfraud.ans2",
  },
  {
    id: 2,
    question: "motorfraud.faq3",
    answer: "motorfraud.ans3",
  },
  {
    id: 3,
    question: "motorfraud.faq4",
    answer: "motorfraud.ans4",
  },
  {
    id: 4,
    question: "motorfraud.faq5",
    answer: "motorfraud.ans5",
  },
  {
    id: 5,
    question: "motorfraud.faq6",
    answer: "motorfraud.ans6",
  },
  {
    id: 6,
    question: "motorfraud.faq7",
    answer: "motorfraud.ans7",
  },
  {
    id: 7,
    question: "motorfraud.faq8",
    answer: "motorfraud.ans8",
  },
];

export const faqTravelData = [
  {
    id: 0,
    question: "cust_travel.faq.q1",
    answer: "cust_travel.faq.a1",
  },
  {
    id: 1,
    question: "cust_travel.faq.q2",
    answer: "cust_travel.faq.a2",
  },
  {
    id: 2,
    question: "cust_travel.faq.q3",
    answer: "cust_travel.faq.a3",
  },
  {
    id: 3,
    question: "cust_travel.faq.q4",
    answer: "cust_travel.faq.a4",
  },
];
export const faqTravelData2 = [
  {
    id: 4,
    question: "cust_travel.faq.q5",
    answer: "cust_travel.faq.a5",
  },
  {
    id: 5,
    question: "cust_travel.faq.q6",
    answer: "cust_travel.faq.a6",
  },
  {
    id: 6,
    question: "cust_travel.faq.q7",
    answer: "cust_travel.faq.a7",
  },
  {
    id: 7,
    question: "cust_travel.faq.q8",
    answer: "cust_travel.faq.a8",
  },
  {
    id: 8,
    question: "cust_travel.faq.q9",
    answer: "cust_travel.faq.a9",
  },
];

export const faqCovidNineteenData = [
  {
    id: 0,
    question: "What is covid-19 Travel insurance?",
    answer:
      "It's important to distinguish between Medical insurance fraud/abuse and mistakes. Medical insurance fraud is an act of deception by misrepresentation of material facts, or silence when good faith requires expression, resulting in material damage to one who relies on it and the right to rely on it. Simply, it is obtaining something of value from someone else through deceit. By contrast, mistakes are made unknowingly by anyone who lacks knowledge, in other words, it’s a misstatement or unintentional deviation from the truth. Therefore, Tawuniya always recommend reading the Medical Insurance Guidelines and visiting Tawuniya social media accounts for more information about the ideal use of your Medical Card.",
  },
  {
    id: 1,
    question: "What companies provide this insurance to citizens?",
    answer:
      "It's important to distinguish between Medical insurance fraud/abuse and mistakes. Medical insurance fraud is an act of deception by misrepresentation of material facts, or silence when good faith requires expression, resulting in material damage to one who relies on it and the right to rely on it. Simply, it is obtaining something of value from someone else through deceit. By contrast, mistakes are made unknowingly by anyone who lacks knowledge, in other words, it’s a misstatement or unintentional deviation from the truth. Therefore, Tawuniya always recommend reading the Medical Insurance Guidelines and visiting Tawuniya social media accounts for more information about the ideal use of your Medical Card.",
  },
  {
    id: 2,
    question: "Why is this insurance applied?",
    answer:
      "It's important to distinguish between Medical insurance fraud/abuse and mistakes. Medical insurance fraud is an act of deception by misrepresentation of material facts, or silence when good faith requires expression, resulting in material damage to one who relies on it and the right to rely on it. Simply, it is obtaining something of value from someone else through deceit. By contrast, mistakes are made unknowingly by anyone who lacks knowledge, in other words, it’s a misstatement or unintentional deviation from the truth. Therefore, Tawuniya always recommend reading the Medical Insurance Guidelines and visiting Tawuniya social media accounts for more information about the ideal use of your Medical Card.",
  },
  {
    id: 3,
    question: "Does this insurance cover the risk of contracting coronavirus only while traveling?",
    answer:
      "It's important to distinguish between Medical insurance fraud/abuse and mistakes. Medical insurance fraud is an act of deception by misrepresentation of material facts, or silence when good faith requires expression, resulting in material damage to one who relies on it and the right to rely on it. Simply, it is obtaining something of value from someone else through deceit. By contrast, mistakes are made unknowingly by anyone who lacks knowledge, in other words, it’s a misstatement or unintentional deviation from the truth. Therefore, Tawuniya always recommend reading the Medical Insurance Guidelines and visiting Tawuniya social media accounts for more information about the ideal use of your Medical Card.",
  },
  {
    id: 4,
    question: "Can residents benefit from this program?",
    answer:
      "It's important to distinguish between Medical insurance fraud/abuse and mistakes. Medical insurance fraud is an act of deception by misrepresentation of material facts, or silence when good faith requires expression, resulting in material damage to one who relies on it and the right to rely on it. Simply, it is obtaining something of value from someone else through deceit. By contrast, mistakes are made unknowingly by anyone who lacks knowledge, in other words, it’s a misstatement or unintentional deviation from the truth. Therefore, Tawuniya always recommend reading the Medical Insurance Guidelines and visiting Tawuniya social media accounts for more information about the ideal use of your Medical Card.",
  },
  {
    id: 5,
    question: "How much does this program cost?",
    answer:
      "It's important to distinguish between Medical insurance fraud/abuse and mistakes. Medical insurance fraud is an act of deception by misrepresentation of material facts, or silence when good faith requires expression, resulting in material damage to one who relies on it and the right to rely on it. Simply, it is obtaining something of value from someone else through deceit. By contrast, mistakes are made unknowingly by anyone who lacks knowledge, in other words, it’s a misstatement or unintentional deviation from the truth. Therefore, Tawuniya always recommend reading the Medical Insurance Guidelines and visiting Tawuniya social media accounts for more information about the ideal use of your Medical Card.",
  },
  {
    id: 6,
    question:
      "Can this insurance be used for all my travel trips outside the kingdom all year round?",
    answer:
      "It's important to distinguish between Medical insurance fraud/abuse and mistakes. Medical insurance fraud is an act of deception by misrepresentation of material facts, or silence when good faith requires expression, resulting in material damage to one who relies on it and the right to rely on it. Simply, it is obtaining something of value from someone else through deceit. By contrast, mistakes are made unknowingly by anyone who lacks knowledge, in other words, it’s a misstatement or unintentional deviation from the truth. Therefore, Tawuniya always recommend reading the Medical Insurance Guidelines and visiting Tawuniya social media accounts for more information about the ideal use of your Medical Card.",
  },
  {
    id: 7,
    question: "Does the insurance cover domestic travel trips?",
    answer:
      "It's important to distinguish between Medical insurance fraud/abuse and mistakes. Medical insurance fraud is an act of deception by misrepresentation of material facts, or silence when good faith requires expression, resulting in material damage to one who relies on it and the right to rely on it. Simply, it is obtaining something of value from someone else through deceit. By contrast, mistakes are made unknowingly by anyone who lacks knowledge, in other words, it’s a misstatement or unintentional deviation from the truth. Therefore, Tawuniya always recommend reading the Medical Insurance Guidelines and visiting Tawuniya social media accounts for more information about the ideal use of your Medical Card.",
  },
];

export const faqTakafulData = [
  {
    id: 0,
    question: "What is a group life insurance scheme?",
    answer:
      "It's important to distinguish between Medical insurance fraud/abuse and mistakes. Medical insurance fraud is an act of deception by misrepresentation of material facts, or silence when good faith requires expression, resulting in material damage to one who relies on it and the right to rely on it. Simply, it is obtaining something of value from someone else through deceit. By contrast, mistakes are made unknowingly by anyone who lacks knowledge, in other words, it’s a misstatement or unintentional deviation from the truth. Therefore, Tawuniya always recommend reading the Medical Insurance Guidelines and visiting Tawuniya social media accounts for more information about the ideal use of your Medical Card.",
  },
  {
    id: 1,
    question: "What is the minimum for issuing a policy?",
    answer:
      "It's important to distinguish between Medical insurance fraud/abuse and mistakes. Medical insurance fraud is an act of deception by misrepresentation of material facts, or silence when good faith requires expression, resulting in material damage to one who relies on it and the right to rely on it. Simply, it is obtaining something of value from someone else through deceit. By contrast, mistakes are made unknowingly by anyone who lacks knowledge, in other words, it’s a misstatement or unintentional deviation from the truth. Therefore, Tawuniya always recommend reading the Medical Insurance Guidelines and visiting Tawuniya social media accounts for more information about the ideal use of your Medical Card.",
  },
  {
    id: 2,
    question: "How is the amount to be paid out on death calculated? What’s the benefit based on?",
    answer:
      "It's important to distinguish between Medical insurance fraud/abuse and mistakes. Medical insurance fraud is an act of deception by misrepresentation of material facts, or silence when good faith requires expression, resulting in material damage to one who relies on it and the right to rely on it. Simply, it is obtaining something of value from someone else through deceit. By contrast, mistakes are made unknowingly by anyone who lacks knowledge, in other words, it’s a misstatement or unintentional deviation from the truth. Therefore, Tawuniya always recommend reading the Medical Insurance Guidelines and visiting Tawuniya social media accounts for more information about the ideal use of your Medical Card.",
  },
  {
    id: 3,
    question: "What is the Sum insured per life for the Wafaa Group Medical Protection Insurance?",
    answer:
      "It's important to distinguish between Medical insurance fraud/abuse and mistakes. Medical insurance fraud is an act of deception by misrepresentation of material facts, or silence when good faith requires expression, resulting in material damage to one who relies on it and the right to rely on it. Simply, it is obtaining something of value from someone else through deceit. By contrast, mistakes are made unknowingly by anyone who lacks knowledge, in other words, it’s a misstatement or unintentional deviation from the truth. Therefore, Tawuniya always recommend reading the Medical Insurance Guidelines and visiting Tawuniya social media accounts for more information about the ideal use of your Medical Card.",
  },
  {
    id: 4,
    question: "How would somebody’s cover end?",
    answer:
      "It's important to distinguish between Medical insurance fraud/abuse and mistakes. Medical insurance fraud is an act of deception by misrepresentation of material facts, or silence when good faith requires expression, resulting in material damage to one who relies on it and the right to rely on it. Simply, it is obtaining something of value from someone else through deceit. By contrast, mistakes are made unknowingly by anyone who lacks knowledge, in other words, it’s a misstatement or unintentional deviation from the truth. Therefore, Tawuniya always recommend reading the Medical Insurance Guidelines and visiting Tawuniya social media accounts for more information about the ideal use of your Medical Card.",
  },
  {
    id: 5,
    question: "What information is required from a business so they can get a quote?",
    answer:
      "It's important to distinguish between Medical insurance fraud/abuse and mistakes. Medical insurance fraud is an act of deception by misrepresentation of material facts, or silence when good faith requires expression, resulting in material damage to one who relies on it and the right to rely on it. Simply, it is obtaining something of value from someone else through deceit. By contrast, mistakes are made unknowingly by anyone who lacks knowledge, in other words, it’s a misstatement or unintentional deviation from the truth. Therefore, Tawuniya always recommend reading the Medical Insurance Guidelines and visiting Tawuniya social media accounts for more information about the ideal use of your Medical Card.",
  },
  {
    id: 6,
    question: "How I can Get Wafaa Group medical protection Insurance?",
    answer:
      "It's important to distinguish between Medical insurance fraud/abuse and mistakes. Medical insurance fraud is an act of deception by misrepresentation of material facts, or silence when good faith requires expression, resulting in material damage to one who relies on it and the right to rely on it. Simply, it is obtaining something of value from someone else through deceit. By contrast, mistakes are made unknowingly by anyone who lacks knowledge, in other words, it’s a misstatement or unintentional deviation from the truth. Therefore, Tawuniya always recommend reading the Medical Insurance Guidelines and visiting Tawuniya social media accounts for more information about the ideal use of your Medical Card.",
  },
];

export const faqChronicData = [
  {
    id: 0,
    question: "What Chronic Diseases are covered ?",
    answer: "It's important to distinguish between Medical insurance fraud/abuse and mistakes. ",
  },
  {
    id: 1,
    question: "What are the program services? ",
    answer: "It's important to distinguish between Medical insurance fraud/abuse and mistakes.",
  },
  {
    id: 2,
    question: "Who’s eligible for the program? ",
    answer: "It's important to distinguish between Medical insurance fraud/abuse and mistakes.",
  },
  {
    id: 3,
    question: "How do I register for the service? ",
    answer: "It's important to distinguish between Medical insurance fraud/abuse and mistakes.",
  },
  {
    id: 4,
    question: "What  is the follow up period between appointments?",
    answer: "It's important to distinguish between Medical insurance fraud/abuse and mistakes.",
  },
  {
    id: 5,
    question: "Is there an option to change the doctor during the follow-up?",
    answer: "It's important to distinguish between Medical insurance fraud/abuse and mistakes.",
  },
];

export const faqhomeChildVaccinationData = [
  {
    id: 0,
    question: "Is the home vaccination service free?",
    answer: "It's important to distinguish between Medical insurance fraud/abuse and mistakes. ",
  },
  {
    id: 1,
    question: "Is the deductible amount includes VAT ?",
    answer: "It's important to distinguish between Medical insurance fraud/abuse and mistakes.",
  },
  {
    id: 2,
    question: "Do I have to pay a deductible amount when visiting a service provider?",
    answer: "It's important to distinguish between Medical insurance fraud/abuse and mistakes.",
  },
  {
    id: 3,
    question: "What does the home vaccination service include?",
    answer: "It's important to distinguish between Medical insurance fraud/abuse and mistakes.",
  },
  {
    id: 4,
    question: "What vaccinations are available?",
    answer: "It's important to distinguish between Medical insurance fraud/abuse and mistakes.",
  },
  {
    id: 5,
    question: "Is there an alert or reminder of the dates of vaccinations?",
    answer: "It's important to distinguish between Medical insurance fraud/abuse and mistakes.",
  },
  {
    id: 6,
    question: "When should a child's vaccination be postponed?",
    answer: "It's important to distinguish between Medical insurance fraud/abuse and mistakes.",
  },
  {
    id: 7,
    question: "What if one of the vaccination doses to the child is delayed?",
    answer: "It's important to distinguish between Medical insurance fraud/abuse and mistakes.",
  },
];

export const faqContainerMedicData = [
  {
    serviceTitle: "custm_medical.faq.report_medical",
    servicePara: "custm_medical.faq.report_medical_para",
    start_report: "custm_medical.faq.start_btn",
  },
];

export const customerServiceQAs = [
  {
    question: "custm_service.q1",
    answer: "custm_service.a1",
    answersList: [
      { answer: "custm_service.a1_l1" },
      { answer: "custm_service.a1_l2" },
      { answer: "custm_service.a1_l3" },
      { answer: "custm_service.a1_l4" },
      { answer: "custm_service.a1_l5" },
      { answer: "custm_service.a1_l6" },
      { answer: "custm_service.a1_l7" },
    ],
  },
  {
    question: "custm_service.q2",
    answer: "custm_service.a2",
  },
  {
    question: "custm_service.q3",
    answer: "custm_service.a3",
    answersList: [
      { answer: "custm_service.a3_l1" },
      { answer: "custm_service.a3_l2" },
      { answer: "custm_service.a3_l3" },
    ],
  },
  {
    question: "custm_service.q4",
    answer: "custm_service.a4",
  },
  {
    question: "custm_service.q5",
    answer: "custm_service.a5",
  },
  {
    question: "custm_service.q6",
    answer: "custm_service.a6",
  },
  {
    question: "custm_service.q7",
    answer: "custm_service.a7",
  },
  {
    question: "custm_service.q8",
    answer: "custm_service.a8",
    link: "https://www.tawuniya.com",
  },
];

export const faqCategoryData = [
  {
    id: 0,
    question: "custm_service.q1",
    answer: "custm_service.a1",
  },
  {
    id: 1,
    question: "custm_service.q2",
    answer: "custm_service.a2",
  },
];

export const faqCategoryData2 = [
  {
    id: 2,
    question: "custm_service.q3",
    answer: "custm_service.a3",
  },
  {
    id: 3,
    question: "custm_service.q4",
    answer: "custm_service.a4",
  },
];

export const faqCategoryDataAgentsPage = [
  {
    id: 0,
    question: "What is insurance fraud?",
    answer:
      "It's important to distinguish between Medical insurance fraud/abuse and mistakes. Medical insurance fraud is an act of deception by misrepresentation of material facts, or silence when good faith requires expression, resulting in material damage to one who relies on it and the right to rely on it. Simply, it is obtaining something of value from someone else through deceit. By contrast, mistakes are made unknowingly by anyone who lacks knowledge, in other words, it’s a misstatement or unintentional deviation from the truth. Therefore, Tawuniya always recommend reading the Medical Insurance Guidelines and visiting Tawuniya social media accounts for more information about the ideal use of your Medical Card.",
  },
  {
    id: 1,
    question: "What kind of people/Organizations commits insurance fraud?",
    answer:
      "It's important to distinguish between Medical insurance fraud/abuse and mistakes. Medical insurance fraud is an act of deception by misrepresentation of material facts, or silence when good faith requires expression, resulting in material damage to one who relies on it and the right to rely on it. Simply, it is obtaining something of value from someone else through deceit. By contrast, mistakes are made unknowingly by anyone who lacks knowledge, in other words, it’s a misstatement or unintentional deviation from the truth. Therefore, Tawuniya always recommend reading the Medical Insurance Guidelines and visiting Tawuniya social media accounts for more information about the ideal use of your Medical Card.",
  },
  {
    id: 2,
    question: "What is fraud vs. Mistake?",
    answer:
      "It's important to distinguish between Medical insurance fraud/abuse and mistakes. Medical insurance fraud is an act of deception by misrepresentation of material facts, or silence when good faith requires expression, resulting in material damage to one who relies on it and the right to rely on it. Simply, it is obtaining something of value from someone else through deceit. By contrast, mistakes are made unknowingly by anyone who lacks knowledge, in other words, it’s a misstatement or unintentional deviation from the truth. Therefore, Tawuniya always recommend reading the Medical Insurance Guidelines and visiting Tawuniya social media accounts for more information about the ideal use of your Medical Card.",
  },
  {
    id: 3,
    question: "What are some examples of insurance fraud/Abuse?",
    answer:
      "It's important to distinguish between Medical insurance fraud/abuse and mistakes. Medical insurance fraud is an act of deception by misrepresentation of material facts, or silence when good faith requires expression, resulting in material damage to one who relies on it and the right to rely on it. Simply, it is obtaining something of value from someone else through deceit. By contrast, mistakes are made unknowingly by anyone who lacks knowledge, in other words, it’s a misstatement or unintentional deviation from the truth. Therefore, Tawuniya always recommend reading the Medical Insurance Guidelines and visiting Tawuniya social media accounts for more information about the ideal use of your Medical Card.",
  },
  {
    id: 4,
    question: "How does fraud impact you?",
    answer:
      "It's important to distinguish between Medical insurance fraud/abuse and mistakes. Medical insurance fraud is an act of deception by misrepresentation of material facts, or silence when good faith requires expression, resulting in material damage to one who relies on it and the right to rely on it. Simply, it is obtaining something of value from someone else through deceit. By contrast, mistakes are made unknowingly by anyone who lacks knowledge, in other words, it’s a misstatement or unintentional deviation from the truth. Therefore, Tawuniya always recommend reading the Medical Insurance Guidelines and visiting Tawuniya social media accounts for more information about the ideal use of your Medical Card.",
  },
  {
    id: 5,
    question: "What are we doing to prevent fraud?",
    answer:
      "It's important to distinguish between Medical insurance fraud/abuse and mistakes. Medical insurance fraud is an act of deception by misrepresentation of material facts, or silence when good faith requires expression, resulting in material damage to one who relies on it and the right to rely on it. Simply, it is obtaining something of value from someone else through deceit. By contrast, mistakes are made unknowingly by anyone who lacks knowledge, in other words, it’s a misstatement or unintentional deviation from the truth. Therefore, Tawuniya always recommend reading the Medical Insurance Guidelines and visiting Tawuniya social media accounts for more information about the ideal use of your Medical Card.",
  },
  {
    id: 6,
    question: "What is medical fraud unit?",
    answer:
      "It's important to distinguish between Medical insurance fraud/abuse and mistakes. Medical insurance fraud is an act of deception by misrepresentation of material facts, or silence when good faith requires expression, resulting in material damage to one who relies on it and the right to rely on it. Simply, it is obtaining something of value from someone else through deceit. By contrast, mistakes are made unknowingly by anyone who lacks knowledge, in other words, it’s a misstatement or unintentional deviation from the truth. Therefore, Tawuniya always recommend reading the Medical Insurance Guidelines and visiting Tawuniya social media accounts for more information about the ideal use of your Medical Card.",
  },
  {
    id: 7,
    question: "Shall I get a reward for reporting medical insurance fraud?",
    answer:
      "It's important to distinguish between Medical insurance fraud/abuse and mistakes. Medical insurance fraud is an act of deception by misrepresentation of material facts, or silence when good faith requires expression, resulting in material damage to one who relies on it and the right to rely on it. Simply, it is obtaining something of value from someone else through deceit. By contrast, mistakes are made unknowingly by anyone who lacks knowledge, in other words, it’s a misstatement or unintentional deviation from the truth. Therefore, Tawuniya always recommend reading the Medical Insurance Guidelines and visiting Tawuniya social media accounts for more information about the ideal use of your Medical Card.",
  },
];

export const faqContainerMotorData = [
  {
    serviceTitle: "cust_motor.faq.title",
    servicePara: "cust_motor.faq.para",
  },
];

export const faqContainerTravelData = [
  {
    serviceTitle: "cust_travel.faq.title",
    servicePara: "cust_travel.faq.para",
  },
];

export const associateMedicData = [
  {
    id: 0,
    liningClass: "associateLining pr-lg-3",
    associateTitleClass: "associateTitle",
    associateTitle: "custm_medical.negative_impact.title",
    associatePara: [
      {
        id: 0,
        para: "custm_medical.negative_impact.impact_1",
        associateParaClass: "associateParaOne",
      },
      {
        id: 1,
        para: "custm_medical.negative_impact.impact_2",
        associateParaClass: "associateParaOne",
      },
      {
        id: 2,
        para: "custm_medical.negative_impact.impact_3",
        associateParaClass: "associateParaOne",
      },
      {
        id: 3,
        para: "custm_medical.negative_impact.impact_4",
        associateParaClass: "associateParaOne",
      },
      {
        id: 4,
        para: "custm_medical.negative_impact.impact_5",
        associateParaClass: "associateParaOne",
      },
      {
        id: 5,
        para: "custm_medical.negative_impact.impact_6",
        associateParaClass: "associateParaOne",
      },
      {
        id: 6,
        para: "custm_medical.negative_impact.impact_7",
        associateParaClass: "associateParaOne",
      },
      {
        id: 7,
        para: "custm_medical.negative_impact.impact_8",
        associateParaClass: "associateParaOne",
      },
    ],
  },
  {
    id: 1,
    liningClass: "associateLining pl-lg-4 pr-lg-3",
    associateTitleClass: "associateTitle",
    associateTitle: "custm_medical.medical_detect.title",
    associatePara: [
      {
        id: 0,
        para: "custm_medical.medical_detect.detect_1",
        associateParaClass: "associateParaOne",
      },
      {
        id: 1,
        para: "custm_medical.medical_detect.detect_2",
        associateParaClass: "associateParaOne",
      },
      {
        id: 2,
        para: "custm_medical.medical_detect.detect_3",
        associateParaClass: "associateParaOne",
      },
      {
        id: 3,
        para: "custm_medical.medical_detect.detect_4",
        associateParaClass: "associateParaOne",
      },
      {
        id: 4,
        para: "custm_medical.medical_detect.detect_5",
        associateParaClass: "associateParaOne",
      },
    ],
  },
  {
    id: 2,
    liningClass: "associateNoLining pl-lg-4",
    associateTitleClass: "associateTitle",
    associateTitle: "custm_medical.response_prevent.title",
    associatePara: [
      {
        id: 0,
        para: "custm_medical.response_prevent.response_1",
        associateParaClass: "associateParaNoLining",
      },
      {
        id: 1,
        para: "custm_medical.response_prevent.response_2",
        associateParaClass: "associateParaNoLining",
      },
      {
        id: 2,
        para: "custm_medical.response_prevent.response_3",
        associateParaClass: "associateParaNoLining",
      },
      {
        id: 3,
        para: "custm_medical.response_prevent.response_4",
        associateParaClass: "associateParaNoLining",
      },
      {
        id: 4,
        para: "custm_medical.response_prevent.response_5",
        associateParaClass: "associateParaNoLining",
      },
    ],
  },
];

export const associateMotorData = [
  {
    id: 0,
    liningClass: "associateLining pr-lg-0",
    associateTitleClass: "associateTitle",
    associateTitle: "cust_motor.negative_impact.title",
    associatePara: [
      {
        id: 0,
        para: "cust_motor.negative_impact.impact_1",
        associateParaClass: "associateParaOne",
      },
      {
        id: 1,
        para: "cust_motor.negative_impact.impact_2",
        associateParaClass: "associateParaOne",
      },
      {
        id: 2,
        para: "cust_motor.negative_impact.impact_3",
        associateParaClass: "associateParaOne",
      },
      {
        id: 3,
        para: "cust_motor.negative_impact.impact_4",
        associateParaClass: "associateParaOne",
      },
      {
        id: 4,
        para: "cust_motor.negative_impact.impact_5",
        associateParaClass: "associateParaOne",
      },
      {
        id: 5,
        para: "cust_motor.negative_impact.impact_6",
        associateParaClass: "associateParaOne",
      },
    ],
  },
  {
    id: 1,
    liningClass: "associateLining pl-lg-4 pr-lg-3",
    associateTitleClass: "associateTitle",
    associateTitle: "cust_motor.fraud_detect.title",
    associatePara: [
      {
        id: 0,
        para: "cust_motor.fraud_detect.detect_1",
        associateParaClass: "associateParaOne",
      },
      {
        id: 1,
        para: "cust_motor.fraud_detect.detect_2",
        associateParaClass: "associateParaOne",
      },
      {
        id: 2,
        para: "cust_motor.fraud_detect.detect_3",
        associateParaClass: "associateParaOne",
      },
      {
        id: 3,
        para: "cust_motor.fraud_detect.detect_4",
        associateParaClass: "associateParaOne",
      },
    ],
  },
  {
    id: 2,
    liningClass: "associateNoLining pl-lg-4",
    associateTitleClass: "associateTitle",
    associateTitle: "cust_motor.prevent_motor.title",
    associatePara: [
      {
        id: 0,
        para: "cust_motor.prevent_motor.prevent_1",
        associateParaClass: "associateParaNoLining",
      },
      {
        id: 1,
        para: "cust_motor.prevent_motor.prevent_2",
        associateParaClass: "associateParaNoLining",
      },
      {
        id: 2,
        para: "cust_motor.prevent_motor.prevent_3",
        associateParaClass: "associateParaNoLining",
      },
    ],
  },
];

export const associateTravelData = [
  {
    id: 0,
    liningClass: "associateLining pr-lg-3",
    associateTitleClass: "associateTitle-travel",
    associateTitle: "cust_travel.prop_casu.title",
    associatePara: [
      {
        id: 0,
        para: "cust_travel.prop_casu.para",
        associateParaClass: "associateParaTwo",
        needList: true,
      },
    ],
  },
  {
    id: 1,
    liningClass: "associateLining pl-lg-4 pr-lg-3",
    associateTitleClass: "associateTitle-travel",
    associateTitle: "cust_travel.ex_prop.title",
    associatePara: [
      {
        id: 0,
        para: "cust_travel.ex_prop.list1",
        associateParaClass: "associateParaTwo",
      },
      {
        id: 1,
        para: "cust_travel.ex_prop.list2",
        associateParaClass: "associateParaTwo",
      },
      {
        id: 2,
        para: "cust_travel.ex_prop.list3",
        associateParaClass: "associateParaTwo",
      },
      {
        id: 3,
        para: "cust_travel.ex_prop.list4",
        associateParaClass: "associateParaTwo",
      },
    ],
  },
  {
    id: 2,
    liningClass: "associateNoLining pl-lg-4",
    associateTitleClass: "associateTitle-travel",
    associateTitle: "cust_travel.negative.title",
    associatePara: [
      {
        id: 0,
        para: "cust_travel.negative.list1",
        associateParaClass: "associateParaNoLining",
      },
      {
        id: 1,
        para: "cust_travel.negative.list2",
        associateParaClass: "associateParaNoLining",
      },
      {
        id: 2,
        para: "cust_travel.negative.list3",
        associateParaClass: "associateParaNoLining",
      },
    ],
  },
];

export const associateTravelNewData = [
  {
    id: 0,
    liningClass: "associateLining pr-lg-3",
    associateTitleClass: "associateTitle-travel",
    associateTitle: "cust_travel.mechanism.title",
    associatePara: [
      {
        id: 0,
        para: "cust_travel.mechanism.list1",
        associateParaClass: "associateParaOne",
      },
      {
        id: 1,
        para: "cust_travel.mechanism.list2",
        associateParaClass: "associateParaOne",
      },
      {
        id: 2,
        para: "cust_travel.mechanism.list3",
        associateParaClass: "associateParaOne",
      },
    ],
  },
  {
    id: 1,
    liningClass: "associateLining pl-lg-4 pr-lg-3",
    associateTitleClass: "associateTitle-travel",
    associateTitle: "cust_travel.prevent.title",
    associatePara: [
      {
        id: 0,
        para: "cust_travel.prevent.para",
        associateParaClass: "associateParaThree",
        needList: true,
      },
    ],
  },
  {
    id: 2,
    liningClass: "associateNoLining pl-lg-4",
    associateTitleClass: "associateTitle-travel",
    associateTitle: "cust_travel.reward.title",
    associatePara: [
      {
        id: 0,
        para: "cust_travel.reward.para",
        associateParaClass: "associateParaNoLiningNew",
        needList: true,
      },
    ],
  },
  // {
  // 	id: 0,
  // 	liningClass: "associateLining pr-lg-3",
  // 	associateTitleClass: "associateTitle-travel",
  // 	associateTitle:
  // 		"cust_travel.insure.title",
  // 	associatePara: [
  // 		{
  // 			id: 0,
  // 			para: "cust_travel.insure.list1",
  // 			associateParaClass: "associateParaOne",
  // 		},
  // 		{
  // 			id: 1,
  // 			para: "cust_travel.insure.list2",
  // 			associateParaClass: "associateParaOne",
  // 		},
  // 		{
  // 			id: 2,
  // 			para: "cust_travel.insure.list3",
  // 			associateParaClass: "associateParaOne",
  // 		},
  // 	],
  // },
  // {
  // 	id: 1,
  // 	liningClass: "associateLining pl-lg-4 pr-lg-3",
  // 	associateTitleClass: "associateTitle-travel",
  // 	associateTitle: "cust_travel.prevent1.title",
  // 	associatePara: [
  // 		{
  // 			id: 0,
  // 			para: "cust_travel.prevent1.para",
  // 			associateParaClass: "associateParaThree",
  // 			needList: true,
  // 		},
  // 	],
  // },
  // {
  // 	id: 2,
  // 	liningClass: "associateNoLining pl-lg-4",
  // 	associateTitleClass: "associateTitle-travel",
  // 	associateTitle:
  // 		"cust_travel.reward1.title",
  // 	associatePara: [
  // 		{
  // 			id: 0,
  // 			para: "cust_travel.reward1.para",
  // 			associateParaClass: "associateParaNoLiningNew",
  // 			needList: true,
  // 		},
  // 	],
  // },
];

export const serviceData = [
  // {
  //   headingEl: "products_and_services.tawuniya_drive",
  //   discrptionEl: "products_and_services.tawuniya_drive_desc",
  //   iconE1: `${tawuniyaDrive}`,
  //   type: "motor",
  // },
  {
    headingEl: "products_and_services.road_assistance",
    discrptionEl: "products_and_services.road_assistance_desc",
    iconE1: `${roadSide}`,
    type: "motor",
    url: "/individuals/customer-service/road-assistance"
  },

  {
    headingEl: "products_and_services.claim_service",
    discrptionEl: "products_and_services.claim_service_desc",
    iconE1: `${roadSide}`,
    type: "motor",
    url: "/services/e2e-claims",
    externalLinks: true
  },
  {
    headingEl: "products_and_services.chronic_diss_management",
    discrptionEl: "products_and_services.chronic_diss_management_desc",
    iconE1: `${eligibility}`,
    type: "health",
    url: "/individuals/customer-service/chronic-disease-management"

  },
  // {
  //   headingEl: "products_and_services.pregnancy_prog",
  //   discrptionEl: "products_and_services.pregnancy_prog_desc",
  //   iconE1: `${pregnancy}`,
  //   type: "health",
  // },
  {
    headingEl: "products_and_services.eligibility_letter",
    discrptionEl: "products_and_services.eligibility_letter_desc",
    iconE1: `${ithra}`,
    type: "health",
    url: "/individuals/products/health/eligibility-letter"

  },
  {
    headingEl: "products_and_services.child_vaccination",
    discrptionEl: "products_and_services.child_vaccination_desc",
    iconE1: `${vaccinate}`,
    type: "health",
    url: "/individuals/customer-service/home-child-vaccination"

  },
  // {
  //   headingEl: "products_and_services.travel_covid",
  //   discrptionEl: "products_and_services.travel_covid_desc",
  //   iconE1: `${covid}`,
  //   type: "travel",
  // },
  {
    headingEl: "products_and_services.periodic_inspection",
    discrptionEl: "products_and_services.periodic_inspection_desc",
    iconE1: `${motorInspect}`,
    hideCard: true,
    type: "travel",
    url: "/individuals/customer-service/periodic-inspection"

  },
];
export const serviceDataBackup = [
  {
    id: 0,
    title: "SupportCenterMobile.c1_title",
    content: "SupportCenterMobile.c1_content",
    contentAlignClass: "pb-5",
    imgOne: serviceIconOne,
    imgTwo: serviceArrow,
  },
  {
    id: 1,
    title: "SupportCenterMobile.c2_title",
    content: "SupportCenterMobile.c2_content",
    contentAlignClass: "pb-5",
    imgOne: telemed,
    imgTwo: serviceArrow,
  },
  {
    id: 2,
    title: "SupportCenterMobile.c3_title",
    content: "SupportCenterMobile.c3_content",
    contentAlignClass: "pb-5",
    imgOne: theft,
    imgTwo: serviceArrow,
  },
  {
    id: 3,
    title: "SupportCenterMobile.c4_title",
    content: "SupportCenterMobile.c4_content",
    contentAlignClass: "pb-5",
    imgOne: flight,
    imgTwo: serviceArrow,
  },
  {
    id: 4,
    title: "SupportCenterMobile.c5_title",
    content: "SupportCenterMobile.c5_content",
    contentAlignClass: "pb-5",
    imgOne: accident,
    imgTwo: serviceArrow,
  },
  {
    id: 5,
    title: "SupportCenterMobile.c6_title",
    content: "SupportCenterMobile.c6_content",
    contentAlignClass: "pb-5",
    imgOne: medAssisst,
    imgTwo: serviceArrow,
  },
  {
    id: 6,
    title: "SupportCenterMobile.c7_title",
    content: "SupportCenterMobile.c7_content",
    contentAlignClass: "pb-5",
    imgOne: theft,
    imgTwo: serviceArrow,
  },
  {
    id: 7,
    title: "SupportCenterMobile.c8_title",
    content: "SupportCenterMobile.c8_content",
    contentAlignClass: "pb-5",
    imgOne: accident,
    imgTwo: serviceArrow,
  },
  {
    id: 8,
    title: "SupportCenterMobile.c9_title",
    content: "SupportCenterMobile.c9_content",
    contentAlignClass: "pb-5",
    imgOne: medAssisst,
    imgTwo: serviceArrow,
  },
  {
    id: 9,
    title: "SupportCenterMobile.c10_title",
    content: "SupportCenterMobile.c10_content",
    contentAlignClass: "pb-5",
    imgOne: telemed,
    imgTwo: serviceArrow,
  },
  {
    id: 10,
    title: "SupportCenterMobile.c11_title",
    content: "SupportCenterMobile.c11_content",
    contentAlignClass: "pb-5",
    imgOne: serviceIconOne,
    imgTwo: serviceArrow,
  },
  {
    id: 11,
    title: "SupportCenterMobile.c12_title",
    content: "SupportCenterMobile.c12_content",
    contentAlignClass: "pb-5",
    imgOne: flight,
    imgTwo: serviceArrow,
  },
];

export const serviceSupportData = [
  {
    id: 0,
    title: "Roadside assistance",
    content: "Our services can be trustable, honest and worthy",
    contentAlignClass: "pb-5",
    imgOne: serviceIconOne,
    imgTwo: serviceArrow,
  },
  {
    id: 1,
    title: "Request Telemedicine",
    content: "Our services can be trustable, honest.",
    contentAlignClass: "pb-5",
    imgOne: telemed,
    imgTwo: serviceArrow,
  },
  {
    id: 2,
    title: "Report a Theft",
    content: "Our services can be trustable, honest and worthy",
    contentAlignClass: "pb-5",
    imgOne: theft,
    imgTwo: serviceArrow,
  },
  {
    id: 3,
    title: "Flight Delay Claim",
    content: "Our services can be trustable, honest and worthy",
    contentAlignClass: "pb-5",
    imgOne: flight,
    imgTwo: serviceArrow,
  },
  {
    id: 4,
    title: "Report a Theft",
    content: "Our services can be trustable, honest and worthy",
    contentAlignClass: "pb-5",
    imgOne: theft,
    imgTwo: serviceArrow,
  },
  {
    id: 5,
    title: "Assist in Accident",
    content: "Our services can be trustable, honest and worthy",
    contentAlignClass: "pb-5",
    imgOne: accident,
    imgTwo: serviceArrow,
  },
  {
    id: 6,
    title: "Roadside assistance",
    content: "Our services can be trustable, honest and worthy",
    contentAlignClass: "pb-5",
    imgOne: serviceIconOne,
    imgTwo: serviceArrow,
  },
  {
    id: 7,
    title: "Flight Delay Claim",
    content: "Our services can be trustable, honest and worthy",
    contentAlignClass: "pb-5",
    imgOne: flight,
    imgTwo: serviceArrow,
  },
];

export const customerLandingData = [
  {
    id: 0,
    title: "custm_service.c1_title",
    content: "custm_service.c1_content",
    contentAlignClass: "",
    imgOne: serviceIconOne,
    imgTwo: serviceArrow,
  },
  {
    id: 1,
    title: "custm_service.c2_title",
    content: "custm_service.c2_content",
    contentAlignClass: "",
    imgOne: telemed,
    imgTwo: serviceArrow,
  },
  {
    id: 2,
    title: "custm_service.c3_title",
    content: "custm_service.c3_content",
    contentAlignClass: "",
    imgOne: theft,
    imgTwo: serviceArrow,
  },
  {
    id: 3,
    title: "custm_service.c4_title",
    content: "custm_service.c4_content",
    contentAlignClass: "",
    imgOne: flight,
    imgTwo: serviceArrow,
  },
  {
    id: 4,
    title: "custm_service.c5_title",
    content: "custm_service.c5_content",
    contentAlignClass: "",
    imgOne: accident,
    imgTwo: serviceArrow,
  },
  {
    id: 5,
    title: "custm_service.c6_title",
    content: "custm_service.c6_content",
    contentAlignClass: "",
    imgOne: medAssisst,
    imgTwo: serviceArrow,
  },
  {
    id: 6,
    title: "custm_service.c7_title",
    content: "custm_service.c7_content",
    contentAlignClass: "",
    imgOne: theft,
    imgTwo: serviceArrow,
  },
  {
    id: 7,
    title: "custm_service.c8_title",
    content: "custm_service.c8_content",
    contentAlignClass: "",
    imgOne: accident,
    imgTwo: serviceArrow,
  },
  {
    id: 8,
    title: "custm_service.c9_title",
    content: "custm_service.c9_content",
    contentAlignClass: "",
    imgOne: medAssisst,
    imgTwo: serviceArrow,
  },
  {
    id: 9,
    title: "custm_service.c10_title",
    content: "custm_service.c10_content",
    contentAlignClass: "",
    imgOne: telemed,
    imgTwo: serviceArrow,
  },
  {
    id: 10,
    title: "custm_service.c11_title",
    content: "custm_service.c11_content",
    contentAlignClass: "",
    imgOne: serviceIconOne,
    imgTwo: serviceArrow,
  },
  {
    id: 11,
    title: "custm_service.c12_title",
    content: "custm_service.c12_content",
    contentAlignClass: "",
    imgOne: flight,
    imgTwo: serviceArrow,
  },
];

export const serviceCategoryData = [
  {
    id: 0,
    title: "Roadside assistance",
    content: "Our services can be trustable, honest and worthy",
    contentAlignClass: "pb-5",
    imgOne: serviceIconOne,
    imgTwo: serviceArrow,
  },
  {
    id: 1,
    title: "Request Telemedicine",
    content: "Our services can be trustable, honest.",
    contentAlignClass: "pb-5",
    imgOne: telemed,
    imgTwo: serviceArrow,
  },
  {
    id: 2,
    title: "Report a Theft",
    content: "Our services can be trustable, honest and worthy",
    contentAlignClass: "pb-5",
    imgOne: theft,
    imgTwo: serviceArrow,
  },
  {
    id: 3,
    title: "Flight Delay Claim",
    content: "Our services can be trustable, honest and worthy",
    contentAlignClass: "pb-5",
    imgOne: flight,
    imgTwo: serviceArrow,
  },
  {
    id: 4,
    title: "Assist in Accident",
    content: "Our services can be trustable, honest and worthy",
    contentAlignClass: "pb-5",
    imgOne: accident,
    imgTwo: serviceArrow,
  },
  {
    id: 5,
    title: "Assist in medical",
    content: "Our services can be trustable, honest and worthy",
    contentAlignClass: "pb-5",
    imgOne: medAssisst,
    imgTwo: serviceArrow,
  },
  {
    id: 6,
    title: "Report a Theft",
    content: "Our services can be trustable, honest and worthy",
    contentAlignClass: "pb-5",
    imgOne: theft,
    imgTwo: serviceArrow,
  },
  {
    id: 7,
    title: "Assist in Accident",
    content: "Our services can be trustable, honest and worthy",
    contentAlignClass: "pb-5",
    imgOne: accident,
    imgTwo: serviceArrow,
  },
  {
    id: 8,
    title: "Assist in medical",
    content: "Our services can be trustable, honest and worthy",
    contentAlignClass: "pb-5",
    imgOne: medAssisst,
    imgTwo: serviceArrow,
  },
  {
    id: 9,
    title: "Request Telemedicine",
    content: "Our services can be trustable, honest.",
    contentAlignClass: "pb-5",
    imgOne: telemed,
    imgTwo: serviceArrow,
  },
  {
    id: 10,
    title: "Roadside assistance",
    content: "Our services can be trustable, honest and worthy",
    contentAlignClass: "pb-5",
    imgOne: serviceIconOne,
    imgTwo: serviceArrow,
  },
  {
    id: 11,
    title: "Flight Delay Claim",
    content: "Our services can be trustable, honest and worthy",
    contentAlignClass: "pb-5",
    imgOne: flight,
    imgTwo: serviceArrow,
  },
  {
    id: 12,
    title: "Report a Theft",
    content: "Our services can be trustable, honest and worthy",
    contentAlignClass: "pb-5",
    imgOne: theft,
    imgTwo: serviceArrow,
  },
  {
    id: 13,
    title: "Assist in Accident",
    content: "Our services can be trustable, honest and worthy",
    contentAlignClass: "pb-5",
    imgOne: accident,
    imgTwo: serviceArrow,
  },
  {
    id: 14,
    title: "Assist in medical",
    content: "Our services can be trustable, honest and worthy",
    contentAlignClass: "pb-5",
    imgOne: medAssisst,
    imgTwo: serviceArrow,
  },
  {
    id: 15,
    title: "Request Telemedicine",
    content: "Our services can be trustable, honest.",
    contentAlignClass: "pb-5",
    imgOne: telemed,
    imgTwo: serviceArrow,
  },
];

export const resultData = [
  {
    id: 0,
    pillNo: 0,
    code: "#COM-0927431",
    date: "12 Dec 2021",
    statusClass: "searchStatus",
    status: "Under Processing",
    detailsData: [
      {
        id: 0,
        complaintTitle: "Complaint number",
        complaintText: "#COM-0927431",
      },
      {
        id: 1,
        complaintTitle: "Complaint Submission Date",
        complaintText: "12 Dec 2021",
      },
      {
        id: 2,
        complaintTitle: "Complaint Closure Date",
        complaintText: "14 Dec 2021",
      },
      {
        id: 3,
        complaintTitle: "Complaint Category",
        complaintText: "Motor",
      },
      {
        id: 4,
        complaintTitle: "Care Owner Details",
        complaintText: "#COM-0927431",
      },
      {
        id: 5,
        complaintTitle: "Care Owner Details",
        complaintText: "Care Owner Name",
      },
      {
        id: 6,
        complaintTitle: "Care Owner Details",
        complaintText: "email@tawuniya.com.sa",
      },
      {
        id: 7,
        complaintTitle: "Care Owner Direct Extension Number",
        complaintText: "Direct Extension Number",
      },
      {
        id: 8,
        complaintTitle: "",
        complaintText: "",
      },
    ],
  },
  {
    id: 1,
    pillNo: 1,
    code: "#COM-0927431",
    date: "12 Dec 2021",
    statusClass: "searchSuccessStatus",
    status: "Resolved",
    detailsData: [
      {
        id: 0,
        complaintTitle: "Complaint number",
        complaintText: "#COM-0927431",
      },
      {
        id: 1,
        complaintTitle: "Complaint Submission Date",
        complaintText: "12 Dec 2021",
      },
      {
        id: 2,
        complaintTitle: "Complaint Closure Date",
        complaintText: "14 Dec 2021",
      },
      {
        id: 3,
        complaintTitle: "Complaint Category",
        complaintText: "Motor",
      },
      {
        id: 4,
        complaintTitle: "Care Owner Details",
        complaintText: "#COM-0927431",
      },
      {
        id: 5,
        complaintTitle: "Care Owner Details",
        complaintText: "Care Owner Name",
      },
      {
        id: 6,
        complaintTitle: "Care Owner Details",
        complaintText: "email@tawuniya.com.sa",
      },
      {
        id: 7,
        complaintTitle: "Care Owner Direct Extension Number",
        complaintText: "Direct Extension Number",
      },
      {
        id: 8,
        complaintTitle: "",
        complaintText: "",
      },
    ],
  },
  {
    id: 2,
    pillNo: 2,
    code: "#COM-0927431",
    date: "12 Dec 2021",
    statusClass: "searchSuccessStatus",
    status: "Resolved",
    detailsData: [
      {
        id: 0,
        complaintTitle: "Complaint number",
        complaintText: "#COM-0927431",
      },
      {
        id: 1,
        complaintTitle: "Complaint Submission Date",
        complaintText: "12 Dec 2021",
      },
      {
        id: 2,
        complaintTitle: "Complaint Closure Date",
        complaintText: "14 Dec 2021",
      },
      {
        id: 3,
        complaintTitle: "Complaint Category",
        complaintText: "Motor",
      },
      {
        id: 4,
        complaintTitle: "Care Owner Details",
        complaintText: "#COM-0927431",
      },
      {
        id: 5,
        complaintTitle: "Care Owner Details",
        complaintText: "Care Owner Name",
      },
      {
        id: 6,
        complaintTitle: "Care Owner Details",
        complaintText: "email@tawuniya.com.sa",
      },
      {
        id: 7,
        complaintTitle: "Care Owner Direct Extension Number",
        complaintText: "Direct Extension Number",
      },
      {
        id: 8,
        complaintTitle: "",
        complaintText: "",
      },
    ],
  },
  {
    id: 3,
    pillNo: 3,
    code: "#COM-0927431",
    date: "12 Dec 2021",
    statusClass: "searchSuccessStatus",
    status: "Resolved",
    detailsData: [
      {
        id: 0,
        complaintTitle: "Complaint number",
        complaintText: "#COM-0927431",
      },
      {
        id: 1,
        complaintTitle: "Complaint Submission Date",
        complaintText: "12 Dec 2021",
      },
      {
        id: 2,
        complaintTitle: "Complaint Closure Date",
        complaintText: "14 Dec 2021",
      },
      {
        id: 3,
        complaintTitle: "Complaint Category",
        complaintText: "Motor",
      },
      {
        id: 4,
        complaintTitle: "Care Owner Details",
        complaintText: "#COM-0927431",
      },
      {
        id: 5,
        complaintTitle: "Care Owner Details",
        complaintText: "Care Owner Name",
      },
      {
        id: 6,
        complaintTitle: "Care Owner Details",
        complaintText: "email@tawuniya.com.sa",
      },
      {
        id: 7,
        complaintTitle: "Care Owner Direct Extension Number",
        complaintText: "Direct Extension Number",
      },
      {
        id: 8,
        complaintTitle: "",
        complaintText: "",
      },
    ],
  },
];

export const motorInsuraceData = [
  {
    imageData: {
      imgFile: motorProducts,
      title: "productOfferings.motor_ins",
      description: "productOfferings.access_to_hundread",
    },
    cardList: [
      {
        title: "productOfferings.al_shamel",
        subTitle: "productOfferings.al_shamel_desc",
        bannerImage: al_shamel_motor,
        linkTo: "/individuals/products/motor",
      },
      {
        title: "productOfferings.sanad",
        subTitle: "productOfferings.sanad_desc",
        bannerImage: SanadNew,
        linkTo: "/individuals/products/motor",
      },
      {
        title: "productOfferings.sanad_plus",
        subTitle: "productOfferings.sanad_plus_desc",
        bannerImage: sanad_plus_motor,
        linkTo: "/individuals/products/motor",
      },
      {
        title: "productOfferings.mechanical_breakdown",
        subTitle: "productOfferings.mechanical_breakdown_desc",
        bannerImage: mech_breakdown_motor,
        linkTo: "/individuals/products/motor/mechanical-breakdown",
      },
      {
        title: "productOfferings.cross_border_own_damage",
        subTitle: "productOfferings.cross_border_own_damage_subtitle",
        bannerImage: CBODICON,
        linkTo: "/individuals/products/motor/cross-border-own-damage",
      },
      {
        title: "productOfferings.tawuniya_drive",
        subTitle: "productOfferings.tawuniya_drive_desc",
        bannerImage: tDrive,
        linkTo: "/individuals/loyalty-program/drive",
      },
      {
        title: "productOfferings.mobility_ecosystem",
        subTitle: "productOfferings.mobility_ecosystem_subtitle",
        bannerImage: mobilityEcosystemIcon,
        linkTo: "/individuals/products/motor/mobility-ecosystem",
      },
    ],
  },
  {
    imageData: {
      imgFile: healthProducts,
      title: "productOfferings.prpty_casualty",
      description: "productOfferings.access_to_hundread",
    },
    cardList: [
      {
        title: "productOfferings.my_family_ins",
        subTitle: "productOfferings.my_family_ins_desc",
        bannerImage: family_insurance_medical,
        linkTo: "/individuals/products/health/my-family-insurance",
      },
      {
        title: "productOfferings.visit_visa_med_ins",
        subTitle: "productOfferings.visit_visa_med_ins_desc",
        bannerImage: Visit_insurance,
        linkTo: "/individuals/products/health/visit-visa-insurance",
      },
      {
        title: "productOfferings.umrah_ins_prog",
        subTitle: "productOfferings.umrah_ins_prog_desc",
        bannerImage: Umrah_insurance,
        linkTo: "/individuals/products/health/umrah-insurance",
      },
      {
        title: "productOfferings.hajj_ins_prog",
        subTitle: "productOfferings.hajj_ins_prog_desc",
        bannerImage: Hajj_insurance,
        linkTo: "/individuals/products/health/hajj-insurance",
      },
      {
        title: "productOfferings.Domestic_Worker_Insurance",
        subTitle: "productOfferings.Domestic_Worker_Insurance",
        bannerImage: domesticWorker,
        linkTo: "/individuals/products/domestic-worker-health-insurance",
      },
      {
        title: "Parents_Insurance_for_Health_Life",
        subTitle: "Parents_Medical_Insurance_for_Health_Life",
        bannerImage: domesticWorker,
        linkTo: "/individuals/products/Parent-health-insurance",
      },
    ],
  },
  {
    imageData: {
      imgFile: healthProducts,
      title: "Life",
      description: "Life",
    },
    cardList: [
      {
        title: "lifeInsurance.lifeInsurance",
        subTitle: "lifeInsurance.product1-desc",
        bannerImage: family_insurance_medical,
        linkTo: "/individuals/products/life-insurance",
      },
      {
        title: "lifeInsurance.lifeInsurancePlus",
        subTitle: "lifeInsurance.product2-desc",
        bannerImage: family_insurance_medical,
        linkTo: "/individuals/products/life-insurance",
      },
      {
        title: "lifeInsurance.RetirementInsurance",
        subTitle: "lifeInsurance.product3-desc",
        bannerImage: family_insurance_medical,
        linkTo: "/individuals/products/life-insurance",
      },
      {
        title: "lifeInsurance.EducationProgram",
        subTitle: "lifeInsurance.product4-desc",
        bannerImage: family_insurance_medical,
        linkTo: "/individuals/products/life-insurance",
      },
    ],
  },
  {
    imageData: {
      imgFile: travelProducts,
      title: "productOfferings.med_takaful",
      description: "productOfferings.access_to_hundread",
    },
    cardList: [
      {
        title: "productOfferings.home_ins",
        subTitle: "productOfferings.home_ins_desc",
        bannerImage: Home_insurance,
        linkTo: "/individuals/products/property-casualty/home-insurance",
      },
      {
        title: "productOfferings.international_travel_ins",
        subTitle: "productOfferings.international_travel_ins_desc",
        bannerImage: International_Travel_insurance,
        linkTo: "/individuals/products/property-casualty/international-travel",
      },
      {
        title: "productOfferings.med_mal_ins",
        subTitle: "productOfferings.med_mal_ins_desc",
        bannerImage: Malpractice_Insurance,
        linkTo: "/individuals/products/property-casualty/medical-malpractice",
      },
      {
        title: "Domestic_Worker_Insurance_general",
        subTitle: "Domestic_Worker_Insurance_general",
        bannerImage: domesticWorker,
        linkTo: "/individuals/products/domestic-worker-insurance",
      },
      // {
      //   title: "productOfferings.Domestic_Worker_Insurance",
      //   subTitle: "productOfferings.Domestic_Worker_Insurance",
      //   bannerImage: domesticWorker,
      //   linkTo: "/individuals/products/domestic-worker-insurance",
      // },
    ],
  },
];

export const corporateProductList = [
  {
    imageData: {
      imgFile: motorProducts,
      title: "productOfferings.motor_ins",
      description: "productOfferings.access_to_hundread",
    },
    cardList: [
      {
        title: "productOfferings.alshamel",
        subTitle: "productOfferings.alshamel_desc",
        bannerImage: al_shamel_motor,
        linkTo: "/individuals/products/motor",
      },
      {
        title: "productOfferings.sanad",
        subTitle: "productOfferings.sanad_entpr_desc",
        bannerImage: SanadNew,
        linkTo: "/individuals/products/motor",
      },
    ],
  },
  {
    imageData: {
      imgFile: healthProducts,
      title: "productOfferings.med_takaful",
      description: "productOfferings.access_to_hundread",
    },
    cardList: [
      {
        title: "productOfferings.balsam",
        subTitle: "productOfferings.balsam_desc",
        bannerImage: family_insurance_medical,
        linkTo: "/corporate/products/health/balsam",
      },
      {
        title: "productOfferings.takaful",
        subTitle: "productOfferings.takaful_desc",
        bannerImage: Malpractice_Insurance,
        linkTo: "/corporate/products/health/takaful",
      },
    ],
  },
  {
    imageData: {
      imgFile: travelProducts,
      title: "productOfferings.prpty_casualty",
      description: "productOfferings.access_to_hundread",
    },
    cardList: [
      {
        title: "productOfferings.prpty_ins",
        subTitle: "productOfferings.prpty_ins_desc",
        bannerImage: Home_insurance,
        linkTo: "/corporate/products/enterprise/property-insurance",
      },
      {
        title: "productOfferings.general_accident_ins",
        subTitle: "productOfferings.general_accident_ins_desc",
        bannerImage: Services_Insurance,
        linkTo: "/corporate/products/enterprise/general-accedent-insurance",
      },
      {
        title: "productOfferings.engg_ins",
        subTitle: "productOfferings.engg_ins_desc",
        bannerImage: Engineering_Insurance,
        linkTo: "/corporate/products/enterprise/engineering-insurance",
      },
      {
        title: "productOfferings.aviation_ins",
        subTitle: "productOfferings.aviation_ins_desc",
        bannerImage: International_Travel_insurance,
        linkTo: "/corporate/products/enterprise/aviation-insurance",
      },
      {
        title: "productOfferings.marine_cargo_ins",
        subTitle: "productOfferings.marine_cargo_ins_desc",
        bannerImage: Marine_Cargo_insurance,
        linkTo: "/corporate/products/enterprise/marine-cargo-marine-hull",
      },
      {
        title: "productOfferings.energy_ins",
        subTitle: "productOfferings.energy_ins_desc",
        bannerImage: Industrial_Insurance,
        linkTo: "/corporate/products/enterprise/energy-insurance",
      },
      // {
      // 	title: "productOfferings.engg_cont_ins",
      // 	subTitle: "productOfferings.engg_cont_ins_desc",
      // 	bannerImage: Engineering_Insurance,
      // },
      // {
      // 	title: "productOfferings.indust_enrg_ins",
      // 	subTitle: "productOfferings.indust_enrg_ins_desc",
      // 	bannerImage: Industrial_Insurance,
      // },
      // {
      // 	title: "productOfferings.finc_invst_ins",
      // 	subTitle: "productOfferings.finc_invst_ins_desc",
      // 	bannerImage: Inverstment_Insurance,
      // },
      // {
      // 	title: "productOfferings.retail_trad_ins",
      // 	subTitle: "productOfferings.retail_trad_ins_desc",
      // 	bannerImage: Retail_Insurance,
      // },
      // {
      // 	title: "productOfferings.srvs_ins",
      // 	subTitle: "productOfferings.srvs_ins_desc",
      // 	bannerImage: Services_Insurance,
      // },
      // {
      // 	title: "productOfferings.tel_it_ins",
      // 	subTitle: "productOfferings.tel_it_ins_desc",
      // 	bannerImage: Telecommunication_Insurance,
      // },
      // {
      // 	title: "productOfferings.trpt_ins",
      // 	subTitle: "productOfferings.trpt_ins_desc",
      // 	bannerImage: Transportion_Insurance,
      // },
    ],
  },
];

export const SMEProductList = [
  {
    imageData: {
      imgFile: healthProducts,
      title: "productOfferings.med_takaful",
      description: "productOfferings.access_to_hundread",
    },
    cardList: [
      {
        title: "productOfferings.health_ins_prog",
        subTitle: "productOfferings.health_ins_prog_desc",
        bannerImage: liability_insurance,
        linkTo: "/corporate/products/health/360-health-insurance",
      },
    ],
  },
  {
    imageData: {
      imgFile: travelProducts,
      title: "productOfferings.prpty_casualty",
      description: "productOfferings.access_to_hundread",
    },
    cardList: [
      {
        title: "productOfferings.prpty_casualty_prog",
        subTitle: "productOfferings.prpty_casualty_prog_desc",
        bannerImage: Damage_insurance,
        linkTo: "/corporate/products/health/360-property_casualty-insurance",
      },
    ],
  },
];

export const careerContentData = [
  {
    id: 1,
    banner: careers_image_one,
    label: "Make a positive impact",
    description:
      "What makes working at Tawuniya meaningful is knowing that you are playing an important role in bringing new technologies to the world that improve people’s lives. Our innovations enable people in new ways, and we employ our technology to contribute to growth and development in the communities in which we operate around the world. Here at Tawuniya, you have the opportunity to do truly meaningful work that has a positive impact on the world.",
  },
  {
    id: 2,
    banner: careers_image_two,
    label: "Shape the future of our world",
    description:
      "It is the diversity, creativity, and passion of the people who work at Tawuniya that have made us one of the world’s most innovative companies, and our people continue to drive our innovation forward. We work together in an open and collaborative environment that promotes sharing of the unique knowledge and expertise that each individual brings. This is a place where you can work with great people and your ideas can be brought to life in new products and solutions that are shaping the future of how we live.",
  },
  {
    id: 3,
    banner: careers_image_three,
    label: "Take on big challenges",
    description:
      "Throughout our history, we’ve taken on big challenges and we strive to be the very best at what we do. This spirit is the driving force that has made us an insurance leader and sustains our ambition to develop cutting-edge technologies that push the boundaries of what’s possible. Come join us as we take on the next big challenges of the future.",
  },
];

export const relatedNewsData = [
  {
    id: 1,
    tagTitle: "News",
    date: "10 May 2022",
    description: "Tawuniya appoints Dr. Ammr Kurdi as Chief Financial Officer",
  },
  {
    id: 2,
    tagTitle: "News",
    date: "10 May 2022",
    description: "Tawuniya appoints Dr. Ammr Kurdi as Chief Financial Officer",
  },
  {
    id: 3,
    tagTitle: "News",
    date: "10 May 2022",
    description: "Tawuniya appoints Dr. Ammr Kurdi as Chief Financial Officer",
  },
  {
    id: 4,
    tagTitle: "News",
    date: "10 May 2022",
    description: "Tawuniya appoints Dr. Ammr Kurdi as Chief Financial Officer",
  },
];

export const dashboardRoadHeaderData = {
  headerBannerTitle: "Motor Services",
  headerBannerPara: "Road Assistance",
  url: "/dashboard/service/road-assistance/new-request",
};

export const dashboardTeleMedHeaderData = {
  headerBannerTitle: "Health Services",
  headerBannerPara: "Telemedicine",
  url: "/dashboard/service/tele-medicine/new-request",
};

export const dashboardPregnancyProgramHeaderData = {
  headerBannerTitle: "Health Services",
  headerBannerPara: "Pregnancy follow-up program",
  url: "/dashboard/service/pregnancy-follow-up-program/new-request",
};

export const dashboardHomeChildProgramHeaderData = {
  headerBannerTitle: "Health Services",
  headerBannerPara: "Home Child Vaccination",
  url: "/dashboard/service/home-child-vaccination/new-request",
};

export const dashboardChronicHeaderData = {
  headerBannerTitle: "Health Services",
  headerBannerPara: "Chronic disease management",
  url: "/dashboard/service/chronic-disease-management/new-request",
};

export const dashboardPeriodicInspectHeaderData = {
  headerBannerTitle: "Motor Services",
  headerBannerPara: "Periodic Inspection",
  url: "/dashboard/service/periodic-inspection/new-request",
};

export const dashboardCarWashHeaderData = {
  headerBannerTitle: "Motor Services",
  headerBannerPara: "Car Wash",
  url: "",
};

export const dashboardRoadProgressData = [
  {
    id: 0,
    progressNo: "1",
    progresssTitle: "Submit Application",
    progressPara: "Complete your request details and attached the needed documents",
    class: "pr-3",
    arrowIcon: progressArrow,
  },
  {
    id: 1,
    progressNo: "2",
    progresssTitle: "Get Service",
    progressPara: "Receive the needed service.",
    class: "pl-3",
    arrowIcon: progressArrow,
  },
];

export const tableBreakDownData = [
  {
    id: 0,
    tableDivider: "customContainerOne p-0",
    tableClass: "benifitContainer pl-lg-5 pl-3 pt-3 pr-lg-5 pr-3",
    tableNoClass: "p-3",
    needBorder: true,
    txt: null,
    newText: [
      {
        id: 0,
        tableCellContent: "Engine",
        tableSubClass: "benifitContainer-top-borderThin-BreakDown pl-lg-5 pl-3 pr-lg-5 pr-3",
        icon: engine,
        iconClass: "img-fluid xCircleIcon",
        needOnlyIcon: true,
      },
      {
        id: 1,
        tableCellContent: "Gearbox",
        tableSubClass: "benifitContainer pl-lg-5 pl-3 pr-lg-5 pr-3",
        icon: gear,
        iconClass: "img-fluid xCircleIcon",
        needOnlyIcon: true,
      },
      {
        id: 2,
        tableCellContent: "Differential",
        tableSubClass: "benifitContainer pl-lg-5 pl-3 pr-lg-5 pr-3",
        icon: differential,
        iconClass: "img-fluid xCircleIcon",
        needOnlyIcon: true,
      },
      {
        id: 3,
        tableCellContent: "Turbo Assembly",
        tableSubClass: "benifitContainer pl-lg-5 pl-3 pr-lg-5 pr-3",
        icon: turbo,
        iconClass: "img-fluid xCircleIcon",
        needOnlyIcon: true,
      },
      {
        id: 4,
        tableCellContent: "Prop Shaft & CV Joints",
        tableSubClass: "benifitContainer pl-lg-5 pl-3 pr-lg-5 pr-3",
        icon: prop,
        iconClass: "img-fluid xCircleIcon",
        needOnlyIcon: true,
      },
      {
        id: 5,
        tableCellContent: "Steering Mechanism",
        tableSubClass: "benifitContainer pl-lg-5 pl-3 pr-lg-5 pr-3",
        icon: steer,
        iconClass: "img-fluid xCircleIcon",
        needOnlyIcon: true,
      },
      {
        id: 6,
        tableCellContent: "Wheel Bearings",
        tableSubClass: "benifitContainer pl-lg-5 pl-3 pr-lg-5 pr-3",
        icon: wheelBearing,
        iconClass: "img-fluid xCircleIcon",
        needOnlyIcon: true,
      },
      {
        id: 7,
        tableCellContent: "Breaking System",
        tableSubClass: "benifitContainer pl-lg-5 pl-3 pr-lg-5 pr-3",
        icon: breakSystem,
        iconClass: "img-fluid xCircleIcon",
        needOnlyIcon: true,
      },
      {
        id: 8,
        tableCellContent: "Fuel System",
        tableSubClass: "benifitContainer pl-lg-5 pl-3 pr-lg-5 pr-3",
        icon: fuel,
        iconClass: "img-fluid xCircleIcon",
        needOnlyIcon: true,
      },
      {
        id: 9,
        tableCellContent: "Electrical Components",
        tableSubClass: "benifitContainer pl-lg-5 pl-3 pr-lg-5 pr-3",
        icon: electricComponents,
        iconClass: "img-fluid xCircleIcon",
        needOnlyIcon: true,
      },
      {
        id: 10,
        tableCellContent: "Suspension",
        tableSubClass: "benifitContainer pl-lg-5 pl-3 pr-lg-5 pr-3",
        icon: suspension,
        iconClass: "img-fluid xCircleIcon",
        needOnlyIcon: true,
      },
      {
        id: 11,
        tableCellContent: "Wheel Bearings",
        tableSubClass: "benifitContainer pl-lg-5 pl-3 pr-lg-5 pr-3",
        icon: wheelBearing,
        iconClass: "img-fluid xCircleIcon",
        needOnlyIcon: true,
      },
      {
        id: 12,
        tableCellContent: "Cylinder Head Gasket",
        tableSubClass: "benifitContainer-bottom-borderThin pl-lg-5 pl-3 pr-lg-5 pr-3",
        icon: cylinder,
        iconClass: "img-fluid xCircleIcon",
        needOnlyIcon: true,
      },
    ],
  },
  {
    id: 1,
    tableDivider: "customContainerTwo p-0",
    tableClass: "bestSellerContainer text-center pt-3",
    tableNoClass: "p-3",
    txt: null,
    newText: [
      {
        id: 0,
        tableCellContent: "20,000",
        tableSubClass: "bestSellerContainer text-center",
        needOnlyIcon: true,
      },
      {
        id: 1,
        tableCellContent: "10,000",
        tableSubClass: "bestSellerContainer text-center",
        needOnlyIcon: true,
      },
      {
        id: 2,
        tableCellContent: "10,000",
        tableSubClass: "bestSellerContainer text-center",
        needOnlyIcon: true,
      },
      {
        id: 3,
        tableCellContent: "5,000",
        tableSubClass: "bestSellerContainer text-center",
        needOnlyIcon: true,
      },
      {
        id: 4,
        tableCellContent: "4,000",
        tableSubClass: "bestSellerContainer text-center",
        needOnlyIcon: true,
      },
      {
        id: 5,
        tableCellContent: "4,000",
        tableSubClass: "bestSellerContainer text-center",
        needOnlyIcon: true,
      },
      {
        id: 6,
        tableCellContent: "4,000",
        tableSubClass: "bestSellerContainer text-center",
        needOnlyIcon: true,
      },
      {
        id: 7,
        tableCellContent: "4,000",
        tableSubClass: "bestSellerContainer text-center",
        needOnlyIcon: true,
      },
      {
        id: 8,
        tableCellContent: "4,000",
        tableSubClass: "bestSellerContainer text-center",
        needOnlyIcon: true,
      },
      {
        id: 9,
        tableCellContent: "4,000",
        tableSubClass: "bestSellerContainer text-center",
        needOnlyIcon: true,
      },
      {
        id: 10,
        tableCellContent: "4,000",
        tableSubClass: "bestSellerContainer text-center",
        needOnlyIcon: true,
      },
      {
        id: 11,
        tableCellContent: "4,000",
        tableSubClass: "bestSellerContainer text-center",
        needOnlyIcon: true,
      },
      {
        id: 12,
        tableCellContent: "4,000",
        tableSubClass: "bestSeller-bottom-Container text-center",
        needOnlyIcon: true,
      },
    ],
  },
  {
    id: 2,
    tableDivider: "customContainerThree p-0",
    tableClass: "sanadContainer text-center pt-3",
    tableNoClass: "p-3",
    txt: null,
    newText: [
      {
        id: 0,
        tableCellContent: "20,000",
        tableSubClass: "sanadContainer text-center",
        needOnlyIcon: true,
      },
      {
        id: 1,
        tableCellContent: "10,000",
        tableSubClass: "sanadContainer text-center",
        needOnlyIcon: true,
      },
      {
        id: 2,
        tableCellContent: "10,000",
        tableSubClass: "sanadContainer text-center",
        needOnlyIcon: true,
      },
      {
        id: 3,
        tableCellContent: "5,000",
        tableSubClass: "sanadContainer text-center",
        needOnlyIcon: true,
      },
      {
        id: 4,
        tableCellContent: "4,000",
        tableSubClass: "sanadContainer text-center",
        needOnlyIcon: true,
      },
      {
        id: 5,
        tableCellContent: "4,000",
        tableSubClass: "sanadContainer text-center",
        needOnlyIcon: true,
      },
      {
        id: 6,
        tableCellContent: "4,000",
        tableSubClass: "sanadContainer text-center",
        needOnlyIcon: true,
      },
      {
        id: 7,
        tableCellContent: "4,000",
        tableSubClass: "sanadContainer text-center",
        needOnlyIcon: true,
      },
      {
        id: 8,
        tableCellContent: "4,000",
        tableSubClass: "sanadContainer text-center",
        needOnlyIcon: true,
      },
      {
        id: 9,
        tableCellContent: "4,000",
        tableSubClass: "sanadContainer text-center",
        needOnlyIcon: true,
      },
      {
        id: 10,
        tableCellContent: "4,000",
        tableSubClass: "sanadContainer text-center",
        needOnlyIcon: true,
      },
      {
        id: 11,
        tableCellContent: "4,000",
        tableSubClass: "sanadContainer text-center",
        needOnlyIcon: true,
      },
      {
        id: 12,
        tableCellContent: null,
        tableSubClass: "sanad-bottom-Container text-center",
        needOnlyIcon: true,
        iconClass: "img-fluid xCircleIcon",
        icon: xOrangeCircle,
      },
    ],
  },
  {
    id: 3,
    tableDivider: "customContainerFour p-0",
    tableClass: "sanadPlusContainer text-center pt-3",
    tableNoClass: "p-3",
    txt: null,
    newText: [
      {
        id: 0,
        tableCellContent: "20,000",
        tableSubClass: "sanadPlusContainer text-center",
        needOnlyIcon: true,
      },
      {
        id: 1,
        tableCellContent: "10,000",
        tableSubClass: "sanadPlusContainer text-center",
        needOnlyIcon: true,
      },
      {
        id: 2,
        tableCellContent: "10,000",
        tableSubClass: "sanadPlusContainer text-center",
        needOnlyIcon: true,
      },
      {
        id: 3,
        tableCellContent: null,
        tableSubClass: "sanadPlusContainer text-center",
        icon: xOrangeCircle,
        iconClass: "img-fluid xCircleIcon",
        needOnlyIcon: true,
      },
      {
        id: 4,
        tableCellContent: null,
        tableSubClass: "sanadPlusContainer text-center",
        icon: xOrangeCircle,
        iconClass: "img-fluid xCircleIcon",
        needOnlyIcon: true,
      },
      {
        id: 5,
        tableCellContent: null,
        tableSubClass: "sanadPlusContainer text-center",
        icon: xOrangeCircle,
        iconClass: "img-fluid xCircleIcon",
        needOnlyIcon: true,
      },
      {
        id: 6,
        tableCellContent: null,
        tableSubClass: "sanadPlusContainer text-center",
        icon: xOrangeCircle,
        iconClass: "img-fluid xCircleIcon",
        needOnlyIcon: true,
      },
      {
        id: 7,
        tableCellContent: null,
        tableSubClass: "sanadPlusContainer text-center",
        icon: xOrangeCircle,
        iconClass: "img-fluid xCircleIcon",
        needOnlyIcon: true,
      },
      {
        id: 8,
        tableCellContent: null,
        tableSubClass: "sanadPlusContainer text-center",
        icon: xOrangeCircle,
        iconClass: "img-fluid xCircleIcon",
        needOnlyIcon: true,
      },
      {
        id: 9,
        tableCellContent: null,
        tableSubClass: "sanadPlusContainer text-center",
        icon: xOrangeCircle,
        iconClass: "img-fluid xCircleIcon",
        needOnlyIcon: true,
      },
      {
        id: 10,
        tableCellContent: null,
        tableSubClass: "sanadPlusContainer text-center",
        icon: xOrangeCircle,
        iconClass: "img-fluid xCircleIcon",
        needOnlyIcon: true,
      },
      {
        id: 11,
        tableCellContent: null,
        tableSubClass: "sanadPlusContainer text-center",
        icon: xOrangeCircle,
        iconClass: "img-fluid xCircleIcon",
        needOnlyIcon: true,
      },
      {
        id: 12,
        tableCellContent: null,
        tableSubClass: "sanadPlus-bottom-Container text-center",
        icon: xOrangeCircle,
        iconClass: "img-fluid xCircleIcon",
        needOnlyIcon: true,
      },
    ],
  },
];

export const headerBreakDownData = [
  {
    id: 0,
    mainClass: "customHeaderOne",
    baseClassOne: "ContainerComponentOne",
    baseClassTwo: "ContainerComponentTwo",
    paraClass: "fs-24 fw-800 pl-lg-5 pl-3 pr-lg-5 pr-3 headerTitleOne",
    paraTitle: "Components covered",
    hideCellOne: true,
    showTip: "hideToolTip",
  },
  {
    id: 1,
    mainClass: "customHeaderTwo",
    baseClassOne: "ContainerGoldOne",
    baseClassTwo: null,
    paraClass: "fs-24 fw-800 headerTitleTwo",
    paraTitle: "Gold",
    btnLabel: "Order Now",
    hideCellOne: false,
    showTip: "hideToolTip",
  },
  {
    id: 2,
    mainClass: "customHeaderThree",
    baseClassOne: "ContainerSilverOne",
    baseClassTwo: null,
    paraClass: "fs-24 fw-800 headerTitleThree",
    paraTitle: "Silver",
    hideCellOne: false,
    showTip: "hideToolTip",
  },
  {
    id: 3,
    mainClass: "customHeaderFour",
    baseClassOne: "ContainerBronzeOne",
    baseClassTwo: null,
    paraClass: "fs-24 fw-800 headerTitleFour",
    paraTitle: "Bronze",
    hideCellOne: false,
    showTip: "hideToolTip",
  },
];

export const dialingCodes = [
  {
    code: "+966",
    image: KSAFlagImage,
  },

];
export const customerServiceFaqList = [
  {
    id: 1,
    question: "What is medical insurance?",
    answer:
      "It's important to distinguish between Medical insurance fraud/abuse and mistakes. Medical insurance fraud is an act of deception by misrepresentation of material facts, or silence when good faith requires expression, resulting in material damage to one who relies on it and the right to rely on it. Simply, it is obtaining something of value from someone else through deceit. By contrast, mistakes are made unknowingly by anyone who lacks knowledge, in other words, it’s a misstatement or unintentional deviation from the truth. Therefore, Tawuniya always recommend reading the Medical Insurance Guidelines and visiting Tawuniya social media accounts for more information about the ideal use of your Medical Card.",
  },

  {
    id: 2,
    question: "How can I obtain medical insurance?",
    answer:
      "It's important to distinguish between Medical insurance fraud/abuse and mistakes. Medical insurance fraud is an act of deception by misrepresentation of material facts, or silence when good faith requires expression, resulting in material damage to one who relies on it and the right to rely on it. Simply, it is obtaining something of value from someone else through deceit. By contrast, mistakes are made unknowingly by anyone who lacks knowledge, in other words, it’s a misstatement or unintentional deviation from the truth. Therefore, Tawuniya always recommend reading the Medical Insurance Guidelines and visiting Tawuniya social media accounts for more information about the ideal use of your Medical Card.",
  },

  {
    id: 3,
    question: "How does fraud impact you?",
    answer:
      "It's important to distinguish between Medical insurance fraud/abuse and mistakes. Medical insurance fraud is an act of deception by misrepresentation of material facts, or silence when good faith requires expression, resulting in material damage to one who relies on it and the right to rely on it. Simply, it is obtaining something of value from someone else through deceit. By contrast, mistakes are made unknowingly by anyone who lacks knowledge, in other words, it’s a misstatement or unintentional deviation from the truth. Therefore, Tawuniya always recommend reading the Medical Insurance Guidelines and visiting Tawuniya social media accounts for more information about the ideal use of your Medical Card.",
  },
  {
    id: 4,
    question: "What is medical fraud unit?",
    answer:
      "It's important to distinguish between Medical insurance fraud/abuse and mistakes. Medical insurance fraud is an act of deception by misrepresentation of material facts, or silence when good faith requires expression, resulting in material damage to one who relies on it and the right to rely on it. Simply, it is obtaining something of value from someone else through deceit. By contrast, mistakes are made unknowingly by anyone who lacks knowledge, in other words, it’s a misstatement or unintentional deviation from the truth. Therefore, Tawuniya always recommend reading the Medical Insurance Guidelines and visiting Tawuniya social media accounts for more information about the ideal use of your Medical Card.",
  },
];
export const myFamilyExclusive = [
  {
    id: 1,
    question: "What is insurance fraud?",
    answer:
      "The insured is provided with an Insurance Card and Bail Bond approved by the Traffic Police together with Third Party Insurance Policy to prevent the arrest or detainment of the driver causing the accident?",
  },
  {
    id: 2,
    question: "What is fraud vs. Mistake?",
    answer:
      "The insured is provided with an Insurance Card and Bail Bond approved by the Traffic Police together with Third Party Insurance Policy to prevent the arrest or detainment of the driver causing the accident?",
  },

  {
    id: 3,
    question: "How does fraud impact you?",
    answer:
      "The insured is provided with an Insurance Card and Bail Bond approved by the Traffic Police together with Third Party Insurance Policy to prevent the arrest or detainment of the driver causing the accident?",
  },
  {
    id: 4,
    question: "What is medical fraud unit?",
    answer:
      "The insured is provided with an Insurance Card and Bail Bond approved by the Traffic Police together with Third Party Insurance Policy to prevent the arrest or detainment of the driver causing the accident?",
  },
  {
    id: 5,
    question: "What kind of people/Organizations commits insurance fraud?",
    answer:
      "The insured is provided with an Insurance Card and Bail Bond approved by the Traffic Police together with Third Party Insurance Policy to prevent the arrest or detainment of the driver causing the accident?",
  },
  {
    id: 6,
    question: "What are some examples of insurance fraud/Abuse?",
    answer:
      "The insured is provided with an Insurance Card and Bail Bond approved by the Traffic Police together with Third Party Insurance Policy to prevent the arrest or detainment of the driver causing the accident?",
  },
  {
    id: 7,
    question: "What are we doing to prevent fraud?",
    answer:
      "The insured is provided with an Insurance Card and Bail Bond approved by the Traffic Police together with Third Party Insurance Policy to prevent the arrest or detainment of the driver causing the accident?",
  },
  {
    id: 8,
    question: "Shall I get a reward for reporting medical insurance fraud?",
    answer:
      "The insured is provided with an Insurance Card and Bail Bond approved by the Traffic Police together with Third Party Insurance Policy to prevent the arrest or detainment of the driver causing the accident?",
  },
];
export const HomePolicyList = [
  {
    id: 1,
    question: "What is insurance fraud?",
    answer:
      "The insured is provided with an Insurance Card and Bail Bond approved by the Traffic Police together with Third Party Insurance Policy to prevent the arrest or detainment of the driver causing the accident?",
  },
  {
    id: 2,
    question: "What is fraud vs. Mistake?",
    answer:
      "The insured is provided with an Insurance Card and Bail Bond approved by the Traffic Police together with Third Party Insurance Policy to prevent the arrest or detainment of the driver causing the accident?",
  },

  {
    id: 3,
    question: "How does fraud impact you?",
    answer:
      "The insured is provided with an Insurance Card and Bail Bond approved by the Traffic Police together with Third Party Insurance Policy to prevent the arrest or detainment of the driver causing the accident?",
  },
  {
    id: 4,
    question: "What is medical fraud unit?",
    answer:
      "The insured is provided with an Insurance Card and Bail Bond approved by the Traffic Police together with Third Party Insurance Policy to prevent the arrest or detainment of the driver causing the accident?",
  },
  {
    id: 5,
    question: "What kind of people/Organizations commits insurance fraud?",
    answer:
      "The insured is provided with an Insurance Card and Bail Bond approved by the Traffic Police together with Third Party Insurance Policy to prevent the arrest or detainment of the driver causing the accident?",
  },
  {
    id: 6,
    question: "What are some examples of insurance fraud/Abuse?",
    answer:
      "The insured is provided with an Insurance Card and Bail Bond approved by the Traffic Police together with Third Party Insurance Policy to prevent the arrest or detainment of the driver causing the accident?",
  },
  {
    id: 7,
    question: "What are we doing to prevent fraud?",
    answer:
      "The insured is provided with an Insurance Card and Bail Bond approved by the Traffic Police together with Third Party Insurance Policy to prevent the arrest or detainment of the driver causing the accident?",
  },
  {
    id: 8,
    question: "Shall I get a reward for reporting medical insurance fraud?",
    answer:
      "The insured is provided with an Insurance Card and Bail Bond approved by the Traffic Police together with Third Party Insurance Policy to prevent the arrest or detainment of the driver causing the accident?",
  },
];
export const PolicyList = [
  {
    image: HealthPolicy,
    title: "Check Your policy details",
  },
  {
    image: HealthPolicy,
    title: "Add/Delete members",
  },
  {
    image: HealthPolicy,
    title: "Upgrade existing members ",
  },
  {
    image: HealthPolicy,
    title: "Update members information",
  },
];

export const dashboardEligibilityHeaderData = {
  headerBannerTitle: "Health Services",
  headerBannerPara: "Eligibility letter",
  url: "/dashboard/service/eligibility-letter/new-request",
};
export const motorFraudQuestions = [
  {
    id: 1,
    question: "What is insurance fraud?",
    answer:
      "It's important to distinguish between Medical insurance fraud/abuse and mistakes. Medical insurance fraud is an act of deception by misrepresentation of material facts, or silence when good faith requires expression, resulting in material damage to one who relies on it and the right to rely on it. Simply, it is obtaining something of value from someone else through deceit. By contrast, mistakes are made unknowingly by anyone who lacks knowledge, in other words, it’s a misstatement or unintentional deviation from the truth. Therefore, Tawuniya always recommend reading the Medical Insurance Guidelines and visiting Tawuniya social media accounts for more information about the ideal use of your Medical Card.",
  },

  {
    id: 2,
    question: "What is fraud vs. Mistake?",
    answer:
      "It's important to distinguish between Medical insurance fraud/abuse and mistakes. Medical insurance fraud is an act of deception by misrepresentation of material facts, or silence when good faith requires expression, resulting in material damage to one who relies on it and the right to rely on it. Simply, it is obtaining something of value from someone else through deceit. By contrast, mistakes are made unknowingly by anyone who lacks knowledge, in other words, it’s a misstatement or unintentional deviation from the truth. Therefore, Tawuniya always recommend reading the Medical Insurance Guidelines and visiting Tawuniya social media accounts for more information about the ideal use of your Medical Card.",
  },

  {
    id: 3,
    question: "How does fraud impact you?",
    answer:
      "It's important to distinguish between Medical insurance fraud/abuse and mistakes. Medical insurance fraud is an act of deception by misrepresentation of material facts, or silence when good faith requires expression, resulting in material damage to one who relies on it and the right to rely on it. Simply, it is obtaining something of value from someone else through deceit. By contrast, mistakes are made unknowingly by anyone who lacks knowledge, in other words, it’s a misstatement or unintentional deviation from the truth. Therefore, Tawuniya always recommend reading the Medical Insurance Guidelines and visiting Tawuniya social media accounts for more information about the ideal use of your Medical Card.",
  },
  {
    id: 4,
    question: "What is medical fraud unit?",
    answer:
      "It's important to distinguish between Medical insurance fraud/abuse and mistakes. Medical insurance fraud is an act of deception by misrepresentation of material facts, or silence when good faith requires expression, resulting in material damage to one who relies on it and the right to rely on it. Simply, it is obtaining something of value from someone else through deceit. By contrast, mistakes are made unknowingly by anyone who lacks knowledge, in other words, it’s a misstatement or unintentional deviation from the truth. Therefore, Tawuniya always recommend reading the Medical Insurance Guidelines and visiting Tawuniya social media accounts for more information about the ideal use of your Medical Card.",
  },
];

export const SocialResponsibilityDetailsData = [
  {
    id: 1,
    tagTitle: "Productivity",
    date: "10 May 2022",
    description: "Tawuniya is the first company in the Kingdom to install vehicle insurance policies",
  },
  {
    id: 2,
    tagTitle: "Environmental",
    date: "10 May 2022",
    description: "Tawuniya is the first company in the Kingdom to install vehicle insurance policies",
  },
  {
    id: 3,
    tagTitle: "Productivity",
    date: "10 May 2022",
    description: "Tawuniya is the first company in the Kingdom to install vehicle insurance policies",
  },
  {
    id: 4,
    tagTitle: "Environmental",
    date: "10 May 2022",
    description: "Tawuniya is the first company in the Kingdom to install vehicle insurance policies",
  },
];
export const MediaCenterLatestNews = [
  {
    id: 1,
    tagTitle: "News",
    date: "Week ago",
    description: "Tawuniya launches Covid-19 Travel insurance program",
  },
  {
    id: 2,
    tagTitle: "News",
    date: "Week ago",
    description: "Tawuniya launches Covid-19 Travel insurance program",
  },
  {
    id: 3,
    tagTitle: "News",
    date: "Week ago",
    description: "Tawuniya launches Covid-19 Travel insurance program",
  },
  {
    id: 4,
    tagTitle: "News",
    date: "Week ago",
    description: "Tawuniya launches Covid-19 Travel insurance program",
  },
];

export const progressRoadData = [
  {
    id: 0,
    number: 1,
    title: "Submit Application",
    para: "Complete your request details and attached the needed documents",
  },
  {
    id: 1,
    number: 2,
    title: "Review",
    para: "The application will be reviewed in 3 days by our team",
    paddClass: "pl-3 pr-3",
  },
  {
    id: 2,
    number: 3,
    title: "Get Reimburse",
    para: "Reimbursement will be deposited to your bank account within 3 working days after approval",
  },
];

export const progressTeleMedData = [
  {
    id: 0,
    number: 1,
    title: "Submit Application",
    para: "Complete your consultation details",
  },
  {
    id: 1,
    number: 2,
    title: "Review the Call",
    para: "Receive reliable medical consultation from accredited doctors in all health specialties.",
    paddClass: "pl-3 pr-3",
  },
];

export const roadAssisstantContentData = {
  bannerTitle: "Road Assistance",
  bannerPara:
    "Sample text: 3 simple steps to reimburse the expenses outside the network Upon receiving the medical treatment outside the appointed network, the member will pay directly to the medical provider. He/she can apply for reimbursement of his/her medical expenses by submitting a request via Tawuniya's website or Tawuniya App and uploading all necessary support documents. Tawuniya will settle the claim and the funds will be transferred to his/her bank account.",
  bannerImg: carBanner,
  bannerClass: "road-sub-home-container",
  bannerImgClass: "road-assisstant-carBanner",
  benifitTitle: "How do I benefit from the service?",
  benifitPara: "3 simple steps to reimburse the expenses",
  faqTitle: "Frequently Asked Questions",
  faqPara:
    "Review answers to commonly asked questions at Tawuniya, which enable you to be directly involved in improving our support experience.",
  progressLineClass: "benifitPrgressLine",
};

export const teleMedicineContentData = {
  bannerTitle: "Telemedicine",
  bannerPara:
    "With the Doctor Consultation service, you will no longer need to book an appointment in the hospital. This service allows you to book a reliable online consultation appointment from accredited doctors in all health specialties via the smartphone app and website.",
  bannerImg: teleMedBanner,
  bannerClass: "teleMedicine-sub-home-container",
  bannerImgClass: "teleMedicine-carBanner",
  benifitTitle: "How it works?",
  benifitPara: "3 simple steps to reimburse the expenses",
  faqTitle: "Frequently Asked Questions",
  faqPara:
    "Review answers to commonly asked questions at Tawuniya, which enable you to be directly involved in improving our support experience.",
  progressLineClass: "benifitTeleMedProgressLine",
};

export const periodicInspectContentData = {
  bannerTitle: "Road Assistance",
  bannerPara:
    "3 simple steps to reimburse the expenses outside the network Upon receiving the medical treatment outside the appointed network, the member will pay directly to the medical provider. He/she can apply for reimbursement of his/her medical expenses by submitting a request via Tawuniya's website or Tawuniya App and uploading all necessary support documents. Tawuniya will settle the claim and the funds will be transferred to his/her bank account.",
  bannerImg: carBanner,
  bannerClass: "periodicInspect-sub-home-container",
  bannerImgClass: "periodicInspect-carBanner",
  benifitTitle: "How do I benefit from the service?",
  benifitPara: "3 simple steps to reimburse the expenses",
  faqTitle: "Frequently Asked Questions",
  faqPara:
    "Review answers to commonly asked questions at Tawuniya, which enable you to be directly involved in improving our support experience.",
  progressLineClass: "benifitPrgressLine",
};

export const carWashContentData = {
  bannerTitle: "Road Assistance",
  bannerPara:
    "Sample text: 3 simple steps to reimburse the expenses outside the network Upon receiving the medical treatment outside the appointed network, the member will pay directly to the medical provider. He/she can apply for reimbursement of his/her medical expenses by submitting a request via Tawuniya's website or Tawuniya App and uploading all necessary support documents. Tawuniya will settle the claim and the funds will be transferred to his/her bank account.",
  bannerImg: carBanner,
  bannerClass: "carWash-sub-home-container",
  bannerImgClass: "carWash-carBanner",
  benifitTitle: "How it works?",
  benifitPara: "3 simple steps to reimburse the expenses",
  faqTitle: "Frequently Asked Questions",
  faqPara:
    "Review answers to commonly asked questions at Tawuniya, which enable you to be directly involved in improving our support experience.",
  progressLineClass: "benifitPrgressLine",
};

export const faqRoadDashboardData = [
  {
    id: 0,
    evenKey: "0",
    question: "What kind of people/Organizations commits insurance fraud?",
    answer:
      "The insured is provided with an Insurance Card and Bail Bond approved by the Traffic Police together with Third Party Insurance Policy to prevent the arrest or detainment of the driver causing the accident?",
  },
  {
    id: 1,
    evenKey: "1",
    question: "What are some examples of insurance fraud/Abuse?",
    answer:
      "It's important to distinguish between Medical insurance fraud/abuse and mistakes. Medical insurance fraud is an act of deception by misrepresentation of material facts, or silence when good faith requires expression, resulting in material damage to one who relies on it and the right to rely on it. Simply, it is obtaining something of value from someone else through deceit. By contrast, mistakes are made unknowingly by anyone who lacks knowledge, in other words, it’s a misstatement or unintentional deviation from the truth. Therefore, Tawuniya always recommend reading the Medical Insurance Guidelines and visiting Tawuniya social media accounts for more information about the ideal use of your Medical Card.",
  },
  {
    id: 2,
    evenKey: "1",
    question: "What are we doing to prevent fraud?",
    answer:
      "It's important to distinguish between Medical insurance fraud/abuse and mistakes. Medical insurance fraud is an act of deception by misrepresentation of material facts, or silence when good faith requires expression, resulting in material damage to one who relies on it and the right to rely on it. Simply, it is obtaining something of value from someone else through deceit. By contrast, mistakes are made unknowingly by anyone who lacks knowledge, in other words, it’s a misstatement or unintentional deviation from the truth. Therefore, Tawuniya always recommend reading the Medical Insurance Guidelines and visiting Tawuniya social media accounts for more information about the ideal use of your Medical Card.",
  },
  {
    id: 3,
    evenKey: "1",
    question: "Shall I get a reward for reporting medical insurance fraud?",
    answer:
      "It's important to distinguish between Medical insurance fraud/abuse and mistakes. Medical insurance fraud is an act of deception by misrepresentation of material facts, or silence when good faith requires expression, resulting in material damage to one who relies on it and the right to rely on it. Simply, it is obtaining something of value from someone else through deceit. By contrast, mistakes are made unknowingly by anyone who lacks knowledge, in other words, it’s a misstatement or unintentional deviation from the truth. Therefore, Tawuniya always recommend reading the Medical Insurance Guidelines and visiting Tawuniya social media accounts for more information about the ideal use of your Medical Card.",
  },
];

export const faqPeriodicInspectDashboardData = [
  {
    id: 0,
    evenKey: "0",
    question: "What kind of people/Organizations commits insurance fraud?",
    answer:
      "The insured is provided with an Insurance Card and Bail Bond approved by the Traffic Police together with Third Party Insurance Policy to prevent the arrest or detainment of the driver causing the accident?",
  },
  {
    id: 1,
    evenKey: "1",
    question: "What are some examples of insurance fraud/Abuse?",
    answer:
      "It's important to distinguish between Medical insurance fraud/abuse and mistakes. Medical insurance fraud is an act of deception by misrepresentation of material facts, or silence when good faith requires expression, resulting in material damage to one who relies on it and the right to rely on it. Simply, it is obtaining something of value from someone else through deceit. By contrast, mistakes are made unknowingly by anyone who lacks knowledge, in other words, it’s a misstatement or unintentional deviation from the truth. Therefore, Tawuniya always recommend reading the Medical Insurance Guidelines and visiting Tawuniya social media accounts for more information about the ideal use of your Medical Card.",
  },
  {
    id: 2,
    evenKey: "1",
    question: "What are we doing to prevent fraud?",
    answer:
      "It's important to distinguish between Medical insurance fraud/abuse and mistakes. Medical insurance fraud is an act of deception by misrepresentation of material facts, or silence when good faith requires expression, resulting in material damage to one who relies on it and the right to rely on it. Simply, it is obtaining something of value from someone else through deceit. By contrast, mistakes are made unknowingly by anyone who lacks knowledge, in other words, it’s a misstatement or unintentional deviation from the truth. Therefore, Tawuniya always recommend reading the Medical Insurance Guidelines and visiting Tawuniya social media accounts for more information about the ideal use of your Medical Card.",
  },
  {
    id: 3,
    evenKey: "1",
    question: "Shall I get a reward for reporting medical insurance fraud?",
    answer:
      "It's important to distinguish between Medical insurance fraud/abuse and mistakes. Medical insurance fraud is an act of deception by misrepresentation of material facts, or silence when good faith requires expression, resulting in material damage to one who relies on it and the right to rely on it. Simply, it is obtaining something of value from someone else through deceit. By contrast, mistakes are made unknowingly by anyone who lacks knowledge, in other words, it’s a misstatement or unintentional deviation from the truth. Therefore, Tawuniya always recommend reading the Medical Insurance Guidelines and visiting Tawuniya social media accounts for more information about the ideal use of your Medical Card.",
  },
];

export const faqCarWashDashboardData = [
  {
    id: 0,
    evenKey: "0",
    question: "What kind of people/Organizations commits insurance fraud?",
    answer:
      "The insured is provided with an Insurance Card and Bail Bond approved by the Traffic Police together with Third Party Insurance Policy to prevent the arrest or detainment of the driver causing the accident?",
  },
  {
    id: 1,
    evenKey: "1",
    question: "What are some examples of insurance fraud/Abuse?",
    answer:
      "It's important to distinguish between Medical insurance fraud/abuse and mistakes. Medical insurance fraud is an act of deception by misrepresentation of material facts, or silence when good faith requires expression, resulting in material damage to one who relies on it and the right to rely on it. Simply, it is obtaining something of value from someone else through deceit. By contrast, mistakes are made unknowingly by anyone who lacks knowledge, in other words, it’s a misstatement or unintentional deviation from the truth. Therefore, Tawuniya always recommend reading the Medical Insurance Guidelines and visiting Tawuniya social media accounts for more information about the ideal use of your Medical Card.",
  },
  {
    id: 2,
    evenKey: "1",
    question: "What are we doing to prevent fraud?",
    answer:
      "It's important to distinguish between Medical insurance fraud/abuse and mistakes. Medical insurance fraud is an act of deception by misrepresentation of material facts, or silence when good faith requires expression, resulting in material damage to one who relies on it and the right to rely on it. Simply, it is obtaining something of value from someone else through deceit. By contrast, mistakes are made unknowingly by anyone who lacks knowledge, in other words, it’s a misstatement or unintentional deviation from the truth. Therefore, Tawuniya always recommend reading the Medical Insurance Guidelines and visiting Tawuniya social media accounts for more information about the ideal use of your Medical Card.",
  },
  {
    id: 3,
    evenKey: "1",
    question: "Shall I get a reward for reporting medical insurance fraud?",
    answer:
      "It's important to distinguish between Medical insurance fraud/abuse and mistakes. Medical insurance fraud is an act of deception by misrepresentation of material facts, or silence when good faith requires expression, resulting in material damage to one who relies on it and the right to rely on it. Simply, it is obtaining something of value from someone else through deceit. By contrast, mistakes are made unknowingly by anyone who lacks knowledge, in other words, it’s a misstatement or unintentional deviation from the truth. Therefore, Tawuniya always recommend reading the Medical Insurance Guidelines and visiting Tawuniya social media accounts for more information about the ideal use of your Medical Card.",
  },
];

export const dashboardTeleMedProgressData = [
  {
    id: 0,
    progressNo: "1",
    progresssTitle: "Submit Application",
    progressPara: "Complete your consultation details",
    class: "pr-3",
    arrowIcon: progressArrow,
  },
  {
    id: 1,
    progressNo: "2",
    progresssTitle: "Receive the Call",
    progressPara:
      "Receive reliable medical consultation from accredited doctors in all health specialties.",
    class: "pl-3",
    arrowIcon: progressArrow,
  },
];

export const dashboardPregnancyProgramProgressData = [
  {
    id: 0,
    progressNo: "1",
    progresssTitle: "",
    progressPara:
      "In case of acceptance, Tawuniya will develop an awareness plan for the pregnant woman and provide tips and consultation starting from joining the program until birth",
    class: "pr-3",
    arrowIcon: progressArrow,
  },
  {
    id: 1,
    progressNo: "2",
    progresssTitle: "",
    progressPara:
      "The client receives an initial call from Tawuniya's customer service or one of the partners for the purpose of explaining the program, verifying the basic information and obtaining approval from client to obtain this service.",
    class: "pl-3",
    arrowIcon: progressArrow,
  },
  {
    id: 2,
    progressNo: "3",
    progresssTitle: "",
    progressPara:
      "In case of acceptance, Tawuniya will develop an awareness plan for the pregnant woman and provide tips and consultation starting from joining the program until birth",
    class: "pl-3",
    arrowIcon: progressArrow,
  },
];

export const dashboardPeriodicInspectionProgressData = [
  {
    id: 0,
    progressNo: "1",
    progresssTitle: "Submit Application",
    progressPara: "Complete your request details and attached the needed documents",
    class: "pr-3",
    arrowIcon: progressArrow,
  },
  {
    id: 1,
    progressNo: "2",
    progresssTitle: "Get Service",
    progressPara: "Receive the needed service.",
    class: "pl-3",
    arrowIcon: progressArrow,
  },
];

export const dashboardCarWashProgressData = [
  {
    id: 0,
    progressNo: "1",
    progresssTitle: "Submit Application",
    progressPara: "Complete your request details and attached the needed documents",
    class: "pr-3",
    arrowIcon: progressArrow,
  },
  {
    id: 1,
    progressNo: "2",
    progresssTitle: "Get Service",
    progressPara: "Receive the needed service.",
    class: "pl-3",
    arrowIcon: progressArrow,
  },
];

export const carMaintainContentData = {
  bannerTitle: "Road Assistance",
  bannerPara:
    "Sample text: 3 simple steps to reimburse the expenses outside the network Upon receiving the medical treatment outside the appointed network, the member will pay directly to the medical provider. He/she can apply for reimbursement of his/her medical expenses by submitting a request via Tawuniya's website or Tawuniya App and uploading all necessary support documents. Tawuniya will settle the claim and the funds will be transferred to his/her bank account.",
  bannerImg: carBanner,
  bannerClass: "carMaintain-sub-home-container",
  bannerImgClass: "carMaintain-carBanner",
  benifitTitle: "How it works?",
  benifitPara: "3 simple steps to reimburse the expenses",
  faqTitle: "Frequently Asked Questions",
  faqPara:
    "Review answers to commonly asked questions at Tawuniya, which enable you to be directly involved in improving our support experience.",
  progressLineClass: "benifitPrgressLine",
};

export const faqCarMaintainDashboardData = [
  {
    id: 0,
    evenKey: "0",
    question: "What kind of people/Organizations commits insurance fraud?",
    answer:
      "The insured is provided with an Insurance Card and Bail Bond approved by the Traffic Police together with Third Party Insurance Policy to prevent the arrest or detainment of the driver causing the accident?",
  },
  {
    id: 1,
    evenKey: "1",
    question: "What are some examples of insurance fraud/Abuse?",
    answer:
      "It's important to distinguish between Medical insurance fraud/abuse and mistakes. Medical insurance fraud is an act of deception by misrepresentation of material facts, or silence when good faith requires expression, resulting in material damage to one who relies on it and the right to rely on it. Simply, it is obtaining something of value from someone else through deceit. By contrast, mistakes are made unknowingly by anyone who lacks knowledge, in other words, it’s a misstatement or unintentional deviation from the truth. Therefore, Tawuniya always recommend reading the Medical Insurance Guidelines and visiting Tawuniya social media accounts for more information about the ideal use of your Medical Card.",
  },
  {
    id: 2,
    evenKey: "1",
    question: "What are we doing to prevent fraud?",
    answer:
      "It's important to distinguish between Medical insurance fraud/abuse and mistakes. Medical insurance fraud is an act of deception by misrepresentation of material facts, or silence when good faith requires expression, resulting in material damage to one who relies on it and the right to rely on it. Simply, it is obtaining something of value from someone else through deceit. By contrast, mistakes are made unknowingly by anyone who lacks knowledge, in other words, it’s a misstatement or unintentional deviation from the truth. Therefore, Tawuniya always recommend reading the Medical Insurance Guidelines and visiting Tawuniya social media accounts for more information about the ideal use of your Medical Card.",
  },
  {
    id: 3,
    evenKey: "1",
    question: "Shall I get a reward for reporting medical insurance fraud?",
    answer:
      "It's important to distinguish between Medical insurance fraud/abuse and mistakes. Medical insurance fraud is an act of deception by misrepresentation of material facts, or silence when good faith requires expression, resulting in material damage to one who relies on it and the right to rely on it. Simply, it is obtaining something of value from someone else through deceit. By contrast, mistakes are made unknowingly by anyone who lacks knowledge, in other words, it’s a misstatement or unintentional deviation from the truth. Therefore, Tawuniya always recommend reading the Medical Insurance Guidelines and visiting Tawuniya social media accounts for more information about the ideal use of your Medical Card.",
  },
];

export const dashboardCarMaintainHeaderData = {
  headerBannerTitle: "Motor Services",
  headerBannerPara: "Car Maintenance",
  url: "/dashboard/service/car-maintainance/new-request",
};

export const dashboardCarMaintainProgressData = [
  {
    id: 0,
    progressNo: "1",
    progresssTitle: "Submit Application",
    progressPara: "Complete your request details and attached the needed documents",
    class: "pr-3",
    arrowIcon: progressArrow,
  },
  {
    id: 1,
    progressNo: "2",
    progresssTitle: "Get Service",
    progressPara: "Receive the needed service.",
    class: "pl-3",
    arrowIcon: progressArrow,
  },
];

export const HomeChildVaccinateContentData = {
  bannerTitle: "Telemedicine",
  bannerPara:
    "Vaccinating your child at home Vaccination is essential for your child's safety and protection, which is part of our responsibility. Therefore, we provide vaccination service at home to Tawuniya members whose age between 0 – 7 years based on the basic vaccinations schedule issued by MOH and included in the Cooperative Health Insurance Uniﬁed Policy published by CCHI.",
  bannerImg: teleMedBanner,
  bannerClass: "homeVaccinate-sub-home-container",
  bannerImgClass: "homeVaccinate-carBanner",
  benifitTitle: "How it works?",
  benifitPara: "3 simple steps to reimburse the expenses",
  faqTitle: "Frequently Asked Questions",
  faqPara:
    "Review answers to commonly asked questions at Tawuniya, which enable you to be directly involved in improving our support experience.",
  progressLineClass: "benifitTeleMedProgressLine",
};

export const faqHomeChildVaccinateDashboardData = [
  {
    id: 0,
    evenKey: "0",
    question: "What kind of people/Organizations commits insurance fraud?",
    answer:
      "The insured is provided with an Insurance Card and Bail Bond approved by the Traffic Police together with Third Party Insurance Policy to prevent the arrest or detainment of the driver causing the accident?",
  },
  {
    id: 1,
    evenKey: "1",
    question: "What are some examples of insurance fraud/Abuse?",
    answer:
      "It's important to distinguish between Medical insurance fraud/abuse and mistakes. Medical insurance fraud is an act of deception by misrepresentation of material facts, or silence when good faith requires expression, resulting in material damage to one who relies on it and the right to rely on it. Simply, it is obtaining something of value from someone else through deceit. By contrast, mistakes are made unknowingly by anyone who lacks knowledge, in other words, it’s a misstatement or unintentional deviation from the truth. Therefore, Tawuniya always recommend reading the Medical Insurance Guidelines and visiting Tawuniya social media accounts for more information about the ideal use of your Medical Card.",
  },
  {
    id: 2,
    evenKey: "1",
    question: "What are we doing to prevent fraud?",
    answer:
      "It's important to distinguish between Medical insurance fraud/abuse and mistakes. Medical insurance fraud is an act of deception by misrepresentation of material facts, or silence when good faith requires expression, resulting in material damage to one who relies on it and the right to rely on it. Simply, it is obtaining something of value from someone else through deceit. By contrast, mistakes are made unknowingly by anyone who lacks knowledge, in other words, it’s a misstatement or unintentional deviation from the truth. Therefore, Tawuniya always recommend reading the Medical Insurance Guidelines and visiting Tawuniya social media accounts for more information about the ideal use of your Medical Card.",
  },
  {
    id: 3,
    evenKey: "1",
    question: "Shall I get a reward for reporting medical insurance fraud?",
    answer:
      "It's important to distinguish between Medical insurance fraud/abuse and mistakes. Medical insurance fraud is an act of deception by misrepresentation of material facts, or silence when good faith requires expression, resulting in material damage to one who relies on it and the right to rely on it. Simply, it is obtaining something of value from someone else through deceit. By contrast, mistakes are made unknowingly by anyone who lacks knowledge, in other words, it’s a misstatement or unintentional deviation from the truth. Therefore, Tawuniya always recommend reading the Medical Insurance Guidelines and visiting Tawuniya social media accounts for more information about the ideal use of your Medical Card.",
  },
];

export const dashboardHomeChildVaccinateHeaderData = {
  headerBannerTitle: "Health Services",
  headerBannerPara: "Home Child Vaccination",
};

export const dashboardHomeChildVaccinateProgressData = [
  {
    id: 0,
    progressNo: "1",
    progresssTitle: "Submit Application",
    progressPara: "Complete your consultation details",
    class: "pr-3",
    arrowIcon: progressArrow,
  },
  {
    id: 1,
    progressNo: "2",
    progresssTitle: "Receive the Call",
    progressPara:
      "Receive reliable medical consultation from accredited doctors in all health specialties.",
    class: "pl-3",
    arrowIcon: progressArrow,
  },
];

export const eligibilityContentData = {
  bannerTitle: "Telemedicine",
  bannerPara:
    "Your right to obtain treatment is reserved In case you have any problem while visiting the approved medical service provider, Tawuniya will issue an eligibility letter, conﬁrming your right to get the necessary treatment.",
  bannerImg: teleMedBanner,
  bannerClass: "eligibility-sub-home-container",
  bannerImgClass: "eligibility-carBanner",
  benifitTitle: "How it works?",
  benifitPara: "3 simple steps to reimburse the expenses",
  faqTitle: "Frequently Asked Questions",
  faqPara:
    "Review answers to commonly asked questions at Tawuniya, which enable you to be directly involved in improving our support experience.",
  progressLineClass: "benifitTeleMedProgressLine",
};

export const faqEligibilityDashboardData = [
  {
    id: 0,
    evenKey: "0",
    question: "What kind of people/Organizations commits insurance fraud?",
    answer:
      "The insured is provided with an Insurance Card and Bail Bond approved by the Traffic Police together with Third Party Insurance Policy to prevent the arrest or detainment of the driver causing the accident?",
  },
  {
    id: 1,
    evenKey: "1",
    question: "What are some examples of insurance fraud/Abuse?",
    answer:
      "It's important to distinguish between Medical insurance fraud/abuse and mistakes. Medical insurance fraud is an act of deception by misrepresentation of material facts, or silence when good faith requires expression, resulting in material damage to one who relies on it and the right to rely on it. Simply, it is obtaining something of value from someone else through deceit. By contrast, mistakes are made unknowingly by anyone who lacks knowledge, in other words, it’s a misstatement or unintentional deviation from the truth. Therefore, Tawuniya always recommend reading the Medical Insurance Guidelines and visiting Tawuniya social media accounts for more information about the ideal use of your Medical Card.",
  },
  {
    id: 2,
    evenKey: "1",
    question: "What are we doing to prevent fraud?",
    answer:
      "It's important to distinguish between Medical insurance fraud/abuse and mistakes. Medical insurance fraud is an act of deception by misrepresentation of material facts, or silence when good faith requires expression, resulting in material damage to one who relies on it and the right to rely on it. Simply, it is obtaining something of value from someone else through deceit. By contrast, mistakes are made unknowingly by anyone who lacks knowledge, in other words, it’s a misstatement or unintentional deviation from the truth. Therefore, Tawuniya always recommend reading the Medical Insurance Guidelines and visiting Tawuniya social media accounts for more information about the ideal use of your Medical Card.",
  },
  {
    id: 3,
    evenKey: "1",
    question: "Shall I get a reward for reporting medical insurance fraud?",
    answer:
      "It's important to distinguish between Medical insurance fraud/abuse and mistakes. Medical insurance fraud is an act of deception by misrepresentation of material facts, or silence when good faith requires expression, resulting in material damage to one who relies on it and the right to rely on it. Simply, it is obtaining something of value from someone else through deceit. By contrast, mistakes are made unknowingly by anyone who lacks knowledge, in other words, it’s a misstatement or unintentional deviation from the truth. Therefore, Tawuniya always recommend reading the Medical Insurance Guidelines and visiting Tawuniya social media accounts for more information about the ideal use of your Medical Card.",
  },
];

export const dashboardEligibilityProgressData = [
  {
    id: 0,
    progressNo: "1",
    progresssTitle: "Submit Request",
    progressPara:
      "In case there is any reason that prevents you from obtaining treatment from the approved service provider, contact Tawuniya on 8001249990, or submit a request through Tawuniya App. or send an SMS to 0501492222, including the member ID number.",
    class: "pr-3",
    arrowIcon: progressArrow,
  },
  {
    id: 1,
    progressNo: "2",
    progresssTitle: "Get Eligibility letter",
    progressPara:
      "Tawuniya will issue an immediate eligibility letter addressed to the service provider stating that Tawuniya has agreed to provide you the necessary treatment.",
    class: "pl-3",
    arrowIcon: progressArrow,
  },
  {
    id: 1,
    progressNo: "2",
    progresssTitle: "Get Eligibility letter",
    progressPara:
      "Tawuniya will issue an immediate eligibility letter addressed to the service provider stating that Tawuniya has agreed to provide you the necessary treatment.",
    class: "pl-3",
    arrowIcon: progressArrow,
  },
  {
    id: 1,
    progressNo: "2",
    progresssTitle: "Get Eligibility letter",
    progressPara:
      "Tawuniya will issue an immediate eligibility letter addressed to the service provider stating that Tawuniya has agreed to provide you the necessary treatment.",
    class: "pl-3",
    arrowIcon: progressArrow,
  },
];

export const pregnancyContentData = {
  bannerTitle: "Telemedicine",
  bannerPara:
    "An unforgettable pregnancy journey We will do our best to make your pregnancy journey full of memorable and beautiful memories through the Pregnancy follow-up program that takes care of a pregnant mother and increases her awareness of all procedures and provide awarness tips during all the phases of pregnancy",
  bannerImg: teleMedBanner,
  bannerClass: "pregnancy-sub-home-container",
  bannerImgClass: "pregnancy-carBanner",
  benifitTitle: "How it works?",
  benifitPara: "3 simple steps to reimburse the expenses",
  faqTitle: "Frequently Asked Questions",
  faqPara:
    "Review answers to commonly asked questions at Tawuniya, which enable you to be directly involved in improving our support experience.",
  progressLineClass: "benifitTeleMedProgressLine",
};

export const chronicsContentData = {
  bannerTitle: "Telemedicine",
  bannerPara:
    "We take care of your health to the smallest detail. If you suffer from any chronic disease, this service allows you to get comprehensive health care that takes care of the smallest detail. From medical consultations to receiving the necessary medication, in addition to laboratory services. In some cases, the service is provided at your residence.",
  bannerImg: teleMedBanner,
  bannerClass: "pregnancy-sub-home-container",
  bannerImgClass: "pregnancy-carBanner",
  benifitTitle: "How it works?",
  benifitPara: "3 simple steps to reimburse the expenses",
  faqTitle: "Frequently Asked Questions",
  faqPara:
    "Review answers to commonly asked questions at Tawuniya, which enable you to be directly involved in improving our support experience.",
  progressLineClass: "benifitTeleMedProgressLine",
};

export const faqPregnancyDashboardData = [
  {
    id: 0,
    evenKey: "0",
    question: "What kind of people/Organizations commits insurance fraud?",
    answer:
      "The insured is provided with an Insurance Card and Bail Bond approved by the Traffic Police together with Third Party Insurance Policy to prevent the arrest or detainment of the driver causing the accident?",
  },
  {
    id: 1,
    evenKey: "1",
    question: "What are some examples of insurance fraud/Abuse?",
    answer:
      "It's important to distinguish between Medical insurance fraud/abuse and mistakes. Medical insurance fraud is an act of deception by misrepresentation of material facts, or silence when good faith requires expression, resulting in material damage to one who relies on it and the right to rely on it. Simply, it is obtaining something of value from someone else through deceit. By contrast, mistakes are made unknowingly by anyone who lacks knowledge, in other words, it’s a misstatement or unintentional deviation from the truth. Therefore, Tawuniya always recommend reading the Medical Insurance Guidelines and visiting Tawuniya social media accounts for more information about the ideal use of your Medical Card.",
  },
  {
    id: 2,
    evenKey: "1",
    question: "What are we doing to prevent fraud?",
    answer:
      "It's important to distinguish between Medical insurance fraud/abuse and mistakes. Medical insurance fraud is an act of deception by misrepresentation of material facts, or silence when good faith requires expression, resulting in material damage to one who relies on it and the right to rely on it. Simply, it is obtaining something of value from someone else through deceit. By contrast, mistakes are made unknowingly by anyone who lacks knowledge, in other words, it’s a misstatement or unintentional deviation from the truth. Therefore, Tawuniya always recommend reading the Medical Insurance Guidelines and visiting Tawuniya social media accounts for more information about the ideal use of your Medical Card.",
  },
  {
    id: 3,
    evenKey: "1",
    question: "Shall I get a reward for reporting medical insurance fraud?",
    answer: "Diabetes , Hypertension , Thyroid Disorders , Dyslipidemia and Asthma",
  },
];

export const faqChronicDashboardData = [
  {
    id: 0,
    evenKey: "0",
    question: "What Chronic Diseases are covered ?",
    answer: "Diabetes , Hypertension , Thyroid Disorders , Dyslipidemia and Asthma",
  },
  {
    id: 1,
    evenKey: "1",
    question: "Who’s eligible for the program? ",
    answer: "Members under the top 6 networks (Gold,+1,1,+2,2,3).",
  },
  {
    id: 2,
    evenKey: "1",
    question: "What are the program services? ",
    answer:
      "Medical advice. Medication refill & delivery in some cities. Home laboratory services in some cities.",
  },
  {
    id: 3,
    evenKey: "1",
    question: "How do I register for the service? ",
    answer:
      "The customer can request the service through one of the channels available through the website or by communicating with customer service.",
  },
];

export const dashboardPregnancyHeaderData = {
  headerBannerTitle: "Health Services",
  headerBannerPara: "Pregnancy Program",
};

export const dashboardPregnancyProgressData = [
  {
    id: 0,
    progressNo: "1",
    progresssTitle: "Submit Application",
    progressPara:
      "In case of acceptance, Tawuniya will develop an awareness plan for the pregnant woman and provide tips and consultation starting from joining the program until birth",
    class: "pr-3",
    arrowIcon: progressArrow,
  },
  {
    id: 1,
    progressNo: "2",
    progresssTitle: "Receive the Call",
    progressPara:
      "In case of acceptance, Tawuniya will develop an awareness plan for the pregnant woman and provide tips and consultation starting from joining the program until birth",
    class: "pl-3",
    arrowIcon: progressArrow,
  },
  {
    id: 2,
    progressNo: "3",
    progresssTitle: "Get Reimburse",
    progressPara:
      "In case of acceptance, Tawuniya will develop an awareness plan for the pregnant woman and provide tips and consultation starting from joining the program until birth",
    class: "pr-3",
    arrowIcon: progressArrow,
  },
];

export const dashboardPregnancyData = [
  {
    id: 0,
    progressNo: "1",
    progresssTitle: "",
    progressPara:
      "Based on the data in Tawuniya medical system, the eligible pregnancy members will be identiﬁed and contacted by Tawuniya team, also customers can request this service through Tawuniya digital channels or by communicating customer service team.",
    class: "pr-3",
    arrowIcon: progressArrow,
  },
  {
    id: 1,
    progressNo: "2",
    progresssTitle: "",
    progressPara:
      "The client receives an initial call from Tawuniya's customer service or one of the partners for the purpose of explaining the program, verifying the basic information and obtaining approval from client to obtain this service.",
    class: "pl-3",
    arrowIcon: progressArrow,
  },
  {
    id: 2,
    progressNo: "3",
    progresssTitle: "",
    progressPara:
      "In case of acceptance, Tawuniya will develop an awareness plan for the pregnant woman and provide tips and consultation starting from joining the program until birth",
    class: "pr-3",
    arrowIcon: progressArrow,
  },
];
export const dashboardHomeChildProgressData = [
  {
    id: 0,
    progressNo: "1",
    progresssTitle: "Submit Application",
    progressPara: "Select child details and vaccine type",
    class: "pr-3",
    arrowIcon: progressArrow,
  },
  {
    id: 1,
    progressNo: "2",
    progresssTitle: "Confirm Request",
    progressPara: "Receive a call from Tawuniya to confirm request",
    class: "pl-3",
    arrowIcon: progressArrow,
  },
  {
    id: 2,
    progressNo: "3",
    progresssTitle: "Receive the Call",
    progressPara: "Receive a call from Medical provider to schedule a visit",
    class: "pr-3",
    arrowIcon: progressArrow,
  },
];

export const dashboardChronicsProgressData = [
  {
    id: 0,
    progressNo: "1",
    progresssTitle: "Submit Application",
    progressPara: "Complete your consultation details",
    class: "pr-3",
    arrowIcon: progressArrow,
  },
  {
    id: 1,
    progressNo: "2",
    progresssTitle: "Receive the Call",
    progressPara:
      "Receive reliable medical consultation from accredited doctors in all health specialties.",
    class: "pl-3",
    arrowIcon: progressArrow,
  },
];

export const reimBursmentContentData = {
  bannerTitle: "Telemedicine",
  bannerPara:
    "Reimburse your expenses outside the network While you are being treated outside the approved network, you will pay directly to the medical provider. In this case, you can apply for reimbursement of your medical expenses by submitting a request via Tawuniya’s website or Tawuniya App and uploading all necessary support documents. Tawuniya will settle the claim, and the funds will be transferred to your bank account",
  bannerImg: teleMedBanner,
  bannerClass: "reimBursment-sub-home-container",
  bannerImgClass: "reimBursment-carBanner",
  benifitTitle: "How it works?",
  benifitPara: "3 simple steps to reimburse the expenses",
  faqTitle: "Frequently Asked Questions",
  faqPara:
    "Review answers to commonly asked questions at Tawuniya, which enable you to be directly involved in improving our support experience.",
  progressLineClass: "benifitTeleMedProgressLine",
};

export const faqReimBursmentDashboardData = [
  {
    id: 0,
    evenKey: "0",
    question: "What kind of people/Organizations commits insurance fraud?",
    answer:
      "The insured is provided with an Insurance Card and Bail Bond approved by the Traffic Police together with Third Party Insurance Policy to prevent the arrest or detainment of the driver causing the accident?",
  },
  {
    id: 1,
    evenKey: "1",
    question: "What are some examples of insurance fraud/Abuse?",
    answer:
      "It's important to distinguish between Medical insurance fraud/abuse and mistakes. Medical insurance fraud is an act of deception by misrepresentation of material facts, or silence when good faith requires expression, resulting in material damage to one who relies on it and the right to rely on it. Simply, it is obtaining something of value from someone else through deceit. By contrast, mistakes are made unknowingly by anyone who lacks knowledge, in other words, it’s a misstatement or unintentional deviation from the truth. Therefore, Tawuniya always recommend reading the Medical Insurance Guidelines and visiting Tawuniya social media accounts for more information about the ideal use of your Medical Card.",
  },
  {
    id: 2,
    evenKey: "1",
    question: "What are we doing to prevent fraud?",
    answer:
      "It's important to distinguish between Medical insurance fraud/abuse and mistakes. Medical insurance fraud is an act of deception by misrepresentation of material facts, or silence when good faith requires expression, resulting in material damage to one who relies on it and the right to rely on it. Simply, it is obtaining something of value from someone else through deceit. By contrast, mistakes are made unknowingly by anyone who lacks knowledge, in other words, it’s a misstatement or unintentional deviation from the truth. Therefore, Tawuniya always recommend reading the Medical Insurance Guidelines and visiting Tawuniya social media accounts for more information about the ideal use of your Medical Card.",
  },
  {
    id: 3,
    evenKey: "1",
    question: "Shall I get a reward for reporting medical insurance fraud?",
    answer:
      "It's important to distinguish between Medical insurance fraud/abuse and mistakes. Medical insurance fraud is an act of deception by misrepresentation of material facts, or silence when good faith requires expression, resulting in material damage to one who relies on it and the right to rely on it. Simply, it is obtaining something of value from someone else through deceit. By contrast, mistakes are made unknowingly by anyone who lacks knowledge, in other words, it’s a misstatement or unintentional deviation from the truth. Therefore, Tawuniya always recommend reading the Medical Insurance Guidelines and visiting Tawuniya social media accounts for more information about the ideal use of your Medical Card.",
  },
];

export const dashboardReimBursmentHeaderData = {
  headerBannerTitle: "Health Services",
  headerBannerPara: "Medical Reimbursement",
};

export const dashboardReimBursmentProgressData = [
  {
    id: 0,
    progressNo: "1",
    progresssTitle: "Submit Application",
    progressPara: "Complete your request details and attached the needed documents",
    class: "pr-3",
    arrowIcon: progressArrow,
  },
  {
    id: 1,
    progressNo: "2",
    progresssTitle: "Review",
    progressPara: "The application will be reviewed in by our team",
    class: "pl-3",
    arrowIcon: progressArrow,
  },
  {
    id: 2,
    progressNo: "3",
    progresssTitle: "Get Reimburse",
    progressPara: "Reimbursement will be deposited to your bank account after approval",
    class: "pr-3",
    arrowIcon: progressArrow,
  },
];

export const loginTranslateData = {
  cardTitleEn: "Login & Join!",
  userIdPlaceHolderEn: "Saudi ID or Iqama Number",
  dobPlaceHolderEn: "Year of Birth",
  mobilePlaceHolderEn: "",
  continueBtnEn: "Continue",
  resetBtnEn: "Reset Mobile Number",
  cardTitleAr: "تسجيل الدخول والانضمام!",
  userIdPlaceHolderAr: "الهوية السعودية أو رقم الإقامة",
  dobPlaceHolderAr: " سنة الولادة",
  mobilePlaceHolderAr: "",
  continueBtnAr: "متابعة",
  termsAr: "من خلال الاستمرار ، فإنك توافق على شروط الخدمة وسياسة الخصوصية الخاصة بالتعاونية",
  resetBtnAr: "إعادة تعيين رقم الهاتف ",
};

export const loginVerifyTranslateData = {
  cardTitleEn: "Verification",
  cardParaEn: "An SMS will be sent to the following mobile number you have registered with us:",
  cardVerifyTitleEn: "Verification Code",
  verifyBtnEn: "Verify",
  resendTxtEn: "Resend code in 00:",
  cardTitleAr: "تحقق",
  cardParaAr: "سيتم إرسال رسالة نصية قصيرة إلى رقم الهاتف المحمول التالي الذي قمت بتسجيله لدينا:",
  cardVerifyTitleAr: "رمز التحقق",
  verifyBtnAr: "تحقق ",
  resendTxtAr: "إعادة إرسال الرمز في ",
  noResendTxtOneAr: "ل إعادة إرسال",
  noResendTxtTwoAr: "م تتلقى الرمز ؟",
};

export const navbarTranslateData = {};

export const MediaCenterLatestRelatedNews = [
  {
    buttonData: "News",
    date: "10 May 2022",
    description: "Tawuniya appoints Dr. Ammr Kurdi as Chief Financial Officer",
  },
  {
    buttonData: "News",
    date: "2 days ago",
    description: "Tawuniya is the first company in the Kingdom to install vehicle insurance policies",
  },
  {
    buttonData: "News",
    date: "Week ago",
    description: "Tawuniya launches Covid-19 Travel insurance program",
  },
  {
    buttonData: "News",
    date: "2 days ago",
    description: "Tawuniya is the first company in the Kingdom to install vehicle insurance policies",
  },
  {
    buttonData: "News",
    date: "Week ago",
    description: "Tawuniya launches Covid-19 Travel insurance program",
  },
];

export const industrialEnergyInsurance = [
  {
    id: 1,
    label: "Property Insurance: All Risk",
    bannerImage: engineerIcon13,
  },
  {
    id: 2,
    label: "Property Insurance: Fire And Additional Perils",
    bannerImage: engineerIcon14,
  },
  {
    id: 3,
    label: "Consequential Loss-Property Damage Insurance",
    bannerImage: engineerIcon15,
  },
  {
    id: 4,
    label: "Energy - All Large/ Major Risks Insurance",
    bannerImage: industrialIcon4,
  },
  {
    id: 5,
    label: "Machinery Breakdown Insurance",
    bannerImage: engineerIcon4,
  },
  {
    id: 6,
    label: "Loss of Profits Insurance following machinery breakdown",
    bannerImage: engineerIcon6,
  },
  {
    id: 7,
    label: "Boiler And Pressure Vessel Insurance",
    bannerImage: industrialIcon7,
  },
  {
    id: 8,
    label: "Public and Product Liability Insurance",
    bannerImage: engineerIcon7,
  },
  {
    id: 9,
    label: "Workmen's Compensation Insurance",
    bannerImage: engineerIcon9,
  },
  {
    id: 10,
    label: "Professional Indemnity Insurance",
    bannerImage: industrialIcon10,
  },
  {
    id: 11,
    label: "Fidelity Guarantee Insurance",
    bannerImage: engineerIcon12,
  },
  {
    id: 12,
    label: "Money Insurance",
    bannerImage: engineerIcon11,
  },
  {
    id: 13,
    label: "Contractors' All Risks Insurance",
    bannerImage: engineerIcon1,
  },
  {
    id: 14,
    label: "Contractors' Plant & Machinery Insurance",
    bannerImage: engineerIcon2,
  },
  {
    id: 15,
    label: "Erection All Risk Insurance",
    bannerImage: engineerIcon3,
  },
  {
    id: 16,
    label: "Sabotage & Terrorism Insurance",
    bannerImage: industrialIcon4,
  },
  {
    id: 17,
    label: "Marine Cargo Insurance",
    bannerImage: engineerIcon16,
  },
];

export const financialInvestmentInsurance = [
  {
    id: 1,
    label: "Public Liability Insurance",
    bannerImage: engineerIcon7,
  },
  {
    id: 2,
    label: "Property Insurance: All Risk",
    bannerImage: engineerIcon13,
  },
  {
    id: 3,
    label: "Property Insurance: Fire And Additional Perils",
    bannerImage: engineerIcon14,
  },
  {
    id: 4,
    label: "Professional Indemnity Insurance for Stock Brokers",
    bannerImage: engineerIcon8,
  },
  {
    id: 5,
    label: "Money Insurance",
    bannerImage: engineerIcon11,
  },
  {
    id: 6,
    label: "Fidelity Guarantee Insurance",
    bannerImage: engineerIcon12,
  },
  {
    id: 7,
    label: "Crime Bond Insurance",
    bannerImage: financialIcon7,
  },
  {
    id: 8,
    label: "Theft Insurance",
    bannerImage: financialIcon8,
  },
  {
    id: 9,
    label: "Business Travel insurance",
    bannerImage: financialIcon9,
  },
  {
    id: 10,
    label: "Sabotage & Terrorism Insurance",
    bannerImage: industrialIcon16,
  },
  {
    id: 11,
    label: "Contractors' All Risks Insurance",
    bannerImage: engineerIcon1,
  },
  {
    id: 12,
    label: "Marine Cargo Insurance",
    bannerImage: engineerIcon16,
  },
];

export const retailTradingInsurance = [
  {
    id: 1,
    label: "Property Insurance: All Risk",
    bannerImage: engineerIcon13,
  },
  {
    id: 2,
    label: "Property Insurance: Fire And Additional Perils",
    bannerImage: engineerIcon14,
  },
  {
    id: 3,
    label: "Consequential Loss-Property Damage Insurance",
    bannerImage: engineerIcon15,
  },
  {
    id: 4,
    label: "Public and Product Liability Insurance",
    bannerImage: engineerIcon7,
  },
  {
    id: 5,
    label: "Theft Insurance",
    bannerImage: financialIcon8,
  },
  {
    id: 6,
    label: "Money Insurance",
    bannerImage: engineerIcon11,
  },
  {
    id: 7,
    label: "Credit Insurance",
    bannerImage: retailIcon7,
  },
  {
    id: 8,
    label: "Plate Glass Insurance",
    bannerImage: retailIcon8,
  },
  {
    id: 9,
    label: "Marine Cargo Insurance",
    bannerImage: engineerIcon16,
  },
  {
    id: 10,
    label: "Goods in Transit Insurance",
    bannerImage: retailIcon10,
  },
  {
    id: 11,
    label: "Workmen's Compensation Insurance",
    bannerImage: engineerIcon9,
  },
  {
    id: 12,
    label: "Deterioration of Stock in cold storage Insurance",
    bannerImage: retailIcon12,
  },
  {
    id: 13,
    label: "Airport Contractors Liability Insurance",
    bannerImage: retailIcon13,
  },
  {
    id: 14,
    label: "Fidelity Guarantee Insurance",
    bannerImage: engineerIcon12,
  },
  {
    id: 15,
    label: "Contractors' All Risks Insurance",
    bannerImage: engineerIcon1,
  },
];

export const servicesInsurance = [
  {
    id: 1,
    label: "Property Insurance: All Risk",
    bannerImage: engineerIcon13,
  },
  {
    id: 2,
    label: "Property Insurance: Fire And Additional Perils",
    bannerImage: engineerIcon14,
  },
  {
    id: 3,
    label: "Consequential Loss-Property Damage Insurance",
    bannerImage: engineerIcon15,
  },
  {
    id: 4,
    label: "Public and Product Liability Insurance",
    bannerImage: engineerIcon7,
  },
  {
    id: 5,
    label: "Theft Insurance",
    bannerImage: financialIcon8,
  },
  {
    id: 6,
    label: "Money Insurance",
    bannerImage: engineerIcon11,
  },
  {
    id: 7,
    label: "Credit Insurance",
    bannerImage: retailIcon7,
  },
  {
    id: 8,
    label: "Plate Glass Insurance",
    bannerImage: retailIcon8,
  },
  {
    id: 9,
    label: "Marine Cargo Insurance",
    bannerImage: engineerIcon16,
  },
  {
    id: 10,
    label: "Airport Contractors Liability Insurance",
    bannerImage: serviceIcon10,
  },
  {
    id: 11,
    label: "Fidelity Guarantee Insurance",
    bannerImage: engineerIcon12,
  },
  {
    id: 12,
    label: "Professional Indemnity Insurance",
    bannerImage: serviceIcon12,
  },
  {
    id: 13,
    label: "Personal Accident Insurance",
    bannerImage: serviceIcon13,
  },
  {
    id: 14,
    label: "Sabotage & Terrorism Insurance",
    bannerImage: industrialIcon16,
  },
  {
    id: 15,
    label: "Contractors' All Risks Insurance",
    bannerImage: engineerIcon1,
  },
];

export const telecommunicationITInsurance = [
  {
    id: 1,
    label: "Property Insurance: All Risk",
    bannerImage: engineerIcon13,
  },
  {
    id: 2,
    label: "Property Insurance: Fire And Additional Perils",
    bannerImage: engineerIcon14,
  },
  {
    id: 3,
    label: "Consequential Loss-Property Damage Insurance",
    bannerImage: engineerIcon15,
  },
  {
    id: 4,
    label: "Public Liability Insurance",
    bannerImage: engineerIcon7,
  },
  {
    id: 5,
    label: "Professional Indemnity Insurance",
    bannerImage: engineerIcon8,
  },
  {
    id: 6,
    label: "Electronic Equipments Insurance",
    bannerImage: engineerIcon10,
  },
  {
    id: 7,
    label: "Erection All Risks Insurance",
    bannerImage: engineerIcon3,
  },
  {
    id: 8,
    label: "Theft Insurance",
    bannerImage: financialIcon8,
  },
  {
    id: 9,
    label: "Fidelity Guarantee Insurance",
    bannerImage: engineerIcon12,
  },
  {
    id: 10,
    label: "Money Insurance",
    bannerImage: engineerIcon11,
  },
  {
    id: 11,
    label: "Plate Glass Insurance",
    bannerImage: retailIcon8,
  },
  {
    id: 12,
    label: "Personal Accident Insurance",
    bannerImage: serviceIcon13,
  },
  {
    id: 13,
    label: "Workmen's Compensation Insurance",
    bannerImage: engineerIcon9,
  },
  {
    id: 14,
    label: "Contractors' All Risks Insurance",
    bannerImage: engineerIcon1,
  },
  {
    id: 15,
    label: "Marine Cargo Insurance",
    bannerImage: engineerIcon16,
  },
];

export const transportationInsurance = [
  {
    id: 1,
    label: "Property Insurance: All Risk",
    bannerImage: engineerIcon13,
  },
  {
    id: 2,
    label: "Property Insurance: Fire And Additional Perils",
    bannerImage: engineerIcon14,
  },
  {
    id: 3,
    label: "Public Liability Insurance",
    bannerImage: engineerIcon7,
  },
  {
    id: 4,
    label: "Marine Cargo Insurance",
    bannerImage: engineerIcon16,
  },
  {
    id: 5,
    label: "Marine Hull Insurance",
    bannerImage: transportIcon5,
  },
  {
    id: 6,
    label: "Goods in Transit Insurance",
    bannerImage: retailIcon10,
  },
  {
    id: 7,
    label: "Aircraft Insurance",
    bannerImage: transportIcon7,
  },
  {
    id: 8,
    label: "Aircraft Hull & Liability Insurance",
    bannerImage: transportIcon8,
  },
  {
    id: 9,
    label: "Aircraft Hull “War and Allied Perils” Insurance",
    bannerImage: transportIcon9,
  },
  {
    id: 10,
    label: "Aircrew Loss of license Insurance",
    bannerImage: transportIcon10,
  },
  {
    id: 11,
    label: "Airport Contractors Liability Insurance",
    bannerImage: retailIcon13,
  },
  {
    id: 12,
    label: "Business Travel insurance",
    bannerImage: financialIcon9,
  },
  {
    id: 13,
    label: "Money Insurance",
    bannerImage: engineerIcon11,
  },
  {
    id: 14,
    label: "Theft Insurance",
    bannerImage: financialIcon8,
  },
  {
    id: 15,
    label: "Professional Indemnity Insurance",
    bannerImage: engineerIcon8,
  },
  {
    id: 16,
    label: "Workmen's Compensation Insurance",
    bannerImage: engineerIcon9,
  },
  {
    id: 17,
    label: "Crime Bond Insurance",
    bannerImage: financialIcon7,
  },
  {
    id: 18,
    label: "Contractors' All Risks Insurance",
    bannerImage: engineerIcon1,
  },
];
export const engineeringContractorsInsuranceQueue = [
  {
    id: 1,
    label: "Contractors' All Risks Insurance",
  },
  {
    id: 2,
    label: "Contractors' Plant & Machinery Insurance",
  },
  {
    id: 3,
    label: "Erection All Risks Insurance",
  },
  {
    id: 4,
    label: "Machinery Breakdown Insurance",
  },
  {
    id: 5,
    label: "Civil Engineering Completed risk Insurance",
  },
  {
    id: 6,
    label: "Loss of Profits Insurance following machinery breakdown",
  },
  {
    id: 7,
    label: "Public Liability Insurance",
  },
  {
    id: 8,
    label: "Professional Indemnity Insurance - Architect’s & Civil Engineering",
  },
  {
    id: 9,
    label: "Workmen's Compensation Insurance",
  },
  {
    id: 10,
    label: "Electronic Equipments Insurance",
  },
  {
    id: 11,
    label: "Money Insurance",
  },
  {
    id: 12,
    label: "Fidelity Guarantee Insurance",
  },
  {
    id: 13,
    label: "Property Insurance: All Risk",
  },
  {
    id: 14,
    label: "Property Insurance: Fire And Additional Perils",
  },
  {
    id: 15,
    label: "Consequential Loss-Property Damage Insurance",
  },
  {
    id: 16,
    label: "Marine Cargo Insurance",
  },
];

export const IndustrialEnergyInsuranceQueue = [
  {
    id: 1,
    label: "Property Insurance: All Risk",
  },
  {
    id: 2,
    label: "Property Insurance: Fire And Additional Perils",
  },
  {
    id: 3,
    label: "Consequential Loss-Property Damage Insurance",
  },
  {
    id: 4,
    label: "Energy - All Large/ Major Risks Insurance",
  },
  {
    id: 5,
    label: "Machinery Breakdown Insurance",
  },
  {
    id: 6,
    label: "Loss Of Profits Insurance following machinery breakdown",
  },
  {
    id: 7,
    label: "Boiler And Pressure Vessel Insurance",
  },
  {
    id: 8,
    label: "Public and Product Liability Insurance",
  },
  {
    id: 9,
    label: "Workmen's Compensation Insurance",
  },
  {
    id: 10,
    label: "Professional Indemnity Insurance",
  },
  {
    id: 11,
    label: "Fidelity Guarantee Insurance",
  },
  {
    id: 12,
    label: "Money Insurance",
  },
  {
    id: 13,
    label: "Contractors' All Risks Insurance",
  },
  {
    id: 14,
    label: "Contractors' Plant & Machinery Insurance",
  },
  {
    id: 15,
    label: "Erection All Risks Insurance",
  },
  {
    id: 16,
    label: "Sabotage & Terrorism Insurance",
  },
  {
    id: 17,
    label: "Marine Cargo Insurance",
  },
];
export const engineeringKeyBenefits = [
  {
    id: 1,
    label: "Premium",
    image: gift,
  },
  {
    id: 2,
    label: "Starting at 500 SAR per employee",
    image: gift,
  },
  {
    id: 3,
    label: "Cashless Hospital",
    image: gift,
  },
  {
    id: 4,
    label: "Geographical Coverage",
    image: gift,
  },
  {
    id: 5,
    label: "6400+ Cashless Hospitals Across KSA",
    image: gift,
  },
];
export const engineeringHealthInsurancepolicy = [
  {
    id: 1,
    image: boost,
    title: "Boost Employee Retention",
    content:
      "Health insurance will not only give your employees and their families enough financial security, but an overall sense of satisfaction that their employer actually cares about them.",
  },
  {
    id: 2,
    image: strength,
    title: "Strengthen Employee Motivation",
    content:
      "Happy employees make happy workspaces and evidently successful companies! It’s no surprise that the safer and satisfied employees feel happier and motivated!",
  },
  {
    id: 3,
    image: boost,
    title: "Enhance their Mental Wellbeing",
    content:
      "Health insurance will not only safeguard the employees' savings but, also enhance their overall mental well-being with the right support",
  },
  {
    id: 4,
    image: boost,
    title: "Protect them from Severe Health Conditions",
    content:
      "Safeguard your employees from the same, amongst other diseases; the earlier these issues are diagnosed the earlier they can be treated and resolved.",
  },
  {
    id: 5,
    image: boost,
    title: "6400+ Cashless Hospitals",
    content:
      "With more than 6400+ cashless hospitals across KSA, your employees can be covered at ease no matter where they are!",
  },
];
export const engineeringQuesAndAnswer = [
  {
    id: 1,
    question: "What is insurance fraud?",
    answer:
      "The insured is provided with an Insurance Card and Bail Bond approved by the Traffic Police together with Third Party Insurance Policy to prevent the arrest or detainment of the driver causing the accident?",
  },
  {
    id: 2,
    question: "What is fraud vs. Mistake?",
    answer:
      "The insured is provided with an Insurance Card and Bail Bond approved by the Traffic Police together with Third Party Insurance Policy to prevent the arrest or detainment of the driver causing the accident?",
  },
  {
    id: 3,
    question: "How does fraud impact you?",
    answer:
      "The insured is provided with an Insurance Card and Bail Bond approved by the Traffic Police together with Third Party Insurance Policy to prevent the arrest or detainment of the driver causing the accident?",
  },
  {
    id: 4,
    question: "What is medical fraud unit?",
    answer:
      "The insured is provided with an Insurance Card and Bail Bond approved by the Traffic Police together with Third Party Insurance Policy to prevent the arrest or detainment of the driver causing the accident?",
  },
  {
    id: 5,
    question: "What kind of people/Organizations commits insurance fraud?",
    answer:
      "The insured is provided with an Insurance Card and Bail Bond approved by the Traffic Police together with Third Party Insurance Policy to prevent the arrest or detainment of the driver causing the accident?",
  },
  {
    id: 6,
    question: "What are some examples of insurance fraud/Abuse?",
    answer:
      "The insured is provided with an Insurance Card and Bail Bond approved by the Traffic Police together with Third Party Insurance Policy to prevent the arrest or detainment of the driver causing the accident?",
  },
  {
    id: 7,
    question: "What are we doing to prevent fraud?",
    answer:
      "The insured is provided with an Insurance Card and Bail Bond approved by the Traffic Police together with Third Party Insurance Policy to prevent the arrest or detainment of the driver causing the accident?",
  },
  {
    id: 8,
    question: "Shall I get a reward for reporting medical insurance fraud?",
    answer:
      "The insured is provided with an Insurance Card and Bail Bond approved by the Traffic Police together with Third Party Insurance Policy to prevent the arrest or detainment of the driver causing the accident?",
  },
];
export const engineeringQuesAndAnswerbottom = [
  {
    id: 1,
    question: "What is insurance fraud?",
    answer:
      "The insured is provided with an Insurance Card and Bail Bond approved by the Traffic Police together with Third Party Insurance Policy to prevent the arrest or detainment of the driver causing the accident?",
  },
  {
    id: 2,
    question: "What is fraud vs. Mistake?",
    answer:
      "The insured is provided with an Insurance Card and Bail Bond approved by the Traffic Police together with Third Party Insurance Policy to prevent the arrest or detainment of the driver causing the accident?",
  },
  {
    id: 3,
    question: "How does fraud impact you?",
    answer:
      "The insured is provided with an Insurance Card and Bail Bond approved by the Traffic Police together with Third Party Insurance Policy to prevent the arrest or detainment of the driver causing the accident?",
  },
  {
    id: 4,
    question: "What is medical fraud unit?",
    answer:
      "The insured is provided with an Insurance Card and Bail Bond approved by the Traffic Police together with Third Party Insurance Policy to prevent the arrest or detainment of the driver causing the accident?",
  },
];

export const engineeringContractorsInsurance = [
  {
    id: 1,
    label: "Contractors' All Risks Insurance",
    bannerImage: engineerIcon1,
  },
  {
    id: 2,
    label: "Contractors' Plant & Machinery Insurance",
    bannerImage: engineerIcon2,
  },
  {
    id: 3,
    label: "Erection All Risks Insurance",
    bannerImage: engineerIcon3,
  },
  {
    id: 4,
    label: "Machinery Breakdown Insurance",
    bannerImage: engineerIcon4,
  },
  {
    id: 5,
    label: "Civil Engineering Completed risk Insurance",
    bannerImage: engineerIcon5,
  },
  {
    id: 6,
    label: "Loss of Profits Insurance following machinery breakdown",
    bannerImage: engineerIcon6,
  },
  {
    id: 7,
    label: "Workmen's Compensation Insurance",
    bannerImage: engineerIcon7,
  },
  {
    id: 8,
    label: "Professional Indemnity Insurance",
    bannerImage: engineerIcon8,
  },
  {
    id: 9,
    label: "Workmen's Compensation Insurance",
    bannerImage: engineerIcon9,
  },
  {
    id: 10,
    label: "Electronic Equipments Insurance",
    bannerImage: engineerIcon10,
  },
  {
    id: 11,
    label: "Money Insurance",
    bannerImage: engineerIcon11,
  },
  {
    id: 12,
    label: "Fidelity Guarantee Insurance",
    bannerImage: engineerIcon12,
  },
  {
    id: 13,
    label: "Property Insurance: All Risk",
    bannerImage: engineerIcon13,
  },
  {
    id: 14,
    label: "Property Insurance: Fire And Additional Perils",
    bannerImage: engineerIcon14,
  },
  {
    id: 15,
    label: "Consequential Loss-Property Damage Insurance",
    bannerImage: engineerIcon15,
  },
  {
    id: 16,
    label: "Marine Cargo Insurance",
    bannerImage: engineerIcon16,
  },
];

export const FinancialInvestmentInsuranceQueue = [
  {
    id: 1,
    label: "Public Liability Insurance",
  },
  {
    id: 2,
    label: "Property Insurance: All Risk",
  },
  {
    id: 3,
    label: "Property Insurance: Fire and Additional Perils",
  },
  {
    id: 4,
    label: "Professional Indemnity Insurance for Stock Brokers",
  },
  {
    id: 5,
    label: "Money Insurance",
  },
  {
    id: 6,
    label: "Fidelity Guarantee Insurance",
  },
  {
    id: 7,
    label: "Crime Bond Insurance",
  },
  {
    id: 8,
    label: "Theft Insurance",
  },
  {
    id: 9,
    label: "Business Travel insurance",
  },
  {
    id: 10,
    label: "Sabotage & Terrorism Insurance",
  },
  {
    id: 11,
    label: "Contractors' All Risks Insurance",
  },
  {
    id: 12,
    label: "Marine Cargo Insurance",
  },
];

export const RetailInsuranceQueue = [
  {
    id: 1,
    label: "Property Insurance: All Risk",
  },
  {
    id: 2,
    label: "Property Insurance: Fire And Additional Perils",
  },
  {
    id: 3,
    label: "Consequential Loss-Property Damage Insurance",
  },
  {
    id: 4,
    label: "Public and Product Liability Insurance",
  },
  {
    id: 5,
    label: "Theft Insurance",
  },
  {
    id: 6,
    label: "Money Insurance",
  },
  {
    id: 7,
    label: "Credit Insurance",
  },
  {
    id: 8,
    label: "Plate Glass Insurance",
  },
  {
    id: 9,
    label: "Marine Cargo Insurance",
  },
  {
    id: 10,
    label: "Goods in Transit Insurance",
  },
  {
    id: 11,
    label: "Workmen's Compensation Insurance",
  },
  {
    id: 12,
    label: "Deterioration of Stock in cold storage Insurance",
  },
  {
    id: 13,
    label: "Airport Contractors Liability Insurance",
  },
  {
    id: 14,
    label: "Fidelity Guarantee Insurance",
  },
  {
    id: 15,
    label: "Contractors' All Risks Insurance",
  },
];

export const ServicesInsuranceQueue = [
  {
    id: 1,
    label: "Property Insurance: All Risk",
  },
  {
    id: 2,
    label: "Property Insurance: Fire And Additional Perils",
  },
  {
    id: 3,
    label: "Consequential Loss-Property Damage Insurance",
  },
  {
    id: 4,
    label: "Public and Product Liability Insurance",
  },
  {
    id: 5,
    label: "Theft Insurance",
  },
  {
    id: 6,
    label: "Money Insurance",
  },
  {
    id: 7,
    label: "Credit Insurance",
  },
  {
    id: 8,
    label: "Plate Glass Insurance",
  },
  {
    id: 9,
    label: "Marine Cargo Insurance",
  },
  {
    id: 10,
    label: "Airport Contractors Liability Insurance",
  },
  {
    id: 11,
    label: "Fidelity Guarantee Insurance",
  },
  {
    id: 12,
    label: "Professional Indemnity Insurance",
  },
  {
    id: 13,
    label: "Personal Accident Insurance",
  },
  {
    id: 14,
    label: "Sabotage & Terrorism Insurance",
  },
  {
    id: 15,
    label: "Contractors' All Risks Insurance",
  },
];

export const TelecommunicationInsuranceQueue = [
  {
    id: 1,
    label: "Property Insurance: All Risk",
  },
  {
    id: 2,
    label: "Property Insurance: Fire And Additional Perils",
  },
  {
    id: 3,
    label: "Consequential Loss-Property Damage Insurance",
  },
  {
    id: 4,
    label: "Public Liability Insurance",
  },
  {
    id: 5,
    label: "Professional Indemnity Insurance",
  },
  {
    id: 6,
    label: "Electronic Equipments Insurance",
  },
  {
    id: 7,
    label: "Erection All Risks Insurance",
  },
  {
    id: 8,
    label: "Theft Insurance",
  },
  {
    id: 9,
    label: "Fidelity Guarantee Insurance",
  },
  {
    id: 10,
    label: "Money Insurance",
  },
  {
    id: 11,
    label: "Plate Glass Insurance",
  },
  {
    id: 12,
    label: "Personal Accident Insurance",
  },
  {
    id: 13,
    label: "Workmen's Compensation Insurance",
  },
  {
    id: 14,
    label: "Contractors' All Risks Insurance",
  },
  {
    id: 15,
    label: "Marine Cargo Insurance",
  },
];

export const TransportationInsuranceQueue = [
  {
    id: 1,
    label: "Property Insurance: All Risk",
  },
  {
    id: 2,
    label: "Property Insurance: Fire And Additional Perils",
  },
  {
    id: 3,
    label: "Public Liability Insurance",
  },
  {
    id: 4,
    label: "Marine Cargo Insurance",
  },
  {
    id: 5,
    label: "Marine Hull Insurance",
  },
  {
    id: 6,
    label: "Goods in Transit Insurance",
  },
  {
    id: 7,
    label: "Aircraft Insurance",
  },
  {
    id: 8,
    label: "Aircraft Hull & Liability Insurance",
  },
  {
    id: 9,
    label: "Aircraft Hull “War and Allied Perils” Insurance",
  },
  {
    id: 10,
    label: "Aircrew Loss of license Insurance",
  },
  {
    id: 11,
    label: "Airport Contractors Liability Insurance",
  },
  {
    id: 12,
    label: "Business Travel insurance",
  },
  {
    id: 13,
    label: "Money Insurance",
  },
  {
    id: 14,
    label: "Theft Insurance",
  },
  {
    id: 15,
    label: "Professional Indemnity Insurance",
  },
  {
    id: 16,
    label: "Workmen's Compensation Insurance",
  },
  {
    id: 17,
    label: "Crime Bond Insurance",
  },
  {
    id: 18,
    label: "Contractors' All Risks Insurance",
  },
];

export const homecustomerServiceFaqList = [
  {
    id: 1,
    question: "What is insurance fraud?",
    answer:
      "The insured is provided with an Insurance Card and Bail Bond approved by the Traffic Police together with Third Party Insurance Policy to prevent the arrest or detainment of the driver causing the accident?",
  },

  {
    id: 2,
    question: "What is fraud vs. Mistake?",
    answer:
      "The insured is provided with an Insurance Card and Bail Bond approved by the Traffic Police together with Third Party Insurance Policy to prevent the arrest or detainment of the driver causing the accident?",
  },

  {
    id: 3,
    question: "How does fraud impact you?",
    answer:
      "The insured is provided with an Insurance Card and Bail Bond approved by the Traffic Police together with Third Party Insurance Policy to prevent the arrest or detainment of the driver causing the accident?",
  },
  {
    id: 4,
    question: "What is medical fraud unit?",
    answer:
      "The insured is provided with an Insurance Card and Bail Bond approved by the Traffic Police together with Third Party Insurance Policy to prevent the arrest or detainment of the driver causing the accident?",
  },
  {
    id: 5,
    question: "What kind of people/Organizations commits insurance fraud?",
    answer:
      "The insured is provided with an Insurance Card and Bail Bond approved by the Traffic Police together with Third Party Insurance Policy to prevent the arrest or detainment of the driver causing the accident?",
  },
  {
    id: 6,
    question: "What are some examples of insurance fraud/Abuse?",
    answer:
      "The insured is provided with an Insurance Card and Bail Bond approved by the Traffic Police together with Third Party Insurance Policy to prevent the arrest or detainment of the driver causing the accident?",
  },
  {
    id: 7,
    question: "What are we doing to prevent fraud?",
    answer:
      "The insured is provided with an Insurance Card and Bail Bond approved by the Traffic Police together with Third Party Insurance Policy to prevent the arrest or detainment of the driver causing the accident?",
  },
  {
    id: 8,
    question: "Shall I get a reward for reporting medical insurance fraud?",
    answer:
      "The insured is provided with an Insurance Card and Bail Bond approved by the Traffic Police together with Third Party Insurance Policy to prevent the arrest or detainment of the driver causing the accident?",
  },
];

export const faqServiceQuestionData = [
  {
    id: 0,
    question: "What is insurance fraud?",
    answer:
      "It's important to distinguish between Medical insurance fraud/abuse and mistakes. Medical insurance fraud is an act of deception by misrepresentation of material facts, or silence when good faith requires expression, resulting in material damage to one who relies on it and the right to rely on it. Simply, it is obtaining something of value from someone else through deceit.By contrast, mistakes are made unknowingly by anyone who lacks knowledge, in other words, it’s a misstatement or unintentional deviation from the truth.",
    nextAnswer:
      "Therefore, Tawuniya always recommend reading the Medical Insurance Guidelines and visiting Tawuniya social media accounts for more information about the ideal use of your Medical Card.",
  },
  {
    id: 1,
    question: "What is fraud vs. Mistake?",
    answer:
      "It's important to distinguish between Medical insurance fraud/abuse and mistakes. Medical insurance fraud is an act of deception by misrepresentation of material facts, or silence when good faith requires expression, resulting in material damage to one who relies on it and the right to rely on it. Simply, it is obtaining something of value from someone else through deceit.By contrast, mistakes are made unknowingly by anyone who lacks knowledge, in other words, it’s a misstatement or unintentional deviation from the truth.",
    nextAnswer:
      "Therefore, Tawuniya always recommend reading the Medical Insurance Guidelines and visiting Tawuniya social media accounts for more information about the ideal use of your Medical Card.",
  },
  {
    id: 2,
    question: "How does fraud impact you?",
    answer:
      "It's important to distinguish between Medical insurance fraud/abuse and mistakes. Medical insurance fraud is an act of deception by misrepresentation of material facts, or silence when good faith requires expression, resulting in material damage to one who relies on it and the right to rely on it. Simply, it is obtaining something of value from someone else through deceit.By contrast, mistakes are made unknowingly by anyone who lacks knowledge, in other words, it’s a misstatement or unintentional deviation from the truth.",
    nextAnswer:
      "Therefore, Tawuniya always recommend reading the Medical Insurance Guidelines and visiting Tawuniya social media accounts for more information about the ideal use of your Medical Card.",
  },
  {
    id: 3,
    question: "What is medical fraud unit? ",
    answer:
      "It's important to distinguish between Medical insurance fraud/abuse and mistakes. Medical insurance fraud is an act of deception by misrepresentation of material facts, or silence when good faith requires expression, resulting in material damage to one who relies on it and the right to rely on it. Simply, it is obtaining something of value from someone else through deceit.By contrast, mistakes are made unknowingly by anyone who lacks knowledge, in other words, it’s a misstatement or unintentional deviation from the truth.",
    nextAnswer:
      "Therefore, Tawuniya always recommend reading the Medical Insurance Guidelines and visiting Tawuniya social media accounts for more information about the ideal use of your Medical Card.",
  },
  {
    id: 4,
    question: "What kind of people/Organizations commits insurance fraud?",
    answer:
      "It's important to distinguish between Medical insurance fraud/abuse and mistakes. Medical insurance fraud is an act of deception by misrepresentation of material facts, or silence when good faith requires expression, resulting in material damage to one who relies on it and the right to rely on it. Simply, it is obtaining something of value from someone else through deceit.By contrast, mistakes are made unknowingly by anyone who lacks knowledge, in other words, it’s a misstatement or unintentional deviation from the truth.",
    nextAnswer:
      "Therefore, Tawuniya always recommend reading the Medical Insurance Guidelines and visiting Tawuniya social media accounts for more information about the ideal use of your Medical Card.",
  },
  {
    id: 5,
    question: "What are some examples of insurance fraud/Abuse?",
    answer:
      "It's important to distinguish between Medical insurance fraud/abuse and mistakes. Medical insurance fraud is an act of deception by misrepresentation of material facts, or silence when good faith requires expression, resulting in material damage to one who relies on it and the right to rely on it. Simply, it is obtaining something of value from someone else through deceit.By contrast, mistakes are made unknowingly by anyone who lacks knowledge, in other words, it’s a misstatement or unintentional deviation from the truth.",
    nextAnswer:
      "Therefore, Tawuniya always recommend reading the Medical Insurance Guidelines and visiting Tawuniya social media accounts for more information about the ideal use of your Medical Card.",
  },
  {
    id: 6,
    question: "What are we doing to prevent fraud?",
    answer:
      "It's important to distinguish between Medical insurance fraud/abuse and mistakes. Medical insurance fraud is an act of deception by misrepresentation of material facts, or silence when good faith requires expression, resulting in material damage to one who relies on it and the right to rely on it. Simply, it is obtaining something of value from someone else through deceit.By contrast, mistakes are made unknowingly by anyone who lacks knowledge, in other words, it’s a misstatement or unintentional deviation from the truth.",
    nextAnswer:
      "Therefore, Tawuniya always recommend reading the Medical Insurance Guidelines and visiting Tawuniya social media accounts for more information about the ideal use of your Medical Card.",
  },
  {
    id: 7,
    question: "Shall I get a reward for reporting medical insurance fraud?",
    answer:
      "It's important to distinguish between Medical insurance fraud/abuse and mistakes. Medical insurance fraud is an act of deception by misrepresentation of material facts, or silence when good faith requires expression, resulting in material damage to one who relies on it and the right to rely on it. Simply, it is obtaining something of value from someone else through deceit.By contrast, mistakes are made unknowingly by anyone who lacks knowledge, in other words, it’s a misstatement or unintentional deviation from the truth.",
    nextAnswer:
      "Therefore, Tawuniya always recommend reading the Medical Insurance Guidelines and visiting Tawuniya social media accounts for more information about the ideal use of your Medical Card.",
  },
];

export const servicesOffered = [
  {
    content: "servicesOffered.periodic_insptn",
    cardIcon: periodicInspection,
    type: "health",
    url: "/dashboard/periodic-inspection",
  },
  {
    content: "servicesOffered.road_asst",
    cardIcon: roadAssist,
    type: "motor",
    url: "/dashboard/road-assistance",
  },
  {
    content: "servicesOffered.car_acc",
    cardIcon: carAccident,
    type: "motor",
    url: "/individuals/car-accident",
  },
  {
    content: "servicesOffered.refil_medi",
    cardIcon: refilMedi,
    type: "health",
  },
  {
    content: "servicesOffered.med_rem",
    cardIcon: medReim,
    type: "health",
  },
  {
    content: "servicesOffered.telemedi",
    cardIcon: teleMedi,
    type: "health",
    url: "/dashboard/service/request-telemedicine",
  },
  {
    content: "servicesOffered.elg_ltr",
    cardIcon: eligibilityLetter,
    type: "health",
    url: "/dashboard/service/eligibility-letter",
  },
  // {
  //   content: "servicesOffered.prgncy_prog",
  //   cardIcon: pregnancyProg,
  //   type: "health",
  // },
  // {
  //   content: "servicesOffered.prgncy_prog",
  //   cardIcon: pregnancyProg,
  //   type: "health",
  // },
  {
    content: "servicesOffered.chrnic_dises_mngt",
    cardIcon: chrnicDises,
    type: "health",
  },
  {
    content: "servicesOffered.home_child_vacc",
    cardIcon: homeChildVacc,
    type: "health",
  },
  {
    content: "servicesOffered.ass_amrca",
    cardIcon: asstAmerica,
    type: "health",
    url: "https://beta.tawuniya.com.sa/dh/assistamerica",
  },
  // {
  //   content: "servicesOffered.flgt_delay_ass",
  //   cardIcon: flightDelayBlue,
  //   type: "prptyncasualty",
  //   url: "/individuals/flight-delay-assistance",
  // },
];

export const staticSearchData = [

  {
    keywords: " COVID-19 ,covide19,covid 19, covid, covid insurance",
    hits: 0,
    lang: "EN",
    description: "COVID-19",
    webUrl: "https://www.tawuniya.com/individuals/products/property-casualty/international-travel",
    webUrl: "https://www.tawuniya.com/individuals/products/property-casualty/international-travel",
    mobileScreenName: "ALLPRODUCTS",
    category: "COVID-19",
    action: "Buy A policy",
    businessLine: "P&C",
  },
  {
    keywords: "كوفيد 19,كوفيد , تأمين كوفيد, تأمين كورونا ,كورونا",
    hits: 0,
    lang: "AR",
    description: "كوفيد-19",
    webUrl: "https://www.tawuniya.com/individuals/products/property-casualty/international-travel?lang=ar",
    webUrl: "https://www.tawuniya.com/individuals/products/property-casualty/international-travel?lang=ar",
    mobileScreenName: "ALLPRODUCTS",
    category: " تأمين كوفيد",
    action: "اشتر وثيقتك الآن",
    businessLine: "الممتلكات والحوادث",
  },
  {
    keywords: "Home, Home insurance, House, House insurance, Property insurance, home  ",
    hits: 0,
    lang: "EN",
    description: "HomeInsurance",
    webUrl: "https://www.tawuniya.com/individuals/products/property-casualty/home-insurance",
    webUrl: "https://www.tawuniya.com/individuals/products/property-casualty/home-insurance",
    mobileScreenName: "ALLPRODUCTS",
    category: " Home insurance ",
    action: "insure your property",
    businessLine: "P&C",
  },
  {
    keywords:
      "الممتلكات، ضد سرقة البيوت,تأمين المنازل,تأمين العقار,حريق المنزل,تأمين المساكن , تأمين البيوت, المساكن, تأمين بيت",
    hits: 0,
    lang: "AR",
    description: "HomeInsurance",
    webUrl: "https://www.tawuniya.com/individuals/products/property-casualty/home-insurance?lang=ar",
    webUrl: "https://www.tawuniya.com/individuals/products/property-casualty/home-insurance?lang=ar",
    mobileScreenName: "ALLPRODUCTS",
    category: "تأمين المنازل",
    action: "أمن على ممتلكاتك",
    businessLine: "الممتلكات والحوادث",
  },
  {
    keywords:
      "medical malpractice, medical insurance, med mal, MMP, malpractice,Medical malpractice insurance, malpractice, malpractice insurance ",
    hits: 0,
    lang: "EN",
    description: "Medical malpractice insurance",
    webUrl: "https://www.tawuniya.com/individuals/products/property-casualty/medical-malpractice",
    webUrl: "https://www.tawuniya.com/individuals/products/property-casualty/medical-malpractice",
    mobileScreenName: "ALLPRODUCTS",
    category: "Medical malpractice insurance",
    action: "Buy A Policy",
    businessLine: "P&C",
  },
  {
    keywords:
      "تأمين الممارسين الطبي، تأمين ممارسي المهن ، تأمين الأخطاء الطبية، تأمين المهن الطبية، تأمين سوء الممارسة،ضد الأخطاء المهنية، ضد الأخطاء الطبية، ضد الأخطاء، أخطاء المهن، تأمين المهن،تأمين المهن الطبية،تأمين الأخطاء الطبية،",
    hits: 0,
    lang: "AR",
    description: "تأمين أخطاء المهن الطبية",
    webUrl: "https://www.tawuniya.com/individuals/products/property-casualty/medical-malpractice?lang=ar",
    webUrl: "https://www.tawuniya.com/individuals/products/property-casualty/medical-malpractice?lang=ar",
    mobileScreenName: "ALLPRODUCTS",
    category: "تأمين أخطاء ممارسة المهن الطبية",
    action: "اشتر وثيقتك",
    businessLine: "الممتلكات والحوادث",
  },
  {
    keywords:
      "Travel insurance, travel, flight delay insurance, schengen insurance, schengen insurance, world wide insurance, worldwide insurance, baggage loss insurance, flight cancelation, baggage loss",
    hits: 0,
    lang: "EN",
    description: "Travel insurance",
    webUrl: "https://www.tawuniya.com/individuals/products/property-casualty/international-travel",
    webUrl: "https://www.tawuniya.com/individuals/products/property-casualty/international-travel",
    mobileScreenName: "ALLPRODUCTS",
    category: "Travel insurance",
    action: "Buy A Policy, For A Safe Travel ",
    businessLine: "P&C",
  },
  {
    keywords:
      "، الشنجن، تأمين الشنجن، تأمين السفر، سفر، السفر،تأمين, تأمين السفر،تأمين إلغاء الرحلة، تأمين فقدان الأمتعة، تأمين تأخر الحقائب، تأخر الرحلة، تأخير الرحلة",
    hits: 0,
    lang: "AR",
    description: "تأمين السفر",
    webUrl: "https://www.tawuniya.com/individuals/products/property-casualty/international-travel?lang=ar",
    webUrl: "https://www.tawuniya.com/individuals/products/property-casualty/international-travel?lang=ar",
    mobileScreenName: "ALLPRODUCTS",
    category: "تأمين السفر",
    action: "اشتر وثيقتك, للسفر بأمان",
    businessLine: "الممتلكات والحوادث",
  },
  {
    keywords:
      "car insurance,motor insurance, vehicle insurance, car, motor, vehicle, comprehensive insurance, third party insurance, sanad, Third Partyplus, mbi, mechanical break down, gcc extension, gcc",
    hits: 0,
    lang: "EN",
    description: "MotorInsurance",
    webUrl: "https://www.tawuniya.com/individuals/products/motor",
    webUrl: "https://www.tawuniya.com/individuals/products/motor",
    mobileScreenName: "ALLPRODUCTS",
    category: "Motor Insurance ",
    action: "Insure Your Motor",
    businessLine: "Motor",
  },
  {
    keywords:
      "عروض تأمين المركبات،سعر تأمين المركبات,مركبات، سيارات,مركبة,سيارة,تأمين سيارات,تأمين مركبات, تأمين سيارات, تأمين مركبات,  أسعار تأمين المركبات،تأمين شامل، تأمين ضد الغير، تأمين سند، تأمين سند بلس، سند، سند بلس، شامل،ضد الغير،اصدار تأمين مركبات",
    hits: 0,
    lang: "AR",
    description: "تأمين مركبات",
    webUrl: "https://www.tawuniya.com/individuals/products/motor?lang=ar",
    webUrl: "https://www.tawuniya.com/individuals/products/motor?lang=ar",
    mobileScreenName: "ALLPRODUCTS",
    category: " تأمين مركبات ",
    action: "أمن على مركبتك",
    businessLine: "مركبة",
  },
  {
    keywords:
      "Drive, Driver Behavior, weekly rewards, Tawuniya drive, Drive safe, Tawuniya drive points  ",
    hits: 0,
    lang: "EN",
    description: "Drive",
    webUrl: "https://www.tawuniya.com/individuals/loyalty-program/drive",
    webUrl: "https://www.tawuniya.com/individuals/loyalty-program/drive",
    mobileScreenName: "ALLPRODUCTS",
    category: "Drive",
    action: "Activate Drive, and get rewarded",
    businessLine: "Motor",
  },
  {
    keywords:
      "درايف, سلوك السائق, مكافآت أسبوعية, تعاونية درايف, تطبيق درايف, التعاونية درايف, نقاط التعاونية درايف, ميزات السلامة",
    hits: 0,
    lang: "AR",
    description: "درايف",
    webUrl: "https://www.tawuniya.com/individuals/loyalty-program/drive?lang=ar",
    webUrl: "https://www.tawuniya.com/individuals/loyalty-program/drive?lang=ar",
    mobileScreenName: "ALLPRODUCTS",
    category: " درايف ",
    action: "فعل درايف، واحصل على مكافآت",
    businessLine: "مركبة",
  },
  {
    keywords:
      "visit insurance,visitors insurance, visit visa, visa extension, visit visa extension, visa, visitors, family visit, family visa, visit, extension, extension insurance",
    hits: 0,
    lang: "EN",
    description: "Visit Visa Insurance",
    webUrl: "https://www.tawuniya.com/individuals/products/health/visit-visa-insurance",
    webUrl: "https://www.tawuniya.com/individuals/products/health/visit-visa-insurance",
    mobileScreenName: "ALLPRODUCTS",
    category: "Visit Visa Insurance",
    action: "Buy A Policy",
    businessLine: "Medical",
  },
  {
    keywords:
      "تأمين التأشيرة، تأشيرة تمديد الزيارة، تمديد التأشيرة،تأمين زيارة،تأشيرة الزيارة،تأمين تأشيرة الزيارة، زيارة عائلية، فيزا، زيارة",
    hits: 0,
    lang: "AR",
    description: "تأمين تأشيرة الزيارة",
    webUrl: "https://www.tawuniya.com/individuals/products/health/visit-visa-insurance?lang=ar",
    webUrl: "https://www.tawuniya.com/individuals/products/health/visit-visa-insurance?lang=ar",
    mobileScreenName: "ALLPRODUCTS",
    category: "تأمين تأشيرة الزيارة",
    action: "اشتر وثيقتك",
    businessLine: "طبي",
  },
  {
    keywords:
      "health, medical, health sme,medical sme, medical 360, health insurance, medical insurance, health sme insurance, medical sme insurance, employees insurance",
    hits: 0,
    lang: "EN",
    description: "Medical SME Insurance",
    webUrl: "https://www.tawuniya.com/corporate/products/health/360-health-insurance",
    webUrl: "https://www.tawuniya.com/corporate/products/health/360-health-insurance",
    mobileScreenName: "ALLPRODUCTS",
    category: "Medical SME Insurance",
    action: "Insure Your Business",
    businessLine: "Medical",
  },
  {
    keywords:
      "تأمين المنشآت الطبي،  الطبي، تأمين صحي، تأمين طبي، تأمين طبي،تأمين صحي، تأمين الموظفين، صحي، طبي،تأمين المنشآت، تأمين الشركات الطبي",
    hits: 0,
    lang: "AR",
    description: "تأمين المنشآت الطبي",
    webUrl: "https://www.tawuniya.com/corporate/products/health/360-health-insurance?lang=ar",
    webUrl: "https://www.tawuniya.com/corporate/products/health/360-health-insurance?lang=ar",
    mobileScreenName: "ALLPRODUCTS",
    category: "تأمين المنشآت الطبي",
    action: "أمن أعمالك",
    businessLine: "طبي",
  },
  {
    keywords:
      "My family, healthcare, family Insurance, policy coverage, My family program, condition My family program",
    hits: 0,
    lang: "EN",
    description: "My family Health Insurance",
    webUrl: "https://www.tawuniya.com/individuals/products/health/my-family-insurance",
    webUrl: "https://www.tawuniya.com/individuals/products/health/my-family-insurance",
    mobileScreenName: "ALLPRODUCTS",
    category: "My family Health Insurance",
    action: "Buy Your Policy Now",
    businessLine: "Medical",
  },
  {
    keywords:
      "عائلتي، رعاية صحية، تأمين عائلتي، برنامج عائلتي، عائلتي للتأمين الطبي، تغطيات تأمين عائلتي، شروط برنامج عائلتي",
    hits: 0,
    lang: "AR",
    description: "عائلتي",
    webUrl: "https://www.tawuniya.com/individuals/products/health/my-family-insurance?lang=ar",
    webUrl: "https://www.tawuniya.com/individuals/products/health/my-family-insurance?lang=ar",
    mobileScreenName: "ALLPRODUCTS",
    category: "عائلتي",
    action: "اشتر وثيقتك الآن",
    businessLine: "طبي",
  },
  {
    keywords:
      "Hajj Insurance, Hajj And Umrah, Hajj medical, Hajj health insurance, Hajj for Foreighs",
    hits: 0,
    lang: "EN",
    description: "Hajj Insurance",
    webUrl: "https://www.tawuniya.com/individuals/products/health/hajj-insurance",
    webUrl: "https://www.tawuniya.com/individuals/products/health/hajj-insurance",
    mobileScreenName: "ALLPRODUCTS",
    category: "Hajj Insurance",
    action: "Buy A Policy",
    businessLine: "Medical",
  },
  {
    keywords:
      "تأمين الحج، لحجاج الخارج، برنامج التأمين للحج ،موسم الحج ، مزايا تأمين الحج،تأمين حجاج  ",
    hits: 0,
    lang: "AR",
    description: "تأمين الحج ",
    webUrl: "https://www.tawuniya.com/individuals/products/health/hajj-insurance?lang=ar",
    webUrl: "https://www.tawuniya.com/individuals/products/health/hajj-insurance?lang=ar",
    mobileScreenName: "ALLPRODUCTS",
    category: "تأمين الحج",
    action: "اشتر وثيقتك",
    businessLine: "طبي",
  },
  {
    keywords: "Umrah Insurance، Umrah coverage,Umrah insurance program,Forign Pilgrims ",
    hits: 0,
    lang: "EN",
    description: "Umrah Insurance",
    webUrl: "https://www.tawuniya.com/individuals/products/health/umrah-insurance",
    webUrl: "https://www.tawuniya.com/individuals/products/health/umrah-insurance",
    mobileScreenName: "ALLPRODUCTS",
    category: "Umrah Insurance",
    action: "Buy A policy",
    businessLine: "Medical",
  },
  {
    keywords:
      "، معتمري الخارج، تغطية تأمين العمرة، تأمين المعتمرين، تأمين العمرة،تغطيات العمرة، برنامج تأمين العمرة ",
    hits: 0,
    lang: "AR",
    description: "تأمين العمرة",
    webUrl: "https://www.tawuniya.com/individuals/products/health/umrah-insurance?lang=ar",
    webUrl: "https://www.tawuniya.com/individuals/products/health/umrah-insurance?lang=ar",
    mobileScreenName: "ALLPRODUCTS",
    category: "تأمين العمرة",
    action: " اشتر وثيقتك",
    businessLine: "طبي",
  },
  {
    keywords:
      "Tawuniya Vitality,Vitality group,healthy lifestyle, vitality benefits, vitality partners,vitality Rewards ",
    hits: 0,
    lang: "EN",
    description: "Tawuniya Vitality",
    webUrl: "https://www.tawuniya.com/individuals/loyalty-program/vitality",
    webUrl: "https://www.tawuniya.com/individuals/loyalty-program/vitality",
    mobileScreenName: "Tawuniya Vitality",
    category: "Tawuniya Vitality",
    action: "Do More, Get More",
    businessLine: "Medical",
  },
  {
    keywords:
      "فيتالتي،التعاونية فيتالتي، أسلوب حياة صحي،شركاء فيتالتي،مجموعة فيتالتي، فيتاليتي،برنامج فيتالتي، مزايا فيتالتي،",
    hits: 0,
    lang: "AR",
    description: " التعاونية فيتاليتي",
    webUrl: "https://www.tawuniya.com/individuals/loyalty-program/vitality?lang=ar",
    webUrl: "https://www.tawuniya.com/individuals/loyalty-program/vitality?lang=ar",
    mobileScreenName: "Tawuniya Vitality",
    category: "التعاونية فيتاليتي",
    action: "تحرك أكثر،تكسب أكثر",
    businessLine: "طبي",
  },
  {
    keywords: "Contact us,customer service,call centre,help,assist,live chat",
    hits: 0,
    lang: "EN",
    description: "Contact Us",
    webUrl: "https://www.tawuniya.com/contactus",
    webUrl: "https://www.tawuniya.com/contactus",
    mobileScreenName: "Contact Us",
    category: "Contact Us",
    action: "Send Your Request",
    businessLine: "Global",
  },
  {
    keywords: "تواصل معنا،خدمة عملاء،مركز اتصال،محادثة مباشرة,مساعدة",
    hits: 0,
    lang: "AR",
    description: " التعاونية فيتاليتي",
    webUrl: "https://www.tawuniya.com/contactus?lang=ar",
    webUrl: "https://www.tawuniya.com/contactus?lang=ar",
    mobileScreenName: "Contact Us",
    category: "التعاونية فيتاليتي",
    action: "أرسل طلبك",
    businessLine: "عام",
  },
  {
    keywords:
      "report fraud, fraud, medical fraud, motor fraud, p&c fraud, insurance fraud, Act of fraud",
    hits: 0,
    lang: "EN",
    description: "Report frouds",
    webUrl: "https://www.tawuniya.com/individuals/customer-service/report-fraud",
    webUrl: "https://www.tawuniya.com/individuals/customer-service/report-fraud",
    mobileScreenName: "Report fraud",
    category: "Report fraud",
    action: "Report A Fraud",
    businessLine: "Global",
  },
  {
    keywords:
      "إبلاغ, احتيال, احتيال الطبي, احتيال المركبات, احتيال الممتلكات،تبليغ احتيال،ابلاغ عن مخالفات، احتيال تأمين،فعل احتيالي،احتيال تأمينات ",
    hits: 0,
    lang: "AR",
    description: " إبلاغ عن احتيال",
    webUrl: "https://www.tawuniya.com/individuals/customer-service/report-fraud?lang=ar",
    webUrl: "https://www.tawuniya.com/individuals/customer-service/report-fraud?lang=ar",
    mobileScreenName: "Report fraud",
    category: " إبلاغ عن احتيال",
    action: " إبلاغ عن احتيال",
    businessLine: "عام",
  },
  {
    keywords: "Investor relation, investors,Tawuniya Relations, ",
    hits: 0,
    lang: "EN",
    description: "Investor relation",
    webUrl: "https://www.tawuniya.com/investor",
    webUrl: "https://www.tawuniya.com/investor",
    mobileScreenName: "Investor relation",
    category: "Investor relation",
    action: "Contact Us",
    businessLine: "Global",
  },
  {
    keywords: "علاقات المساهمين، علاقات المستثمرين، علاقات التعاونية، أنواع أعمال التأمين ",
    hits: 0,
    lang: "AR",
    Description: "علاقات المساهمين",
    webUrl: "https://www.tawuniya.com/investor?lang=ar",
    webUrl: "https://www.tawuniya.com/investor?lang=ar",
    mobileScreenName: "Investor relation",
    category: " علاقات المساهمين",
    action: "تواصل معنا",
    businessLine: "عام",
  },
  {
    keywords:
      "Careers, Career, jobs,about tawuniya,life at tawuniya,join tawuniya,current vacancies, ",
    hits: 0,
    lang: "EN",
    description: "Careers",
    webUrl: "https://www.tawuniya.com/careers",
    webUrl: "https://www.tawuniya.com/careers",
    mobileScreenName: "Careers",
    category: "Careers",
    action: "Join Us",
    businessLine: "Global",
  },
  {
    keywords:
      "الوظائف, التوظيف،شواغر حالية،الحياة في التعاونية،عن التعاونية،التسجيل للوظائف،انضم الينا ",
    hits: 0,
    lang: "AR",
    Description: "الوظائف",
    webUrl: "https://www.tawuniya.com/careers?lang=ar",
    webUrl: "https://www.tawuniya.com/careers?lang=ar",
    mobileScreenName: "Careers",
    category: " الوظائف",
    action: "انضم الينا",
    businessLine: "عام",
  },
  {
    keywords: "taj.taj program,tawuniya taj,suit your needs,diverse services",
    hits: 0,
    lang: "EN",
    description: "Taj program",
    webUrl: "https://www.tawuniya.com/",
    mobileScreenName: "ALLPRODUCTS",
    category: "Taj program",
    action: "Request Service",
    businessLine: "Medical",
  },
  {
    keywords: "تاج، برنامج تاج،تاج التعاونية,رعاية صحية,تغطية احتياجاتك,خدمات شاملة،",
    hits: 0,
    lang: "AR",
    Description: "برنامج تاج",
    webUrl: "https://www.tawuniya.com/",
    mobileScreenName: "Taj program",
    category: "برنامج تاج",
    action: "اطلب الخدمة",
    businessLine: "طبي",
  },
  {
    keywords:
      "Home Child Vaccine,vaccinate by age,vaccine schedule,vaccinate your children, your child health,your child safety, child vaccinate service, vaccinate your child,",
    hits: 0,
    lang: "EN",
    description: "Home Child Vaccine",
    webUrl: "https://www.tawuniya.com/individuals/customer-service/home-child-vaccination",
    webUrl: "https://www.tawuniya.com/individuals/customer-service/home-child-vaccination",
    mobileScreenName: "Home Child Vaccine",
    category: "Home Child Vaccine",
    action: "Request Service",
    businessLine: "Medical",
  },
  {
    keywords:
      "التطعيمات المنزلية للأطفال،تطعيم أولادك،تطعيم طفلك، صحة أطفالك،جدول التطعيمات،طفلك في أمان،خدمة التطعيمات للأطفال،",
    hits: 0,
    lang: "AR",
    description: "التطعيمات المنزلية للأطفال",
    webUrl: "https://www.tawuniya.com/individuals/customer-service/home-child-vaccination?lang=ar",
    webUrl: "https://www.tawuniya.com/individuals/customer-service/home-child-vaccination?lang=ar",
    mobileScreenName: "Home Child Vaccine",
    category: "التطعيمات المنزلية للأطفال",
    action: "اطلب الخدمة",
    businessLine: "طبي",
  },
  {
    keywords:
      "Chronic disease management,medical consultation,refill medications,chronic disease,laboratory services,",
    hits: 0,
    lang: "EN",
    description: "Chronic disease management",
    webUrl: "https://www.tawuniya.com/individuals/customer-service/chronic-disease-management",
    webUrl: "https://www.tawuniya.com/individuals/customer-service/chronic-disease-management",
    mobileScreenName: "Chronic disease management",
    category: "Chronic disease management",
    action: "Request Service",
    businessLine: "Medical",
  },
  {
    keywords:
      "، رعاية صحية، خدمات المختبر،صحتك،إعادة صرف الدواء، استشارة طبية،مرض مزمن،تحاليل مخبرية،إدارة الأمراض المزمنة",
    hits: 0,
    lang: "AR",
    description: "إدارة الأمراض المزمنة",
    webUrl: "https://www.tawuniya.com/individuals/customer-service/chronic-disease-management?lang=ar",
    webUrl: "https://www.tawuniya.com/individuals/customer-service/chronic-disease-management?lang=ar",
    mobileScreenName: "Chronic disease management",
    category: "إدارة الأمراض المزمنة",
    action: "اطلب الخدمة",
    businessLine: "طبي",
  },
  // {
  //   keywords:
  //     "Pregnancy follow-up program,pregnancy journey, pregnancy member,pregnant program,birth,pregnant women ",
  //   hits: 0,
  //   lang: "EN",
  //   description: "Pregnancy follow-up program",
  //   webUrl: "https://www.tawuniya.com/individuals/customer-service/pregnancy-follow-up-program",
  //   webUrl: "https://www.tawuniya.com/individuals/customer-service/pregnancy-follow-up-program",
  //   mobileScreenName: "Pregnancy follow-up program",
  //   category: "Pregnancy follow-up program",
  //   action: "Request Service",
  //   businessLine: "Medical",
  // },
  // {
  //   keywords:
  //     "حوامل،مراحل حملك،حالات الحمل،العضوة الحامل،توعوية للحامل،ولادة،برنامج حتى الولادة،متابعة الحمل التعاونية،برنامج متابعة الحمل",
  //   hits: 0,
  //   lang: "AR",
  //   description: "برنامج متابعة الحمل",
  //   webUrl: "https://www.tawuniya.com/individuals/customer-service/pregnancy-follow-up-program?lang=ar",
  //   webUrl: "https://www.tawuniya.com/individuals/customer-service/pregnancy-follow-up-program?lang=ar",
  //   mobileScreenName: "Pregnancy follow-up program",
  //   category: "برنامج متابعة الحمل",
  //   action: "اطلب الخدمة",
  //   businessLine: "طبي",
  // },
  {
    keywords: "Reﬁll Medication,dawae,refilling medicines,chronic diseases,",
    hits: 0,
    lang: "EN",
    description: "Reﬁll Medication",
    webUrl: "https://www.tawuniya.com/login",
    webUrl: "https://www.tawuniya.com/login",
    mobileScreenName: "Reﬁll Medication",
    category: "Reﬁll Medication",
    action: "Request A Refill",
    businessLine: "Medical",
  },
  {
    keywords: " ،,اصرف دوائك،مرض مزمن،دوائي،مراجعة الطبيب،إعادة صرف أدوية الأمراض المزمنة",
    hits: 0,
    lang: "AR",
    description: "إعادة صرف أدوية الأمراض المزمنة",
    webUrl: "https://www.tawuniya.com/login",
    webUrl: "https://www.tawuniya.com/login",
    mobileScreenName: "Reﬁll Medication",
    category: "إعادة صرف أدوية الأمراض المزمنة",
    action: "اطلب إعادة صرف",
    businessLine: "طبي",
  },
  {
    keywords:
      "Telemedicine,online consultation,consultation service,book an appointment online,accredited doctors ",
    hits: 0,
    lang: "EN",
    description: "Telemedicine",
    webUrl: "https://www.tawuniya.com/",
    mobileScreenName: "ALLPRODUCTS",
    category: "Telemedicine",
    action: "Request A Consultation",
    businessLine: "Medical",
  },
  {
    keywords:
      "استشر طبيبك،استشارة موثوقة، استشارة أونلاين،أطباء معتمدين،استشارة طبية،حجز موعد أونلاين، ",
    hits: 0,
    lang: "AR",
    description: "استشر طبيبك",
    webUrl: "https://www.tawuniya.com/",
    mobileScreenName: "Telemedicine",
    category: "استشر طبيبك",
    action: "اطلب استشارة طبية",
    businessLine: "طبي",
  },
  {
    keywords: "Assist America,Medical emergency, support outside Saudi,medical insurance,assist,",
    hits: 0,
    lang: "EN",
    description: "Assist America",
    webUrl: "https://www.tawuniya.com/",
    mobileScreenName: "ALLPRODUCTS",
    category: "Assist America",
    action: "Request The Service",
    businessLine: "Medical",
  },
  {
    keywords: "، اسست،خدمات خارج المملكة، طارئ طبي، الشريك الدولي،أسست أمريكا",
    hits: 0,
    lang: "AR",
    description: "أسست أمريكا",
    webUrl: "https://www.tawuniya.com/",
    mobileScreenName: "Assist America",
    category: "أسست أمريكا",
    action: "اطلب الخدمة",
    businessLine: "طبي",
  },
  {
    keywords:
      "Medical Reimbursement,reimburse your expenses,apply for reimbursement, tawuniya claims, claim",
    hits: 0,
    lang: "EN",
    description: "Medical Reimbursement",
    webUrl: "https://www.tawuniya.com/",
    mobileScreenName: "Medical Reimbursement",
    category: "Medical Reimbursement",
    action: "Request Service",
    businessLine: "Medical",
  },
  {
    keywords:
      "استعاضة المصروفات الطبية، استرداد مصروفاتك الطبية، استرداد الكتروني تسوية الطلبات بالتعاونية،خدمات التعاونية",
    hits: 0,
    lang: "AR",
    description: "استعاضة المصروفات الطبية",
    webUrl: "https://www.tawuniya.com/",
    mobileScreenName: "Medical Reimbursement",
    category: "استعاضة المصروفات الطبية",
    action: "استعاضة المصروفات الطبية",
    businessLine: "طبي",
  },
  {
    keywords: "Eligibility Letter",
    hits: 0,
    lang: "EN",
    description:
      "Eligibility Letter,immediate eligibility letter,request eligibility letter,link to eligibility letter",
    webUrl: "https://www.tawuniya.com/",
    mobileScreenName: "Eligibility Letter",
    category: "Eligibility Letter",
    action: "Request Service",
    businessLine: "Medical",
  },
  {
    keywords:
      "، الحصول على علاج،خطاب أهلية فوري، تأكيد الأهلية،طلب خطاب أهلية،حصول على خطاب أهلية، رابط خطاب الأهلية،خطاب الأهلية",
    hits: 0,
    lang: "AR",
    description: "خطاب الأهلية",
    webUrl: "https://www.tawuniya.com/",
    mobileScreenName: "Eligibility Letter",
    category: "خطاب الأهلية",
    action: "اطلب خطاب الأهلية",
    businessLine: "طبي",
  },
  {
    keywords:
      "claims, motor claim,individual claims, claim,alshamel motor claim,Third Partymotor claim,report motor claim,tawuniya claim,track your claim,claims tracking,motor claims details,motor claims submission",
    hits: 0,
    lang: "EN",
    description: "Submit Claim",
    webUrl: "https://www.tawuniya.com/product/tp-claims",
    webUrl: "https://www.tawuniya.com/product/tp-claims",
    mobileScreenName: "Submit Claim",
    category: "Submit Claim",
    action: "Submit Your Claims",
    businessLine: "Motor",
  },
  {
    keywords:
      "،مطالبات، تعويضات،تعويض عملاء الشامل،مطالبات تعويض المركبات،الحد الأقصى لتعويض المركبات،قيمة التعويض،مطالبات المركبات،تقديم مطالبة",
    hits: 0,
    lang: "AR",
    Description: "تقديم مطالبة",
    webUrl: "https://www.tawuniya.com/product/tp-claims?lang=ar",
    webUrl: "https://www.tawuniya.com/product/tp-claims?lang=ar",
    mobileScreenName: "Submit Claim",
    category: "تقديم مطالبة",
    action: "قدم مطالباتك",
    businessLine: "مركبة",
  },
  {
    keywords:
      "claims,claim details,motor insurance claims track,Tawuniya claims,claim tracking,online claim,third party claim,track your claim,",
    hits: 0,
    lang: "EN",
    description: "Track Claim",
    webUrl: "https://www.tawuniya.com/product/tp-claims",
    webUrl: "https://www.tawuniya.com/product/tp-claims",
    mobileScreenName: "Track Claim",
    category: "Track Claim",
    action: "Track Your Claims",
    businessLine: "Motor",
  },
  {
    keywords:
      "متابعة المطالبات،تتبع مطالبة،مطالبة غير مكتملة،نموذج مطالبة،تسليم مطالبة،مطالبة،مطالبة طرف ثالث،فاصيل المطالبة،",
    hits: 0,
    lang: "AR",
    description: "متابعة المطالبات",
    webUrl: "https://www.tawuniya.com/product/tp-claims?lang=ar",
    webUrl: "https://www.tawuniya.com/product/tp-claims?lang=ar",
    mobileScreenName: "Track Claim",
    category: "متابعة المطالبات",
    action: "تابع مطالباتك",
    businessLine: "مركبة",
  },
  {
    keywords:
      "ithra,ithraa partners,ithraa program,ithraa,ithra discounts,ithra beneficiary,ithra benefits,ithra Tawuniya",
    hits: 0,
    lang: "EN",
    description: "Ithra",
    webUrl: "https://www.tawuniya.com/individuals/loyalty-program/ithra",
    webUrl: "https://www.tawuniya.com/individuals/loyalty-program/ithra",
    mobileScreenName: "Ithra",
    category: "Ithra",
    action: "Start Here",
    businessLine: "ALL",
  },
  {
    keywords:
      "إثراء،عروض إثراء،شركاء إثراء،مستفيدي إثراء،خصومات إثراء،برنامج إثراء،مدة برنامج إثراء،تثري حياتك،تثري،مزايا إثراء،",
    hits: 0,
    lang: "AR",
    description: "إثراء",
    webUrl: "https://www.tawuniya.com/individuals/loyalty-program/ithra?lang=ar",
    webUrl: "https://www.tawuniya.com/individuals/loyalty-program/ithra?lang=ar",
    mobileScreenName: "IthraPrime",
    category: "إثراء",
    action: "إثراء",
    businessLine: "الجميع",
  },
  {
    keywords: "ithra prime",
    hits: 0,
    lang: "EN",
    description: "Ithra Prime",
    webUrl: "https://www.tawuniya.com/individuals/loyalty-program/ithra-prime",
    webUrl: "https://www.tawuniya.com/individuals/loyalty-program/ithra-prime",
    mobileScreenName: "IthraPrime",
    category: "Ithra Ithra Prime",
    action: "Get Started",
    businessLine: "motor",
  },
  {
    keywords: "إثراء برايم",
    hits: 0,
    lang: "AR",
    description: "إثراء برايم",
    webUrl: "https://www.tawuniya.com/individuals/loyalty-program/ithra-prime?lang=ar",
    webUrl: "https://www.tawuniya.com/individuals/loyalty-program/ithra-prime?lang=ar",
    mobileScreenName: "IthraPrime",
    category: "إثراء برايم",
    action: "إبدأ",
    businessLine: "مركبة",
  },
  {
    keywords:
      "Road assistance, battery charge,change battery emergency,fuel supply,Tyre change, tawuniya service",
    hits: 0,
    lang: "EN",
    description: "Roadside assistance",
    webUrl: "https://www.tawuniya.com/individuals/customer-service/road-assistance",
    webUrl: "https://www.tawuniya.com/individuals/customer-service/road-assistance",
    mobileScreenName: "Roadside assistance",
    category: "Roadside assistance",
    action: "Submit a request",
    businessLine: "Motor",
  },
  {
    keywords:
      "،مساعدة الطريق،تغير البطارية،مساعدة طارئة، خدمات التعاونية، تغير إطار المركبة،خدمة بنزين",
    hits: 0,
    lang: "AR",
    Description: "مساعدة الطريق",
    webUrl: "https://www.tawuniya.com/individuals/customer-service/road-assistance?lang=ar",
    webUrl: "https://www.tawuniya.com/individuals/customer-service/road-assistance?lang=ar",
    mobileScreenName: "Roadside assistance",
    category: "مساعدة الطريق",
    action: "اطلب الخدمة",
    businessLine: "مركبة",
  },
  {
    keywords:
      "Motor vehicle periodic inspection, free charge of transferring, inspecting your vehicle,vehicles services, tawuniya MVPI,inspection report",
    hits: 0,
    lang: "EN",
    description: "Motor vehicle periodic inspection",
    webUrl: "https://www.tawuniya.com/individuals/products/motor",
    webUrl: "https://www.tawuniya.com/individuals/products/motor",
    mobileScreenName: "MVPI",
    category: "Motor vehicle periodic inspection",
    action: "submit a request",
    businessLine: "Motor",
  },
  {
    keywords:
      " خدمة الفحص الدوري، فحص المركبات، خدمة فحص مركبتك،خدمات التعاونية،فحص المركبة،تقرير الفحص ",
    hits: 0,
    lang: "AR",
    Description: "خدمة الفحص الدوري",
    webUrl: "https://www.tawuniya.com/individuals/products/motor?lang=ar",
    webUrl: "https://www.tawuniya.com/individuals/products/motor?lang=ar",
    mobileScreenName: "MVPI",
    category: "خدمة الفحص الدوري",
    action: "اطلب الخدمة",
    businessLine: "مركبة",
  },
  {
    keywords:
      "Balsam, tawuniya balsam,balsam cooperative health insurance,balsam gold,balsam direct,balsam benefits,balsam exclusive, balsam program,",
    hits: 0,
    lang: "EN",
    description: "Balsam",
    webUrl: "https://www.tawuniya.com/corporate/products/health/balsam",
    webUrl: "https://www.tawuniya.com/corporate/products/health/balsam",
    mobileScreenName: "ALLPRODUCTS",
    category: "Balsam",
    action: "Insure Your Business",
    businessLine: "Medical",
  },
  {
    keywords:
      ",بلسم،بلسم مباشر، بلسم الذهبي،بلسم للتأمين الطبي،برنامج بلسم،فئات برنامج بلسم،تغطيات بلسم،مزايا بلسم، شروط برنامج بلسم،خدمات بلسم،امتيازات بلسم",
    hits: 0,
    lang: "AR",
    description: "بلسم",
    webUrl: "https://www.tawuniya.com/corporate/products/health/balsam?lang=ar",
    webUrl: "https://www.tawuniya.com/corporate/products/health/balsam?lang=ar",
    mobileScreenName: "ALLPRODUCTS",
    category: "بلسم",
    action: "أمن أعمالك",
    businessLine: "طبي",
  },
  {
    keywords:
      "takaful, group life insurance, takaful insurance,Takaful insurance,benefit of takaful insurance,takaful insurance",
    hits: 0,
    lang: "EN",
    description: "Takaful",
    webUrl: "https://www.tawuniya.com/corporate/products/health/takaful",
    webUrl: "https://www.tawuniya.com/corporate/products/health/takaful",
    mobileScreenName: "ALLPRODUCTS",
    category: "Takaful",
    action: "Buy Apolicy",
    businessLine: "Medical",
  },
  {
    keywords: "تكافل,منافع برنامج تكافل،تغطية برنامج تكافل،التكافل الجماعي،منافع إضافية",
    hits: 0,
    lang: "AR",
    description: "تكافل",
    webUrl: "https://www.tawuniya.com/corporate/products/health/takaful?lang=ar",
    webUrl: "https://www.tawuniya.com/corporate/products/health/takaful?lang=ar",
    mobileScreenName: "ALLPRODUCTS",
    category: "تكافل",
    action: "اشتر وثيقتك",
    businessLine: "طبي",
  },

  {
    keywords:
      "motor,individual Motor, ,alshamel motor ,Third Party motor,tracking,motordetails,motor,Third Party plus Insurance",
    hits: 0,
    lang: "EN",
    description: "Alshamel",
    webUrl: "https://www.tawuniya.com/individuals/products/motor",
    webUrl: "https://www.tawuniya.com/individuals/products/motor",
    mobileScreenName: "Alshamel",
    category: "Alshamel",
    action: "Go Alshamel",
    businessLine: "Motor",
  },
  {
    keywords: "المركبات , الشامل , المركبات , مركبه ,موتور ",
    hits: 0,
    lang: "AR",
    Description: "تأمين الشامل",
    webUrl: "https://www.tawuniya.com/individuals/product/motor?lang=ar",
    webUrl: "https://www.tawuniya.com/individuals/product/motor?lang=ar",
    mobileScreenName: "تأمين المركبات",
    category: "المركبات",
    action: "تأمين المركبات",
    businessLine: "مركبة",
  },
  {
    keywords:
      "Mechanical Breakdown,breakdown,break,faults",
    hits: 0,
    lang: "EN",
    description: "Mechanical Breakdown",
    webUrl: "https://www.tawuniya.com/individuals/products/motor/mechanical-breakdown",
    webUrl: "https://www.tawuniya.com/individuals/products/motor/mechanical-breakdown",
    mobileScreenName: "Mechanical Breakdown",
    category: "Mechanical Breakdown",
    action: "Mechanical Breakdown",
    businessLine: "Motor",
  },
  {
    keywords: "المركبات , الشامل , المركبات , مركبه ,موتور ",
    hits: 0,
    lang: "AR",
    Description: "الأعطال الميكانيكية",
    webUrl: "https://www.tawuniya.com/individuals/product/motor/mechanical-breakdown?lang=ar",
    webUrl: "https://www.tawuniya.com/individuals/product/motor/mechanical-breakdown?lang=ar",
    mobileScreenName: "الأعطال الميكانيكية",
    category: "الأعطال الميكانيكية",
    action: "الأعطال الميكانيكية",
    businessLine: "مركبة",
  },
  {
    keywords:
      "Tawuniya drive,drive",
    hits: 0,
    lang: "EN",
    description: "Tawuniya drive",
    webUrl: "https://www.tawuniya.com/individuals/products/motor/drive",
    webUrl: "https://www.tawuniya.com/individuals/products/motor/drive",
    mobileScreenName: "Tawuniya drive",
    category: "Tawuniya drive",
    action: "Tawuniya drive",
    businessLine: "Motor",
  },
  {
    keywords: "درايف ,ميزات درايف, تحميل التعاونية درايف",
    hits: 0,
    lang: "AR",
    Description: "التعاونية درايف",
    webUrl: "https://www.tawuniya.com/individuals/product/motor/drive?lang=ar",
    webUrl: "https://www.tawuniya.com/individuals/product/motor/drive?lang=ar",
    mobileScreenName: "التعاونية درايف",
    category: "موتور",
    action: "التعاونية درايف",
    businessLine: "مركبة",
  },
  {
    keywords:
      "nsurance against theft, fire and additional risks,home,home insurance",
    hits: 0,
    lang: "EN",
    description: "Home insurance",
    webUrl: "https://www.tawuniya.com/individuals/products/property-casualty/home-insurance",
    webUrl: "https://www.tawuniya.com/individuals/products/property-casualty/home-insurance",
    mobileScreenName: "Home insurance",
    category: "Home insurance",
    action: "Home insurance",
    businessLine: "General insurance",
  },
  {
    keywords: "برنامج التأمين على المساكن , تأمين المساكن, تأمين المنزل , المنازل ",
    hits: 0,
    lang: "AR",
    Description: "تأمين المساكن",
    webUrl: "https://www.tawuniya.com/product/property_casualty/homeinsurance?lang=ar",
    webUrl: "https://www.tawuniya.com/product/property_casualty/homeinsurance?lang=ar",
    mobileScreenName: "تأمين المساكن ",
    category: "التأمين العام",
    action: "تأمين المساكن",
    businessLine: "التأمين العام",
  },
  {
    keywords:
      "Travel insurance,travel",
    hits: 0,
    lang: "EN",
    description: "Travel insurance",
    webUrl: "https://www.tawuniya.com/individuals/products/property-casualty/international-travel",
    webUrl: "https://www.tawuniya.com/individuals/products/property-casualty/international-travel",
    mobileScreenName: "Travel insurance",
    category: "Travel insurance",
    action: "Travel insurance",
    businessLine: "General insurance",
  },
  {
    keywords: "تأمين السفر , ترافيل , الرحلات ,تأمين الرحلات ,الرحله",
    hits: 0,
    lang: "AR",
    Description: "تأمين السفر",
    webUrl: "https://www.tawuniya.com/product/property_casualty/internationaltravel?lang=ar",
    webUrl: "https://www.tawuniya.com/product/property_casualty/internationaltravel?lang=ar",
    mobileScreenName: "تأمين السفر ",
    category: "التأمين العام",
    action: "تأمين السفر",
    businessLine: "التأمين العام",
  },
  {
    keywords:
      "Medical malpractice insurance,medmal",
    hits: 0,
    lang: "EN",
    description: "Medical malpractice insurance",
    webUrl: "https://www.tawuniya.com/individuals/products/property-casualty/medical-malpractice",
    webUrl: "https://www.tawuniya.com/individuals/products/property-casualty/medical-malpractice",
    mobileScreenName: "Medical malpractice insurance",
    category: "Medical malpractice insurance",
    action: "Medical malpractice insurance",
    businessLine: "General insurance",
  },
  {
    keywords: "تأمين أخطاء ممارسة المهن الطبية",
    hits: 0,
    lang: "AR",
    Description: "تأمين أخطاء ممارسة المهن الطبية",
    webUrl: "https://www.tawuniya.com/product/property_casualty/medicalmalpractice?lang=ar",
    webUrl: "https://www.tawuniya.com/product/property_casualty/medicalmalpractice?lang=ar",
    mobileScreenName: "تأمين أخطاء ممارسة المهن الطبية ",
    category: "تأمين أخطاء ممارسة المهن الطبية",
    action: "أخطاء ممارسة المهن ",
    businessLine: "التأمين العام",
  },
  {
    keywords:
      "Domestic Worker Contract Insurance",
    hits: 0,
    lang: "EN",
    description: "Domestic Worker Contract Insurance,domestic worker",
    webUrl: "https://www.tawuniya.com/individuals/products/domestic-worker-insurance",
    webUrl: "https://www.tawuniya.com/individuals/products/domestic-worker-insurance",
    mobileScreenName: "Domestic Worker Contract Insurance",
    category: "General insurance",
    action: "Domestic Worker",
    businessLine: "General insurance",
  },
  {
    keywords: "تأمين عقد العمالة المنزلية",
    hits: 0,
    lang: "AR",
    Description: "تأمين عقد العمالة المنزلية",
    webUrl: "https://www.tawuniya.com/product/domestic-worker-insurance?lang=ar",
    webUrl: "https://www.tawuniya.com/product/domestic-worker-insurance?lang=ar",
    mobileScreenName: "تأمين عقد العمالة المنزلية ",
    category: "التأمين العام",
    action: "تأمين عقد العمالة المنزلية ",
    businessLine: "التأمين العام",
  },
  {
    keywords:
      "MY FAMILY - HEALTH INSURANCE,family,my family",
    hits: 0,
    lang: "EN",
    description: "My Family Medical Insurance",
    webUrl: "https://www.tawuniya.com/individuals/products/health/my-family-insurance",
    webUrl: "https://www.tawuniya.com/individuals/products/health/my-family-insurance",
    mobileScreenName: "MY FAMILY - HEALTH INSURANCE",
    category: "Health & Life",
    action: "My Family",
    businessLine: "Health & Life",
  },
  {
    keywords: "عائلتي",
    hits: 0,
    lang: "AR",
    Description: "تأمين العائله",
    webUrl: "https://www.tawuniya.com/product/health/myfamily-insurance?lang=ar",
    webUrl: "https://www.tawuniya.com/product/health/myfamily-insurance?lang=ar",
    mobileScreenName: "تأمين العائلة ",
    category: "تأمين الصحة والحياة",
    action: "تأمين عائلتي ",
    businessLine: "تأمين الصحة والحياة",
  },
  {
    keywords:
      "petromin,petromin express",
    hits: 0,
    lang: "EN",
    description: "Petromin Express",
    webUrl: "https://www.tawuniya.com/individuals/loyalty-program/tawuniya-petromin",
    webUrl: "https://www.tawuniya.com/individuals/loyalty-program/tawuniya-petromin",
    mobileScreenName: "Petromin Express",
    category: "loyalty programs",
    action: "View More",
    businessLine: "Motor",
  },
  {
    keywords: "بترومين اكسبريس ",
    hits: 0,
    lang: "AR",
    Description: "بترومين",
    webUrl: "https://www.tawuniya.com/product/loyalty-program/tawuniya-petromin?lang=ar",
    webUrl: "https://www.tawuniya.com/product/loyalty-program/tawuniya-petromin?lang=ar",
    mobileScreenName: "بترومين اكسبريس ",
    category: "برامج الولاء",
    action: "أعرف المزيد ",
    businessLine: "مركبة",
  },
  {
    keywords: " معلومات السهم ,الأرباح ,المعلومات المالية, قائمة المحللين والتوصيات",
    hits: 0,
    lang: "AR",
    Description: "المعلومات الماليه ",
    webUrl: "https://tawuniya.com/en/investor",
    webUrl: "https://tawuniya.com/en/investor",
    mobileScreenName: "المستثمرين",
    category: "علاقات المستثمرين",
    action: "مشاهدة",
    businessLine: "المستثمرين",
  },
  {
    keywords: "investors,Financial information,Announcements,Fact Sheet",
    hits: 0,
    lang: "EN",
    Description: "Investor overview",
    webUrl: "https://tawuniya.com/en/investor",
    webUrl: "https://tawuniya.com/en/investor",
    mobileScreenName: "Investors",
    category: "investor",
    action: "Go investor",
    businessLine: "investors",
  },
  {
    keywords: " معلومات السهم ,الأرباح ,المعلومات المالية, قائمة المحللين والتوصيات",
    hits: 0,
    lang: "AR",
    Description: "المعلومات الماليه ",
    webUrl: "https://tawuniya.com/en/investor",
    webUrl: "https://tawuniya.com/en/investor",
    mobileScreenName: "المستثمرين",
    category: "علاقات المستثمرين",
    action: "مشاهدة",
    businessLine: "المستثمرين",
  },
  {
    keywords: "investors,Financial information,Announcements,Fact Sheet",
    hits: 0,
    lang: "EN",
    Description: "Investor overview",
    webUrl: "https://tawuniya.com/en/investor",
    webUrl: "https://tawuniya.com/en/investor",
    mobileScreenName: "Investors",
    category: "investor",
    action: "Go investor",
    businessLine: "investors",
  },
  {
    keywords: " لجنة منازعات التأمين",
    hits: 0,
    lang: "AR",
    Description: "SAMA",
    webUrl: "https://www.ia.gov.sa",
    webUrl: "https://www.ia.gov.sa",
    mobileScreenName: "منازعات التأمين",
    category: "منازعات التأمين",
    action: "الذهاب",
    businessLine: "عام",
  },
  {
    keywords: "Committee of Insurance Disputes",
    hits: 0,
    lang: "EN",
    Description: "Committee of Insurance Disputes",
    webUrl: "https://www.ia.gov.sa",
    webUrl: "https://www.ia.gov.sa",
    mobileScreenName: "Committee of Insurance Disputes",
    category: "General",
    action: "GO",
    businessLine: "General insurance",
  },
  {
    keywords: "Boiler and Pressure Vessel,Contractors,Deterioration of Stock,Electronic Equipment,Cyber Risk insurance,Erection,Loss of Profits Following Machinery Breakdown",
    hits: 0,
    lang: "EN",
    Description: "Engineeering Insurance",
    webUrl: "https://www.tawuniya.com/corporate/products/enterprise/engineering-insurance",
    webUrl: "https://www.tawuniya.com/corporate/products/enterprise/engineering-insurance",
    mobileScreenName: "Committee of Insurance Disputes,Machinery Breakdown,Cyber Risk insurance,Moqawiloon",
    category: "Engineering Insurance",
    action: "Go to Page",
    businessLine: "Corporate",
  },
  {
    keywords: "تأمين المراجل وأوعية الضغط,المقاولون,تأمين تجهيزات وآليات المقاولون,تأمين فساد المخزون,تأمين الأجهزة الإلكترونية,,تأمين جميع أخطار التركيبتأمين خسارة الأرباج بعد تعطل الآلات,تأمين عطب الآلات,تأمين مخاطر الجرائم الإلكترونية,مقاولون",
    hits: 0,
    lang: "AR",
    Description: "التأمين الهندسي",
    webUrl: "https://www.tawuniya.com/corporate/products/enterprise/engineering-insurance",
    webUrl: "https://www.tawuniya.com/corporate/products/enterprise/engineering-insurance",
    mobileScreenName: "التأمين الهندسي",
    category: "التأمين الهندسي",
    action: "الذهاب",
    businessLine: "الشركات",
  },
  {
    keywords: "Public Liability,Fidelity Guarantee Insurance Policy,Money Insurance Policy,Theft Insurance Policy,Personel Accident Insurance Policy,Goods in Transit,Workmen's Compensation,Travel International Insurance,Medical Malpractice,Professional Indemnity --Architect’s & Civil Engineers,Professional Indemnity --Insurance Brokers and Agents,Professional Indemnity - Management Consultants,Professional Indemnity -Surveyors and Loss Adjusters,Professional Indemnity - Valuers (Taqeem),Directors & Officers Liability,Products Liability,Office Comprehensive,Travel insurance including Covid-19 cover,Flight Delay Compensation Insurance,Domestic Workers Contract insurance",
    hits: 0,
    lang: "EN",
    Description: "General accident Insurance",
    webUrl: "https://www.tawuniya.com/corporate/products/enterprise/general-accedent-insurance",
    webUrl: "https://www.tawuniya.com/corporate/products/enterprise/general-accedent-insurance",
    mobileScreenName: "Committee of Insurance Disputes,Machinery Breakdown,Cyber Risk insurance,Moqawiloon",
    category: "General accident Insurance",
    action: "Go to Page",
    businessLine: "Corporate",
  },
  {
    keywords: "تأمين المسؤولية تجاه الغير,وثيقة تأمين خيانة الأمانة,وثيقة تأمين الأموال,وثيقة تأمين السرقة,وثيقة تأمين ضد الحوادث الشخصية,تأمين نقل البضائع,تأمين إصابات العمل,تأمين المسؤولية المهنية -للمهندسين المعماريين والمدنيين,تأمين المسؤولية المهنية لوكلاء/ وسطاء التأمين,تأمين المسؤولية المهنية لاستشاريي الإدارة,تأمين المسؤولية المهنية لمقيمين الأخطار والخسائر,تأمين المسؤولية المهنية لمقيمين الأخطار والخسائرتأمين المسؤولية المهنية للمقيمين العقاريين,تأمين مسؤولية المدراء والإداريين,تأمين مسؤولية المنتجات,التأمين الشامل على المكاتب,تأمين تأخر رحلات الطيران,",
    hits: 0,
    lang: "AR",
    Description: "تأمين الحوادث العامة",
    webUrl: "https://www.tawuniya.com/corporate/products/enterprise/general-accedent-insurance",
    webUrl: "https://www.tawuniya.com/corporate/products/enterprise/general-accedent-insurance",
    mobileScreenName: "تأمين الحوادث العامة",
    category: "تأمين الحوادث العامة",
    action: "الذهاب",
    businessLine: "الشركات",
  },
  {
    keywords: "Accidental Damage -Property and Business interruption,Business Interruption -Accidental Damage,Consequential Loss -Property Damage,Fire Insurance,Home Insurance,Property Damage,Sabotage and Terrorism,Hospitality Comprehensive insurance,Manufacturers Comprehensive insurance,Traders Comprehensive insurance",
    hits: 0,
    lang: "EN",
    Description: "Property Insurance",
    webUrl: "https://www.tawuniya.com/corporate/products/enterprise/property-insurance",
    webUrl: "https://www.tawuniya.com/corporate/products/enterprise/property-insurance",
    mobileScreenName: "A “property” can be a simple villa or a shop or a huge airport or a petrochemical facility etc.",
    category: "Property Insurance",
    action: "Go to Page",
    businessLine: "Corporate",
  },
  {
    keywords: "الأضرار العرضية - الممتلكات وانقطاع الأعمال,انقطاع الأعمال -الأضرار العرضية,الخسارة التبعية -الضرر بالممتلكات,تأمين الحريق,تأمين على المساكن,أضرار الممتلكات,التخريب والإرهاب,تأمين الضيافة,تأمين المصنعون,تأمين التجار",
    hits: 0,
    lang: "AR",
    Description: "تأمين الممتلكات",
    webUrl: "https://www.tawuniya.com/corporate/products/enterprise/property-insurance",
    webUrl: "https://www.tawuniya.com/corporate/products/enterprise/property-insurance",
    mobileScreenName: "تأمين الممتلكات",
    category: "تأمين الممتلكات",
    action: "الذهاب",
    businessLine: "الشركات",
  },
  {
    keywords: "privacy policy, privacy , privacy notice",
    hits: 0,
    lang: "EN",
    Description: "Privacy Notice",
    webUrl: "https://www.tawuniya.com/privacy-policy",
    webUrl: "https://www.tawuniya.com/privacy-policy",
    mobileScreenName: "Privacy Notice.",
    category: "Privacy Notice",
    action: "Go to Page",
    businessLine: "General",
  },
  {
    keywords: "إشعار الخصوصية, سياسة الخصوصية",
    hits: 0,
    lang: "AR",
    Description: "Privacy Notice",
    webUrl: "https://www.tawuniya.com/privacy-policy",
    webUrl: "https://www.tawuniya.com/privacy-policy",
    mobileScreenName: "Privacy Notice.",
    category: "Privacy Notice",
    action: "Go to Page",
    businessLine: "General",
  },
];



export const networkCoverageQAs = [
  {
    question: "networkCoverage.q1",
    answer: "networkCoverage.a1",
  },
  {
    question: "networkCoverage.q2",
    answer: "networkCoverage.a1",
  },
  {
    question: "networkCoverage.q3",
    answer: "networkCoverage.a1",
  },
  {
    question: "networkCoverage.q4",
    answer: "networkCoverage.a1",
  },
  {
    question: "networkCoverage.q5",
    answer: "networkCoverage.a1",
  },
  {
    question: "networkCoverage.q6",
    answer: "networkCoverage.a1",
  },
  {
    question: "networkCoverage.q7",
    answer: "networkCoverage.a1",
  },
  {
    question: "networkCoverage.q8",
    answer: "networkCoverage.a1",
  },
];

export const medRemHomeQAs = [
  {
    question: "medical_reimbursement.medical_faq.question1",
    answer: "medical_reimbursement.medical_faq.answer1",
  },
  {
    question: "medical_reimbursement.medical_faq.question2",
    answer: "medical_reimbursement.medical_faq.answer2",
  },
  {
    question: "medical_reimbursement.medical_faq.question3",
    answer: "medical_reimbursement.medical_faq.answer3",
  },
  {
    question: "medical_reimbursement.medical_faq.question4",
    answer: "medical_reimbursement.medical_faq.answer4",
  },
];

export const medRemSidebarQAs = [
  {
    question: "medical_reimbursement.medical_faq.question5",
    answer: "medical_reimbursement.medical_faq.answer5",
  },
  {
    question: "medical_reimbursement.medical_faq.question6",
    answer: "medical_reimbursement.medical_faq.answer6",
  },
  {
    question: "medical_reimbursement.medical_faq.question7",
    answer: "medical_reimbursement.medical_faq.answer7",
  },
  {
    question: "medical_reimbursement.medical_faq.question8",
    answer: "medical_reimbursement.medical_faq.answer8",
  },
];

export const embassyLetterQAs = [
  {
    question: "embassyLetter.q1",
    answer: "embassyLetter.a1",
  },
  {
    question: "embassyLetter.q2",
    answer: "embassyLetter.a2",
  },
  {
    question: "embassyLetter.q3",
    answer: "embassyLetter.a3",
  },
  {
    question: "embassyLetter.q4",
    answer: "embassyLetter.a4",
  },
  {
    question: "embassyLetter.q5",
    answer: "embassyLetter.a5",
  },
];

export const workShopData = [
  {
    id: 1,
    code: "550846",
    name: "DAR SALAMAT WORKSHOP FOR CARS SERVICES - RIYADH",
    region: "CRO",
    city: "Riyadh",
    phone: "0552049649",
    email: "salamat@rrc.com.sa",
    kind: "Car",
    location: "https://goo.gl/maps/JfrBHPnfCu7P71396",
    language: "E",
    latitude: "24.688392322390076",
    longitude: "46.851283006746506",
    name_ar: "ورشة دار سلامات لخدمات المركبات ",
    region_ar: "المنطقة الوسطى",
    city_ar: "الرياض",
    kind_ar: "مركبات",
  },
  {
    id: 3,
    code: "551291",
    name: "NASMAT FUTURE CENTER FOR CAR MAINTENANCE - RUH",
    region: "CRO",
    city: "Riyadh",
    phone: "0542813295",
    email: "nsamat2012@hotmail.com",
    kind: "Car",
    location: "https://goo.gl/maps/pAAqSYTEcBiYhW8r6",
    language: "E",
    latitude: "24.635373417416552",
    longitude: "46.79215617791087",
    name_ar: "مركز نسمات المستقبل لصيانة المركبات",
    region_ar: "المنطقة الوسطى",
    city_ar: "الرياض",
    kind_ar: "مركبات",
  },
  {
    id: 5,
    code: "531865",
    name: "AL AWAL ROAD CENTER FOR CAR MAINT. - RIYADH",
    region: "CRO",
    city: "Riyadh",
    phone: "0503497001",
    email: "roadcenter1st@hotmail.com",
    kind: "Car + Trucks",
    location: "https://goo.gl/maps/wQQfq9FVKpXVSNPp7",
    language: "E",
    latitude: "24.63388051474109",
    longitude: "46.74205846441783",
    name_ar: "مركز الطريق الأول لصيانة المركبات ",
    region_ar: "المنطقة الوسطى",
    city_ar: "الرياض",
    kind_ar: "مركبات وشاحنات",
  },
  {
    id: 7,
    code: "529778",
    name: "Adil Ali Abdullah Al Faraaj Workshop",
    region: "CRO",
    city: "Qassim",
    phone: "0508256820",
    email: "alfarraj1976@gmail.com",
    kind: "Car + Trucks",
    location: "https://goo.gl/maps/i3i1NbSq3B1i43857",
    language: "E",
    latitude: "26.413758226722674",
    longitude: "43.89030840227381",
    name_ar: "ورشة عادل علي عبدالله الفراج",
    region_ar: "المنطقة الوسطى",
    city_ar: "القصيم",
    kind_ar: "سيارات وشاحنات",
  },
  {
    id: 9,
    code: "550468",
    name: "Aman express Co. Hail",
    region: "CRO",
    city: "Hail",
    phone: "0534942890",
    email: "amanex996@gmail.com",
    kind: "Car",
    location: "https://goo.gl/maps/cm3dDFEhnLuPmHq67",
    language: "E",
    latitude: "27.475697095981563",
    longitude: "41.72987407791086",
    name_ar: "شركة امان السريع للسيارات",
    region_ar: "المنطقة الوسطى",
    city_ar: "حائل",
    kind_ar: "مركبات",
  },
  {
    id: 11,
    code: "556720",
    name: "Al-Mefleh Co. Trading & Contracting - ArAr",
    region: "CRO",
    city: "Arar",
    phone: "0564530455",
    email: "fars.12345@hotmail.com",
    kind: "Car",
    location: "https://goo.gl/maps/o4kmSqSoH5DYtrPt9",
    language: "E",
    latitude: "30.990908158008413",
    longitude: "40.94528073373258",
    name_ar: "مركز مفلح لاصلاح المركبات",
    region_ar: "المنطقة الوسطى",
    city_ar: "عرعر",
    kind_ar: "مركبات",
  },
  {
    id: 13,
    code: "554163",
    name: "AL MULHIM AUTO SERVICE CO - DAMMAM",
    region: "ERO",
    city: "Dammam",
    phone: "0555024533",
    email: "ws.dammam@mulhimauto.com",
    kind: "Car",
    location: "https://goo.gl/maps/ZWt5n8uVpfwSgsST6",
    language: "E",
    latitude: "26.446239215836815",
    longitude: "50.045374472043896",
    name_ar: "شركة الملحم لخدمة المركبات",
    region_ar: "المنطقة الشرقية",
    city_ar: "الدمام",
    kind_ar: "مركبات",
  },
  {
    id: 15,
    code: "531809",
    name: "AL AMRI CENTER FOR CAR REPAIR - DAMMAM",
    region: "ERO",
    city: "Dammam",
    phone: "0509081375",
    email: "alamricenter@gmail.com",
    kind: "Trucks",
    location: "https://goo.gl/maps/YrgecxKh7WxWkWaG9",
    language: "E",
    latitude: "26.450865239135236",
    longitude: "50.02185340864913",
    name_ar: "مركز فيصل العمري لاصلاح المركبات ",
    region_ar: "المنطقة الشرقية",
    city_ar: "الدمام",
    kind_ar: "شاحنات",
  },
  {
    id: 17,
    code: "552397",
    name: "AL MULHIM AUTO SERVICE CO. - AL KHOBAR",
    region: "ERO",
    city: "Khobar",
    phone: "0555148322",
    email: "ws.khobar@mulhimauto.com",
    kind: "Car",
    location: "https://goo.gl/maps/dGc2eUCPDa1DaTR38",
    language: "E",
    latitude: "26.253855130733363",
    longitude: "50.19598903743177",
    name_ar: " شركة الملحم لخدمة المركبات - الخبر",
    region_ar: "المنطقة الشرقية",
    city_ar: "الخبر",
    kind_ar: "مركبات",
  },
  {
    id: 19,
    code: "557355",
    name: "THE DREAMS HIGHNESS CENTER FOR CAR REPAIRS",
    region: "ERO",
    city: "Dammam",
    phone: "0558580713",
    email: "dreams8cars@gmail.com",
    kind: "Car + Trucks",
    location: "https://goo.gl/maps/yvRuMVoWn83dsCL68",
    language: "E",
    latitude: "26.44296553063965",
    longitude: "50.05444753558216",
    name_ar: "مركز الاحلام لصيانة المركبات ",
    region_ar: "المنطقة الشرقية",
    city_ar: "الدمام",
    kind_ar: "مركبات وشاحنات",
  },
  {
    id: 21,
    code: "529630",
    name: "INTERNATIONAL CAR MAIN. CENTER-AL KHOBAR",
    region: "ERO",
    city: "Khobar",
    phone: "0504941785",
    email: "icmc.ksa@gmail.com",
    kind: "Car",
    location: "https://goo.gl/maps/QpTGfMfaxo8D2MVs5",
    language: "E",
    latitude: "26.247148686870165",
    longitude: "50.214632606746505",
    name_ar: "مركز المركبات العالمية لصيانة المركبات",
    region_ar: "المنطقة الشرقية",
    city_ar: "الخبر",
    kind_ar: "مركبات",
  },
  {
    id: 23,
    code: "20588",
    name: "AL MULHIM AUTO SERVICE CO. - HASSA",
    region: "ERO",
    city: "Hassa",
    phone: "0550131115",
    email: "ws.sanaya@mulhimauto.com",
    kind: "Car",
    location: "https://goo.gl/maps/NgMeTejoWDJojWHp6",
    language: "E",
    latitude: "25.41279738174459",
    longitude: "49.470000707504454",
    name_ar: "شركة الملحم لخدمة المركبات - الاحساء",
    region_ar: "المنطقة الشرقية",
    city_ar: "الأحساء",
    kind_ar: "مركبات",
  },
  {
    id: 25,
    code: "556721",
    name: "Ahmed A. Albrahim Tech. Complex for Car Services - Hassa",
    region: "ERO",
    city: "Hassa",
    phone: "0504933707",
    email: "albrahim.auto@hotmail.com",
    kind: "Car",
    location: "https://goo.gl/maps/Fn5rwJb6YtsGycbQ8",
    language: "E",
    latitude: "25.405294772034168",
    longitude: "49.467664064417825",
    name_ar: "مجمع احمد ابراهيم الفني لخدمات المركبات",
    region_ar: "المنطقة الشرقية",
    city_ar: "الأحساء",
    kind_ar: "مركبات",
  },
  {
    id: 27,
    code: "552396",
    name: "AL MULHIM AUTO SERVICE CO. - QATIF",
    region: "ERO",
    city: "Qatif",
    phone: "0550522719",
    email: "ws.qatif@mulhimauto.com",
    kind: "Car",
    location: "https://goo.gl/maps/nstjHRMRdi699u4P9",
    language: "E",
    latitude: "26.581857277801593",
    longitude: "50.034696167025366",
    name_ar: "شركة الملحم لخدمة المركبات - القطيف",
    region_ar: "المنطقة الشرقية",
    city_ar: "القطيف",
    kind_ar: "مركبات",
  },
  {
    id: 29,
    code: "9883",
    name: "AL-ITIFAQ WORKSHOP (JUBAIL)",
    region: "ERO",
    city: "Jubail",
    phone: "0504961156",
    email: "alitifaq.work.shop@hotmail.com",
    kind: "Car",
    location: "https://goo.gl/maps/uAGgCg6PbGnQ1Q1T9",
    language: "E",
    latitude: "27.005224954374484",
    longitude: "49.617650835608686",
    name_ar: "ورشة الاتفاق لصيانة المركبات ",
    region_ar: "المنطقة الشرقية",
    city_ar: "الجبيل",
    kind_ar: "مركبات",
  },
  {
    id: 31,
    code: "550344",
    name: "UNIVERSAL CAR MAINT. - JED",
    region: "WRO",
    city: "Jeddah",
    phone: "0556681265",
    email: "sales.body@universalcar-sa.com",
    kind: "Car",
    location: "https://goo.gl/maps/2okLYHjWjfrvbcdH9",
    language: "E",
    latitude: "21.69707715139549",
    longitude: "39.20837037121736",
    name_ar: "العالمية لصيانة المركبات ",
    region_ar: "المنطقة الغربية",
    city_ar: "جدة",
    kind_ar: "مركبة",
  },
  {
    id: 33,
    code: "556518",
    name: "Zahrat Alwafa Workshop - Jeddah",
    region: "WRO",
    city: "Jeddah",
    phone: "0567788345",
    email: "z.al-wafa@hotmail.com",
    kind: "Car + Trucks",
    location: "https://goo.gl/maps/BWJu6fNxjgio7tQK9",
    language: "E",
    latitude: "21.444614656744722",
    longitude: "39.26036907791086",
    name_ar: "ورشة زهرة الوفاء ",
    region_ar: "المنطقة الغربية",
    city_ar: "جدة",
    kind_ar: "مركبات وشاحنات",
  },
  {
    id: 35,
    code: "553399",
    name: "CAR MAP CO for Cars Maintenance",
    region: "WRO",
    city: "Makkah",
    phone: "0540079658",
    email: "wael@albrashi-center.com",
    kind: "Car",
    location: "https://g.page/carmapksa?share",
    language: "E",
    latitude: "21.46796159777383",
    longitude: "39.84968776441784",
    name_ar: "شركة خريطة المركبة - كار ماب ",
    region_ar: "المنطقة الغربية",
    city_ar: "مكة المكرمة",
    kind_ar: "مركبات",
  },
  {
    id: 37,
    code: "556348",
    name: "Munif Al-Nahdi Group Holding Com. Makkah (Workshop)",
    region: "WRO",
    city: "Makkah",
    phone: "0552006467",
    email: "makkah3@mize.sa",
    kind: "Car",
    location: "https://goo.gl/maps/RqNaQJLdvRL3MPWs7",
    language: "E",
    latitude: "21.462165729812025",
    longitude: "39.83770211107108",
    name_ar: "ورشة منيف النهدي - مكة",
    region_ar: "المنطقة الغربية",
    city_ar: " مكة المكرمة",
    kind_ar: "مركبات",
  },
  {
    id: 39,
    code: "556339",
    name: "Munif Al-Nahdi Group Holding Com. Medinah (Workshop)",
    region: "WRO",
    city: "Makkah",
    phone: "0504302235",
    email: "madinah@mize.sa",
    kind: "Car",
    location: "https://goo.gl/maps/Y3QYp4eZvX7ETv4u6",
    language: "E",
    latitude: "24.5937558996542",
    longitude: "39.670623149075205",
    name_ar: "ورشة منيف النهدي - المدينة",
    region_ar: "المنطقة الغربية",
    city_ar: "مكة المكرمة",
    kind_ar: "مركبات",
  },
  {
    id: 41,
    code: "529559",
    name: "AL WAKALAH CENTER FOR CAR MAINT.- MEDINAH",
    region: "WRO",
    city: "Makkah",
    phone: "0505320424",
    email: "alwakala@hotmail.com",
    kind: "Car",
    location: "https://goo.gl/maps/zbz2DUJizKxtdA7PA",
    language: "E",
    latitude: "24.502712943232904",
    longitude: "39.601251706121154",
    name_ar: "الوكالة لصيانة المركبات",
    region_ar: "المنطقة الغربية",
    city_ar: "مكة المكرمة",
    kind_ar: "مركبات",
  },
  {
    id: 43,
    code: "556719",
    name: "7 Star Center for Car Maintenance - Tabuk",
    region: "WRO",
    city: "Tabuk",
    phone: "0558213947",
    email: "7starscenter7@gmail.com",
    kind: "Car",
    location: "https://goo.gl/maps/koeRWFGMGPjNQWpY9",
    language: "E",
    latitude: "28.443281092005442",
    longitude: "36.46569102208914",
    name_ar: "مركز سبع نجوم لصيانة المركبات ",
    region_ar: "المنطقة الغربية",
    city_ar: "تبوك",
    kind_ar: "المركبات",
  },
  {
    id: 45,
    code: "531892",
    name: "AL FAW VEHICLES MAINTENANCE - YANBU",
    region: "WRO",
    city: "Yanbu",
    phone: "0543303654",
    email: "alfaw1973@hotmail.com",
    kind: "Car",
    location: "https://g.page/ALFAW-YANBU?share",
    language: "E",
    latitude: "24.119261731929562",
    longitude: "38.1244334490752",
    name_ar: "مركز الفاو التقني لصيانة المركبات",
    region_ar: "المنطقة الغربية",
    city_ar: "ينبع",
    kind_ar: "مركبات",
  },
  {
    id: 47,
    code: "550243",
    name: "MUNIF AL NEHDI SERVICE CENTER - KHAMIS MUSHAIT",
    region: "WRO",
    city: "Khamis Mushait",
    phone: "0502532285",
    email: "kh-mushait@mize.sa",
    kind: "Car",
    location: "https://goo.gl/maps/wyWaQg9rL4t43TRZA",
    language: "E",
    latitude: "18.394657392196763",
    longitude: "42.70697768960731",
    name_ar: "مركز خدمات منيف النهدي - خميس مشيط",
    region_ar: "المنطقة الغربية",
    city_ar: "خميس مشيط",
    kind_ar: "مركبات",
  },
  {
    id: 49,
    code: "558113",
    name: "Zahra Al-Wafa Workshop for Car Maintenance - Khamis Mushait",
    region: "WRO",
    city: "Khamis Mushait",
    phone: "0568000589",
    email: "quote.kms@zalwafa.com",
    kind: "Car",
    location: "https://goo.gl/maps/5mnFz6BQrJi7kAr67",
    language: "E",
    latitude: "18.403759732815235",
    longitude: "42.702610793253484",
    name_ar: "ورشة زهرة الوفاء لصيانة المركبات ",
    region_ar: "المنطقة الغربية",
    city_ar: "خميس مشيط",
    kind_ar: "مركبات",
  },
  {
    id: 51,
    code: "558563",
    name: "Awaser International Trading Co. Main Branch - Jazan",
    region: "WRO",
    city: "Jazan",
    phone: "0555657596",
    email: "raheem@awaserksa.com",
    kind: "Car",
    location: "https://goo.gl/maps/kuuoQu5EfoBQfA2i9",
    language: "E",
    latitude: "16.88003489851176",
    longitude: "42.6062360282368",
    name_ar: "أواصر العالمية - الفرع الرئيسي ",
    region_ar: "المنطقة الغربية",
    city_ar: "جازان",
    kind_ar: "مركبة",
  },
  {
    id: 53,
    code: "558564",
    name: "Awaser International Trading Co. Abu Areesh - Jazan",
    region: "WRO",
    city: "Jazan",
    phone: "0555048173",
    email: "hisham@awaserksa.com",
    kind: "Car",
    location: "https://goo.gl/maps/X5fpDAH3JGZecnia8",
    language: "E",
    latitude: "16.978525870266726",
    longitude: "42.83214170987337",
    name_ar: "أواصر العالمية - ابو عريش ",
    region_ar: "المنطقة الغربية",
    city_ar: "جازان",
    kind_ar: "مركبات",
  },
  {
    id: 55,
    code: "558565",
    name: "Awaser International Trading Co. Ahd Almasarahah - Jazan",
    region: "WRO",
    city: "Jazan",
    phone: "0555753964",
    email: "gasmalla@awaserksa.com",
    kind: "Car",
    location: "https://goo.gl/maps/jyDZJMSM9qNH87Hw6",
    language: "E",
    latitude: "16.729005131258322",
    longitude: "42.9390346798665",
    name_ar: "أواصر العالمية - احد المسارحة ",
    region_ar: "المنطقة الغربية",
    city_ar: "جازان",
    kind_ar: "مركبات",
  },
  {
    id: 57,
    code: "556340",
    name: "Munif Al-Nahdi Group Holding Com. Sabia (Workshop)",
    region: "WRO",
    city: "Jazan",
    phone: "0500471742",
    email: "sabia@mize.sa",
    kind: "Car",
    location: "https://goo.gl/maps/xg4ZcYZRNsqLgqMU7",
    language: "E",
    latitude: "16.83726749243943",
    longitude: "42.612525167025375",
    name_ar: "ورشة منيف النهدي - صبيا",
    region_ar: "المنقطة الغربية",
    city_ar: "جازان",
    kind_ar: "المركبات",
  },
  {
    id: 59,
    code: "558653",
    name: "Maged Ghlab Amaash Alamri for Cars Maint. Workshop - Ruh",
    region: "CRO",
    city: "Riyadh",
    phone: "0504359313",
    email: "majedalamri@hotmail.com",
    kind: "الغاز",
    location: "https://goo.gl/maps/SgKd9hB24czhEg3XA",
    language: "E",
    latitude: "24.58166498264495",
    longitude: "46.740042954570974",
    name_ar: "ورشة ماجد غلاب العمري لصيانة المركبات ",
    region_ar: "المنطقة الوسطى",
    city_ar: "الرياض",
    kind_ar: "الغاز",
  },
  {
    id: 61,
    code: "558652",
    name: "Sultan Ahmed Aljowie Workshop - Riyadh",
    region: "CRO",
    city: "Riyadh",
    phone: "0551376336",
    email: "burhann2010@hotmail.com",
    kind: "الغاز",
    location: "https://goo.gl/maps/t8txeNWZPpSjqJoM7",
    language: "E",
    latitude: "24.653402610420894",
    longitude: "46.812060617510674",
    name_ar: "ورشة سلطان احمد  ",
    region_ar: "المنطقة الوسطى",
    city_ar: "الرياض",
    kind_ar: "الغاز",
  },
  {
    id: 63,
    code: "558702",
    name: "Gulf Heavy Industries Co. Jubail",
    region: "ERO",
    city: "Jubail",
    phone: "0568155899",
    email: "kraj@ghi.com.sa",
    kind: "الغاز",
    location: "https://goo.gl/maps/jKsQFgCaio2V2gTG9",
    language: "E",
    latitude: "27.04112746156319",
    longitude: "49.499752122089134",
    name_ar: "شركة الخليج للصناعات الثقيلة",
    region_ar: "المنطقة الشرقية",
    city_ar: "الجبيل",
    kind_ar: "الغاز",
  },
];

export const individualCovidFaqlist = [
  {
    id: 1,
    question: "What is covid-19 Travel insurance?",
    answer:
      "The insured is provided with an Insurance Card and Bail Bond approved by the Traffic Police together with Third Party Insurance Policy to prevent the arrest or detainment of the driver causing the accident?",
  },
  {
    id: 2,
    question: "Why is this insurance applied?",
    answer:
      "The insured is provided with an Insurance Card and Bail Bond approved by the Traffic Police together with Third Party Insurance Policy to prevent the arrest or detainment of the driver causing the accident?",
  },
  {
    id: 3,
    question: "Can residents benefit from this program?",
    answer:
      "The insured is provided with an Insurance Card and Bail Bond approved by the Traffic Police together with Third Party Insurance Policy to prevent the arrest or detainment of the driver causing the accident?",
  },
  {
    id: 4,
    question: "What is medical fraud unit?",
    answer:
      "The insured is provided with an Insurance Card and Bail Bond approved by the Traffic Police together with Third Party Insurance Policy to prevent the arrest or detainment of the driver causing the accident?",
  },
  {
    id: 5,
    question: "What companies provide this insurance to citizens?",
    answer:
      "The insured is provided with an Insurance Card and Bail Bond approved by the Traffic Police together with Third Party Insurance Policy to prevent the arrest or detainment of the driver causing the accident?",
  },
  {
    id: 6,
    question: "Does this insurance cover the risk of contracting coronavirus only while traveling?",
    answer:
      "The insured is provided with an Insurance Card and Bail Bond approved by the Traffic Police together with Third Party Insurance Policy to prevent the arrest or detainment of the driver causing the accident?",
  },
  {
    id: 7,
    question: "How much does this program cost?",
    answer:
      "The insured is provided with an Insurance Card and Bail Bond approved by the Traffic Police together with Third Party Insurance Policy to prevent the arrest or detainment of the driver causing the accident?",
  },
  {
    id: 8,
    question: "Does the insurance cover domestic travel trips?",
    answer:
      "The insured is provided with an Insurance Card and Bail Bond approved by the Traffic Police together with Third Party Insurance Policy to prevent the arrest or detainment of the driver causing the accident?",
  },
];

export const vitalityQAs = [
  {
    question: "vitality.accordion1.q1",
    answer: "vitality.accordion1.q1Answer",
  },
  {
    question: "vitality.accordion2.q1",
    answer: "vitality.accordion2.q1Answer",
  },
  {
    question: "vitality.accordion1.q2",
    answer: "vitality.accordion1.q2Answer",
  },
  {
    question: "vitality.accordion2.q2",
    answer: "vitality.accordion2.q2Answer",
  },
  {
    question: "vitality.accordion1.q3",
    answer: "vitality.accordion1.q3Answer",
  },
  {
    question: "vitality.accordion1.q4",
    answer: "vitality.accordion1.q4Answer",
  },
];

export const periodicInspectionQAs = [
  {
    question: "PeriodicInspect.q1",
    answer: "PeriodicInspect.a1",
  },
  {
    question: "PeriodicInspect.q2",
    answer: "PeriodicInspect.a2",
  },
  {
    question: "PeriodicInspect.q3",
    answer: "PeriodicInspect.a3",
  },
  {
    question: "PeriodicInspect.q4",
    answer: "PeriodicInspect.a4",
  },
];

export const roadAssistanceQAs = [
  {
    question: "roadAssistance.q1",
    answer: "roadAssistance.a1",
  },
  {
    question: "roadAssistance.q2",
    answer: "roadAssistance.a2",
  },
  {
    question: "roadAssistance.q3",
    answer: "roadAssistance.a3",
  },
  {
    question: "roadAssistance.q4",
    answer: "roadAssistance.a4",
  },
];

export const complaintServiceTypes = [
  {
    name: "Claims",
    value: "claims",
    label: "Claims",
  },
  {
    name: "Sales",
    value: "sales",
    label: "Sales",
  },
  {
    name: "Other",
    value: "other",
    label: "Other",
  },
];

export const complaintServiceTypes_ar = [
  {
    name: "مطالبات",
    value: "claims",
    label: "مطالبات",
  },
  {
    name: "مبيعات",
    value: "sales",
    label: "مبيعات",
  },
  {
    name: "أخرى",
    value: "other",
    label: "أخرى",
  },
];
export const PolicyListVisitVisa = [
  {
    image: extentPolicy,
    title: "Extend Your policy",
  },
  {
    image: cancelPolicy,
    title: "Cancel Your policy",
  },
  {
    image: policyBenefits,
    title: "policy coverage and benefits",
  },
];

export const visitvisa = [
  {
    id: 1,
    question: "How can I apply for Visit Visa and purchase its insurance policy?",
    answer:
      "The insured is provided with an Insurance Card and Bail Bond approved by the Traffic Police together with Third Party Insurance Policy to prevent the arrest or detainment of the driver causing the accident?",
  },
  {
    id: 2,
    question: "Is obtaining Visit Visa Insurance compulsory?",
    answer:
      "The insured is provided with an Insurance Card and Bail Bond approved by the Traffic Police together with Third Party Insurance Policy to prevent the arrest or detainment of the driver causing the accident?",
  },

  {
    id: 3,
    question:
      "What is the procedure of purchasing Visit Visa Insurance for the purpose of Extending Visit?",
    answer:
      "The insured is provided with an Insurance Card and Bail Bond approved by the Traffic Police together with Third Party Insurance Policy to prevent the arrest or detainment of the driver causing the accident?",
  },
  {
    id: 4,
    question: "What is the term period of Visit Visa Insurance policy & COVID-19 Insurance Policy?",
    answer:
      "The insured is provided with an Insurance Card and Bail Bond approved by the Traffic Police together with Third Party Insurance Policy to prevent the arrest or detainment of the driver causing the accident?",
  },
  {
    id: 5,
    question: "Does COVID-19 Insurance policy cover PCR test costs?",
    answer:
      "The insured is provided with an Insurance Card and Bail Bond approved by the Traffic Police together with Third Party Insurance Policy to prevent the arrest or detainment of the driver causing the accident?",
  },
  {
    id: 6,
    question: "Is it compulsory to obtain COVID-19 insurance besides Visit visa basic insurance?",
    answer:
      "The insured is provided with an Insurance Card and Bail Bond approved by the Traffic Police together with Third Party Insurance Policy to prevent the arrest or detainment of the driver causing the accident?",
  },
  {
    id: 7,
    question: "Can Insurance Policy of Visit Visa Extension be cancelled?",
    answer:
      "The insured is provided with an Insurance Card and Bail Bond approved by the Traffic Police together with Third Party Insurance Policy to prevent the arrest or detainment of the driver causing the accident?",
  },
  {
    id: 8,
    question: "Can Visit Visa Insurance policies be cancelled and their fees be refunded?",
    answer:
      "The insured is provided with an Insurance Card and Bail Bond approved by the Traffic Police together with Third Party Insurance Policy to prevent the arrest or detainment of the driver causing the accident?",
  },
];

export const welcomekitHealthServices = [
  {
    name: "welcome_kit.health.services.chronic_medication",
    description: "welcome_kit.health.services.chronic_medication_desc",
    navigate_to: "/login",
    img: chronicDiseaseMedication,
  },
  {
    name: "welcome_kit.health.services.health_reim",
    description: "welcome_kit.health.services.health_reim_desc",
    navigate_to: "/login",
    img: healthReim,
  },
  {
    name: "welcome_kit.health.services.telemedicine",
    description: "welcome_kit.health.services.telemedicine_desc",
    navigate_to: "/login",
    img: telemedicine,
  },
  {
    name: "welcome_kit.health.services.eligibility_letter",
    description: "welcome_kit.health.services.eligibility_letter_desc",
    navigate_to: "/login",
    img: eligibility,
  },
  //  {
  //   name: "welcome_kit.health.services.pregnancy_program",
  //   description: "welcome_kit.health.services.pregnancy_program_desc",
  //   navigate_to: "/individuals/customer-service/pregnancy-follow-up-program",
  //   img: pregnancy,
  //  },
  {
    name: "welcome_kit.health.services.chronic_disease_management",
    description: "welcome_kit.health.services.chronic_disease_management_desc",
    navigate_to: "/individuals/customer-service/chronic-disease-management",
    img: chronicDisease,
  },
  {
    name: "welcome_kit.health.services.home_child_vacc",
    description: "welcome_kit.health.services.home_child_vacc_desc",
    navigate_to: "/individuals/customer-service/home-child-vaccination",
    img: homeChildVaccination,
  },
  {
    name: "welcome_kit.health.services.assist_america",
    description: "welcome_kit.health.services.assist_america_desc",
    navigate_to: "/individuals/customer-service/assist-america",
    img: assistAmerica,
  },
];

export const welcomekitAdditionalServices = [
  {
    img: tawnIthra,
    bgImg: tawnIthraBg,
    description: "welcome_kit.health.addServices.tawn_ithra_desc",
    discount: "",
    navigate_to: "/individuals/loyalty-program/ithra",
  },
  {
    img: driveLogo,
    bgImg: driveBg,
    description: "welcome_kit.health.addServices.drive_desc",
    discount: "",
    // precentage: true,
    navigate_to: "/individuals/loyalty-program/drive",
  },
  {
    img: ithraPrime,
    bgImg: IthraPrimeBg,
    description: "welcome_kit.health.addServices.ithra_prime_desc",
    discount: "",
    navigate_to: "/individuals/loyalty-program/ithra-prime",
  },
];

export const embassiesList = [
  {
    code: 301,
    name: "Afghanistan",
    name_ar: "أفغانستان",
  },
  {
    code: 502,
    name: "Albania",
    name_ar: "البانيا",
  },
  {
    code: 202,
    name: "Algeria",
    name_ar: "الجزائر",
  },
  {
    code: 809,
    name: "American Samoa",
    name_ar: "ساموا الامريكية",
  },
  {
    code: 522,
    name: "Andorra",
    name_ar: "اندورا",
  },
  {
    code: 438,
    name: "Angola",
    name_ar: "انجولا",
  },
  {
    code: 346,
    name: "Angous",
    name_ar: "انقوش",
  },
  {
    code: 639,
    name: "Anguilla",
    name_ar: "انجويلا",
  },
  {
    code: 705,
    name: "Antarctica",
    name_ar: "انتاركتيكا",
  },
  {
    code: 640,
    name: "Antigua",
    name_ar: "انتيكوا",
  },
  {
    code: 130,
    name: "Arab 48",
    name_ar: "عرب ثمانية وأربعون",
  },
  {
    code: 646,
    name: "Arabism",
    name_ar: "عروبا",
  },
  {
    code: 602,
    name: "Argentina",
    name_ar: "الارجنتين",
  },
  {
    code: 539,
    name: "Armenia",
    name_ar: "ارمينيا",
  },
  {
    code: 701,
    name: "Australia",
    name_ar: "استراليا",
  },
  {
    code: 523,
    name: "Austria",
    name_ar: "النمسا",
  },
  {
    code: 343,
    name: "Azerbaijan",
    name_ar: "اذربيجان",
  },
  {
    code: 612,
    name: "Bahamas",
    name_ar: "جزر البهاما",
  },
  {
    code: 103,
    name: "Bahrain",
    name_ar: "البحرين",
  },
  {
    code: 129,
    name: "Bahrain ID",
    name_ar: "وثيقة بحرينيه",
  },
  {
    code: 118,
    name: "Bahrain's population",
    name_ar: "من سكان البحرين",
  },
  {
    code: 305,
    name: "Bangladesh",
    name_ar: "بنجلاديش",
  },
  {
    code: 115,
    name: "Bani Harith",
    name_ar: "بني حارث",
  },
  {
    code: 603,
    name: "Barbados",
    name_ar: "بربادوس",
  },
  {
    code: 659,
    name: "Barbuda",
    name_ar: "باربودا",
  },
  {
    code: 537,
    name: "Belarusian",
    name_ar: "روسيا البيضاء",
  },
  {
    code: 509,
    name: "Belgium",
    name_ar: "بلجيكا",
  },
  {
    code: 635,
    name: "Belize",
    name_ar: "بيليز",
  },
  {
    code: 814,
    name: "Belo",
    name_ar: "بيلو",
  },
  {
    code: 413,
    name: "Benin",
    name_ar: "بنين",
  },
  {
    code: 636,
    name: "Bermuda",
    name_ar: "بيرمودا",
  },
  {
    code: 324,
    name: "Bhutan",
    name_ar: "بهوتان",
  },
  {
    code: 626,
    name: "Bolivia",
    name_ar: "بوليفيا",
  },
  {
    code: 647,
    name: "Bonaire",
    name_ar: "بونيري",
  },
  {
    code: 545,
    name: "Bosnia and Herzegovina",
    name_ar: "البوسنة والهرسك",
  },
  {
    code: 403,
    name: "Botswana",
    name_ar: "بوتسوانا",
  },
  {
    code: 445,
    name: "Bovthatswana",
    name_ar: "بوفثاتسوانا",
  },
  {
    code: 604,
    name: "Brazil",
    name_ar: "البرازيل",
  },
  {
    code: 641,
    name: "British Virgin",
    name_ar: "ج فيرجن البريطانية",
  },
  {
    code: 306,
    name: "Brunei Darussalam",
    name_ar: "بروني",
  },
  {
    code: 508,
    name: "Bulgaria",
    name_ar: "بلغاريا",
  },
  {
    code: 424,
    name: "Burkina Faso",
    name_ar: "بوركينا فاسو",
  },
  {
    code: 404,
    name: "Burundi",
    name_ar: "بوروندي",
  },
  {
    code: 317,
    name: "Cambodia",
    name_ar: "كمبوديا",
  },
  {
    code: 425,
    name: "Cameroon",
    name_ar: "الكاميرون",
  },
  {
    code: 610,
    name: "Canada",
    name_ar: "كندا",
  },
  {
    code: 439,
    name: "Cape Verde",
    name_ar: "الراس  الاخضر",
  },
  {
    code: 642,
    name: "Cayman Islands",
    name_ar: "جزر كايمون",
  },
  {
    code: 437,
    name: "Central African Republic",
    name_ar: "افريقيا الوسطى",
  },
  {
    code: 405,
    name: "Chad",
    name_ar: "تشاد",
  },
  {
    code: 344,
    name: "Chechnya",
    name_ar: "الشاشان",
  },
  {
    code: 627,
    name: "Chile",
    name_ar: "شيلي",
  },
  {
    code: 325,
    name: "China",
    name_ar: "الصين الشعبية",
  },
  {
    code: 708,
    name: "Christmas Island",
    name_ar: "جزيرة كريسماس",
  },
  {
    code: 709,
    name: "Cocos Islands",
    name_ar: "جزيرةكوكو-كيلنج",
  },
  {
    code: 611,
    name: "Colombia",
    name_ar: "كولومبيا",
  },
  {
    code: 452,
    name: "Comorian island",
    name_ar: "جزيرة مايوت",
  },
  {
    code: 410,
    name: "Comoros",
    name_ar: "جزر القمر",
  },
  {
    code: 426,
    name: "Congo",
    name_ar: "الكونغو",
  },
  {
    code: 657,
    name: "Cook Islands",
    name_ar: "جزر كوك",
  },
  {
    code: 613,
    name: "Costa Rica",
    name_ar: "كوستاريكا",
  },
  {
    code: 418,
    name: "Cote D'Ivoire",
    name_ar: "ساحل العاج",
  },
  {
    code: 546,
    name: "Croatia",
    name_ar: "كرواتيا",
  },
  {
    code: 614,
    name: "Cuba",
    name_ar: "كوبا",
  },
  {
    code: 648,
    name: "Curaçao",
    name_ar: "كيوراكو",
  },
  {
    code: 326,
    name: "Cyprus",
    name_ar: "قبرص",
  },
  {
    code: 552,
    name: "Czech Republic",
    name_ar: "التشيك",
  },
  {
    code: 345,
    name: "Dagestan",
    name_ar: "داغستان",
  },
  {
    code: 512,
    name: "Denmark",
    name_ar: "الدانمارك",
  },
  {
    code: 203,
    name: "Djibouti",
    name_ar: "جيبوتي",
  },
  {
    code: 615,
    name: "Dominica",
    name_ar: "دومينيكا",
  },
  {
    code: 616,
    name: "Dominican Republic",
    name_ar: "جمهورية دومينيكان",
  },
  {
    code: 628,
    name: "Ecuador",
    name_ar: "الاكوادور",
  },
  {
    code: 207,
    name: "Egypt",
    name_ar: "جمهورية مصر العربية",
  },
  {
    code: 617,
    name: "El Salvador",
    name_ar: "السلفادور",
  },
  {
    code: 128,
    name: "Emarite ID",
    name_ar: "وثيقة اماراتيه",
  },
  {
    code: 440,
    name: "Equatorial Guinea",
    name_ar: "غينيا الاستوائية",
  },
  {
    code: 449,
    name: "Eritrea",
    name_ar: "اريتريا",
  },
  {
    code: 543,
    name: "Estonia",
    name_ar: "استونيا",
  },
  {
    code: 401,
    name: "Ethiopia",
    name_ar: "اثيوبيا",
  },
  {
    code: 654,
    name: "Falkland Islands (Malvinas)",
    name_ar: "جزر فاكلاند",
  },
  {
    code: 554,
    name: "Faroe Islands",
    name_ar: "جزر فيرو",
  },
  {
    code: 801,
    name: "Fiji",
    name_ar: "فيجي",
  },
  {
    code: 517,
    name: "Finland",
    name_ar: "فنلندا",
  },
  {
    code: 516,
    name: "France",
    name_ar: "فرنسا",
  },
  {
    code: 655,
    name: "French Guiana",
    name_ar: "جيانا الفرنسية",
  },
  {
    code: 815,
    name: "French Polynesia",
    name_ar: "بولينيسي الفرنسية",
  },
  {
    code: 408,
    name: "Gabon",
    name_ar: "الجابون",
  },
  {
    code: 409,
    name: "Gambia",
    name_ar: "غامبيا",
  },
  {
    code: 541,
    name: "Georgia",
    name_ar: "جورجيا",
  },
  {
    code: 503,
    name: "Germany",
    name_ar: "المانيا",
  },
  {
    code: 421,
    name: "Ghana",
    name_ar: "غانا",
  },
  {
    code: 534,
    name: "Gibraltar",
    name_ar: "جبل طارق",
  },
  {
    code: 521,
    name: "Greece",
    name_ar: "اليونان",
  },
  {
    code: 634,
    name: "Greenland",
    name_ar: "جرينلاند",
  },
  {
    code: 618,
    name: "Grenada",
    name_ar: "جرانادا",
  },
  {
    code: 644,
    name: "Guadeloupe",
    name_ar: "جيودي لوب",
  },
  {
    code: 810,
    name: "Guam",
    name_ar: "جوام",
  },
  {
    code: 619,
    name: "Guatemala",
    name_ar: "جواتيمالا",
  },
  {
    code: 422,
    name: "Guinea",
    name_ar: "غينيا",
  },
  {
    code: 423,
    name: "Guinea-Bissau",
    name_ar: "غينيا بيساو",
  },
  {
    code: 620,
    name: "Haiti",
    name_ar: "هايتي",
  },
  {
    code: 621,
    name: "Honduras",
    name_ar: "هندوراس",
  },
  {
    code: 322,
    name: "Hong Kong",
    name_ar: "هونج كونج",
  },
  {
    code: 525,
    name: "Hungary",
    name_ar: "المجر",
  },
  {
    code: 526,
    name: "Iceland",
    name_ar: "ايسلندا",
  },
  {
    code: 321,
    name: "India",
    name_ar: "الهند",
  },
  {
    code: 302,
    name: "Indonesia",
    name_ar: "اندونيسيا",
  },
  {
    code: 303,
    name: "Iran, Islamic Republic of",
    name_ar: "ايران",
  },
  {
    code: 105,
    name: "Iraq",
    name_ar: "العراق",
  },
  {
    code: 504,
    name: "Ireland",
    name_ar: "ايرلندا",
  },
  {
    code: 816,
    name: "Islands Wallis",
    name_ar: "جزر والس وفوتونا",
  },
  {
    code: 505,
    name: "Italy",
    name_ar: "ايطاليا",
  },
  {
    code: 607,
    name: "Jamaica",
    name_ar: "جامايكا",
  },
  {
    code: 323,
    name: "Japan",
    name_ar: "اليابان",
  },
  {
    code: 608,
    name: "Joanna",
    name_ar: "جوانا",
  },
  {
    code: 102,
    name: "Jordan",
    name_ar: "الاردن",
  },
  {
    code: 336,
    name: "Kazakhstan",
    name_ar: "كازاخستان",
  },
  {
    code: 427,
    name: "Kenya",
    name_ar: "كينيا",
  },
  {
    code: 334,
    name: "Kharestan",
    name_ar: "بخارستان",
  },
  {
    code: 802,
    name: "Kiribati",
    name_ar: "كيريباتي",
  },
  {
    code: 328,
    name: "Korea, Democratic People's Republic of",
    name_ar: "كوريا الشمالية",
  },
  {
    code: 318,
    name: "Korea, Republic of",
    name_ar: "كوريا الجنوبية",
  },
  {
    code: 550,
    name: "Kosovo",
    name_ar: "كوسوفو",
  },
  {
    code: 109,
    name: "Kuwait",
    name_ar: "الكويت",
  },
  {
    code: 116,
    name: "Kuwait - without",
    name_ar: "الكويت - بدون",
  },
  {
    code: 340,
    name: "Kyrgyzstan",
    name_ar: "قيرغيزيا",
  },
  {
    code: 329,
    name: "Lao People's Democratic Republic",
    name_ar: "لاوس",
  },
  {
    code: 544,
    name: "Latvia",
    name_ar: "لاتفيا",
  },
  {
    code: 110,
    name: "Lebanon",
    name_ar: "لبنان",
  },
  {
    code: 428,
    name: "Lesotho",
    name_ar: "ليسوتو",
  },
  {
    code: 429,
    name: "Liberia",
    name_ar: "ليبيريا",
  },
  {
    code: 206,
    name: "Libyan Arab Jamahiriya",
    name_ar: "ليبيا",
  },
  {
    code: 527,
    name: "Liechtenstein",
    name_ar: "ليختنشتين",
  },
  {
    code: 542,
    name: "Lithuania",
    name_ar: "ليتوانيا",
  },
  {
    code: 528,
    name: "Luxembourg",
    name_ar: "لوكسمبورج",
  },
  {
    code: 331,
    name: "Macao",
    name_ar: "ماكاو",
  },
  {
    code: 549,
    name: "Macedonia, The Former Yugoslav Republic of",
    name_ar: "مقدونيا",
  },
  {
    code: 818,
    name: "Madagascar",
    name_ar: "مدغشقر",
  },
  {
    code: 441,
    name: "Malagasy",
    name_ar: "ملاجاسي",
  },
  {
    code: 432,
    name: "Malawi",
    name_ar: "مالاوي",
  },
  {
    code: 319,
    name: "Malaysia",
    name_ar: "ماليزيا",
  },
  {
    code: 310,
    name: "Maldives",
    name_ar: "جزر المالديف",
  },
  {
    code: 430,
    name: "Mali",
    name_ar: "مالي",
  },
  {
    code: 529,
    name: "Malta",
    name_ar: "مالطا",
  },
  {
    code: 146,
    name: "Manahel and Muhrra",
    name_ar: "المناهيل والمهرة",
  },
  {
    code: 811,
    name: "Mariana Islands",
    name_ar: "جزر ماريانا",
  },
  {
    code: 813,
    name: "Marshall Islands",
    name_ar: "جزر ماريشال",
  },
  {
    code: 645,
    name: "Martinique",
    name_ar: "مارتينيكو",
  },
  {
    code: 209,
    name: "Mauritania",
    name_ar: "موريتانيا",
  },
  {
    code: 433,
    name: "Mauritius",
    name_ar: "موريشيوس",
  },
  {
    code: 622,
    name: "Mexico",
    name_ar: "المكسيك",
  },
  {
    code: 812,
    name: "Micronesia",
    name_ar: "ميكرونيسيا",
  },
  {
    code: 342,
    name: "Mohreh",
    name_ar: "مهرة",
  },
  {
    code: 540,
    name: "Moldova, Republic of",
    name_ar: "مولدوفا",
  },
  {
    code: 530,
    name: "Monaco",
    name_ar: "موناكو",
  },
  {
    code: 330,
    name: "Mongolia",
    name_ar: "مانغوليا",
  },
  {
    code: 643,
    name: "Monte Sirat",
    name_ar: "مونت  سيرات",
  },
  {
    code: 524,
    name: "Montenegro",
    name_ar: "الجبل الا سود",
  },
  {
    code: 208,
    name: "Morocco",
    name_ar: "المغرب",
  },
  {
    code: 434,
    name: "Mozambique",
    name_ar: "موزمبيق",
  },
  {
    code: 307,
    name: "Myanmar",
    name_ar: "ميانمار",
  },
  {
    code: 353,
    name: "Myanmar/Bangladesh Passport",
    name_ar: "اميانمار/جوازبنجلا دش",
  },
  {
    code: 352,
    name: "Myanmar/Pakistan Passport",
    name_ar: "ميانمار/جواز باكستان",
  },
  {
    code: 351,
    name: "Myanmar/Resident",
    name_ar: "البرماويين المقيمين",
  },
  {
    code: 412,
    name: "Namibia",
    name_ar: "ناميبيا",
  },
  {
    code: 803,
    name: "Nauru",
    name_ar: "نورو",
  },
  {
    code: 320,
    name: "Nepal",
    name_ar: "نيبال",
  },
  {
    code: 519,
    name: "Netherlands",
    name_ar: "هولندا",
  },
  {
    code: 704,
    name: "New",
    name_ar: "نيو",
  },
  {
    code: 817,
    name: "New Caledonia",
    name_ar: "كاليدونيا الجديد",
  },
  {
    code: 702,
    name: "New Zealand",
    name_ar: "نيوزيلندا",
  },
  {
    code: 623,
    name: "Nicaragua",
    name_ar: "نيكاراجوا",
  },
  {
    code: 436,
    name: "Niger",
    name_ar: "النيجر",
  },
  {
    code: 435,
    name: "Nigeria",
    name_ar: "نيجيريا",
  },
  {
    code: 348,
    name: "No Name",
    name_ar: "مكررلقرغيزيا لايستخدم",
  },
  {
    code: 706,
    name: "Norfolk Island",
    name_ar: "جزر نورفولك",
  },
  {
    code: 531,
    name: "Norway",
    name_ar: "النرويج",
  },
  {
    code: 511,
    name: "Old Code Czechoslovakia",
    name_ar: "رمزقديم تشكوسلوفاكيا",
  },
  {
    code: 551,
    name: "Old Code Montenegro",
    name_ar: "رمزقديم للجبل الاسود",
  },
  {
    code: 106,
    name: "Oman",
    name_ar: "عمان",
  },
  {
    code: 127,
    name: "Oman ID",
    name_ar: "وثيقة عمانيه",
  },
  {
    code: 450,
    name: "Other African countries",
    name_ar: "دول افريقية اخري",
  },
  {
    code: 304,
    name: "Pakistan",
    name_ar: "باكستان",
  },
  {
    code: 107,
    name: "Palestine",
    name_ar: "فلسطين",
  },
  {
    code: 121,
    name: "Palestinian Egyptian",
    name_ar: "فلسطيني بوثيقة مصرية",
  },
  {
    code: 124,
    name: "Palestinian Iraqi",
    name_ar: "فلسطيني بوثيقة عراقية",
  },
  {
    code: 123,
    name: "Palestinian Jordan",
    name_ar: "فلسطيني بوثيقة اردنية",
  },
  {
    code: 122,
    name: "Palestinian Lebanese",
    name_ar: "فلسطيني بوثيقة لبناني",
  },
  {
    code: 125,
    name: "Palestinian Syria",
    name_ar: "فلسطيني بوثيقة سورية",
  },
  {
    code: 605,
    name: "Panama",
    name_ar: "بنما",
  },
  {
    code: 703,
    name: "Papua yoga",
    name_ar: "بابوا نيوغينا",
  },
  {
    code: 629,
    name: "Paraguay",
    name_ar: "باراجواي",
  },
  {
    code: 630,
    name: "Peru",
    name_ar: "بيرو",
  },
  {
    code: 315,
    name: "Philippines",
    name_ar: "الفلبين",
  },
  {
    code: 510,
    name: "Poland",
    name_ar: "بولندا",
  },
  {
    code: 507,
    name: "Portugal",
    name_ar: "البرتغال",
  },
  {
    code: 652,
    name: "Puerto Rico",
    name_ar: "بورتوريكو",
  },
  {
    code: 108,
    name: "Qatar",
    name_ar: "قطر",
  },
  {
    code: 126,
    name: "Qatar ID",
    name_ar: "وثيقة قطريه",
  },
  {
    code: 350,
    name: "Resident",
    name_ar: "مقيم",
  },
  {
    code: 446,
    name: "Reunion",
    name_ar: "رينيون",
  },
  {
    code: 513,
    name: "Romania",
    name_ar: "رومانيا",
  },
  {
    code: 311,
    name: "Russian Federation",
    name_ar: "روسيا الاتحادية",
  },
  {
    code: 414,
    name: "Rwanda",
    name_ar: "رواندا",
  },
  {
    code: 650,
    name: "Saba",
    name_ar: "سابا",
  },
  {
    code: 451,
    name: "Saint Helena",
    name_ar: "سانت هيلانة",
  },
  {
    code: 638,
    name: "Saint Kitts and Nevis",
    name_ar: "سان كريستوفرنيفز",
  },
  {
    code: 624,
    name: "Saint Lucia",
    name_ar: "سانت  لوسيا",
  },
  {
    code: 651,
    name: "Saint Martin",
    name_ar: "سان مارتين",
  },
  {
    code: 633,
    name: "Saint Pierre and Miquelon",
    name_ar: "سانيت بييري وميكويلن",
  },
  {
    code: 625,
    name: "Saint Vincent and The Grenadines",
    name_ar: "سان فينسنت",
  },
  {
    code: 649,
    name: "San Astatios",
    name_ar: "سان استاتيوس",
  },
  {
    code: 532,
    name: "San Marino",
    name_ar: "سان مارينو",
  },
  {
    code: 442,
    name: "Sao Tome/FranceBank",
    name_ar: "ساوتومي/برنسبى",
  },
  {
    code: 113,
    name: "Saudi Arabia",
    name_ar: "المملكة العربية السعودية",
  },
  {
    code: 419,
    name: "Senegal",
    name_ar: "السنغال",
  },
  {
    code: 548,
    name: "Serbia",
    name_ar: "صربيا والجبل الاسود",
  },
  {
    code: 518,
    name: "Serbia",
    name_ar: "صربيا",
  },
  {
    code: 443,
    name: "Seychelles",
    name_ar: "جزر سيشل",
  },
  {
    code: 420,
    name: "Sierra Leone",
    name_ar: "سيراليون",
  },
  {
    code: 312,
    name: "Singapore",
    name_ar: "سنغافورة",
  },
  {
    code: 553,
    name: "Slovakia",
    name_ar: "سلوفاكيا",
  },
  {
    code: 547,
    name: "Slovenia",
    name_ar: "سلوفينيا",
  },
  {
    code: 341,
    name: "Socotra",
    name_ar: "سقطرة",
  },
  {
    code: 804,
    name: "Solomon Islands",
    name_ar: "جزر سليمان",
  },
  {
    code: 205,
    name: "Somalia",
    name_ar: "الصومال",
  },
  {
    code: 411,
    name: "South Africa",
    name_ar: "جنوب افريقيا",
  },
  {
    code: 453,
    name: "South Sudan",
    name_ar: "جمهورية جنوب السودان",
  },
  {
    code: 112,
    name: "South Yemen",
    name_ar: "اليمن الجنوبي",
  },
  {
    code: 501,
    name: "Spain",
    name_ar: "اسبانيا",
  },
  {
    code: 313,
    name: "Sri Lanka",
    name_ar: "سريلانكا",
  },
  {
    code: 204,
    name: "Sudan",
    name_ar: "السودان",
  },
  {
    code: 631,
    name: "Suriname",
    name_ar: "سورينام",
  },
  {
    code: 444,
    name: "Swaziland",
    name_ar: "سويزلاند",
  },
  {
    code: 514,
    name: "Sweden",
    name_ar: "السويد",
  },
  {
    code: 515,
    name: "Switzerland",
    name_ar: "سويسرا",
  },
  {
    code: 104,
    name: "Syrian Arab Republic",
    name_ar: "سوريا",
  },
  {
    code: 314,
    name: "Taiwan, Province of China",
    name_ar: "الصين الوطنية ( تايوان )",
  },
  {
    code: 339,
    name: "Tajikistan",
    name_ar: "طاجكستان",
  },
  {
    code: 406,
    name: "Tanzania, United Republic of",
    name_ar: "تنزانيا",
  },
  {
    code: 347,
    name: "Tatarstan",
    name_ar: "تتارستان",
  },
  {
    code: 308,
    name: "Thailand",
    name_ar: "تايلاند",
  },
  {
    code: 349,
    name: "Timor-Leste",
    name_ar: "تيمور الشرقية",
  },
  {
    code: 407,
    name: "Togo",
    name_ar: "توجو",
  },
  {
    code: 707,
    name: "Tokelau",
    name_ar: "توكيلاو",
  },
  {
    code: 805,
    name: "Tonga",
    name_ar: "تونجا",
  },
  {
    code: 447,
    name: "Transkei",
    name_ar: "ترانسكي",
  },
  {
    code: 819,
    name: "Tribe BalObeid",
    name_ar: "قبيلة بالعبيد",
  },
  {
    code: 822,
    name: "Tribe Harath",
    name_ar: "قبيلة الحرث",
  },
  {
    code: 823,
    name: "Tribe Nahd",
    name_ar: "قبيلة نهد",
  },
  {
    code: 820,
    name: "Tribe Nasi",
    name_ar: "قبيلة النسي",
  },
  {
    code: 145,
    name: "Tribe Saier",
    name_ar: "قبيلة الصيعر",
  },
  {
    code: 119,
    name: "Tribes adjacent to Attvin",
    name_ar: "قبائل مجاورة للعطفين",
  },
  {
    code: 821,
    name: "Tribes adjacent to the cross",
    name_ar: "قبائل مجاورة للعبر",
  },
  {
    code: 335,
    name: "Tribes displaced",
    name_ar: "القبائل النازحة",
  },
  {
    code: 131,
    name: "Tribes, IDP / allied",
    name_ar: "قبائل نازحة/الحليفه",
  },
  {
    code: 133,
    name: "Tribes, IDP / Kuwait",
    name_ar: "قبائل نازحة/الكويت",
  },
  {
    code: 117,
    name: "Tribesmen",
    name_ar: "افراد القبائل",
  },
  {
    code: 606,
    name: "Trinidad and Tobago",
    name_ar: "ترينداد وتوباجو",
  },
  {
    code: 201,
    name: "Tunisia",
    name_ar: "تونس",
  },
  {
    code: 332,
    name: "Turkestan",
    name_ar: "تركستان",
  },
  {
    code: 309,
    name: "Turkey",
    name_ar: "تركيا",
  },
  {
    code: 338,
    name: "Turkmenistan",
    name_ar: "تركمانستان",
  },
  {
    code: 637,
    name: "Turks and Caicos Islands",
    name_ar: "جزيرة الترك  والقوقاز",
  },
  {
    code: 806,
    name: "Tuvalu",
    name_ar: "توفالو",
  },
  {
    code: 402,
    name: "Uganda",
    name_ar: "اوغندا",
  },
  {
    code: 536,
    name: "Ukraine",
    name_ar: "اوكرانيا",
  },
  {
    code: 101,
    name: "United Arab Emirates",
    name_ar: "الامارات العربية المتحدة",
  },
  {
    code: 506,
    name: "United Kingdom",
    name_ar: "المملكة المتحدة",
  },
  {
    code: 656,
    name: "United Nations",
    name_ar: "الامم المتحدة",
  },
  {
    code: 601,
    name: "United States",
    name_ar: "الولايات المتحدة",
  },
  {
    code: 632,
    name: "Uruguay",
    name_ar: "اوروجواي",
  },
  {
    code: 337,
    name: "Uzbekistan",
    name_ar: "ازبكستان",
  },
  {
    code: 807,
    name: "Vanuatu",
    name_ar: "فانيوتو",
  },
  {
    code: 533,
    name: "Vatican City",
    name_ar: "مدينة الفاتيكان",
  },
  {
    code: 448,
    name: "Venda",
    name_ar: "فيندا",
  },
  {
    code: 609,
    name: "Venezuela",
    name_ar: "فنزويلا",
  },
  {
    code: 316,
    name: "Viet Nam",
    name_ar: "فيتنام",
  },
  {
    code: 653,
    name: "Virgin Islands, U.S.",
    name_ar: "جزيرة فيرجن الامريكية",
  },
  {
    code: 808,
    name: "Western Samoa",
    name_ar: "ساموا الغربية",
  },
  {
    code: 111,
    name: "Yemen",
    name_ar: "اليمن",
  },
  {
    code: 114,
    name: "Yemeni south - sultinn",
    name_ar: "يمني جنوبي-السلاطين",
  },
  {
    code: 132,
    name: "Yemen-Lahaj",
    name_ar: "اليمن - لحج",
  },
  {
    code: 520,
    name: "Yugoslavia",
    name_ar: "يوغسلافيا",
  },
  {
    code: 416,
    name: "Zaire",
    name_ar: "زائير",
  },
  {
    code: 417,
    name: "Zambia",
    name_ar: "زامبيا",
  },
  {
    code: 415,
    name: "Zimbabwe",
    name_ar: "زيمبابوي",
  },
];
