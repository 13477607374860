import React from 'react'
import { useTranslation } from 'react-i18next'
import error404 from '../../assets/svg/Error404/error404.svg'
import { history } from "service/helpers";
import './style.scss'
import HeaderStickyMenu from 'component/common/MobileReuseable/HeaderStickyMenu';


const PageNotFound = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language
  const returnHome = () => {
    const redirectBaseURL = `${window.location.protocol}//${window.location.host}`;
    window.location.href = redirectBaseURL; // Redirects to the base URL
  };
  return (
    <>
      <HeaderStickyMenu />
      <div className='page-not-found-container'>
        <img src={error404} alt='error-404' className='error-404' />
        <div className='description'>
          <h5>{t('PAGE_NOT_FOUND.DESCRIPTION_HEADING')}</h5>
          <p>{t('PAGE_NOT_FOUND.DESCRIPTION_PARA')}</p>
        </div>
        <div className='links-container'>
          <button className='not_found_style_404' onClick={returnHome}>{t("Telemedicine.ReturnHome")}</button>
        </div>
      </div>
    </>
  )
}

export default PageNotFound