import healthIcon from "assets/svg/productsSection/health.svg";
import motorIcon from "assets/svg/productsSection/motor.svg";
import propertyIcon from "assets/svg/productsSection/property.svg";
import home from "assets/svg/home.svg";
import medmalIcon from "assets/svg/medmalIcon.svg";
import dwIcon from "assets/svg/dwIcon.svg";
import motor from "assets/images/productsSection/motor.png";
import health from "assets/images/productsSection/health.png";
// import property from "assets/images/productsSection/property.png";
import driveLogo from "assets/images/productsSection/drive-logo.png";
import driveBgImage from "assets/images/productsSection/drive-2.jpeg";
import Vitality from "assets/images/mobile/Tawuniya_Vitality.svg";
import VitalityBg from "assets/images/vitality_home.jpeg";
import { externalLinks } from "helpers/constant";
import { name } from "react-date-object/calendars/gregorian";

export const categories = [
  {
    id: "MOTOR",
    name: "Motor",
    icon: motorIcon,
    backgroundImage: motor,
    heading: "Drive with confidence",
    subHeading: "Comprehensive, valuable and unique motor insurance solutions",
  },

  {
    id: "Health",
    name: "Health & Life ",
    icon: healthIcon,
    backgroundImage: health,
    heading: "Your health is important to us",
    subHeading: "We provide the best and trusted healthcare solutions for you",
  },
  {
    id: "property",
    name: "General insurance",
    icon: propertyIcon,
    backgroundImage: "",
    heading: "For a safer life",
    subHeading: "Comprehensive risk protection for all aspects of your life!",
  },
];

export const categoriesAr = [
  {
    id: "MOTOR",
    name: "تأمين المركبات",
    icon: motorIcon,
    backgroundImage: motor,
    heading: "مارس قيادة مركبتك بثقة",
    subHeading: "حلول شاملة، قيمة وفريدة لتأمين المركبات",
  },
  {
    id: "Health",
    name: "تأمين الصحة والحياة",
    icon: healthIcon,
    backgroundImage: health,
    heading: "صحتك تهمنا",
    subHeading: "نحن نقدم لك أفضل حلول الرعاية الصحية الموثوقة",
  },
  {
    id: "property",
    name: "تأمين العام",
    icon: propertyIcon,
    backgroundImage: "",
    heading: "لحياة أكثر أمانًا",
    subHeading: "حماية شاملة من المخاطر لجميع جوانب حياتك!",
  },
];

export const products = [
  {
    category: "MOTOR",
    icon: motorIcon,
    name: "",
    brief: "360° Motor Insurance Plan for SME",
    description:
      "A program that combines the basic and extensions benefits of the comprehensive insurance (Alshamel) and third party liability insurance (Sanad) to protect the SME motor vehicles",
    url: externalLinks.Motor,
    redirect:
      "https://www.tawuniya.com.sa/corporate/products/sme/360-motor-insurance",
    visibility: {
      individual: false,
      corporate: {
        SME: true,
        enterprise: false,
      },
    },
  },
  {
    category: "MOTOR",
    icon: motorIcon,
    name: "AlShamel",
    brief: "Comprehensive motor insurance",
    description:
      "The maximum cover of AlShamel insurance is determined by the value of the insured vehicle at the time of accident, in addition to the public liability towards third party affected by the incident",
    url: externalLinks.Motor,
    redirect: "/individuals/products/motor",
    visibility: {
      individual: true,
      corporate: {
        SME: false,
        enterprise: true,
      },
    },
  },
  {
    category: "MOTOR",
    icon: motorIcon,
    name: "Third party Insurance",
    brief:
      "Third party Insurance for private motor vehicle liability insurance",
    description:
      "Covers the liability of commercial vehicles to third parties resulting from accidents up to a maximum of 10 million riyals",
    url: externalLinks.Motor,
    redirect: "/individuals/products/motor",
    visibility: {
      individual: true,
      corporate: {
        SME: false,
        enterprise: true,
      },
    },
  },
  {
    category: "MOTOR",
    name: "MOTOR placeholder",
    template: "placeholder",
    visibility: {
      individual: true,
      corporate: {
        SME: false,
        enterprise: false,
      },
    },
  },
  {
    category: "MOTOR",
    icon: motorIcon,
    name: "Third party plus Insurance",
    brief:
      "Third party plus Insurance for limited motor Comprehensive insurance",
    description:
      "Provides limited insurance cover for the damage of the insured private car as well as the liability to third parties",
    url: externalLinks.Motor,
    redirect: "/individuals/products/motor",
    visibility: {
      individual: true,
      corporate: {
        SME: false,
        enterprise: false,
      },
    },
  },
  {
    category: "MOTOR",
    icon: motorIcon,
    name: "Mechanical breakdown",
    brief: "Mechanical breakdown insurance",
    description:
      "Cover the repair of mechanical and electrical failures of the car and the wages of the necessary human resources",
    url: externalLinks.baseurl + "product/#/motor/mechanical-breakdown",
    redirect: "/individuals/products/motor/mechanical-breakdown",
    visibility: {
      individual: true,
      corporate: {
        SME: false,
        enterprise: false,
      },
    },
  },
  {
    category: "MOTOR",
    logo: driveLogo,
    backgroundImage: driveBgImage,
    template: "alternate",
    name: "Tawuniya Drive",
    brief:
      "Driving telematics program, offering weekly rewards and discounts for good driving",
    url: externalLinks.Motor,
    redirect: "/individuals/loyalty-program/drive",
    visibility: {
      individual: true,
      corporate: {
        SME: false,
        enterprise: false,
      },
    },
  },
  {
    category: "Health",
    icon: motorIcon,
    name: "360° Health Insurance",
    brief: "360° Health Insurance Plan for SME",
    description:
      "A program that combines the benefits of the medical insurance policy, Taj program for integrated health care, free membership of vitality Tawuniya and many other features",
    url: "/corporate/products/health/360-health-insurance",
    redirect: "/corporate/products/health/360-health-insurance",
    visibility: {
      individual: false,
      corporate: {
        SME: true,
        enterprise: false,
      },
    },
  },
  {
    category: "Health",
    icon: motorIcon,
    name: "Balsam",
    brief: "Balsam Cooperative health insurance",
    description:
      "integrated healthcare for employees and their family members through a wide network of accredited hospitals and medical centers",
    url: "/corporate/products/health/balsam",
    redirect: "/corporate/products/health/balsam",
    visibility: {
      individual: false,
      corporate: {
        SME: false,
        enterprise: true,
      },
    },
  },
  {
    category: "Health",
    icon: motorIcon,
    name: "Takaful",
    brief: "Employee Group Life",
    description:
      "Provides insurance coverage for employees against the death due to any cause",
    url: "/corporate/products/health/takaful",
    redirect: "/corporate/products/health/takaful",
    visibility: {
      individual: false,
      corporate: {
        SME: false,
        enterprise: true,
      },
    },
  },
  {
    category: "Health",
    icon: motorIcon,
    name: "Group Credit Life",
    brief: "Group Credit Life",
    description:
      "Provides the guarantees that financial institutions need to provide credit facilites",
    url: "https://www.tawuniya.com.sa/corporate/products/enterprise",
    redirect: "https://www.tawuniya.com.sa/corporate/products/enterprise",
    visibility: {
      individual: false,
      corporate: {
        SME: false,
        enterprise: true,
      },
    },
  },
  {
    category: "Health",
    icon: healthIcon,
    name: "My family",
    brief: "My family medical insurance",
    description:
      "Provides adequate healthcare for all family members with four categories of benefits",
    url: externalLinks.myFamily,
    redirect: "/individuals/products/health/my-family-insurance",
    visibility: {
      individual: true,
      corporate: {
        SME: false,
        enterprise: false,
      },
    },
  },
  {
    category: "Health",
    icon: healthIcon,
    name: "Visit visa medical insurance",
    brief: "Visitors insurance program",
    description:
      "The visitors to the Kingdom of Saudi Arabia will obtain the healthcare for medical emergencies or accidents",
    url: externalLinks.visitVisa,
    redirect: "/individuals/products/health/visit-visa-insurance",
    visibility: {
      individual: true,
      corporate: {
        SME: false,
        enterprise: false,
      },
    },
  },
  {
    category: "Health",
    icon: healthIcon,
    name: "Umrah health insurance",
    HideBuyNow: true,
    brief: "Umrah insurance program (for foreign pilgrims)",
    description:
      "Provides insurance coverage for medical emergency cases and general accidents for foreign Ummrah performers",
    url: "/individuals/products/health/umrah-insurance",
    redirect: "/individuals/products/health/umrah-insurance",
    visibility: {
      individual: true,
      corporate: {
        SME: false,
        enterprise: false,
      },
    },
  },
  {
    category: "Health",
    icon: healthIcon,
    name: "Hajj health insurance",
    brief: "Hajj insurance (for foreign pilgrims)",
    description:
      "Provides insurance coverage for medical emergency cases  and general accidents for foreign Hajj performers.",
    url: "/individuals/products/health/hajj-insurance",
    redirect: "/individuals/products/health/hajj-insurance",
    HideBuyNow: true,
    visibility: {
      individual: true,
      corporate: {
        SME: false,
        enterprise: false,
      },
    },
  },
  // {
  //   category: "Health",
  //   icon: healthIcon,
  //   name: "Domestic Worker Health Insurance",
  //   brief: "Domestic Worker Health Insurance",
  //   description:
  //     "Safeguards both employers and employees against legal liabilities from death, absence, disability, illness, refusal to work, or emergency situations.",
  //   url: "/individuals/products/domestic-worker-health-insurance",
  //   redirect: "/individuals/products/domestic-worker-health-insurance",
  //   HideBuyNow: true,
  //   visibility: {
  //     individual: true,
  //     corporate: {
  //       SME: false,
  //       enterprise: false,
  //     },
  //   },
  // },
  {
    category: "Health",
    logo: Vitality,
    backgroundImage: VitalityBg,
    template: "alternate",
    name: "Tawuniya vitality program",
    brief:
      "A program that improves your lifestyle and rewards you for your healthy choices",
    url: "/individuals/loyalty-program/vitality",
    redirect: "/individuals/loyalty-program/vitality",
    visibility: {
      individual: true,
      corporate: {
        SME: false,
        enterprise: false,
      },
    },
  },
  {
    category: "property",
    icon: home,
    name: "Home insurance",
    brief: "Insurance against theft, fire and additional risks",
    redirect: "/individuals/products/property-casualty/home-insurance",
    url: externalLinks.homeInsurance_v3,
    description:
      "Provides protection to residential buildings and their contents against natural disasters, and covers losses resulting from any incidents leading to the deterioration or damage to the house",
    visibility: {
      individual: true,
      corporate: {
        SME: false,
        enterprise: false,
      },
    },
  },
  {
    category: "property",
    icon: propertyIcon,
    name: "Travel insurance",
    brief: "Worldwide travel insurance",
    description:
      "Provides comprehensive coverage for travellers regarding the risks related to travel outside Saudi Arabia",
    url: externalLinks.travelInsurance_v3,
    redirect: "/individuals/products/property-casualty/international-travel",
    visibility: {
      individual: true,
      corporate: {
        SME: false,
        enterprise: false,
      },
    },
  },
  {
    category: "property",
    name: "property placeholder individual",
    template: "placeholder",
    visibility: {
      individual: true,
      corporate: {
        SME: false,
        enterprise: false,
      },
    },
  },
  {
    category: "property",
    icon: medmalIcon,
    name: "Medical malpractice insurance",
    brief: "Medical malpractice insurance",
    description:
      "Provide coverage for those practicing medical professions from the risks associated with their work and the legal third party liability that may arise out of any error, negligence, or omission incurred during the performance of their work",
    url: externalLinks.medmal_v3,
    redirect: "/individuals/products/property-casualty/medical-malpractice",
    visibility: {
      individual: true,
      corporate: {
        SME: false,
        enterprise: false,
      },
    },
  },
  // {
  //   category: "property",
  //   icon: dwIcon,
  //   name: "Domestic Worker Contract Insurance",
  //   brief: "Domestic Worker Contract Insurance",
  //   description:
  //     "Cover the costs if a domestic worker escapes or refuses to work for the employer during their contracted period, as well as compensation for unpaid wages.",
  //   url: "/individuals/products/domestic-worker-insurance",
  //   redirect: "/individuals/products/domestic-worker-insurance",
  //   HideBuyNow: true,
  //   visibility: {
  //     individual: true,
  //     corporate: {
  //       SME: false,
  //       enterprise: false,
  //     },
  //   },
  // },
  {
    category: "property",
    icon: healthIcon,
    name: "360° General insurance Program",
    brief: "Insurance against theft, fire and additional risks",
    description:
      "Provides protection to residential buildings and their contents against natural disasters, and covers losses resulting from any incidents leading to the deterioration or damage to the house",
    url: "https://www.tawuniya.com/corporate/products/health/360-property_casualty-insurance",
    redirect: "/corporate/products/health/360-property_casualty-insurance",
    visibility: {
      individual: false,
      corporate: {
        SME: true,
        enterprise: false,
      },
    },
  },
  {
    category: "property",
    icon: healthIcon,
    name: "Engineering & Contractors Insurance",
    brief: "",
    description:
      "We designed this collection of insurance products especially for the industrial and energy business to provide the appropriate protection to transact its activites safely.",
    url: "https://tawuniya.com/corporate/products/health/balsam",
    redirect: "/corporate/products/enterprise/engineering-insurance",
    visibility: {
      individual: false,
      corporate: {
        SME: false,
        enterprise: true,
      },
    },
  },
  {
    category: "property",
    icon: healthIcon,
    name: "Industrial & Energy Insurance",
    brief: "",
    description:
      "We designed this collection of insurance products especially for the industrial and energy business to provide the appropriate protection to transact its activites safely.",
    url: "https://www.tawuniya.com/corporate/products/enterprise/energy-insurance",
    redirect: "/corporate/products/enterprise/energy-insurance",
    visibility: {
      individual: false,
      corporate: {
        SME: false,
        enterprise: true,
      },
    },
  },
  {
    category: "property",
    name: "property placeholder",
    template: "placeholder",
    visibility: {
      individual: false,
      corporate: {
        SME: false,
        enterprise: true,
      },
    },
  },
  {
    category: "property",
    icon: healthIcon,
    name: "Financial & Investment Insurance",
    brief: "",
    description:
      "We designed this collection of insurance products especially for the industrial and energy business to provide the appropriate protection to transact its activites safely.",
    url: "https://www.tawuniya.com",
    redirect:
      "https://www.tawuniya.com",
    visibility: {
      individual: false,
      corporate: {
        SME: false,
        enterprise: true,
      },
    },
  },
  {
    category: "property",
    icon: healthIcon,
    name: "Retail & Trading Insurance",
    brief: "",
    description:
      "We designed this collection of insurance products especially for the industrial and energy business to provide the appropriate protection to transact its activites safely.",
    url: "https://www.tawuniya.com.sa/corporate/products/enterprise/retail-n-trading-insurance",
    redirect:
      "https://www.tawuniya.com.sa/corporate/products/enterprise/retail-n-trading-insurance",
    visibility: {
      individual: false,
      corporate: {
        SME: false,
        enterprise: true,
      },
    },
  },
  {
    category: "property",
    icon: healthIcon,
    name: "Services Insurance",
    brief: "",
    description:
      "We designed this collection of insurance products especially for the industrial and energy business to provide the appropriate protection to transact its activites safely.",
    url: "https://www.tawuniya.com",
    redirect:
      "https://www.tawuniya.com",
    visibility: {
      individual: false,
      corporate: {
        SME: false,
        enterprise: true,
      },
    },
  },
  {
    category: "property",
    icon: healthIcon,
    name: "Telecommunication & IT Insurance",
    brief: "",
    description:
      "We designed this collection of insurance products especially for the industrial and energy business to provide the appropriate protection to transact its activites safely.",
    url: "https://www.tawuniya.com",
    redirect:
      "https://www.tawuniya.com",
    visibility: {
      individual: false,
      corporate: {
        SME: false,
        enterprise: true,
      },
    },
  },
  {
    category: "property",
    icon: healthIcon,
    name: "Transportation Insurance",
    brief: "",
    description:
      "We designed this collection of insurance products especially for the industrial and energy business to provide the appropriate protection to transact its activites safely.",
    url: "https://www.tawuniya.com/individuals/products/motor",
    redirect:
      "https://www.tawuniya.com.sa/corporate/products/enterprise/telecommunication-n-it-insurance",
    visibility: {
      individual: false,
      corporate: {
        SME: false,
        enterprise: true,
      },
    },
  },
];

export const productsAr = [
  {
    category: "MOTOR",
    icon: motorIcon,
    name: "",
    brief: "خطة تأمين المركبات 360 درجة للشركات الصغيرة والمتوسطة",
    description:
      "برنامج يجمع بين المزايا الأساسية والتوسعية للتأمين الشامل (الشامل) والتأمين ضد المسؤولية تجاه الغير (ضد الغير) لحماية المركبات الصغيرة والمتوسطة الحجم",
    url: externalLinks.Motor,
    redirect: "/individuals/products/motor",
    visibility: {
      individual: false,
      corporate: {
        SME: true,
        enterprise: false,
      },
    },
  },
  {
    category: "MOTOR",
    icon: motorIcon,
    name: "التأمين الشامل ",
    brief: "برنامج التأمين الشامل على المركبات",
    description:
      "يتم تحديد الحد الأقصى للتغطية التأمينية الشاملة على أساس قيمة المركبة المؤمن عليها وقت وقوع الحادث , بالإضافة إلى المسؤولية العامة تجاه الطرف الثالث المتضرر من الحادث",

    url: externalLinks.Motor,
    redirect: "/individuals/products/motor",
    visibility: {
      individual: true,
      corporate: {
        SME: false,
        enterprise: true,
      },
    },
  },
  {
    category: "MOTOR",
    icon: motorIcon,
    name: "تأمين ضد الغير",
    brief: "ضد الغير لتأمين مسؤولية المركبات الخاصة",
    brief: "ضد الغير لتأمين مسؤولية المركبات الخاصة",
    description:
      "تغطية تصل إلى 10 مليون ريال سعودي فيما يتعلق بالإصابة الجسدية للغير وتلف الممتلكات",
    url: externalLinks.Motor,
    redirect: "/individuals/products/motor",
    visibility: {
      individual: true,
      corporate: {
        SME: false,
        enterprise: true,
      },
    },
  },
  {
    category: "MOTOR",
    name: "MOTOR placeholder",
    template: "placeholder",
    visibility: {
      individual: true,
      corporate: {
        SME: false,
        enterprise: false,
      },
    },
  },
  {
    category: "MOTOR",
    icon: motorIcon,
    name: "تأمين ضد الغير بلس",
    brief: "ضد لغير بلس للتأمين الشامل على المركبات",
    brief: "ضد لغير بلس للتأمين الشامل على المركبات",
    description:
      "يوفر تغطية تأمينية محدودة للأضرار التي تلحق بالمركبات الخاصة المؤمن عليها وكذلك المسؤولية تجاه الأطراف الثالثة",
    url: externalLinks.Motor,
    redirect: "/individuals/products/motor",
    visibility: {
      individual: true,
      corporate: {
        SME: false,
        enterprise: false,
      },
    },
  },
  {
    category: "MOTOR",
    icon: motorIcon,
    name: "تأمين الأعطال  الميكانيكية",
    brief: "تأمين الأعطال  الميكانيكية",
    description:
      "تغطية إصلاح الأعطال الميكانيكية والكهربائية للمركبة وأجور الكوادر البشرية اللازمة",
    url: externalLinks.baseurl + "product/#/motor/mechanical-breakdown",
    redirect: "/individuals/products/mechanical-breakdown",
    visibility: {
      individual: true,
      corporate: {
        SME: false,
        enterprise: false,
      },
    },
  },
  {
    category: "MOTOR",
    logo: driveLogo,
    backgroundImage: driveBgImage,
    template: "alternate",
    name: " برنامج التعاونية للقيادة",
    brief:
      "يقيس سلوكك في القيادة ويكافئك أسبوعيًا , بالإضافة إلى خصم خاص على تأمين مركبتك",
    url: externalLinks.Motor,
    redirect: "/individuals/loyalty-program/drive",
    visibility: {
      individual: true,
      corporate: {
        SME: false,
        enterprise: false,
      },
    },
  },
  {
    category: "Health",
    icon: motorIcon,
    name: "برنامج 360° للتأمين الصحي",
    brief: "خطة التأمين الصحي 360 درجة للشركات الصغيرة والمتوسطة",
    description:
      "برنامج يجمع بين مزايا وثيقة التأمين الصحي وبرنامج تاج للرعاية الصحية المتكاملة والعضوية المجانية لحيوية التعاونية والعديد من الميزات الأخرى",
    url: "/corporate/products/health/360-health-insurance",
    redirect: "/corporate/products/health/360-health-insurance",
    visibility: {
      individual: false,
      corporate: {
        SME: true,
        enterprise: false,
      },
    },
  },
  {
    category: "Health",
    icon: motorIcon,
    name: "بلسم",
    brief: "التأمين الصحي التعاوني بلسم",
    description:
      "رعاية صحية متكاملة للموظفين وأفراد أسرهم من خلال شبكة واسعة من المستشفيات والمراكز الطبية المعتمدة",
    url: "/corporate/products/health/balsam",
    redirect: "/corporate/products/health/balsam",
    visibility: {
      individual: false,
      corporate: {
        SME: false,
        enterprise: true,
      },
    },
  },
  {
    category: "Health",
    icon: motorIcon,
    name: "تكافل",
    brief: "حياة مجموعة الموظف",
    description: "يوفر تغطية تأمينية للموظفين ضد الوفاة لأي سبب",
    url: "/corporate/products/health/takaful",
    redirect: "/corporate/products/health/takaful",
    visibility: {
      individual: false,
      corporate: {
        SME: false,
        enterprise: true,
      },
    },
  },
  {
    category: "Health",
    icon: motorIcon,
    name: "حياة ائتمان المجموعة",
    brief: "الحياة الائتمانية الجماعية",
    description:
      "يوفر الضمانات التي تحتاجها المؤسسات المالية لتوفير التسهيلات الائتمانية",
    url: "https://www.tawuniya.com.sa/corporate/products/enterprise",
    redirect: "https://www.tawuniya.com.sa/corporate/products/enterprise",
    visibility: {
      individual: false,
      corporate: {
        SME: false,
        enterprise: true,
      },
    },
  },

  {
    category: "Health",
    icon: healthIcon,
    name: "تأمين عائلتي",
    brief: "تأمين عائلتي الطبي",
    description:
      "يوفر رعاية صحية مناسبة لجميع أفراد الأسرة مع أربع فئات من المزايا",
    url: "/individuals/products/property-casualty/home-insurance",
    redirect: "/individuals/products/property-casualty/home-insurance",
    visibility: {
      individual: true,
      corporate: {
        SME: false,
        enterprise: false,
      },
    },
  },
  {
    category: "Health",
    icon: healthIcon,
    name: "تأمين تأشيرة الزيارة الطبي",
    brief: "برنامج تأمين الزوار",
    description:
      "سيحصل زوار المملكة العربية السعودية على الرعاية الصحية لحالات الطوارئ الطبية أو الحوادث",
    url: "/individuals/products/health/visit-visa-insurance",
    redirect: "/individuals/products/health/visit-visa-insurance",
    visibility: {
      individual: true,
      corporate: {
        SME: false,
        enterprise: false,
      },
    },
  },
  {
    category: "Health",
    icon: healthIcon,
    name: "برنامج تأمين العمرة",
    brief: "تأمين صحي للمعتمرين الأجانب",
    description:
      "يوفر تغطية تأمينية للحالات الطبية الطارئة والحوادث العامة لأداء العمرة الأجانب",
    url: "/individuals/products/health/umrah-insurance",
    redirect: "/individuals/products/health/umrah-insurance",
    HideBuyNow: true,
    visibility: {
      individual: true,
      corporate: {
        SME: false,
        enterprise: false,
      },
    },
  },
  {
    category: "Health",
    icon: healthIcon,
    name: "برنامج تأمين الحج",
    brief: "تأمين صحي للحجاج الأجانب",
    description:
      "يوفر تغطية تأمينية للحالات الطبية الطارئة والحوادث العامة لأداء الحج من الخارج.",
    url: "/individuals/products/health/hajj-insurance",
    redirect: "/individuals/products/health/hajj-insurance",
    HideBuyNow: true,
    visibility: {
      individual: true,
      corporate: {
        SME: false,
        enterprise: false,
      },
    },
  },
  // {
  //   category: "Health",
  //   icon: healthIcon,
  //   name: "تأمين العمالة المنزلية",
  //   brief: "تأمين العمالة المنزلية",
  //   description:
  //     "يحمي كلاً من أصحاب العمل والموظفين من المسؤوليات القانونية الناتجة عن الوفاة، أو الغياب، أو الإعاقة، أو المرض، أو رفض العمل، أو المواقف الطارئة",
  //   url: "/individuals/products/domestic-worker-health-insurance",
  //   redirect: "/individuals/products/domestic-worker-health-insurance",
  //   HideBuyNow: true,
  //   visibility: {
  //     individual: true,
  //     corporate: {
  //       SME: false,
  //       enterprise: false,
  //     },
  //   },
  // },
  {
    category: "Health",
    logo: Vitality,
    backgroundImage: VitalityBg,
    template: "alternate",
    name: "برنامج التعاونية  فيتالي",
    brief: " برنامج لتحسين نمط حياتك ويكافئك على عاداتك الصحية ",
    url: externalLinks.Motor,
    redirect: "/individuals/loyalty-program/vitality",
    visibility: {
      individual: true,
      corporate: {
        SME: false,
        enterprise: false,
      },
    },
  },
  {
    category: "property",
    icon: home,
    name: "تأمين على المساكن",
    brief: "تأمين على المساكن",
    redirect: "/individuals/products/property-casualty/home-insurance",
    url: externalLinks.homeInsurance_v3,
    description:
      "يوفر الحماية للمباني السكنية ومحتوياتها من الكوارث الطبيعية ، ويغطي الخسائر الناتجة عن أي حوادث تؤدي إلى تدهور المنزل أو إتلافه",
    visibility: {
      individual: true,
      corporate: {
        SME: false,
        enterprise: false,
      },
    },
  },
  {
    category: "property",
    icon: propertyIcon,
    name: "تأمين السفر",
    brief: "تأمين السفر لأوروبا وبقية دول العالم",
    description:
      "يوفر تغطية شاملة للمسافرين فيما يتعلق بالمخاطر المتعلقة بالسفر خارج المملكة العربية السعودية",
    url: "/individuals/products/property-casualty/international-travel",
    redirect: "/individuals/products/property-casualty/international-travel",
    visibility: {
      individual: true,
      corporate: {
        SME: false,
        enterprise: false,
      },
    },
  },
  {
    category: "property",
    name: "property placeholder individual",
    template: "placeholder",
    visibility: {
      individual: true,
      corporate: {
        SME: false,
        enterprise: false,
      },
    },
  },
  {
    category: "property",
    icon: medmalIcon,
    name: "تأمين أخطاء ممارسة المهن الطبية",
    brief: "تأمين أخطاء ممارسة المهن الطبية",
    description:
      "توفير تغطية أخطاء ممارسة المهن الطبية من المخاطر المرتبطة بعملهم والمسؤولية القانونية تجاه الغير التي قد تنشأ عن أي خطأ أو إهمال أو إغفال يتكبدونه أثناء أداء عملهم",
    url: "/individuals/products/property-casualty/medical-malpractice",
    redirect: "/individuals/products/property-casualty/medical-malpractice",
    visibility: {
      individual: true,
      corporate: {
        SME: false,
        enterprise: false,
      },
    },
  },
  // {
  //   category: "property",
  //   icon: dwIcon,
  //   name: "تأمين عقد العمالة المنزلية",
  //   brief: "تأمين عقد العمالة المنزلية",
  //   description:
  //     "Cover the costs if a domestic worker escapes or refuses to work for the employer during their contracted period, as well as compensation for unpaid wages.",
  //   url: "/individuals/products/domestic-worker-insurance",
  //   redirect: "/individuals/products/domestic-worker-insurance",
  //   HideBuyNow: true,
  //   visibility: {
  //     individual: true,
  //     corporate: {
  //       SME: false,
  //       enterprise: false,
  //     },
  //   },
  // },

  {
    category: "property",
    icon: healthIcon,
    name: "برنامج 360° درجة للممتلكات والإصابات",
    brief: "التأمين ضد السرقة والحريق والمخاطر الإضافية",
    description:
      "يوفر الحماية للمباني السكنية ومحتوياتها من الكوارث الطبيعية , ويغطي الخسائر الناتجة عن أي حوادث تؤدي إلى تدهور المنزل أو إتلافه",
    url: "https://www.tawuniya.com/corporate/products/health/360-property_casualty-insurance",
    redirect: "/corporate/products/health/360-property_casualty-insurance",
    visibility: {
      individual: false,
      corporate: {
        SME: true,
        enterprise: false,
      },
    },
  },
  {
    category: "property",
    icon: healthIcon,
    name: " تأمين المهندسين والمقاولين",
    brief: "",
    description:
      "لقد صممنا هذه المجموعة من منتجات التأمين خاصة للأعمال الصناعية والطاقة لتوفير الحماية المناسبة لممارسة أنشطتها بأمان.",

    url: "https://www.tawuniya.com.sa/ar/corporate/products/enterprise/balsam",
    redirect: "/corporate/products/enterprise/engineering-insurance",

    visibility: {
      individual: false,
      corporate: {
        SME: false,
        enterprise: true,
      },
    },
  },
  {
    category: "property",
    icon: healthIcon,
    name: "التأمين الصناعي والطاقة",
    brief: "",
    description:
      "لقد صممنا هذه المجموعة من منتجات التأمين خاصة للأعمال الصناعية والطاقة لتوفير الحماية المناسبة لممارسة أنشطتها بأمان.",
    url: "https://www.tawuniya.com.sa/ar/corporate/products/enterprise/industrial-n-energy-insurance",
    redirect: "/corporate/products/enterprise/energy-insurance",
    visibility: {
      individual: false,
      corporate: {
        SME: false,
        enterprise: true,
      },
    },
  },
  {
    category: "property",
    name: "property placeholder",
    template: "placeholder",
    visibility: {
      individual: false,
      corporate: {
        SME: false,
        enterprise: true,
      },
    },
  },

  //  {
  //   category: "property",
  //   icon: healthIcon,
  //   name: "التأمين المالي والاستثماري",
  //   brief: "",
  //   description:
  //    "لقد صممنا هذه المجموعة من منتجات التأمين خاصة للأعمال الصناعية والطاقة لتوفير الحماية المناسبة لممارسة أنشطتها بأمان.",
  //   url: "https://beta.tawuniya.com.sa/web/motor",
  //   visibility: {
  //    individual: false,
  //    corporate: {
  //     SME: false,
  //     enterprise: true,
  //    },
  //   },
  //  },
  //  {
  //   category: "property",
  //   icon: healthIcon,
  //   name: "التجزئة والتأمين التجاري",
  //   brief: "",
  //   description:
  //    "لقد صممنا هذه المجموعة من منتجات التأمين خاصة للأعمال الصناعية والطاقة لتوفير الحماية المناسبة لممارسة أنشطتها بأمان.",
  //   url: "https://beta.tawuniya.com.sa/web/motor",
  //   visibility: {
  //    individual: false,
  //    corporate: {
  //     SME: false,
  //     enterprise: true,
  //    },
  //   },
  //  },
  {
    category: "property",
    icon: healthIcon,
    name: " تأمين الخدمات",
    brief: "",
    description:
      "لقد صممنا هذه المجموعة من منتجات التأمين خاصة للأعمال الصناعية والطاقة لتوفير الحماية المناسبة لممارسة أنشطتها بأمان.",
    url: "https://www.tawuniya.com.sa/ar/corporate/products/enterprise/financial-n-investment-insurance",
    redirect:
      "https://www.tawuniya.com.sa/ar/corporate/products/enterprise/financial-n-investment-insurance",
    visibility: {
      individual: false,
      corporate: {
        SME: false,
        enterprise: true,
      },
    },
  },
  {
    category: "property",
    icon: healthIcon,
    name: "تأمين الاتصالات وتكنولوجيا المعلومات",
    brief: "",
    description:
      "لقد صممنا هذه المجموعة من منتجات التأمين خاصة للأعمال الصناعية والطاقة لتوفير الحماية المناسبة لممارسة أنشطتها بأمان.",
    url: "https://www.tawuniya.com",
    redirect:
      "https://www.tawuniya.com",
    visibility: {
      individual: false,
      corporate: {
        SME: false,
        enterprise: true,
      },
    },
  },
  {
    category: "property",
    icon: healthIcon,
    name: "تأمين النقل",
    brief: "",
    description:
      "لقد صممنا هذه المجموعة من منتجات التأمين خاصة للأعمال الصناعية والطاقة لتوفير الحماية المناسبة لممارسة أنشطتها بأمان.",
    url: "https://www.tawuniya.com",
    redirect:
      "https://www.tawuniya.com",
    visibility: {
      individual: false,
      corporate: {
        SME: false,
        enterprise: true,
      },
    },
  },
];