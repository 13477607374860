import React from "react";
import { useTranslation } from "react-i18next";
import "./style.scss";

import social_media_fb from "assets/images/mobile/social_media_fb.svg";
import social_media_instagram from "assets/images/mobile/social_media_instagram.svg";
import social_media_linkin from "assets/images/mobile/social_media_linkin.svg";
import social_media_twitter from "assets/images/mobile/social_media_twitter.svg";
import social_media_youtube from "assets/images/mobile/social_media_youtube.svg";
import social_media_whastsapp from "assets/images/mobile/social_media_whastsapp.svg";
import footer_tawuniya_logo from "assets/images/mobile/footer_tawuniya_logo.svg";
import { useHistory } from "react-router-dom";
// import { history } from "service/helpers";
import { LandingSiteFilesLink } from "../../../../helpers/constant";
import { FooterCard } from "component/HomePage/LandingComponent/NavbarCards";

const FooterMobile = () => {
  const { i18n, t } = useTranslation();
  const history = useHistory();

  let pathArray = history.location.pathname.split("/");
  let currentPage = pathArray[pathArray.length - 2];
  let mediaCenterPage = pathArray[pathArray.length - 1];
  const navigateBottomPage = (routeURL) => {
    routeURL && window.location.assign(routeURL);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  const handleDownloadFile = () => {
    if (i18n.language == "ar") {
      window.open(LandingSiteFilesLink.CustomerProtectionFilear);
    } else {
      window.open(LandingSiteFilesLink.CustomerProtectionFile);
    }
  };
  const trackEvent = (tabClicked) => {
    window.lmSMTObj.track(tabClicked);
  };
  return (
    <>
      <div className="footer_container_home">
        <div className="follow_as_section">
          <h6 className="text-uppercase mb-2 pr-1">{t("follow_us")}</h6>
          <div className="footer-social-icons d-flex">
            <img
              className="twitter-img"
              onClick={(e) => {
                window.open("https://mobile.twitter.com/tawuniya");
                trackEvent("TwitterClicked");
              }}
              src={social_media_twitter}
              alt="Twitter"
            />
            <img
              onClick={(e) => {
                window.open("https://m.facebook.com/Tawuniya");
                trackEvent("FaceBookClicked");
              }}
              src={social_media_fb}
              alt="facebook"
            />
            <img
              onClick={(e) => {
                window.open("https://youtube.com/c/tawuniya");
                trackEvent("YoutubeClicked");
              }}
              src={social_media_youtube}
              alt="youtube"
            />
            <img
              onClick={(e) => {
                window.open("https://www.linkedin.com/company/tawuniya/");
                trackEvent("LinkedInClicked");
              }}
              src={social_media_linkin}
              alt="linkedin"
            />
            <img
              onClick={(e) => {
                window.open(
                  "https://instagram.com/tawuniya?igshid=YzAyZWRlMzg="
                );
                trackEvent("InstagramClicked");
              }}
              src={social_media_instagram}
              alt="instagram"
            />
            <img
              onClick={(e) => {
                window.open("https://wa.me/+966920019990");
                trackEvent("WhatsAppClicked");
              }}
              src={social_media_whastsapp}
              alt="wa"
            />
          </div>
        </div>

      <div className="footer_bottom_section row">
        <div className="col-6">
          <p onClick={() => {history.push("/privacy-policy");trackEvent(t("Privacy_Policy")+"Clicked")}}>{t("Privacy_Policy")}</p>
          <a
            title={t("footer.insurance_activites")}
            className="fs-12 fw-400 footerInlineText"
            href="https://tawuniya.com/docs/Online_Insurance_Activities_Regulation.pdf"
            target="_blank"
            rel="noreferrer"
            onClick={() => {
              trackEvent(t("footer.insurance_activites") + "TabClicked")
            }}
          >
            {t("footer.insurance_activites")}
          </a>
        </div>
        <div className="col-6">
          <p
            onClick={() => {history.push("/tawuniya-auction");trackEvent(t("Tawuniya_Auctions")+"Clicked")}}
          >
            {t("Tawuniya_Auctions")}
          </p>
          <p className="fs-12 fw-400 footerInlineText" onClick={()  => {handleDownloadFile();trackEvent(t("footer.link2")+"Clicked")}}>
            {t("footer.link2")}
          </p>
          {/* <p
            onClick={(e) => {
              window.open(
                "http://www.sama.gov.sa/ar-sa/Laws/ConsumerProtectionRules/%D9%85%D8%A8%D8%A7%D8%AF%D8%A6%20%D8%AD%D9%85%D8%A7%D9%8A%D8%A9%20%D8%B9%D9%85%D9%84%D8%A7%D8%A1%20%D8%B4%D8%B1%D9%83%D8%A7%D8%AA%20%D8%A7%D9%84%D8%AA%D8%A3%D9%85%D9%8A%D9%86.pdf#search=%D9%85%D8%A8%D8%A7%D8%AF%D8%A6%20%D8%AD%D9%85%D8%A7%D9%8A%D8%A9%20%D8%B9%D9%85%D9%84%D8%A7%D8%A1%20%D8%B4%D8%B1%D9%83%D8%A7%D8%AA%20%D8%A7%D9%84%D8%AA%D8%A3%D9%85%D9%8A%D9%86"
              );
            }}
          >
            {t("TawuConsumer_Protection_Rules_And_Instructionsniya_Auctions")}
          </p>
          <p
            onClick={(e) => {
              window.open(
                "http://www.sama.gov.sa/en-US/Laws/InsuranceRulesAndRegulations/IIR_4600_%E2%80%8EOnline_IEN.pdf#search=Online%20Insurance%20Activities%20Regulation"
              );
            }}
          >
            {t("Online_Insurance_Activities_Regulation")}
          </p> */}
            <p
              onClick={(e) => {
                window.open("https://www.tawuniya.com/");
                trackEvent(t("Committee_of_Insurance_Disputes") + "Clicked");
              }}
            >
              {t("Committee_of_Insurance_Disputes")}
            </p>
          </div>
        </div>
        <div className="copy_right_section  pb-5">
          <img src={footer_tawuniya_logo} alt="Plus" />
          <label> {t("copy_right")}</label>
        </div>
      </div>
    </>
  );
};

export default FooterMobile;
