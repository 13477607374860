import HomeMenu from "assets/svg/home_subMenu.svg";
import HeartIcon from "assets/svg/medicalMarpractice.svg";
import motor_blue from "assets/svg/motor/motor_blue.png";
import { ReactComponent as PrintIcon } from "assets/svg/print_icon.svg";
import { ReactComponent as ShareIcon } from "assets/svg/share_icon_menu.svg";
import TravelInsuranceIcon from "assets/svg/trave_insurance_icon.svg";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { getI18n, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { externalLinks } from "../../../helpers/constant";
import "./subMenu.scss";

const ExtraHeaderDesktop = ({ title }) => {
  let history = useHistory();
  let pageLocation = history.location.pathname;
  const { url } = useParams();
  const [menu, setMenu] = useState({});
  const [menuActivation, setMenuActivation] = useState(false);
  const { t, i18n } = useTranslation();
  const currentLanguage = getI18n().language;
  // const serviceURL = useSelector(state => state.serviceURL);
  const serviceURL = useSelector((state) => state.serviceInsuranceReducer.serviceUrl);


  const executeScroll = (elementId, padding = 50) => {
    const element = document.getElementById(elementId);

    if (element) {
      const elementRect = element.getBoundingClientRect();
      const offset = elementRect.top - padding;

      window.scrollTo({
        top: offset,
        behavior: 'smooth', // You can change this to 'auto' for instant scrolling without animation.
        block: 'start', // 'start', 'center', 'end', or 'nearest'
      });
    }
  };

  const handleShare = async () => {
    try {
      await navigator.share({
        title: 'Tawuniya',
        text: 'Check out this awesome website!',
        url: window.location.href,

      });
      console.log('Successfully shared.');
    } catch (error) {
      console.error('Error sharing:', error);
    }
  };

  let ExtraMotorInsuranceMenu = {
    url: "/individuals/products/motor",
    heading: t("subHeadersData.motorHeader.key1"),
    headIcon: motor_blue,
    buttonText: t("subHeadersData.motorHeader.buttonText"),
    buttonLink:
      currentLanguage === "ar"
        ? externalLinks.Motor + "?lang=ar"
        : externalLinks.Motor + "?lang=en",
    links: [
      {
        menuItem: t("subHeadersData.motorHeader.key2"),
        menuLink: "motorPlans",
      },
      {
        menuItem: t("subHeadersData.motorHeader.key3"),
        menuLink: "motorFeature",
      },
      {
        menuItem: t("subHeadersData.motorHeader.key4"),
        menuLink: "motorService",
      },
      {
        menuItem: t("subHeadersData.motorHeader.key5"),
        menuLink: "motorFaq",
      },
    ],
  };

  let ExtraCBODMenu = {
    url: "/individuals/products/motor/cross-border-own-damage",
    heading: t("subHeadersData.motorHeader.key1"),
    headIcon: motor_blue,
    links: [
      {
        menuItem: t("subHeadersData.CBODHeader.key1"),
        menuLink: "CBODOverview",
      },
      {
        menuItem: t("subHeadersData.CBODHeader.key2"),
        menuLink: "CBODBenifits",
      },
      {
        menuItem: t("subHeadersData.CBODHeader.key3"),
        menuLink: "CBODFaq",
      },
    ],
  };

  let ExtraMobiityEcosystemMenu = {
    url: "/individuals/products/motor/mobility-ecosystem",
    heading: t("subHeadersData.motorHeader.key1"),
    headIcon: motor_blue,
    links: [],
  };

  let homeInsurance = {
    url: "/individuals/products/property-casualty/home-insurance",
    heading: t("subHeadersData.homeInsuranceHeader.key1"),
    headIcon: HomeMenu,
    buttonText: t("subHeadersData.homeInsuranceHeader.buttonText"),
    buttonLink: externalLinks.homeInsurance_v3+`?lang=${currentLanguage}`,
    links: [
      {
        menuItem: t("subHeadersData.homeInsuranceHeader.key2"),
        menuLink: "homeOverview",
      },
      {
        menuItem: t("subHeadersData.homeInsuranceHeader.key3"),
        menuLink: "homeCoverage",
      },
      {
        menuItem: t("subHeadersData.homeInsuranceHeader.key4"),
        menuLink: "homeAdvantage",
      },
      {
        menuItem: t("subHeadersData.homeInsuranceHeader.key5"),
        menuLink: "homeFaq",
      },
    ],
  };

  let ExtrainternationaltravelMenu = {
    url: "/individuals/products/property-casualty/international-travel",
    heading: t("subHeadersData.travelHeader.key1"),
    headIcon: TravelInsuranceIcon,
    buttonText: t("subHeadersData.travelHeader.buttonText"),
    buttonLink: externalLinks.travelInsurance_v3+`?lang=${currentLanguage}`,
    links: [
      {
        menuItem: t("subHeadersData.travelHeader.key2"),
        menuLink: "travelOverview",
      },
      {
        menuItem: t("subHeadersData.travelHeader.key3"),
        menuLink: "travelPlanID",
      },
      {
        menuItem: t("subHeadersData.travelHeader.key4"),
        menuLink: "travelAdvantageId",
      },
      {
        menuItem: t("subHeadersData.travelHeader.key5"),
        menuLink: "travelTermID",
      },
      {
        menuItem: t("subHeadersData.travelHeader.key6"),
        menuLink: "travelFAQID",
      },
    ],
  };

  let medicalmalpractice = {
    url: "/individuals/products/property-casualty/medical-malpractice",
    heading: t("subHeadersData.medicalMalHeader.key1"),
    headIcon: HeartIcon,
    buttonText: t("subHeadersData.medicalMalHeader.buttonText"),
    buttonLink: externalLinks.medmal_v3+`?lang=${currentLanguage}`,
    links: [
      {
        menuItem: t("subHeadersData.medicalMalHeader.key2"),
        menuLink: "medicalMarPracticeID",
      },
      {
        menuItem: t("subHeadersData.medicalMalHeader.key3"),
        menuLink: "medicalPersonEligibility",
      },
      {
        menuItem: t("subHeadersData.medicalMalHeader.key4"),
        menuLink: "medicalAdvantages",
      },
      {
        menuItem: t("subHeadersData.medicalMalHeader.key5"),
        menuLink: "medicalBenefits",
      },
      {
        menuItem: t("subHeadersData.medicalMalHeader.key6"),
        menuLink: "medicalFAQ",
      },
    ],
  };

  let healthinsurancevisitvisa = {
    url: "/individuals/products/health/visit-visa-insurance",
    heading: t("subHeadersData.visitVisaHeader.key1"),
    headIcon: HeartIcon,
    buttonText: t("subHeadersData.visitVisaHeader.buttonText"),
    buttonLink: "",
    links: [
      {
        menuItem: t("subHeadersData.visitVisaHeader.key2"),
        menuLink: "visitVisaOverview",
      },
      {
        menuItem: t("subHeadersData.visitVisaHeader.key3"),
        menuLink: "visitVisaPlan",
      },
      {
        menuItem: t("subHeadersData.visitVisaHeader.key4"),
        menuLink: "visitVisaBenefit",
      },
      // {
      //     menuItem: t('subHeadersData.visitVisaHeader.key5'),
      //     menuLink: 'visitVisaLoyaltyProgram',
      // },
      {
        menuItem: t("subHeadersData.visitVisaHeader.key6"),
        menuLink: "visitVisaFAQ",
      },
    ],
  };

  let healthinsurancemyfamily = {
    url: "/individuals/products/health/my-family-insurance",
    heading: t("subHeadersData.myFamilyHeader.key1"),
    headIcon: HeartIcon,
    buttonText: t("subHeadersData.medicalMalHeader.buttonText"),
    buttonLink: "",
    links: [
      {
        menuItem: t("subHeadersData.myFamilyHeader.key2"),
        menuLink: "familyOverView",
      },
      {
        menuItem: t("subHeadersData.myFamilyHeader.key3"),
        menuLink: "familyPlan",
      },
      {
        menuItem: t("subHeadersData.myFamilyHeader.key4"),
        menuLink: "familyTerm",
      },
      {
        menuItem: t("subHeadersData.myFamilyHeader.key5"),
        menuLink: "familyFAQ",
      },
    ],
  };

  let hajjInsuranceMenuItems = {
    url: "/individuals/products/health/hajj-insurance",
    heading: t("subHeadersData.hajjUmrahHeader.key1"),
    headIcon: HeartIcon,
    buttonText: t("subHeadersData.visitVisaHeader.buttonText"),
    buttonLink: "",
    links: [
      {
        menuItem: t("subHeadersData.hajjUmrahHeader.key2"),
        menuLink: "hajjOverview",
      },
      {
        menuItem: t("subHeadersData.hajjUmrahHeader.key3"),
        menuLink: "hajjWhy",
      },
      {
        menuItem: t("subHeadersData.hajjUmrahHeader.key4"),
        menuLink: "hajjFeature",
      },
      {
        menuItem: t("subHeadersData.hajjUmrahHeader.key5"),
        menuLink: "hajjBenefit",
      },
      {
        menuItem: t("subHeadersData.hajjUmrahHeader.key6"),
        menuLink: "hajjServices",
      },
    ],
  };

  let umrahInsuranceMenuItems = {
    url: "/individuals/products/health/hajj-insurance",
    heading: t("subHeadersData.hajjUmrahHeader.key1"),
    headIcon: HeartIcon,
    buttonText: t("subHeadersData.visitVisaHeader.buttonText"),
    buttonLink: "",
    links: [
      {
        menuItem: t("subHeadersData.hajjUmrahHeader.key2"),
        menuLink: "hajjOverview",
      },
      {
        menuItem: t("subHeadersData.hajjUmrahHeader.key3"),
        menuLink: "hajjWhy",
      },
      {
        menuItem: t("subHeadersData.hajjUmrahHeader.key4"),
        menuLink: "hajjFeature",
      },
      {
        menuItem: t("subHeadersData.hajjUmrahHeader.key5"),
        menuLink: "hajjBenefit",
      },
      {
        menuItem: t("subHeadersData.hajjUmrahHeader.key6"),
        menuLink: "hajjServices",
      },
    ],
  };

  let threeSixtyPAndCMenu = {
    url: "/corporate/products/health/360-property_casualty-insurance",
    heading: t("subHeadersData.threeSixtyPropertyCasuality.key1"),
    headIcon: HeartIcon,
    buttonText: t("subHeadersData.visitVisaHeader.buttonText"),
    buttonLink: '',
    links: [
      {
        menuItem: t("subHeadersData.threeSixtyPropertyCasuality.key2"),
        menuLink: "threeSixtyOverview",
      },
      {
        menuItem: t("subHeadersData.threeSixtyPropertyCasuality.key3"),
        menuLink: "threeSixtyCoverage",
      },
      {
        menuItem: t("subHeadersData.threeSixtyPropertyCasuality.key4"),
        menuLink: "threeSixtyBeneficiaries",
      },
      {
        menuItem: t("subHeadersData.threeSixtyPropertyCasuality.key5"),
        menuLink: "threeSixtyWhyTawuniya",
      },
      {
        menuItem: t("subHeadersData.threeSixtyPropertyCasuality.key6"),
        menuLink: "threeSixtyFAQ",
      },
    ],
  };

  let threeSixtyHealthInsurance = {
    url: "/corporate/products/health/360-health-insurance",
    heading: t("subHeadersData.healthInsuranceSME.key1"),
    headIcon: HeartIcon,
    buttonText: t("subHeadersData.healthInsuranceSME.buttonText"),
    buttonLink:
      currentLanguage === "ar" ? externalLinks.smelinkar : externalLinks.smeLink,
    links: [
      {
        menuItem: t("subHeadersData.healthInsuranceSME.key2"),
        menuLink: "healthSMEOverview",
      },
      {
        menuItem: t("subHeadersData.healthInsuranceSME.key3"),
        menuLink: "healthSMEPlans",
      },
      {
        menuItem: t("subHeadersData.visitVisaHeader.key5"),
        menuLink: "healthSMEWhyTawuniya",
      },
      {
        menuItem: t("subHeadersData.healthInsuranceSME.key4"),
        menuLink: "healthSMEAVS",
      },
      {
        menuItem: t("subHeadersData.healthInsuranceSME.key5"),
        menuLink: "healthSMEAVS",
      },
      {
        menuItem: t("subHeadersData.healthInsuranceSME.key6"),
        menuLink: "healthSMEBenefit",
      },
    ],
  };

  let balsamHealthInsurance = {
    url: "/corporate/products/health/balsam",
    heading: t("subHeadersData.balsam.key1"),
    headIcon: HeartIcon,
    buttonText: t("subHeadersData.balsam.buttonText"),
    links: [
      {
        menuItem: t("subHeadersData.balsam.key2"),
        menuLink: "balsamOverview",
      },
      {
        menuItem: t("subHeadersData.balsam.key3"),
        menuLink: "balsamPlans",
      },
      {
        menuItem: t("subHeadersData.balsam.key4"),
        menuLink: "balsamAVS",
      },
      {
        menuItem: t("subHeadersData.balsam.key5"),
        menuLink: "balsamWhyTawuniya",
      },
      {
        menuItem: t("subHeadersData.balsam.key6"),
        menuLink: "balsamBenefit",
      },
      // {
      //   menuItem: t("subHeadersData.balsam.key7"),
      //   menuLink: "balsamFAQ",
      // },
    ],
  };

  let takafulHealthInsurance = {
    url: "/corporate/products/health/takaful",
    heading: t("subHeadersData.takaful.key1"),
    headIcon: HeartIcon,
    buttonText: t("subHeadersData.takaful.buttonText"),
    buttonLink: "/contactus",
    links: [
      {
        menuItem: t("subHeadersData.takaful.key2"),
        menuLink: "takafulOverview",
      },
      {
        menuItem: t("subHeadersData.takaful.key3"),
        menuLink: "takafulGroup",
      },
      {
        menuItem: t("subHeadersData.takaful.key4"),
        menuLink: "takafulGroupCredit",
      },
      {
        menuItem: t("subHeadersData.takaful.key5"),
        menuLink: "takafulWafa",
      },
      {
        menuItem: t("subHeadersData.takaful.key6"),
        menuLink: "takafulFAQ",
      },
    ],
  };

  let domesticWorkerHealth = {
    url: "/individuals/products/domestic-worker-health-insurance",
    heading: t("subHeadersData.domestic.key1"),
    headIcon: HeartIcon,
    buttonText: t("subHeadersData.domestic.buttonText"),
    buttonLink: externalLinks.domesticWorker_v3 + '?lang=' + i18n.language,
    links: [
      {
        menuItem: t("subHeadersData.domestic.key2"),
        menuLink: "domesticPlan",
      },
      {
        menuItem: t("subHeadersData.domestic.key3"),
        menuLink: "domesticFaq",
      },

    ],
  };

  let parentHealth = {
    url: "/individuals/products/Parent-health-insurance",
    heading: t("Parent.banners.ParentInsuranceSME.key1"),
    headIcon: HeartIcon,
    buttonText: t("Parent.banners.ParentInsuranceSME.buttonText"),
    buttonLink:
      currentLanguage === "ar" ? externalLinks.ParentHealth : externalLinks.ParentHealth,
    links: [
      {
        menuItem: t("Parent.banners.ParentInsuranceSME.key2"),
        menuLink: "ParentSMEOverview",
      },
      {
        menuItem: t("Parent.banners.ParentInsuranceSME.key3"),
        menuLink: "healthSMEPlans",
      },   {
        menuItem: t("Parent.banners.ParentInsuranceSME.key6"),
        menuLink: "healthSMEBenefit",
      }
   
    ],
 
  };


  
  //for all property pagesg
  let serviceInsurance = {
    url: `/products/enterprise/${serviceURL}`,
    heading: t("subHeadersData.engineeringAndInsurance.key1"),
    headIcon: HeartIcon,
    buttonText: t("subHeadersData.takaful.buttonText"),
    links: [
      {
        menuItem: t("subHeadersData.engineeringAndInsurance.key2"),
        menuLink: "takafulOverview",
      },
      {
        menuItem: t("subHeadersData.engineeringAndInsurance.key3"),
        menuLink: "takafulGroup",
      },
      {
        menuItem: t("subHeadersData.engineeringAndInsurance.key4"),
        menuLink: "takafulGroupCredit",
      },
      {
        menuItem: t("subHeadersData.engineeringAndInsurance.key5"),
        menuLink: "takafulWafa",
      },
      {
        menuItem: t("subHeadersData.engineeringAndInsurance.key6"),
        menuLink: "takafulFAQ",
      },
    ],
  };

  let lifeInsuranceMenu = {
    url: "/individuals/products/life-insurance",
    heading: t("subHeadersData.lifeInsuranceMenu.lifeInsurance"),
    headIcon: HeartIcon,
    buttonText: t("subHeadersData.lifeInsuranceMenu.getQuote"),
    buttonLink: "lifeInsuranceGETQUOTE",
    links: [
      {
        menuItem: t("subHeadersData.lifeInsuranceMenu.Products"),
        menuLink: "lifeInsuranceProducts",
      },
      {
        menuItem: t("subHeadersData.lifeInsuranceMenu.Plans"),
        menuLink: "lifeInsurancePlans",
      }
    ]
  };

  useEffect(() => {
    if (
      pageLocation === "/individuals/products/motor" ||
      pageLocation === "/individuals/products/motor/mobility-ecosystem" ||
      pageLocation === "/individuals/products/motor/cross-border-own-damage" ||
      pageLocation === "/individuals/products/property-casualty/international-travel" ||
      pageLocation === "/individuals/products/property-casualty/medical-malpractice" ||
      pageLocation === "/individuals/products/health/visit-visa-insurance" ||
      pageLocation === "/individuals/products/health/my-family-insurance" ||
      pageLocation === "/individuals/products/property-casualty/home-insurance" ||
      pageLocation === "/individuals/products/Parent-health-insurance" ||
      pageLocation === "/individuals/products/health/hajj-insurance" ||
      pageLocation === "/individuals/products/health/umrah-insurance" ||
      pageLocation === "/individuals/products/domestic-worker-health-insurance" ||
      pageLocation === "/corporate/products/health/360-property_casualty-insurance" ||
      pageLocation === "/corporate/products/health/360-health-insurance" ||
      pageLocation === "/corporate/products/health/takaful" ||
      pageLocation === "/corporate/products/health/balsam" ||
      pageLocation === "/individuals/products/life-insurance" ||
      pageLocation === `/products/enterprise/${serviceURL}`
    ) {
      setMenuActivation(true);
    } else {
      setMenuActivation(false);
    }
  }, [pageLocation, i18n.language, currentLanguage, serviceURL]);

  useEffect(() => {
    if (pageLocation === "/individuals/products/motor") {
      setMenu(ExtraMotorInsuranceMenu);
    } else if (pageLocation === "/individuals/products/motor/cross-border-own-damage") {
      setMenu(ExtraCBODMenu);
    } else if (pageLocation === "/individuals/products/motor/mobility-ecosystem") {
      setMenu(ExtraMobiityEcosystemMenu);
    }  else if (pageLocation === "/individuals/products/property-casualty/international-travel") {
      setMenu(ExtrainternationaltravelMenu);
    } else if (pageLocation === "/individuals/products/property-casualty/medical-malpractice") {
      setMenu(medicalmalpractice);
    } else if (pageLocation === "/individuals/products/health/visit-visa-insurance") {
      setMenu(healthinsurancevisitvisa);
    } else if (pageLocation === "/individuals/products/health/my-family-insurance") {
      setMenu(healthinsurancemyfamily);
    } else if (pageLocation === "/individuals/products/property-casualty/home-insurance") {
      setMenu(homeInsurance);
    } else if (pageLocation === "/individuals/products/health/hajj-insurance") {
      setMenu(hajjInsuranceMenuItems);
    } else if (pageLocation === "/individuals/products/health/umrah-insurance") {
      setMenu(umrahInsuranceMenuItems);
    } else if (pageLocation === "/corporate/products/health/360-property_casualty-insurance") {
      setMenu(threeSixtyPAndCMenu);
    } else if (pageLocation === "/corporate/products/health/360-health-insurance") {
      setMenu(threeSixtyHealthInsurance);
    } else if (pageLocation === "/corporate/products/health/balsam") {
      setMenu(balsamHealthInsurance);
    } else if (pageLocation === "/corporate/products/health/takaful") {
      setMenu(takafulHealthInsurance);
    } else if (pageLocation === "/individuals/products/domestic-worker-health-insurance") {
      setMenu(domesticWorkerHealth);
    } else if (pageLocation === "/Parent-health-insurance") {
      setMenu(parentHealth);
    }
    else if (pageLocation === "/individuals/products/life-insurance") {
      setMenu(lifeInsuranceMenu);
    }
    else if (pageLocation === `/products/enterprise/${serviceURL}`) {
      setMenu(serviceInsurance);
    }else {
      setMenu({});
    }
  }, [pageLocation, i18n.language, currentLanguage, serviceURL]);

  const navigatePage = (link) => {
    // debugger
    if (link === "lifeInsuranceGETQUOTE") {
      executeScroll("lifeInsuranceGETQUOTE")
      return
    } 
    window.location.assign(link)
  }

  const trackEvent = (heading) => {
    window.lmSMTObj.track(heading);
  }
  return (
    <>
      {menuActivation && (
        <div className="col-12 insuranceNavBg navbar unset-position">
          <div className="page-header-health page-header-bottom w-100 mt-3">
            <div className="custom_container p-0">
              <div className="page-header-bottom-box">
                <div className="page-header-bottom-box__left">
                  <div className="icon-logo-box">
                    <img src={menu.headIcon} alt="Health icon" />
                  </div>
                  <span className="icon-logo-box__text">
                    <Link to={menu.url} className="icon-logo-box__text--dark linkTo">
                      {menu.heading}
                    </Link>
                  </span>
                </div>

                <div className="page-header-bottom-box__right">
                  <nav className="bottom-nav">
                    <ul className="bottom-nav-list mb-0">
                      {menu?.links?.map((item, index) => (
                        <li className="bottom-nav-list__item cursor-pointer">
                          <span onClick={() => {
                            executeScroll(item.menuLink)
                          }
                          }
                          >
                            {item.menuItem}
                          </span>
                        </li>
                      ))}
                      <li className="bottom-nav-list__item">
                        <div role={"button"} onClick={() => { window.print(); trackEvent(menu.heading + "PrintClicked") }}>
                          <PrintIcon />
                        </div>
                      </li>
                      <li className="bottom-nav-list__item">
                        <div role={"button"} onClick={handleShare}>

                          <ShareIcon />
                        </div>
                      </li>
                      {!_.isEmpty(menu.buttonLink) && (
                        <li className="bottom-nav-list__item">
                          <p
                            onClick={(e) => { navigatePage(menu.buttonLink); trackEvent(menu.heading + menu.buttonText + "Clicked") }}

                            className="btn-nav-buy-now btn-common-small cursor-pointer"
                          >
                            {menu.buttonText}
                          </p>
                        </li>
                      )}
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div >
      )}
    </>
  );
};

export default ExtraHeaderDesktop;
