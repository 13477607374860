const getDateListYears = (currentYear) => {
  return Array(100)
    .fill()
    .map((ele, i) => {
      const value = i + currentYear - 99;
      return {
        key: value,
        label: "" + value,
        value: "" + value,
      };
    })
    .sort((a, b) => b.key - a.key);
};
export const isValidSaudiPhoneNumber = (value) => {
  return /^(5)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/.test(value);
};
export const isValidSaudiPhone = (value) => {
  return /^(0\d{9}|5\d{8})$/.test(value);
};


export const isValidSaudiIDOrIQama = (idNumber) => {
  return /^[1,2]{1}[0-9]{9}$/i.test(idNumber);
};
export const isSaudi = (nationalId) => {
  return nationalId && nationalId[0] === "1";
};
export const getHijriYearOfBirthList = () => {
  const currentYear = new Date().getFullYear();
  const hijriYear = Math.round((currentYear - 623) * (33 / 32));
  return getDateListYears(hijriYear);
};
export const getGregYearOfBirthList = () => {
  const gregYear = new Date().getFullYear();
  return getDateListYears(gregYear);
};


export const yearOfBirth = (startYear) => {
  const currentYear = new Date().getFullYear(), years = [];
  startYear = startYear || 1980;
  while (startYear <= currentYear) {
    years.push(startYear++);
  }
  return years;
}

export const getMedicalPolicyNumber = (data = []) => {
  let policyNumber = ''
  data.forEach(item => {
    if (item.s_Lob === "MD") policyNumber = item.s_PolicyNo
  })

  return policyNumber
}

const deg2rad = (deg) => {
  return deg * (Math.PI / 180);
}

export const getDistanceFromLatLonInKm = (lat1, lon1, lat2, lon2) => {
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1); // deg2rad below
  const dLon = deg2rad(lon2 - lon1);
  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
    Math.cos(deg2rad(lat2)) *
    Math.sin(dLon / 2) *
    Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in km
  return Math.round(d * 100) / 100;
}

export const isValidEmail = (email) => {
  return /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i.test(
    email
  );
};

const isValidName = (name) => {
  return /^[a-zA-Z\s]+$/.test(name);
};


export const formatDateToOrdinal = (dateString, language) => {
  
  let dateParts;
  if (dateString.includes("/")) {
    dateParts = dateString.split("/");

    if (dateParts.length === 3) {
      dateString = `${dateParts[2]}-${dateParts[1].padStart(2, '0')}-${dateParts[0].padStart(2, '0')}`;
    }
  }

  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    return "Invalid Date";
  }

  const day = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();

  // Month names array with fallback for unsupported languages
  const monthNames = {
    en: [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ],
    ar: [
      "يناير", "فبراير", "مارس", "أبريل", "مايو", "يونيو",
      "يوليو", "أغسطس", "سبتمبر", "أكتوبر", "نوفمبر", "ديسمبر"
    ]
  };

  // Use a fallback language if the requested language is not found
  const selectedLanguage = monthNames[language] ? language : 'en';
  
  const getOrdinalEnglish = (n) => {
    if (n >= 10 && n <= 12) {
      return n;
    }
    const s = ["th", "st", "nd", "rd"];
    const v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
  };

  const getOrdinalArabic = (n) => `${n} ${n === 1 ? "الأول" : "من"}`;

  const ordinalDay = selectedLanguage === 'ar' ? getOrdinalArabic(day) : getOrdinalEnglish(day);
  const monthName = monthNames[selectedLanguage][month];

  return selectedLanguage === 'ar'
    ? `${ordinalDay} ${monthName} ${year}`
    : `${ordinalDay} ${monthName} ${year}`;
};
